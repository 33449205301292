import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const InputContainerStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: "0 0 calc((100% - 80px) / 7)",
  marginLeft: "20px",
  ".MuiFormHelperText-root": {
    minHeight: 0,
    height: 0,
    margin: 0,
  },
}));

export const SignStyled = styled(Typography)(() => ({
  marginLeft: "20px",
}));

export const CheckboxNameContainerStyled = styled(Box)(() => ({
  display: "flex",
  flex: "0 0 calc((100% - 80px) / 7 * 2)",
  alignItems: "center",
}));
