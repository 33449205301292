import { styled } from "@mui/material/styles";
import { Box, Breakpoint, Tab, Tabs } from "@mui/material";
import { palette } from "styles/theme/palette";
import { defaultTheme } from "styles/theme/theme";
import { StyledInput } from "components/UI/Input/styles";
import { Button } from "pages/Teams/AddEmployeeModal/styles";

export const TabsStyled = styled(Tabs)(() => ({
  ".MuiTabs-indicator": {
    display: "none",
  },
  marginBottom: "15px",
}));

export const TabStyled = styled(Tab)(() => ({
  ...defaultTheme.typography.h1,
  textTransform: "unset",
  color: palette.custom.grey.lightIco,
  padding: "0 20px 0 0",
  fontWeight: 700,
  "&.Mui-selected": {
    color: palette.custom.black.light,
  },
}));

export const FormRowStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  marginBottom: "24px",
  "& > div": {
    marginRight: "10px",
    maxWidth: "30%",
  },
  [theme.breakpoints.down("md" as Breakpoint)]: {
    flexWrap: "wrap",
    marginBottom: 0,
    "& > div": {
      maxWidth: "100%",
      marginRight: 0,
      width: "100%",
    },
  },
}));

export const AmountInputContainer = styled(Box)(() => ({
  display: "flex",
  position: "relative",
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  maxWidth: "30%",
  textAlign: "center",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    maxWidth: "100%",
    width: "100%",
  },
}));

export const CurrencyStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "absolute",
  ...defaultTheme.typography.h2,
  color: palette.custom.grey.lightIco,
  top: "17px",
  right: "10px",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    top: "12px",
  },
}));

export const CustomInputStyled = styled(StyledInput)(({ theme }) => ({
  input: {
    height: "35px",
    ...defaultTheme.typography.h2,
    color: palette.custom.grey.dark,
    paddingRight: "30px",
  },
  [theme.breakpoints.down("md" as Breakpoint)]: {
    marginBottom: "24px",
    width: "100%",
    input: {
      height: "25px",
      width: "100%",
      maxWidth: "100%",
    },
  },
}));

export const FakeButtonStyled = styled(Box)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  height: "60px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "30%",
  width: "100%",
  background: "rgba(0, 0, 0, 0.12)",
  color: "rgba(0, 0, 0, 0.26)",
  padding: "13px 50px",
  borderRadius: "10px",
  textAlign: "center",
}));
