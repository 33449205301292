import { type FC } from "react";
import {
  type Control,
  Controller,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import type { InvoiceCreateForm } from "interfaces/freelancer/invoice";
import { Checkbox } from "@mui/material";
import { StyledInput } from "pages/Teams/AddEmployeeModal/styles";
import { useTranslation } from "react-i18next";
import { LabelStyled } from "components/Freelancer/Projects/AddEdit/Expenses/styles";
import { getNumber } from "utils/freelancer/helpers/getNumber";
import { CheckboxNameContainerStyled, InputContainerStyled } from "./styles";

import {
  LabelsContainerStyled,
  RowStyled,
  ColumnContainerStyled,
  TitleStyled,
} from "../styles";
import { DownPaymentContainerStyled } from "../../styles";
import { useAdditionalBillableAmount } from "hooks/freelancer/useAdditionalBillableAmount";

interface AdditionalExpensesProps {
  parentIndex: number;
  control: Control<InvoiceCreateForm>;
  overrideLanguage?: string;
}

const AdditionalExpenses: FC<AdditionalExpensesProps> = ({
  parentIndex,
  control,
  overrideLanguage,
}) => {
  const { fields } = useFieldArray({
    control,
    name: `phases.${parentIndex}.additionalExpenses`,
  });
  const { t } = useTranslation();
  const labels = [
    "",
    "",
    "freelancer.page.invoices.add.additionalExpenses.billedServicePrice",
    "freelancer.page.invoices.add.additionalExpenses.invoicedAmount",
    "freelancer.page.invoices.add.additionalExpenses.remaining",
  ];
  const watchAdditionalExpenses = useWatch({
    control,
    name: `phases.${parentIndex}.additionalExpenses`,
  });
  const controlledAdditionalExpenses = fields.map((field, index) => {
    return {
      ...field,
      ...watchAdditionalExpenses?.[index],
    };
  });
  const { getAmount } = useAdditionalBillableAmount();

  return (
    <ColumnContainerStyled>
      <TitleStyled variant="h1">
        {t("freelancer.page.invoices.add.additionalExpenses.title", {
          lng: overrideLanguage,
        })}
      </TitleStyled>
      {controlledAdditionalExpenses?.length && (
        <LabelsContainerStyled>
          {labels.map((label) => (
            <LabelStyled key={`invoice-expenses-${label}`}>
              {t(label, {
                lng: overrideLanguage,
              })}
            </LabelStyled>
          ))}
        </LabelsContainerStyled>
      )}
      {controlledAdditionalExpenses?.map((additionalExpenseField, index) => (
        <RowStyled key={`invoice-expenses-${additionalExpenseField.id}`}>
          <CheckboxNameContainerStyled>
            <Controller
              name={`phases.${parentIndex}.additionalExpenses.${index}.name`}
              control={control}
              render={({ field }) => <StyledInput {...field} />}
            />
          </CheckboxNameContainerStyled>
          <InputContainerStyled>
            <StyledInput disabled value={additionalExpenseField.billedPrice} />
          </InputContainerStyled>
          <InputContainerStyled>
            <Controller
              name={`phases.${parentIndex}.additionalExpenses.${index}.invoicedAmount`}
              control={control}
              render={({ field }) => <StyledInput {...field} type="number" />}
            />
          </InputContainerStyled>
          <InputContainerStyled>
            <StyledInput disabled value={getAmount(additionalExpenseField)} />
          </InputContainerStyled>
        </RowStyled>
      ))}
      {controlledAdditionalExpenses?.length && (
        <DownPaymentContainerStyled>
          <Controller
            name={`phases.${parentIndex}.isDownPaymentExpenses`}
            control={control}
            render={({ field: CheckboxField }) => (
              <Checkbox checked={CheckboxField.value} {...CheckboxField} />
            )}
          />
          <LabelStyled>
            {t("downPayment", {
              lng: overrideLanguage,
            })}
          </LabelStyled>
        </DownPaymentContainerStyled>
      )}
    </ColumnContainerStyled>
  );
};

export default AdditionalExpenses;
