import { styled } from "@mui/material/styles";
import {
  Box,
  Radio,
  Typography,
  FormControl,
  FormControlLabel,
  Select,
  Breakpoint,
} from "@mui/material";
import { palette } from "styles/theme/palette";

interface FormHelperTextProps {
  isError: boolean | undefined;
}

interface ContainerProps {
  isSpaceBottom?: number;
}

export const Container = styled(FormControl)<ContainerProps>`
  margin-bottom: ${({ isSpaceBottom }) =>
    isSpaceBottom ? `${isSpaceBottom}px` : 0};
  width: 100%;
`;

export const Label = styled("span")`
  color: #838383;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
  margin-bottom: 10px;
`;

export const FormHelperText = styled("span")<FormHelperTextProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #d52727;
  margin-top: 8px;
  display: ${({ isError }) => (isError ? "block" : "none")};
  height: 12px;
`;

export const ContainerItem = styled(Box)`
  display: flex;
  align-items: center;
`;

export const Separator = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  background: `${palette?.custom.grey.light}`,
  height: "1px",
  position: "absolute",
  left: "0",
  top: "30px",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    top: "47px",
  },
}));

export const Image = styled("img")`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const LabelItem = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  width: fit-content;
`;

export const CheckboxLabel = styled(FormControlLabel)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const StyledRadio = styled(Radio)`
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0 10px 0 0;
`;

export const MaterialUISelect = styled(Select)`
  max-height: 300px;
  &&:hover {
    fieldset {
      border-color: ${({ theme }) => theme?.palette?.primary?.light};
    }
  }
`;
