import { type TableInvoice } from "interfaces/freelancer/invoice";
import { INVOICE_STATE } from "../../../utils/freelancer/constants";
import { TFunction } from "react-i18next";

interface HeadCell {
  id: keyof TableInvoice;
  label: string;
  numeric: boolean;
  width?: number;
}

export const headCells: HeadCell[] = [
  {
    id: "name",
    numeric: false,
    label: "freelancer.page.invoices.column.name",
  },
  {
    id: "state",
    numeric: false,
    label: "freelancer.page.invoices.column.state",
    width: 160,
  },
  {
    id: "projectName",
    numeric: false,
    label: "freelancer.page.invoices.column.project",
  },
  {
    id: "clientName",
    numeric: false,
    label: "freelancer.page.invoices.column.client",
  },
  {
    id: "startDate",
    numeric: false,
    label: "freelancer.page.invoices.column.startDate",
  },
  {
    id: "dueDate",
    numeric: true,
    label: "freelancer.page.invoices.column.dueDate",
  },
  {
    id: "invoiced",
    numeric: true,
    label: "freelancer.page.invoices.column.invoiced",
  },
];

export const mockTableInvoices: TableInvoice[] = [
  {
    name: "Invoice #1",
    id: "1",
    projectName: "Project 1",
    clientName: "Google",
    invoiced: 2000,
    startDateFormatted: "20.01.2023",
    startDate: new Date(2023, 1, 1),
    state: INVOICE_STATE.CREATED,
    dueDateFormatted: "20.03.2024",
    dueDate: new Date(2023, 1, 1),
    currency: "$",
    projectId: "1",
  },
  {
    name: "Invoice #2",
    id: "2",
    projectName: "Project 2",
    clientName: "Google",
    invoiced: 1000,
    startDateFormatted: "20.01.2023",
    startDate: new Date(2023, 1, 1),
    state: INVOICE_STATE.PAID,
    dueDateFormatted: "20.03.2024",
    dueDate: new Date(2023, 1, 1),
    currency: "$",
    projectId: "3",
  },
  {
    name: "Invoice #3",
    id: "3",
    projectName: "Project 3",
    clientName: "Google",
    invoiced: 1000,
    startDateFormatted: "20.01.2023",
    startDate: new Date(2023, 1, 1),
    state: INVOICE_STATE.EXPIRED,
    dueDateFormatted: "20.03.2024",
    dueDate: new Date(2023, 1, 1),
    currency: "$",
    projectId: "4",
  },
];

export const mockCanceledTableInvoices: TableInvoice[] = [
  {
    name: "Invoice #1",
    id: "1",
    projectName: "Project 1",
    clientName: "Google",
    invoiced: 2000,
    startDateFormatted: "20.01.2023",
    startDate: new Date(2023, 1, 1),
    state: INVOICE_STATE.CANCELED,
    dueDateFormatted: "20.03.2024",
    dueDate: new Date(2023, 1, 1),
    currency: "$",
    projectId: "1",
  },
  {
    name: "Invoice #2",
    id: "2",
    projectName: "Project 2",
    clientName: "Google",
    invoiced: 1000,
    startDateFormatted: "20.01.2023",
    startDate: new Date(2023, 1, 1),
    state: INVOICE_STATE.CANCELED,
    dueDateFormatted: "20.03.2024",
    dueDate: new Date(2023, 1, 1),
    currency: "$",
    projectId: "3",
  },
  {
    name: "Invoice #3",
    id: "3",
    projectName: "Project 3",
    clientName: "Google",
    invoiced: 1000,
    startDateFormatted: "20.01.2023",
    startDate: new Date(2023, 1, 1),
    state: INVOICE_STATE.CANCELED,
    dueDateFormatted: "20.03.2024",
    dueDate: new Date(2023, 1, 1),
    currency: "$",
    projectId: "4",
  },
];

export const getStateData = (t: TFunction, isActive: boolean) =>
  !isActive
    ? [
        {
          label: t("invoiceState.canceled"),
          value: `${INVOICE_STATE.CANCELED}`,
        },
      ]
    : [
        {
          label: t("invoiceState.created"),
          value: `${INVOICE_STATE.CREATED}`,
        },
        {
          label: t("invoiceState.paid"),
          value: `${INVOICE_STATE.PAID}`,
        },
        {
          label: t("invoiceState.expired"),
          value: `${INVOICE_STATE.EXPIRED}`,
        },
      ];
