import { type FC } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  NumbersContainerStyled,
  CardStyled,
  LabelStyled,
  TitleContainerStyled,
  ImportantNumbersStyled,
  DateRangeContainerStyled,
} from "./styles";
import { useImportantNumbers } from "hooks/freelancer/useImportantNumbers";
import {
  Input as DateInput,
  ContainerInput,
} from "components/UI/DatePicker/styles";

interface ImportantNumbersProps {
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (newDate: Date) => void;
  onEndDateChange: (newDate: Date) => void;
}
const ImportantNumbers: FC<ImportantNumbersProps> = ({
  startDate,
  endDate,
  onEndDateChange,
  onStartDateChange,
}) => {
  const { t } = useTranslation();
  const numbersData = useImportantNumbers(startDate, endDate);

  return (
    <ImportantNumbersStyled>
      <TitleContainerStyled>
        <Typography variant="h1">
          {t("freelancer.page.dashboard.numbers.title")}
        </Typography>
        <DateRangeContainerStyled>
          <ContainerInput>
            <DateInput selected={startDate} onChange={onStartDateChange} />
          </ContainerInput>
          <ContainerInput>
            <DateInput selected={endDate} onChange={onEndDateChange} />
          </ContainerInput>
        </DateRangeContainerStyled>
      </TitleContainerStyled>
      <NumbersContainerStyled>
        {numbersData.map(({ label, color, value }) => (
          <CardStyled>
            <Typography variant="h2" color={color}>
              {value}
            </Typography>
            <LabelStyled>{t(label)}</LabelStyled>
          </CardStyled>
        ))}
      </NumbersContainerStyled>
    </ImportantNumbersStyled>
  );
};

export default ImportantNumbers;
