import { type FC } from "react";
import { useTranslation } from "react-i18next";
import {
  CollapseHeaderStyled,
  CollapseStyled,
  ColumnStyled,
  FormFieldsContainerStyled,
  SelectWithMargin,
} from "./styles";
import { Arrow } from "pages/ProductsAndServices/ProductsTable/styles";
import { type Control, Controller, type FieldErrors } from "react-hook-form";
import Input from "components/UI/Input";
import { useDrawer } from "hooks/useDrawer";
import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";
import { StyledInput } from "pages/Teams/AddEmployeeModal/styles";
import { type ProjectCreateForm } from "interfaces/freelancer/project";
import { useGetEmployeesQuery } from "store/employees/employeesServices";
import { EMPLOYEE_TYPE } from "utils/freelancer/constants";
import { type Option } from "types/select";
import LimitedAccessTooltip from "components/Freelancer/LimitedAccessTooltip";
import { useTrialUser } from "hooks/useTrialUser";

interface ExpensesProps {
  control: Control<ProjectCreateForm>;
  errors: FieldErrors<ProjectCreateForm>;
  onAddNewClientClick: () => void;
}
const BaseProjectInfo: FC<ExpensesProps> = ({
  control,
  errors,
  onAddNewClientClick,
}) => {
  const { t } = useTranslation();
  const { currency } = useFreelancerDefaults();
  const { isOpen, toggle } = useDrawer(true);
  const { project_id, pipeline_id } = useFreelancerDefaults();
  const { data } = useGetEmployeesQuery({ project_id, pipeline_id });
  const clients =
    data?.filter((employee) => employee.type === EMPLOYEE_TYPE.CONTRACTOR) ??
    [];
  const clientOptions: Option[] =
    clients?.map(({ name, id }) => ({ value: id, label: name })) ?? [];

  const addNewClientButton: { onClick: () => void; label: string } = {
    label: t("freelancer.page.addEditProject.client.addNew"),
    onClick: onAddNewClientClick,
  };
  const { limitedAccess } = useTrialUser();

  return (
    <CollapseStyled collapsedSize={50} in={isOpen}>
      <CollapseHeaderStyled onClick={toggle}>
        <Arrow $isOpen={isOpen} />
      </CollapseHeaderStyled>
      <FormFieldsContainerStyled>
        <ColumnStyled>
          <Controller
            name="projectName"
            control={control}
            rules={{ required: t("fieldIsRequired") }}
            render={({ field }) => {
              if (limitedAccess) {
                return (
                  <LimitedAccessTooltip>
                    <Input
                      {...field}
                      disabled
                      required
                      label={`${t(
                        "freelancer.page.addEditProject.name.label"
                      )} *`}
                      placeholder={t(
                        "freelancer.page.addEditProject.name.placeholder"
                      )}
                    />
                  </LimitedAccessTooltip>
                );
              }
              return (
                <Input
                  {...field}
                  required
                  label={`${t("freelancer.page.addEditProject.name.label")} *`}
                  error={!!errors?.projectName?.message}
                  placeholder={t(
                    "freelancer.page.addEditProject.name.placeholder"
                  )}
                  helperText={errors?.projectName?.message}
                />
              );
            }}
          />
        </ColumnStyled>
        <ColumnStyled>
          <Controller
            name="clientId"
            control={control}
            rules={{ required: t("fieldIsRequired") }}
            render={({ field }) => {
              if (limitedAccess) {
                return (
                  <LimitedAccessTooltip>
                    <SelectWithMargin
                      {...field}
                      disabled
                      required
                      data={clientOptions}
                      label={`${t(
                        "freelancer.page.addEditProject.client.label"
                      )} *`}
                      placeholder={t(
                        "freelancer.page.addEditProject.client.placeholder"
                      )}
                    />
                  </LimitedAccessTooltip>
                );
              }
              return (
                <SelectWithMargin
                  {...field}
                  required
                  data={clientOptions}
                  label={`${t(
                    "freelancer.page.addEditProject.client.label"
                  )} *`}
                  placeholder={t(
                    "freelancer.page.addEditProject.client.placeholder"
                  )}
                  button={addNewClientButton}
                />
              );
            }}
          />
        </ColumnStyled>
        <ColumnStyled>
          <StyledInput
            fullWidth={false}
            disabled
            label={t("currency")}
            value={currency}
          />
        </ColumnStyled>
      </FormFieldsContainerStyled>
    </CollapseStyled>
  );
};

export default BaseProjectInfo;
