import { type FC, useCallback, useState } from "react";
import { TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { type Order } from "types/global";
import { getComparator, stableSort } from "utils/teams/helpers";
import dayjs from "dayjs";
import { palette } from "styles/theme/palette";
import EditDeleteTooltip from "components/Freelancer/EditDeleteTooltip";
import {
  TRANSACTION_TYPE,
  type UserTransaction,
} from "store/transactions/transactionsTypes";
import { useTranslation } from "react-i18next";
import EditModal from "./EditModal";
import { useDrawer } from "hooks/useDrawer";
import { useGetTransactionsQuery } from "store/transactions/transactionsServices";
import ConfirmationModal from "../../../../ConfirmationModal";
import { useDeleteInvoiceMutation } from "store/invoices/invoicesServices";
import { useDeleteUserExpenseMutation } from "store/userExpense/userExpenseServices";

interface TransactionsTableBodyProps {
  order: Order;
  orderBy: any;
  transactions: UserTransaction[] | [];
}

const TransactionsTableBody: FC<TransactionsTableBodyProps> = ({
  transactions,
  orderBy,
  order,
}) => {
  const { refetch } = useGetTransactionsQuery(null);
  const { t } = useTranslation();
  const {
    open: openEditModal,
    isOpen: isEditModalOpen,
    close: closeEditModal,
  } = useDrawer();
  const {
    open: openConfirmationModal,
    isOpen: isConfirmationModalOpen,
    close: closeConfirmationModal,
  } = useDrawer();
  const [selectedItem, setSelectedItem] = useState<UserTransaction | null>(
    null
  );
  const [selectedItemToDelete, setSelectedItemToDelete] =
    useState<UserTransaction | null>(null);
  const [deleteInvoice] = useDeleteInvoiceMutation();
  const [deleteUserExpense] = useDeleteUserExpenseMutation();

  const editTransactionItem = useCallback((item: UserTransaction) => {
    setSelectedItem(item);
    openEditModal();
  }, []);

  const handleCloseEditModal = useCallback(() => {
    setSelectedItem(null);
    closeEditModal();
  }, []);

  const handleCloseConfirmationModal = useCallback(() => {
    setSelectedItemToDelete(null);
    closeConfirmationModal();
  }, []);

  const handleOnDelete = useCallback((item: UserTransaction) => {
    setSelectedItemToDelete(item);
    openConfirmationModal();
  }, []);

  const handleDelete = useCallback(async () => {
    if (selectedItemToDelete) {
      if (selectedItemToDelete.type === TRANSACTION_TYPE.INVOICE) {
        try {
          await deleteInvoice({
            invoice_id: selectedItemToDelete.id,
          });
          refetch();
          closeConfirmationModal();
        } catch (e) {
          console.error("Error on invoice delete, ", e);
        }
      }
      if (selectedItemToDelete.type === TRANSACTION_TYPE.USER_EXPENSE) {
        try {
          await deleteUserExpense({
            id: selectedItemToDelete.id,
          });
          refetch();
          closeConfirmationModal();
        } catch (e) {
          console.error("Error on user expense delete, ", e);
        }
      }
    }
  }, [selectedItemToDelete]);
  return (
    <TableBody>
      {!!transactions &&
        stableSort(transactions, getComparator(order, orderBy)).map((item) => (
          <TableRow hover role="checkbox" tabIndex={-1} key={item.id}>
            <TableCell>
              {item.type === TRANSACTION_TYPE.INVOICE
                ? t("invoice")
                : t("expense")}
            </TableCell>
            <TableCell>{dayjs(item.created_at).format("DD.MM.YYYY")}</TableCell>
            <TableCell>
              <Typography
                variant="body2"
                color={
                  item.type === TRANSACTION_TYPE.USER_EXPENSE
                    ? palette.custom.red.light
                    : palette.custom.green.chartGreen
                }
              >
                {item.type === TRANSACTION_TYPE.USER_EXPENSE
                  ? `-${item.amount}`
                  : item.amount}
              </Typography>
            </TableCell>
            <EditDeleteTooltip
              onEdit={() => editTransactionItem(item)}
              onDelete={() => handleOnDelete(item)}
            />
          </TableRow>
        ))}
      <EditModal
        item={selectedItem}
        isOpen={isEditModalOpen}
        onClose={handleCloseEditModal}
        refetch={refetch}
      />
      <ConfirmationModal
        deleteHandler={handleDelete}
        onClose={handleCloseConfirmationModal}
        isOpen={isConfirmationModalOpen}
        title={t("deleteConfirmation")}
      />
    </TableBody>
  );
};

export default TransactionsTableBody;
