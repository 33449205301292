import { type FC, useCallback } from "react";
import {
  type Control,
  Controller,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import type { InvoiceCreateForm } from "interfaces/freelancer/invoice";
import { Checkbox, Typography } from "@mui/material";
import { StyledInput } from "pages/Teams/AddEmployeeModal/styles";
import { useTranslation } from "react-i18next";
import { LabelStyled } from "components/Freelancer/Projects/AddEdit/Expenses/styles";
import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";
import { getNumber } from "utils/freelancer/helpers/getNumber";
import {
  LabelsContainerStyled,
  ColumnContainerStyled,
  RowStyled,
} from "../styles";

import {
  InputContainerStyled,
  SignStyled,
  CheckboxNameContainerStyled,
} from "./styles";
import { DownPaymentContainerStyled } from "../../styles";
import { useBillableHours } from "hooks/freelancer/useBillableHours";

interface EmployeesProps {
  parentIndex: number;
  control: Control<InvoiceCreateForm>;
  overrideLanguage?: string;
}

const MULTIPLY_SIGN = "x";
const EQUAL_SIGN = "=";
const Employees: FC<EmployeesProps> = ({
  parentIndex,
  control,
  overrideLanguage,
}) => {
  const { fields } = useFieldArray({
    control,
    name: `phases.${parentIndex}.employees`,
  });
  const { getBillableHours } = useBillableHours();

  const { employeeName, userId } = useFreelancerDefaults();
  const getEmployeeName = useCallback(
    (name: string) => (userId === name ? employeeName : name),
    [userId, employeeName]
  );
  const { t } = useTranslation();
  const labels = [
    "",
    "",
    "freelancer.page.invoices.add.employees.hoursWorked",
    "freelancer.page.invoices.add.employees.rate",
    "freelancer.page.invoices.add.employees.hoursToBePaid",
    "freelancer.page.invoices.add.employees.amountToBePaid",
    "freelancer.page.invoices.add.employees.notBilledHours",
  ];
  const watchEmployees = useWatch({
    control,
    name: `phases.${parentIndex}.employees`,
  });
  const controlledEmployees = fields.map((field, index) => {
    return {
      ...field,
      ...watchEmployees?.[index],
    };
  });

  return (
    <ColumnContainerStyled>
      {controlledEmployees?.length && (
        <LabelsContainerStyled>
          {labels.map((label) => (
            <LabelStyled key={`invoice-employees-${label}`}>
              {t(label, {
                lng: overrideLanguage,
              })}
            </LabelStyled>
          ))}
        </LabelsContainerStyled>
      )}
      {controlledEmployees?.map((employeeField, index) => (
        <RowStyled key={`invoice-employees-${employeeField.id}`}>
          <CheckboxNameContainerStyled>
            <Typography>{getEmployeeName(employeeField.name)}</Typography>
          </CheckboxNameContainerStyled>
          <InputContainerStyled>
            <StyledInput disabled value={employeeField.totalHours} />
          </InputContainerStyled>
          <InputContainerStyled>
            <StyledInput disabled value={employeeField.rate} />
            <SignStyled>{MULTIPLY_SIGN}</SignStyled>
          </InputContainerStyled>
          <InputContainerStyled>
            <Controller
              name={`phases.${parentIndex}.employees.${index}.hoursToBePaid`}
              control={control}
              render={({ field }) => <StyledInput {...field} />}
            />
            <SignStyled>{EQUAL_SIGN}</SignStyled>
          </InputContainerStyled>
          <InputContainerStyled>
            <StyledInput
              disabled
              value={getNumber(
                employeeField.rate * employeeField.hoursToBePaid
              ).toFixed(1)}
            />
          </InputContainerStyled>
          <InputContainerStyled>
            <StyledInput disabled value={getBillableHours(employeeField)} />
          </InputContainerStyled>
        </RowStyled>
      ))}
      {controlledEmployees?.length && (
        <DownPaymentContainerStyled>
          <Controller
            name={`phases.${parentIndex}.isDownPaymentEmployees`}
            control={control}
            render={({ field: CheckboxField }) => (
              <Checkbox checked={CheckboxField.value} {...CheckboxField} />
            )}
          />
          <LabelStyled>
            {t("downPayment", {
              lng: overrideLanguage,
            })}
          </LabelStyled>
        </DownPaymentContainerStyled>
      )}
    </ColumnContainerStyled>
  );
};

export default Employees;
