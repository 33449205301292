import React, { useCallback } from "react";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { useGoogleLogin } from "@react-oauth/google";
import { useLoginGoogleMutation } from "store/auth/authService";
import { Button } from "@mui/material";
// Global Components
import LoadingButton from "components/UI/Buttons/LoadingButton";
// Interfaces
import { ILogin } from "interfaces/auth";
// Icons
import { GoogleIcon } from "assets/icons";
// Styles
import { GoogleButton, TextStyled, TextContainerStyled } from "./styles";
// Translation
import { useTranslation } from "react-i18next";
import { authRoutePath } from "routes/routePath";
import { useNavigate } from "react-router-dom";

interface LoginButtonsProps {
  onSubmit: SubmitHandler<ILogin>;
  formState: UseFormReturn<ILogin>;
  isLoading: boolean;
}

const LoginButtons: React.FC<LoginButtonsProps> = ({
  onSubmit,
  formState,
  isLoading,
}) => {
  const [createLogin] = useLoginGoogleMutation();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      createLogin(codeResponse?.access_token as string);
    },
    flow: "implicit",
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToRegister = useCallback(() => {
    navigate(authRoutePath.REGISTRATION);
  }, [navigate]);

  return (
    <>
      <LoadingButton
        onClick={formState.handleSubmit(onSubmit)}
        isDisabled={!formState.formState.isValid}
        title={t("pages.Login.LoginButtons.Увійти")}
        loading={isLoading}
      />
      <GoogleButton
        onClick={() => login()}
        startIcon={<GoogleIcon />}
        variant="textIcon"
        fullWidth
      >
        {t("pages.Login.LoginButtons.LoginWithGoogle")}
      </GoogleButton>
      <TextContainerStyled>
        <TextStyled variant="h5">
          {t("pages.Login.CreateAccountLink.Ще не зареєстровані?")}
        </TextStyled>
        <Button
          size="small"
          variant="textIcon"
          className="toolbar-btns create-btn"
          onClick={navigateToRegister}
        >
          {t("pages.Login.CreateAccountLink.Створити аккаунт")}
        </Button>
      </TextContainerStyled>
    </>
  );
};

export default LoginButtons;
