import {
  type FC,
  type MouseEvent,
  useCallback,
  useState,
  Fragment,
} from "react";
import { Button, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useDeleteEmployeeMutation } from "store/tutors/tutorsServices";
import ConfirmationModal from "components/ConfirmationModal";
import { StyledTableContainer, TableRowStyled } from "./styles";
import { useTranslation } from "react-i18next";
import { type Order } from "types/global";
import { getComparator, stableSort } from "utils/teams/helpers";
import { useScreenSize } from "hooks/useScreenSize";
import TeamsTableHead from "./TeamsTableHead";
import { getTableData } from "utils/freelancer/teams/helpers";
import { useDrawer } from "hooks/useDrawer";
import { type Employee } from "store/employees/employeesTypes";
import { NoDataContainerStyled } from "../../styles";
import AddEditMemberModal from "../AddEditMemberModal";
import { useSelectTableData } from "hooks/useSelectTableData";
import { EMPLOYEE_TYPE } from "utils/freelancer/constants";
import EditDeleteTooltip from "components/Freelancer/EditDeleteTooltip";
import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";
import { PlusIcon } from "assets/icons";
import { useTrialUser } from "hooks/useTrialUser";

export interface TeamTableData {
  name: string;
}

interface TeamTableComponentProps {
  employees?: Employee[];
  refetch: () => void;
  openAddMemberModal: (type: EMPLOYEE_TYPE) => void;
  employeeType: EMPLOYEE_TYPE;
}

const TeamTable: FC<TeamTableComponentProps> = ({
  employees,
  refetch,
  openAddMemberModal,
  employeeType,
}) => {
  const [deleteEmployeeItem, setDeleteEmployeeItem] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof TeamTableData>("name");
  const [editing, setEditing] = useState<Employee | null>(null);
  const { isMobile } = useScreenSize();
  const { employeeName } = useFreelancerDefaults();
  const [deleteEmployee] = useDeleteEmployeeMutation();
  const { limitedAccess } = useTrialUser();
  const {
    isOpen: isDeleteModalOpen,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useDrawer();
  const deleteEmployeeHandle = async () => {
    await deleteEmployee(deleteEmployeeItem);
    closeDeleteModal();
    refetch();
  };
  const { t } = useTranslation();
  const tableData = getTableData(employees);
  const { isSelected } = useSelectTableData(tableData);
  const handleRequestSort = (
    event: MouseEvent,
    property: keyof TeamTableData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleCloseModal = useCallback(() => {
    setEditing(null);
    setOpenEditModal((prev) => !prev);
    refetch();
  }, [refetch, setEditing, setOpenEditModal]);
  const cellWidth = isMobile ? 200 : 400;
  const handleOpenAddMemberModal = useCallback(
    () => openAddMemberModal?.(employeeType),
    [employeeType, openAddMemberModal]
  );
  const noDataButtonLabel =
    employeeType === EMPLOYEE_TYPE.PARTNER
      ? "freelancer.page.members.addFirst.teammateLabel"
      : "freelancer.page.members.addFirst.clientLabel";
  const openEditMember = useCallback(
    (row) => {
      if (!limitedAccess) {
        setEditing(row);
        setOpenEditModal((prev) => !prev);
      }
    },
    [limitedAccess]
  );
  const openDeleteMember = useCallback((row) => {
    setDeleteEmployeeItem(`${row?.id}`);
    openDeleteModal();
  }, []);

  return (
    <StyledTableContainer>
      <Table aria-labelledby="tableTitle">
        <TeamsTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        {!!employees?.length && (
          <TableBody>
            {tableData?.map(({ rows }, index) => (
              <TableRowStyled
                key={`employee-${index}`}
                withBorder={index !== 0}
              >
                <TableCell style={{ padding: 0 }} colSpan={12}>
                  <Table>
                    <TeamsTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      collapse
                    />
                    <TableBody>
                      {!!rows &&
                        stableSort<Employee>(
                          rows,
                          getComparator(order, orderBy)
                        ).map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              <TableCell
                                width={cellWidth}
                                scope="row"
                                id={labelId}
                                onClick={() => openEditMember(row)}
                              >
                                {row.name}
                              </TableCell>
                              {!isMobile && (
                                <>
                                  <TableCell
                                    width={cellWidth}
                                    onClick={() => openEditMember(row)}
                                  />
                                  <TableCell
                                    width={cellWidth}
                                    onClick={() => openEditMember(row)}
                                  />
                                  <TableCell
                                    width={cellWidth}
                                    onClick={() => openEditMember(row)}
                                  />
                                  <TableCell
                                    width={cellWidth}
                                    onClick={() => openEditMember(row)}
                                  />
                                </>
                              )}
                              <TableCell
                                width={cellWidth}
                                onClick={() => openEditMember(row)}
                              />
                              <EditDeleteTooltip
                                showDelete={row.name !== employeeName}
                                onDelete={() => openDeleteMember(row)}
                                onEdit={() => openEditMember(row)}
                              />
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRowStyled>
            ))}
          </TableBody>
        )}
      </Table>
      {!employees?.length && (
        <NoDataContainerStyled>
          <Button
            onClick={handleOpenAddMemberModal}
            variant="textIcon"
            className="toolbar-btns create-btn"
            startIcon={<PlusIcon />}
          >
            {t(noDataButtonLabel)}
          </Button>
        </NoDataContainerStyled>
      )}
      <AddEditMemberModal
        isOpen={openEditModal}
        employee={editing}
        onClose={handleCloseModal}
      />
      <ConfirmationModal
        deleteHandler={deleteEmployeeHandle}
        onClose={closeDeleteModal}
        isOpen={isDeleteModalOpen}
        title={t("Видалити") + "?"}
      />
    </StyledTableContainer>
  );
};

export default TeamTable;
