import { styled } from "@mui/material/styles";
import { ContainerForm } from "pages/Teams/AddEmployeeModal/styles";
import { Box, type Breakpoint } from "@mui/material";
import { palette } from "styles/theme/palette";

export const ContainerFormStyled = styled(ContainerForm)(({ theme }) => ({
  maxWidth: "800px",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    maxWidth: "100vw",
    padding: 0,
  },
}));

export const RowStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "withBorder",
})<{ withBorder?: boolean }>(({ withBorder, theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  justifyContent: "space-between",
  gap: "10px",
  paddingTop: "20px",
  "& > .MuiFormControl-root": {
    maxWidth: "calc(50% - 10px)",
  },
  borderBottom: withBorder
    ? `1px solid ${palette.custom.green.chartGreen}`
    : "none",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    gap: 0,
    "& > .MuiFormControl-root": {
      maxWidth: "100%",
    },
    justifyContent: "center",
  },
}));
