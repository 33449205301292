import type { ProjectPhaseCreate } from "interfaces/freelancer/project";
import type {
  InvoiceEmployeeExpense,
  InvoiceExpense,
  InvoicePhase,
} from "interfaces/freelancer/invoice";
import { PROJECT_TYPE } from "../constants";

export const getInitialInvoicePhases = (
  initialPhases: ProjectPhaseCreate[],
  projectType?: PROJECT_TYPE
): InvoicePhase[] =>
  initialPhases.map(({ name, expenses, description }) => {
    const employees: InvoiceEmployeeExpense[] =
      projectType === PROJECT_TYPE.FIX
        ? []
        : expenses.employees.map(({ name, amount, hours, id, employeeId }) => ({
            name,
            totalHours: hours,
            hoursToBePaid: 0,
            rate: amount,
            id: id ?? "",
            itemId: employeeId,
          }));
    const additionalExpenses: InvoiceExpense[] =
      expenses.additionalExpenses.map(({ name, amount, id }) => ({
        name,
        id: id ?? "",
        invoicedAmount: 0,
        billedPrice: amount,
      }));
    const services: InvoiceExpense[] =
      projectType === PROJECT_TYPE.HOURLY
        ? []
        : expenses.services.map(({ name, price, id, serviceId }) => ({
            name,
            invoicedAmount: 0,
            billedPrice: price,
            id: id ?? "",
            itemId: serviceId,
          }));
    return {
      name,
      description,
      isDownPaymentService: false,
      isDownPaymentExpenses: false,
      isDownPaymentEmployees: false,
      employees,
      additionalExpenses,
      services,
    };
  });
