import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";
import { palette } from "styles/theme/palette";
import { useScreenSize } from "hooks/useScreenSize";

// @ts-ignore
const CustomLabel = (props) => {
  const { x, y, width, height, value, color } = props;
  const { currency } = useFreelancerDefaults();
  const { isMobile } = useScreenSize();
  const size = isMobile ? 20 : 31;

  if (!value) return null;

  return (
    <g>
      <text
        x={x + width + 10}
        y={y + height / 2}
        fill={color ?? palette.custom.grey.main}
        fontSize={size}
        fontWeight={400}
        textAnchor="right"
        dominantBaseline="middle"
      >
        {`${currency}${+value}`}
      </text>
    </g>
  );
};

export default CustomLabel;
