import { getNumber } from "utils/freelancer/helpers/getNumber";
import { useSearchParams } from "react-router-dom";
import { INVOICE_ITEM_KIND, URL_PARAMS } from "utils/freelancer/constants";
import { useGetPaidInvoiceItemsByProjectQuery } from "store/invoices/invoicesServices";
import { useCallback } from "react";

export const useAdditionalBillableAmount = () => {
  const [searchParams] = useSearchParams();
  const projectIdFromUrl = searchParams.get(URL_PARAMS.PROJECT_ID);
  const { data: paidExpenses = [] } = useGetPaidInvoiceItemsByProjectQuery({
    project_id: `${projectIdFromUrl}`,
  });

  const getAmount = useCallback(
    (additionalExpenseField): string => {
      if (!paidExpenses?.length) return "0.0";
      const billedAmount = paidExpenses
        .filter(
          ({ id, kind }) =>
            additionalExpenseField.id === id &&
            kind === INVOICE_ITEM_KIND.ADDITIONAL
        )
        .reduce((partialSum, { total }) => partialSum + total, 0);
      return (
        getNumber(additionalExpenseField.billedPrice) - billedAmount
      ).toFixed(1);
    },
    [paidExpenses]
  );

  return { paidExpensesByProject: paidExpenses, getAmount };
};
