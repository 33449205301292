import { FC } from "react";
import Modal from "components/UI/Modal";
// Store
import { useGetSubscriptionsQuery } from "store/payments/paymentsServicers";
import { useCancelSubscribeMutation } from "store/auth/authService";
// Icons
import { InfoSubscribe } from "assets/icons";
// Styles
import {
  Container,
  Title,
  SubTitle,
  ContainerButton,
  Button,
  Wrapper,
  Text,
} from "./styles";
import { useTranslation } from "react-i18next";

interface CancelSubscribeModalProps {
  open: boolean;
  onClose: () => void;
}

const CancelSubscribeModal: FC<CancelSubscribeModalProps> = ({
  onClose,
  open,
}) => {
  const { data } = useGetSubscriptionsQuery({});
  const { t } = useTranslation();
  const [cancelSubscribe] = useCancelSubscribeMutation();

  const onCancelHandler = async () => {
    await cancelSubscribe({
      subscription_id: data?.[0]?.subscription_id as string,
    }).unwrap();

    onClose();
  };

  return (
    <Modal maxWidth={500} onClose={onClose} isOpen={open}>
      <Container>
        <Title variant="h2">{t("Йой, припинити підписку?")}</Title>

        <SubTitle variant="regularText">
          {t(
            "Ваша підписка на “Діскваері” буде скасована наприкінці вашого розрахункового періоду."
          )}
        </SubTitle>

        <ContainerButton>
          <Button
            variant="contained"
            onClick={onCancelHandler}
            disabled={!data?.length}
          >
            {t("Підтвердити")}
          </Button>

          <Button variant="light" onClick={onClose}>
            {t("Скасувати")}
          </Button>
        </ContainerButton>

        <Wrapper>
          <InfoSubscribe />

          <Text>
            {t(
              "Нагадуємо, що ви можете повернутися до нас у будь який час, знову натиснувши “Підписатись”!"
            )}
          </Text>
        </Wrapper>
      </Container>
    </Modal>
  );
};

export default CancelSubscribeModal;
