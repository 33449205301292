export enum PROJECT_TYPE {
  HOURLY = "hourly",
  FIX = "fixed",
}
export enum ALERT_SEVERITY {
  ERROR = "error",
  SUCCESS = "success",
}

export enum URL_PARAMS {
  TYPE = "type",
  PROJECT_ID = "project_id",
  INVOICE_ID = "invoice_id",
}

export enum EMPLOYEE_TYPE {
  PARTNER = "partner",
  CONTRACTOR = "contractor",
  ADMIN = "administration",
}

export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum PRODUCT_GROUP {
  SERVICE = "service",
  PRODUCT = "product",
}

export enum PRODUCT_GROUP_VISIBILITY {
  PUBLIC = "public",
  PRIVATE = "private",
}

export enum PRODUCT_GROUP_STATUS {
  ACTIVE = "active",
  ARCHIVE = "archive",
}

export enum EXPENSE_STATUS {
  DRAFT = "draft",
  SUBMITTED = "submitted",
  APPROVED = "approved",
  REJECTED = "rejected",
  PAID = "paid",
}

export enum INVOICE_STATE {
  CREATED = "created",
  SENT = "sent",
  CANCELED = "cancelled",
  DECLINED = "declined",
  EXPIRED = "expired",
  PAID = "paid",
}

export enum INVOICE_ITEM_KIND {
  SERVICE = "service",
  TEAM = "team",
  ADDITIONAL = "additional",
}

export enum PROJECT_STATUS {
  FIRST_MEETING = "first_meeting",
  IN_WORK = "in_work",
  PAYMENT_PENDING = "payment_pending",
  FINISHED = "finished",
}

export const DEFAULT_ALERT_HIDE_DURATION = 2000;
