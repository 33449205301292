import type {
  ProductExpenseUpdate,
  ProjectPhaseCreate,
} from "interfaces/freelancer/project";
import { ALERT_SEVERITY } from "../constants";
import { getPhaseExpenses } from "./getPhaseExpenses";

export const updateExpenses = async (
  phasesToUpdate: ProjectPhaseCreate[],
  currency: string,
  updatePhaseExpenseById: (props: {
    id: string;
    expense: ProductExpenseUpdate;
  }) => void,
  createPhaseExpense: (
    props: [
      {
        product_id: string;
        expenses: ProductExpenseUpdate[];
      }
    ]
  ) => void,
  setAlertSeverity: (text: ALERT_SEVERITY) => void,
  initialPhases?: ProjectPhaseCreate[]
): Promise<void> => {
  const existingExpenses: string[] = [];
  initialPhases?.forEach(({ expenses }) => {
    const {
      additionalExpenses: existingAdditional,
      employees: existingEmployees,
      services: existingServices,
    } = expenses;
    phasesToUpdate.forEach((phaseToUpdate) => {
      const { additionalExpenses, employees, services } =
        phaseToUpdate.expenses;
      if (additionalExpenses.length) {
        additionalExpenses.forEach((expense) => {
          const [existingExpense] = existingAdditional.filter(
            ({ id }) => expense.id === id
          );
          if (existingExpense?.id) {
            existingExpenses.push(existingExpense.id);
          }
        });
      }
      if (employees.length) {
        employees.forEach((expense) => {
          const [existingExpense] = existingEmployees.filter(
            ({ id }) => expense.id === id
          );
          if (existingExpense?.id) {
            existingExpenses.push(existingExpense.id);
          }
        });
      }
      if (services.length) {
        services.forEach((expense) => {
          const [existingExpense] = existingServices.filter(
            ({ id }) => expense.id === id
          );
          if (existingExpense?.id) {
            existingExpenses.push(existingExpense.id);
          }
        });
      }
    });
  });
  try {
    await Promise.all(
      phasesToUpdate.map((phaseToUpdate) => {
        if (
          phaseToUpdate.expenses.additionalExpenses.length ||
          phaseToUpdate.expenses.employees.length ||
          phaseToUpdate.expenses.services.length
        ) {
          const mappedExpensesToUpdate = getPhaseExpenses(
            phaseToUpdate,
            currency
          );
          return mappedExpensesToUpdate.map(
            ({ extras, name, value, amount, id, group }) =>
              id &&
              existingExpenses.some(
                (existingExpenseId) => existingExpenseId === id
              )
                ? updatePhaseExpenseById({
                    expense: { name, amount, extras, value: value ?? "" },
                    id,
                  })
                : createPhaseExpense([
                    {
                      product_id: phaseToUpdate.id ?? "",
                      expenses: [
                        { name, amount, extras, value: value ?? "", group },
                      ],
                    },
                  ])
          );
        }
        return null;
      })
    );
  } catch (e) {
    setAlertSeverity(ALERT_SEVERITY.ERROR);
    console.error("Error on remove phase, ", e);
  }
};
