/* eslint-disable max-len */
import React, { FC, useMemo } from "react";
import { Control, Controller, FieldErrors, useWatch } from "react-hook-form";
import { CancelIcon } from "assets/icons";
import Input from "components/UI/Input";
import MuiCreatableSelect from "components/UI/MuiCreatableSelect";
// Interface
import { IMaterialCreate, IProductCreate } from "interfaces/product";
// Styles
import { Container, DeleteBtn } from "./styles";
// Translation
import { useTranslation } from "react-i18next";
interface MaterialsFormRowProps {
  updateItem: (id?: string) => void;
  deleteItem: (id: number, material_id?: string) => void;
  errors: FieldErrors<{ materialProducts: IProductCreate[] }>;
  control: Control<{ materialProducts: IProductCreate[] }>;
  indexProduct: number;
  indexMaterial: number;
  material: IMaterialCreate;
}

const units = [
  { value: "m", label: "M" },
  { value: "m2", label: "M²" },
  { value: "шт", label: "ШТ" },
  { value: "кг", label: "КГ" },
];

const MaterialsFormRow: FC<MaterialsFormRowProps> = ({
  indexMaterial,
  control,
  indexProduct,
  material,
  errors,
  deleteItem,
  updateItem,
}) => {
  const { t } = useTranslation();
  const searchValue = () => {
    const item = units.find((i) => i.value === material.extras.dimension);

    return material.material_id && item?.label ? t(item.label) : "";
  };
  const unitsDropDownValues = useMemo(
    () =>
      units.map(({ value, label }) => ({
        value,
        label: t(label),
      })),
    [units, t]
  );

  const updateMaterial = () => {
    updateItem(material?.material_id);
  };

  const deleteProduct = () => {
    deleteItem(indexMaterial, material?.material_id);
  };

  const watch = useWatch({ control });
  const costs =
    watch?.materialProducts?.[indexProduct]?.expenses?.Materials?.[
      indexMaterial
    ]?.value || 0;
  const per_product =
    watch?.materialProducts?.[indexProduct]?.expenses?.Materials?.[
      indexMaterial
    ]?.amount || 0;

  const totalPrice = (+costs * +per_product)?.toFixed(2);

  return (
    <Container onBlur={updateMaterial}>
      <Controller
        render={({ field }) => (
          <Input
            label={`${indexMaterial + 1}. ${field.value}`}
            {...field}
            className="name-input"
            error={
              !!errors.materialProducts?.[indexProduct]?.expenses?.Materials?.[
                indexMaterial
              ]?.name?.message
            }
            helperText={
              errors.materialProducts?.[indexProduct]?.expenses?.Materials?.[
                indexMaterial
              ]?.name?.message
            }
          />
        )}
        control={control}
        name={`materialProducts.${indexProduct}.expenses.Materials.${indexMaterial}.name`}
        rules={{ required: t("fieldIsRequired") }}
      />

      <MuiCreatableSelect
        label={t("Од. виміру")}
        data={unitsDropDownValues}
        control={control}
        name={`materialProducts.${indexProduct}.expenses.Materials.${indexMaterial}.extras.dimension`}
        errors={
          errors.materialProducts?.[indexProduct]?.expenses?.Materials?.[
            indexMaterial
          ]?.extras?.dimension?.message
        }
        activityIndex={indexProduct}
        index={indexMaterial}
        defaultValue={searchValue()}
      />

      <Controller
        render={({ field }) => (
          <Input
            label={t("На 1 айтем")}
            {...field}
            type="number"
            placeholder="0"
            className="amount-input"
            error={
              !!errors.materialProducts?.[indexProduct]?.expenses?.Materials?.[
                indexMaterial
              ]?.amount?.message
            }
            helperText={
              errors.materialProducts?.[indexProduct]?.expenses?.Materials?.[
                indexMaterial
              ]?.amount?.message
            }
          />
        )}
        control={control}
        name={`materialProducts.${indexProduct}.expenses.Materials.${indexMaterial}.amount`}
        rules={{ required: t("fieldIsRequired") }}
      />

      <Controller
        render={({ field }) => (
          <Input
            label={t("Ціна")}
            {...field}
            type="number"
            placeholder="0"
            className="amount-input"
            error={
              !!errors.materialProducts?.[indexProduct]?.expenses?.Materials?.[
                indexMaterial
              ]?.value?.message
            }
            helperText={
              errors.materialProducts?.[indexProduct]?.expenses?.Materials?.[
                indexMaterial
              ]?.value?.message
            }
          />
        )}
        control={control}
        name={`materialProducts.${indexProduct}.expenses.Materials.${indexMaterial}.value`}
        rules={{ required: t("fieldIsRequired") }}
      />

      <Input
        label={t("Загалом")}
        type="number"
        InputProps={{
          readOnly: true,
        }}
        value={totalPrice}
        className="amount-input"
      />

      <DeleteBtn onClick={deleteProduct} variant="icon">
        <CancelIcon />
      </DeleteBtn>
    </Container>
  );
};

export default MaterialsFormRow;
