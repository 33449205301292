import { Box, Typography } from "@mui/material";
import {
  BaseContainerStyled,
  CalculationItemStyled,
  CalculationRowStyled,
  CalculationsContainerStyled,
  CalculationSubtitleStyled,
  CalculationTitleStyled,
  CalculationValueStyled,
  ContentContainerStyled,
  GraphContainerStyled,
} from "../styles";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useTranslation } from "react-i18next";
import { type FC } from "react";
import { useScreenSize } from "hooks/useScreenSize";
import { type ProjectCalculationsReturn } from "hooks/freelancer/useProjectCalculations";
import CustomTooltip from "./CustomTooltip";
import CustomLabel from "./CustomLabel";
import { palette } from "styles/theme/palette";

interface GraphWithCalculationsProps {
  data: ProjectCalculationsReturn;
}

const GraphWithCalculations: FC<GraphWithCalculationsProps> = ({
  data: { graphData, numbers },
}) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const margin = isMobile
    ? { top: 10, right: 80, left: 20, bottom: 0 }
    : { top: 10, right: 120, left: 50, bottom: 0 };
  const graphHeight = isMobile ? 250 : 300;

  return (
    <BaseContainerStyled>
      <Typography variant="h2">
        {t("freelancer.page.addEditProject.graph.title")}
      </Typography>
      <ContentContainerStyled>
        <GraphContainerStyled>
          <ResponsiveContainer height={graphHeight} width="100%">
            <BarChart
              data={graphData}
              layout="vertical"
              barCategoryGap={10}
              margin={margin}
            >
              <XAxis type="number" unit="$" />
              <YAxis type="category" dataKey="name" />
              <Tooltip
                content={(props) => <CustomTooltip tooltipProps={props} />}
              />
              <Bar dataKey="value" minPointSize={5} isAnimationActive={false}>
                <LabelList dataKey="value" content={<CustomLabel />} />
                {graphData.map(({ name, color }) => (
                  <Cell key={`cell-${name}`} fill={color} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </GraphContainerStyled>
        <CalculationsContainerStyled>
          {numbers.map(({ label, data }) => (
            <CalculationItemStyled key={label}>
              <CalculationTitleStyled variant="h6">
                {label}
              </CalculationTitleStyled>
              <CalculationRowStyled>
                {data.map((dataEl, index) => (
                  <CalculationRowStyled>
                    <Box>
                      <CalculationValueStyled
                        color={
                          +dataEl.value >= 0
                            ? palette.custom.black
                            : palette.custom.red.dark
                        }
                        variant="body1"
                      >
                        {`${+dataEl.value.toLocaleString("en-US")}${
                          dataEl?.unit ?? ""
                        } `}
                      </CalculationValueStyled>
                      <CalculationSubtitleStyled variant="h6">
                        {dataEl.label}
                      </CalculationSubtitleStyled>
                    </Box>
                    {index === 0 && (
                      <CalculationValueStyled variant="body1">
                        /
                      </CalculationValueStyled>
                    )}
                  </CalculationRowStyled>
                ))}
              </CalculationRowStyled>
            </CalculationItemStyled>
          ))}
        </CalculationsContainerStyled>
      </ContentContainerStyled>
    </BaseContainerStyled>
  );
};

export default GraphWithCalculations;
