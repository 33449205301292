import type {
  InvoiceBankInfo,
  InvoiceContactInfo,
  InvoiceCreate,
  InvoiceItemCreate,
} from "store/invoices/invoicesTypes";
import type { InvoiceCreateForm } from "interfaces/freelancer/invoice";
import { INVOICE_ITEM_KIND } from "../constants";

export const getMappedInvoiceValues = (
  values: InvoiceCreateForm,
  currency: string,
  projectId?: string
): InvoiceCreate => {
  const {
    name,
    notes,
    tax,
    dueDate,
    subTotal,
    total,
    discount,
    phases,
    contractNumber,
    credentials,
  } = values;
  const { executor } = credentials?.bank ?? {};
  const { executor: executorContactInfo, client } =
    credentials?.employment ?? {};
  const contractorBankInfo: InvoiceBankInfo = {
    bank_address: executor?.beneficiary?.address ?? "",
    account_number: executor?.beneficiary?.account ?? "",
    bank_name: executor?.beneficiary?.name ?? "",
    swift_code: executor?.beneficiary?.swift ?? "",
    correspondent_bank: executor?.correspondent?.name,
    correspondent_account_number: executor?.correspondent?.account,
    correspondent_swift_code: executor?.correspondent?.swift,
  };
  const contractorInfo: InvoiceContactInfo = {
    name: executorContactInfo?.name ?? "",
    address: executorContactInfo?.address,
    email: executorContactInfo?.email,
    tax_id: executorContactInfo?.edrpo,
  };
  const clientInfo: InvoiceContactInfo = {
    name: client?.name ?? "",
    address: client?.address,
    email: client?.email,
    tax_id: client?.edrpo,
  };
  const items: InvoiceItemCreate[] = [];
  phases.forEach(
    ({
      employees,
      name: phaseName,
      description,
      services,
      additionalExpenses,
    }) => {
      const employeeItems: InvoiceItemCreate[] =
        employees?.map(({ name, itemId, id, rate, hoursToBePaid }) => ({
          name,
          project_item_id: itemId,
          id,
          group: phaseName,
          kind: INVOICE_ITEM_KIND.TEAM,
          price: +rate,
          description,
          quantity: +hoursToBePaid,
        })) ?? [];
      const serviceItems: InvoiceItemCreate[] =
        services?.map(({ name, itemId, id, invoicedAmount }) => ({
          name,
          project_item_id: itemId,
          id,
          group: phaseName,
          kind: INVOICE_ITEM_KIND.SERVICE,
          price: +invoicedAmount,
          description,
          quantity: 1,
        })) ?? [];
      const expenseItems: InvoiceItemCreate[] =
        additionalExpenses?.map(({ name, id, invoicedAmount }) => ({
          name,
          id,
          project_item_id: id,
          group: phaseName,
          kind: INVOICE_ITEM_KIND.ADDITIONAL,
          price: +invoicedAmount,
          description,
          quantity: 1,
        })) ?? [];
      items.push(...employeeItems, ...serviceItems, ...expenseItems);
    }
  );
  return {
    name,
    notes,
    tax,
    total,
    sub_total: subTotal,
    discount,
    contract_name: contractNumber,
    contractor_bank_info: contractorBankInfo,
    contractor_info: contractorInfo,
    customer_info: clientInfo,
    valid_until: dueDate,
    items,
    currency,
    parent_id: projectId ?? "",
  };
};
