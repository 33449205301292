import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
// Store
import { useCreateLaborMutation } from "store/tutors/tutorsServices";
import {
  IEmployeeProducts,
  IEmployeeLabor,
  ILabor,
} from "store/tutors/tutorsTypes";
import { selectTutor } from "store/tutors/tutorsSlice";
// Components
import EmployeeProductItem from "./EmployeeProductItem";
// Styles
import { FormContainer, FormWrap, HeaderSection } from "../styles";

const EmployeeProduct = () => {
  const { t } = useTranslation();
  const { employeeProduct } = useSelector(selectTutor);
  const { product_id } = useParams<string>();
  const [createLabor] = useCreateLaborMutation();

  const formState = useForm({
    defaultValues: { employeeProduct },
    mode: "all",
  });

  const formStateArray = useFieldArray({
    control: formState.control,
    name: "employeeProduct",
  });

  const updateLabors = (labors: IEmployeeLabor[]) => {
    const labor: ILabor[] = [];

    labors.forEach((item) => {
      item.employees.forEach((empl) => {
        labor.push({
          labor_id: empl.labor_id,
          amount: +empl.amount,
          employee_id: empl.employee_id,
          extra: {
            name: empl.name,
            employee_id: empl.employee_id,
            department: item.department,
          },
        });
      });
    });

    const checkLaborId = labor.filter((item) => !item.labor_id);

    if (checkLaborId?.length) {
      return checkLaborId;
    }

    return [];
  };

  const submitNewItem = async (employeeProduct: IEmployeeProducts[]) => {
    try {
      const employees = employeeProduct.map((item: IEmployeeProducts) => ({
        product_id: item.product.id,
        labors: updateLabors(item.labors),
      }));

      const filteredEmployees = employees.filter((item) => item.labors?.length);

      if (filteredEmployees?.length) {
        await createLabor(filteredEmployees).unwrap();
        formState.reset();
      }

      formState.setValue("employeeProduct", employeeProduct);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // @ts-ignore
    const findProductEmployee: IEmployeeProducts = employeeProduct.find(
      (employee) => employee.product.id === product_id
    );

    formState.setValue("employeeProduct", [findProductEmployee]);
  }, [employeeProduct]);

  const update = () => {
    const { employeeProduct } = formState.getValues();
    submitNewItem(employeeProduct);
  };

  return (
    <FormWrap id="materialForm">
      <HeaderSection>
        <Typography variant="h2">
          {t("Витрати робочого часу на одиницю")}
        </Typography>
      </HeaderSection>
      <FormContainer>
        {formStateArray.fields.map((item, index) => (
          <EmployeeProductItem
            addNewEmployee={update}
            errors={formState.formState.errors}
            control={formState.control}
            key={item.id}
            indexProduct={index}
            itemProduct={item}
          />
        ))}
      </FormContainer>
    </FormWrap>
  );
};

export default EmployeeProduct;
