import {
  type ProjectPhaseCreate,
  type ProjectWithPhases,
} from "interfaces/freelancer/project";

import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { EXPENSE_STATUS, URL_PARAMS } from "utils/freelancer/constants";
import { getProjectTypes } from "utils/freelancer/helpers/getProjectTypes";
import { useProjectsWithPhases } from "./useProjectsWithPhases";
import { mapExpenses } from "utils/freelancer/projects";
import { useTranslation } from "react-i18next";
import { type Option } from "types/select";
import { useGetPipelineCalculateQuery } from "store/dashboard/dashboardServices";
import { useFreelancerDefaults } from "../useFreelancerDefaults";
import { useAllInvoices } from "./useAllInvoices";
import dayjs from "dayjs";

interface ExistingProjectReturn {
  initialPhases: ProjectPhaseCreate[];
  initialWorkPhases: ProjectPhaseCreate[];
  projectType: Option;
  existingProject: ProjectWithPhases;
}

export const useExistingProject = (): ExistingProjectReturn => {
  const { activeInvoices } = useAllInvoices();
  const { t } = useTranslation();
  const { project_id, pipeline_id } = useFreelancerDefaults();
  const [searchParams] = useSearchParams();
  const typeFromUrl = searchParams.get(URL_PARAMS.TYPE);
  const projectIdFromUrl = searchParams.get(URL_PARAMS.PROJECT_ID);
  const projectTypeOptions = getProjectTypes(t);
  const { activeProjectsWithPhases: projectsWithPhases } =
    useProjectsWithPhases();
  const [existingProject] = projectsWithPhases.filter(
    ({ id }) => id === projectIdFromUrl
  );
  const { data: calculations } = useGetPipelineCalculateQuery({
    project_id,
    pipeline_id,
  });

  const initialPhases: ProjectPhaseCreate[] = useMemo(
    () =>
      existingProject?.phases
        .filter(({ extras }) => extras?.phaseStatus === EXPENSE_STATUS.DRAFT)
        .sort((a, b) => dayjs(a.created_at).diff(dayjs(b.created_at)))
        .map(({ name, extras, expenses, id }) => {
          return {
            name,
            startDate: extras?.startDate
              ? new Date(extras.startDate)
              : new Date(),
            endDate: extras?.endDate ? new Date(extras.endDate) : undefined,
            description: extras?.phaseDescription,
            status: extras?.phaseStatus ?? EXPENSE_STATUS.DRAFT,
            expenses: mapExpenses(expenses),
            id,
            groupId: existingProject?.groupId,
            // calculations: calculations?.products[id],
            clockify: extras?.clockify,
          };
        }) ?? [],
    [existingProject, calculations]
  );
  const initialWorkPhases: ProjectPhaseCreate[] = useMemo(
    () =>
      existingProject?.phases
        ?.filter(
          ({ extras }) => extras?.phaseStatus === EXPENSE_STATUS.SUBMITTED
        )
        .sort((a, b) => dayjs(a.created_at).diff(dayjs(b.created_at)))
        .map(({ name, extras, expenses, id }) => {
          return {
            name,
            startDate: extras?.startDate
              ? new Date(extras.startDate)
              : new Date(),
            endDate: extras?.endDate ? new Date(extras.endDate) : undefined,
            description: extras?.phaseDescription,
            status: extras?.phaseStatus ?? EXPENSE_STATUS.SUBMITTED,
            expenses: mapExpenses(expenses),
            id,
            groupId: existingProject?.groupId,
            // calculations: calculations?.products[id],
            twin_id: extras?.twin_id,
            clockify: extras?.clockify,
          };
        }) ?? [],
    [existingProject, calculations]
  );

  const [projectType] =
    projectTypeOptions.filter(({ value }) => {
      const valueToCompare = existingProject?.type
        ? existingProject.type
        : typeFromUrl;
      return value === valueToCompare;
    }) ?? [];

  useEffect(() => {
    if (existingProject && activeInvoices?.length) {
      existingProject.invoices = activeInvoices.filter(
        ({ projectId }) => projectId === existingProject.id
      );
    }
  }, [existingProject, activeInvoices]);

  return { initialPhases, initialWorkPhases, projectType, existingProject };
};
