import { styled } from "@mui/material/styles";
import { ContainerForm } from "pages/Teams/AddEmployeeModal/styles";
import {
  LabelStyled,
  TextareaAutosizeStyled,
} from "../../Projects/AddEdit/Expenses/styles";

export const ContainerFormStyled = styled(ContainerForm)(() => ({
  display: "flex",
  ".inline-input": {
    width: "calc((100% - 80px) / 2)",
  },
}));

export const TextareaStyled = styled(TextareaAutosizeStyled)(() => ({
  margin: "0 0 20px",
  width: "100%",
}));

export const TextAreaLabelStyled = styled(LabelStyled)(() => ({
  margin: "0 0 10px",
}));
