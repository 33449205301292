import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

interface ContainerProps {
  isSticki?: boolean;
}

export const Container = styled("footer")<ContainerProps>`
  display: flex;
  justify-content: space-between;
  height: 73px;
  align-items: center;
  position: ${({ isSticki }) => (isSticki ? "sticky" : "static")};
  bottom: 0;
  margin-right: 20px;
`;

export const Label = styled(Box)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #a4a4a4;
`;
