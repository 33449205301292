import { Typography } from "@mui/material";
import {
  type Control,
  Controller,
  useFieldArray,
  type UseFieldArrayAppend,
} from "react-hook-form";
import {
  DeleteIconContainerStyled,
  ExpenseGroupContainerStyled,
  ExpenseInputStyled,
  ExpensesLabelRowStyled,
  InputLabelStyled,
  InputRowStyled,
  InputStyled,
  LinkStyled,
  RowStyled,
} from "./styles";
import { palette } from "styles/theme/palette";
import { type FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { type ProjectCreateForm } from "interfaces/freelancer/project";
import { DeleteIcon } from "assets/icons";
import { useScreenSize } from "hooks/useScreenSize";
import { NoTableDataTextStyled } from "../../../styles";
import { EXPENSE_STATUS } from "utils/freelancer/constants";
import { v4 as uuidv4 } from "uuid";

interface AdditionalExpensesProps {
  parentIndex: number;
  control: Control<ProjectCreateForm>;
  phasesName?: "phases" | "workPhases";
  onExpenseRemove?: UseFieldArrayAppend<ProjectCreateForm, "removedExpenses">;
}

const AdditionalExpenses: FC<AdditionalExpensesProps> = ({
  parentIndex,
  control,
  phasesName = "phases",
  onExpenseRemove = undefined,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${phasesName}.${parentIndex}.expenses.additionalExpenses`,
  });
  const removeExpense = useCallback(
    ({ currentTarget }) => {
      const expenseIndex = currentTarget.getAttribute("data-expense-index");
      if (expenseIndex) {
        remove(expenseIndex);
        if (fields[expenseIndex].expenseId) {
          onExpenseRemove?.({ id: fields[expenseIndex].expenseId ?? "" });
        }
      }
    },
    [remove, onExpenseRemove, fields]
  );
  const addMoreExpenses = useCallback(() => {
    append({
      name: "",
      amount: 0,
      status: EXPENSE_STATUS.DRAFT,
      id: uuidv4(),
    });
  }, []);

  return (
    <ExpenseGroupContainerStyled>
      <Typography variant="h1">{t("additionalExpenses")}</Typography>
      {!!fields?.length && !isMobile && (
        <ExpensesLabelRowStyled>
          <Typography
            variant="regularText"
            color={palette.custom.primary.silver}
          >
            {t("freelancer.page.addEditProject.additionalExpenses.name.label")}
          </Typography>
          <RowStyled>
            <InputLabelStyled variant="regularText">
              {t(
                "freelancer.page.addEditProject.additionalExpenses.amount.label"
              )}
            </InputLabelStyled>
          </RowStyled>
        </ExpensesLabelRowStyled>
      )}
      {!!fields?.length ? (
        fields?.map((item, nestedIndex) => (
          <RowStyled key={item.id}>
            <Controller
              name={`${phasesName}.${parentIndex}.expenses.additionalExpenses.${nestedIndex}.name`}
              control={control}
              rules={{ required: t("fieldIsRequired") }}
              render={({ field }) => (
                <ExpenseInputStyled
                  {...field}
                  type="text"
                  placeholder={t(
                    "freelancer.page.addEditProject.additionalExpenses.name.placeholder"
                  )}
                />
              )}
            />
            <InputRowStyled>
              <Controller
                name={`${phasesName}.${parentIndex}.expenses.additionalExpenses.${nestedIndex}.amount`}
                control={control}
                rules={{ required: t("fieldIsRequired") }}
                render={({ field }) => (
                  <InputStyled
                    {...field}
                    type="text"
                    placeholder={t(
                      "freelancer.page.addEditProject.additionalExpenses.amount.placeholder"
                    )}
                  />
                )}
              />
              <DeleteIconContainerStyled
                data-expense-index={nestedIndex}
                onClick={removeExpense}
              >
                <DeleteIcon />
              </DeleteIconContainerStyled>
            </InputRowStyled>
          </RowStyled>
        ))
      ) : (
        <NoTableDataTextStyled variant="h2">
          {t("freelancer.page.addEditProject.additionalExpenses.noData")}
        </NoTableDataTextStyled>
      )}
      <LinkStyled
        underline="none"
        onClick={addMoreExpenses}
        color={palette.custom.green.dark}
      >
        {t("addMoreExpenses")}
      </LinkStyled>
    </ExpenseGroupContainerStyled>
  );
};

export default AdditionalExpenses;
