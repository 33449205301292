import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { type Control, Controller, type FieldErrors } from "react-hook-form";
import { type InvoiceCreateForm } from "interfaces/freelancer/invoice";
import {
  ContainerInput,
  Input as DateInput,
} from "components/UI/DatePicker/styles";
import { DatePickerContainerStyled } from "../../../Projects/AddEdit/Expenses/styles";
import { Label } from "components/UI/Input/styles";
import Input from "components/UI/Input";
import {
  BaseInfoContainerStyled,
  BaseInfoRowStyled,
  DateContainerStyled,
} from "./styles";

interface BaseInfoProps {
  control: Control<InvoiceCreateForm>;
  errors: FieldErrors<InvoiceCreateForm>;
  overrideLanguage?: string;
}
const BaseInfo: FC<BaseInfoProps> = ({ control, errors, overrideLanguage }) => {
  const { t } = useTranslation();

  return (
    <BaseInfoContainerStyled>
      <BaseInfoRowStyled>
        <Controller
          name="name"
          control={control}
          rules={{
            required: t("fieldIsRequired", { lng: overrideLanguage }),
          }}
          render={({ field }) => (
            <Input
              {...field}
              required
              label={`${t("freelancer.page.invoices.add.name.label", {
                lng: overrideLanguage,
              })} *`}
              error={!!errors?.name?.message}
              placeholder={t("freelancer.page.invoices.add.name.placeholder", {
                lng: overrideLanguage,
              })}
              helperText={errors?.name?.message}
            />
          )}
        />
        <Controller
          name="contractNumber"
          control={control}
          rules={{
            required: t("fieldIsRequired", { lng: overrideLanguage }),
          }}
          render={({ field }) => (
            <Input
              {...field}
              required
              label={`${t("freelancer.page.invoices.add.contractId.label", {
                lng: overrideLanguage,
              })} *`}
              error={!!errors?.contractNumber?.message}
              placeholder={t(
                "freelancer.page.invoices.add.contractId.placeholder",
                { lng: overrideLanguage }
              )}
              helperText={errors?.contractNumber?.message}
            />
          )}
        />
      </BaseInfoRowStyled>
      <DateContainerStyled>
        <DatePickerContainerStyled>
          <Label>
            {t("freelancer.page.invoices.add.invoiceDate.label", {
              lng: overrideLanguage,
            })}
          </Label>
          <ContainerInput>
            <Controller
              name="invoiceDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DateInput selected={value} onChange={onChange} />
              )}
            />
          </ContainerInput>
        </DatePickerContainerStyled>
        <DatePickerContainerStyled>
          <Label>
            {t("freelancer.page.invoices.add.dueDate.label", {
              lng: overrideLanguage,
            })}
          </Label>
          <ContainerInput>
            <Controller
              name="dueDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DateInput selected={value} onChange={onChange} />
              )}
            />
          </ContainerInput>
        </DatePickerContainerStyled>
      </DateContainerStyled>
    </BaseInfoContainerStyled>
  );
};

export default BaseInfo;
