import {
  USER_EXPENSE_DETAILS,
  USER_EXPENSE_TYPE,
} from "../store/userExpense/userExpenseTypes";

export enum OPERATION_TYPE {
  INCOME = "income",
  OUTCOME = "outcome",
}

export interface AddTransactionForm {
  operationType: OPERATION_TYPE;
  type: USER_EXPENSE_TYPE;
  amount: number;
  group: USER_EXPENSE_DETAILS;
}

export interface AddHoursForm {
  member: string;
  project: string;
  phase: string;
  hours: string;
}
