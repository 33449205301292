import React, { FC } from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { ToggleButton } from "@mui/material";
import { CancelIcon } from "assets/icons";
import Input from "components/UI/Input";
// Interface
import { IProductCreate } from "interfaces/product";
// Styles
import { Container, StyledToggleButtonGroup, DeleteBtn } from "./styles";
// Translation
import { useTranslation } from "react-i18next";

interface ProductRowItemProps {
  index: number;
  updateItem: (id?: string) => void;
  deleteItem: (id: number, product_id?: string) => void;
  product: IProductCreate;
  control: Control<{ products: IProductCreate[] }>;
  errors: FieldErrors<{ products: IProductCreate[] }>;
}

const ProductRowItem: FC<ProductRowItemProps> = ({
  updateItem,
  control,
  index,
  errors,
  product,
  deleteItem,
}) => {
  const { t } = useTranslation();
  const deleteProduct = () => {
    deleteItem(index, product?.pipeline_id);
  };

  const updateProduct = () => {
    updateItem(product?.pipeline_id);
  };

  return (
    <Container onBlur={updateProduct}>
      <Controller
        render={({ field }) => (
          <Input
            label={`${index + 1}. ${field.value}`}
            // label={`${index + 1}. ` + t('Назва')}
            {...field}
            placeholder={t("Ведіть назву продукту або сервісу")}
            className="name-input"
            error={!!errors?.products?.[index]?.name?.message}
            helperText={errors?.products?.[index]?.name?.message}
          />
        )}
        control={control}
        name={`products.${index}.name`}
        rules={{ required: t("fieldIsRequired") }}
      />

      <Controller
        render={({ field }) => (
          <StyledToggleButtonGroup {...field} exclusive>
            <ToggleButton value="product">{t("Продукт")}</ToggleButton>

            <ToggleButton value="service">{t("Сервіс")}</ToggleButton>
          </StyledToggleButtonGroup>
        )}
        control={control}
        name={`products.${index}.group`}
      />

      <DeleteBtn variant="icon" onClick={deleteProduct}>
        <CancelIcon />
      </DeleteBtn>
    </Container>
  );
};

export default ProductRowItem;
