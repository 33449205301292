import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { type Control, Controller } from "react-hook-form";
import { type InvoiceCreateForm } from "interfaces/freelancer/invoice";
import {
  GreenLabelStyled,
  NotesAndCalculationsContainerStyled,
  GreenValueStyled,
  ContentContainerStyled,
  CalculationsContainerStyled,
  TextAreaContainerStyled,
  CalculationRowStyled,
  TotalContainerStyled,
  TotalRowStyled,
} from "./styles";
import {
  InputStyled,
  LabelStyled,
  TextareaAutosizeStyled,
} from "../../../Projects/AddEdit/Expenses/styles";
import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";
import { useInvoiceTotalCalculations } from "hooks/freelancer/useInvoiceTotalCalculations";
import { type UseFormSetValue } from "react-hook-form/dist/types/form";

interface NotesAndCalculationsProps {
  control: Control<InvoiceCreateForm>;
  setValue: UseFormSetValue<InvoiceCreateForm>;
  overrideLanguage?: string;
}
const NotesAndCalculations: FC<NotesAndCalculationsProps> = ({
  control,
  setValue,
  overrideLanguage,
}) => {
  const { t } = useTranslation();
  const { currency } = useFreelancerDefaults();
  const { total, subtotal, tax, discount, onTaxChange, onDiscountChange } =
    useInvoiceTotalCalculations(control, setValue);

  return (
    <NotesAndCalculationsContainerStyled>
      <ContentContainerStyled>
        <TextAreaContainerStyled>
          <LabelStyled>
            {t("freelancer.page.invoices.add.notes.label", {
              lng: overrideLanguage,
            })}
          </LabelStyled>
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <TextareaAutosizeStyled
                minRows={4}
                placeholder={t(
                  "freelancer.page.invoices.add.notes.placeholder",
                  { lng: overrideLanguage }
                )}
                {...field}
              />
            )}
          />
        </TextAreaContainerStyled>
        <CalculationsContainerStyled>
          <CalculationRowStyled>
            <GreenLabelStyled>
              {t("freelancer.page.invoices.add.calculations.subtotalLabel", {
                lng: overrideLanguage,
              })}
            </GreenLabelStyled>
            <GreenValueStyled>{`${currency} ${subtotal}`}</GreenValueStyled>
          </CalculationRowStyled>
          <CalculationRowStyled>
            <GreenLabelStyled>
              {t("freelancer.page.invoices.add.calculations.discountLabel", {
                currency,
                lng: overrideLanguage,
              })}
            </GreenLabelStyled>
            <InputStyled
              value={discount}
              onChange={onDiscountChange}
              type="number"
            />
          </CalculationRowStyled>
          <CalculationRowStyled>
            <GreenLabelStyled>
              {t("freelancer.page.invoices.add.calculations.taxLabel", {
                lng: overrideLanguage,
              })}
            </GreenLabelStyled>
            <InputStyled value={tax} onChange={onTaxChange} type="number" />
          </CalculationRowStyled>
        </CalculationsContainerStyled>
      </ContentContainerStyled>
      <TotalContainerStyled>
        <TotalRowStyled>
          <GreenValueStyled>
            {t("freelancer.page.invoices.add.calculations.totalLabel", {
              lng: overrideLanguage,
            })}
          </GreenValueStyled>
          <GreenValueStyled>{`${currency} ${total}`}</GreenValueStyled>
        </TotalRowStyled>
      </TotalContainerStyled>
    </NotesAndCalculationsContainerStyled>
  );
};

export default NotesAndCalculations;
