import React from "react";
import Loading from "components/UI/Loading";
import { UseFormReturn, Controller, SubmitHandler } from "react-hook-form";
// Interface
import { IUserProfile } from "interfaces/user-profile";
// Components
import SelectCountries from "components/UI/SelectCountries";
// Constants
import { InputsRules } from "utils/constants/auth";
import { USER_PROFILE_INPUTS } from "utils/constants/user-profile";
// Styles
import { Container, InputStyled, InputWrap } from "./styles";
// Translation
import { useTranslation } from "react-i18next";

interface UserInfoInputsProps {
  formState: UseFormReturn<IUserProfile>;
  onSubmit: SubmitHandler<IUserProfile>;
  submitButtonLabel?: string;
  isLoading: boolean;
  disableFields?: boolean;
}

const UserInfoInputs: React.FC<UserInfoInputsProps> = ({
  formState,
  isLoading,
  // onSubmit,
  // submitButtonLabel,
  disableFields = false,
}) => {
  // const { isMobile } = useScreenSize();
  const { t } = useTranslation();
  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }
  return (
    <Container>
      {USER_PROFILE_INPUTS.map((input) => (
        <Controller
          key={input.name}
          name={input.name as keyof IUserProfile}
          control={formState.control}
          rules={{
            required: t(input.rules.required.message),
          }}
          render={({ field }) => (
            <InputStyled
              disabled={disableFields}
              error={
                !!formState.formState.errors?.[input.name as keyof IUserProfile]
                  ?.message
              }
              helperText={
                formState.formState.errors?.[input.name as keyof IUserProfile]
                  ?.message
              }
              placeholder={t(`${input.placeholder}`)}
              label={t(`utils.constants.user-profile.${input.label}`)}
              type={input.type}
              {...field}
            />
          )}
        />
      ))}

      <InputWrap>
        <Controller
          rules={{ required: InputsRules.required }}
          control={formState.control}
          name="residence"
          render={({ field }) => (
            <SelectCountries
              disabled={disableFields}
              errorMessage={formState.formState.errors?.residence?.message}
              error={!!formState.formState.errors?.residence?.message}
              label={t(
                "pages.UserProfile.UserInfoInputs.Країна вашого резидентства"
              )}
              {...field}
            />
          )}
        />
      </InputWrap>

      {/* <Button
        onClick={formState.handleSubmit(onSubmit)}
        disabled={!formState.formState.isValid}
        variant="contained"
        fullWidth={isMobile}
      >
        {submitButtonLabel ?? t("pages.UserProfile.UserInfoInputs.Зберегти")}
      </Button> */}
    </Container>
  );
};

export default UserInfoInputs;
