import { type TeamTableData } from "./index";
import { type Employee } from "store/employees/employeesTypes";
import { EMPLOYEE_TYPE, PROJECT_TYPE } from "utils/freelancer/constants";

interface HeadCell {
  id: keyof TeamTableData;
  label: string;
  numeric: boolean;
}

export const headCells: (HeadCell | null)[] = [
  {
    id: "name",
    numeric: false,
    label: "Ім’я та позиція",
  },
  null,
  null,
  null,
  null,
  null,
];

export const mockEmployees: Employee[] = [
  {
    amount_type: PROJECT_TYPE.FIX,
    type: EMPLOYEE_TYPE.PARTNER,
    department: "UX",
    name: "Test Employee",
    id: "1",
    wage: 20,
    edrpo: "1234",
    account_number: "UA123123234234",
    phone: "3805555234",
    email: "test@test.com",
  },
  {
    amount_type: PROJECT_TYPE.HOURLY,
    type: EMPLOYEE_TYPE.PARTNER,
    department: "UX",
    name: "Test Employee 2",
    id: "2",
    wage: 10,
    edrpo: "1234",
    account_number: "UA343434322222",
    phone: "3833222222",
    email: "test2@test.com",
  },
];
