import React from "react";
import { useTranslation } from "react-i18next";
// Icons
import { FooterLogo } from "assets/icons";
// Styles
import { Container, StyledLink, StyledMuiLink } from "./styles";
import { PRIVACY_POLICY_URL, PUBLIC_OFFER_URL } from "utils/constants/urls";

interface FooterLinksProps {
  isSpace?: boolean;
}

const FooterLinks: React.FC<FooterLinksProps> = ({ isSpace }) => {
  const { t } = useTranslation();

  return (
    <Container isSpace={isSpace}>
      <FooterLogo />

      <StyledLink target="blank" to={PRIVACY_POLICY_URL}>
        Privacy Policy
      </StyledLink>

      <StyledLink target="blank" to={PUBLIC_OFFER_URL}>
        Public Offer
      </StyledLink>

      <StyledMuiLink href="mailto:support@pipeliner.online?subject=Problem Report&body=Just let us know if you face any issues.">
        {t("Щось пішло не так?")}
      </StyledMuiLink>
    </Container>
  );
};

export default FooterLinks;
