import { type FC, useCallback, useState } from "react";
import Modal from "components/UI/Modal";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  Container,
  ContainerButton,
  Title,
} from "pages/Teams/AddEmployeeModal/styles";
import type { Employee } from "store/employees/employeesTypes";
import { useGetEmployeesQuery } from "store/employees/employeesServices";
import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";
import Select from "components/UI/Select";
import { EMPLOYEE_TYPE } from "utils/freelancer/constants";
import { type Option } from "types/select";
import { SelectChangeEvent } from "@mui/material";
import { useAuth } from "hooks/useAuth";

interface SelectTeammateModalProps {
  onClose: (addedTeammates?: Employee[]) => void;
  isOpen: boolean;
  idsToExclude: string[];
}

const SelectTeammateModal: FC<SelectTeammateModalProps> = ({
  onClose,
  isOpen,
  idsToExclude,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { project_id, pipeline_id, employeeName } = useFreelancerDefaults();
  const { data } = useGetEmployeesQuery({
    project_id,
    pipeline_id,
  });

  const employees = data?.filter(
    ({ type, id }) =>
      type === EMPLOYEE_TYPE.PARTNER && !idsToExclude.includes(id)
  );

  const employeeOptions: Option[] =
    employees?.map(({ id, name }) => {
      if (name === user?.user_id) {
        return { label: employeeName, value: id };
      }
      return { label: name, value: id };
    }) ?? [];
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);

  const selectEmployees = useCallback(() => {
    onClose(selectedEmployees);
    setSelectedEmployees([]);
  }, [selectedEmployees]);

  const handleSelectEmployees = useCallback(
    ({ target }: SelectChangeEvent<unknown>) => {
      if (target?.value) {
        const selectedEmployee =
          employees?.filter(({ id }) => target.value === id) ?? [];
        setSelectedEmployees(selectedEmployee);
      }
    },
    [employees]
  );

  const closeModal = useCallback(() => {
    setSelectedEmployees([]);
    onClose();
  }, []);

  return (
    <Modal onClose={closeModal} isOpen={isOpen}>
      <Container>
        <Title variant="h1">
          {t("freelancer.page.addEditProject.selectEmployee.title")}
        </Title>
        <Select
          data={employeeOptions}
          label={t("freelancer.page.addEditProject.selectEmployee.label")}
          fullWidth
          onChange={handleSelectEmployees}
        />
        <ContainerButton>
          <Button
            disabled={!selectedEmployees?.length}
            variant="contained"
            component="span"
            onClick={selectEmployees}
          >
            {t("Додати")}
          </Button>

          <Button variant="light" onClick={closeModal}>
            {t("Скасувати")}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default SelectTeammateModal;
