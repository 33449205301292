import { type FC, useEffect, useMemo } from "react";
import { type Control, Controller, FieldErrors } from "react-hook-form";
import type { InvoiceCreateForm } from "interfaces/freelancer/invoice";
import { Typography } from "@mui/material";
import { useDrawer } from "hooks/useDrawer";
import {
  ArrowContainerStyled,
  CollapseStyled,
  HeaderStyled,
  IconContainerStyled,
  LabelStyled,
  TitleContainerStyled,
} from "components/Freelancer/Projects/AddEdit/Expenses/styles";
import { StyledInput } from "pages/Teams/AddEmployeeModal/styles";
import { palette } from "styles/theme/palette";
import { TitleStyled } from "../styles";
import { useTranslation } from "react-i18next";
import { Arrow } from "pages/ProductsAndServices/ProductsTable/styles";
import { clientBankFields, executorBankFields } from "./data";
import {
  BankDetailsContainer,
  ColumnStyled,
  ContentContainerStyled,
  InputContainerStyled,
} from "./styles";
import {
  getExecutorFields,
  getClientFields,
} from "utils/freelancer/helpers/invoiceHelpers";
import { type Employee } from "store/employees/employeesTypes";
import { type UseFormSetValue } from "react-hook-form/dist/types/form";
import { useGetEmployeesQuery } from "store/employees/employeesServices";
import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";
import { EMPLOYEE_TYPE } from "utils/freelancer/constants";
import {
  setClientDefaults,
  setUserDefaults,
} from "utils/freelancer/teams/helpers";
import { useAuth } from "hooks/useAuth";
import { Container, Label } from "components/UI/Input/styles";

interface BankAccountDetailsProps {
  control: Control<InvoiceCreateForm>;
  errors: FieldErrors<InvoiceCreateForm>;
  setValue: UseFormSetValue<InvoiceCreateForm>;
  client?: Employee;
  overrideLanguage?: string;
}

const BankAccountDetails: FC<BankAccountDetailsProps> = ({
  control,
  setValue,
  client,
  overrideLanguage,
}) => {
  const { t } = useTranslation();
  const { project_id, pipeline_id, userId } = useFreelancerDefaults();
  const { user } = useAuth();
  const { toggle, isOpen } = useDrawer(true);
  const { toggle: toggleBankDetails, isOpen: isBankDetailsOpen } =
    useDrawer(true);
  const executorFields = getExecutorFields(t);
  const clientFields = getClientFields(t);
  const { data } = useGetEmployeesQuery({ project_id, pipeline_id });
  const [userEmployee] = useMemo(
    () =>
      data?.filter(
        ({ type, name }) => type === EMPLOYEE_TYPE.PARTNER && name === userId
      ) ?? [],
    [data, userId]
  );

  useEffect(() => {
    setClientDefaults(setValue, client);
  }, [client]);

  useEffect(() => {
    if (user) {
      setUserDefaults(setValue, userEmployee, user);
    }
  }, [userEmployee, user]);

  return (
    <BankDetailsContainer>
      <TitleStyled variant="h1">
        {t("freelancer.page.invoices.add.bankDetails.title", {
          lng: overrideLanguage,
        })}
      </TitleStyled>
      <CollapseStyled collapsedSize={50} in={isOpen}>
        <HeaderStyled onClick={toggle}>
          <TitleContainerStyled>
            <Typography variant="body1" color={palette.custom.green.dark}>
              {t(
                "freelancer.page.invoices.add.bankDetails.employment.clientLabel",
                { lng: overrideLanguage }
              )}
            </Typography>
          </TitleContainerStyled>
          <TitleContainerStyled>
            <Typography variant="body1" color={palette.custom.green.dark}>
              {t(
                "freelancer.page.invoices.add.bankDetails.employment.executorLabel",
                { lng: overrideLanguage }
              )}
            </Typography>
            <ArrowContainerStyled>
              <IconContainerStyled>
                <Arrow $isOpen={isOpen} />
              </IconContainerStyled>
            </ArrowContainerStyled>
          </TitleContainerStyled>
        </HeaderStyled>
        <ContentContainerStyled>
          <ColumnStyled>
            {clientFields.map(
              ({ name, label, placeholder, rules, isRequired = false }) => (
                <InputContainerStyled key={name}>
                  <Controller
                    name={name}
                    control={control}
                    rules={rules}
                    render={({ field }) => (
                      <Container>
                        <Label
                          required={isRequired}
                          isEmpty={(field.value || "").length === 0}
                        >
                          {isRequired
                            ? `${t(label, { lng: overrideLanguage })} *`
                            : t(label, { lng: overrideLanguage })}
                        </Label>
                        <StyledInput
                          {...field}
                          required={isRequired}
                          placeholder={t(placeholder, {
                            lng: overrideLanguage,
                          })}
                        />
                      </Container>
                    )}
                  />
                </InputContainerStyled>
              )
            )}
          </ColumnStyled>
          <ColumnStyled>
            {executorFields.map(
              ({ name, label, placeholder, rules, isRequired = false }) => (
                <InputContainerStyled key={name}>
                  <Controller
                    name={name}
                    control={control}
                    rules={rules}
                    render={({ field }) => (
                      <Container>
                        <Label
                          required={isRequired}
                          isEmpty={(field.value || "").length === 0}
                        >
                          {isRequired
                            ? `${t(label, { lng: overrideLanguage })} *`
                            : t(label, { lng: overrideLanguage })}
                        </Label>
                        <StyledInput
                          {...field}
                          required={isRequired}
                          placeholder={t(placeholder, {
                            lng: overrideLanguage,
                          })}
                        />
                      </Container>
                    )}
                  />
                </InputContainerStyled>
              )
            )}
          </ColumnStyled>
        </ContentContainerStyled>
      </CollapseStyled>
      <CollapseStyled collapsedSize={50} in={isBankDetailsOpen}>
        <HeaderStyled onClick={toggleBankDetails}>
          <TitleContainerStyled>
            <Typography variant="body1" color={palette.custom.green.dark}>
              {t("freelancer.page.invoices.add.bankDetails.bank.clientLabel", {
                lng: overrideLanguage,
              })}
            </Typography>
          </TitleContainerStyled>
          <TitleContainerStyled>
            <Typography variant="body1" color={palette.custom.green.dark}>
              {t(
                "freelancer.page.invoices.add.bankDetails.bank.executorLabel",
                { lng: overrideLanguage }
              )}
            </Typography>
            <ArrowContainerStyled>
              <IconContainerStyled>
                <Arrow $isOpen={isBankDetailsOpen} />
              </IconContainerStyled>
            </ArrowContainerStyled>
          </TitleContainerStyled>
        </HeaderStyled>
        <ContentContainerStyled>
          <ColumnStyled>
            {clientBankFields.map(({ name, label, placeholder, rules }) => (
              <InputContainerStyled key={name}>
                <LabelStyled>{t(label, { lng: overrideLanguage })}</LabelStyled>
                <Controller
                  name={name}
                  control={control}
                  rules={rules}
                  render={({ field }) => (
                    <StyledInput
                      {...field}
                      placeholder={t(placeholder, { lng: overrideLanguage })}
                    />
                  )}
                />
              </InputContainerStyled>
            ))}
          </ColumnStyled>
          <ColumnStyled>
            {executorBankFields.map(({ name, label, placeholder, rules }) => (
              <InputContainerStyled key={name}>
                <LabelStyled>{t(label, { lng: overrideLanguage })}</LabelStyled>
                <Controller
                  name={name}
                  control={control}
                  rules={rules}
                  render={({ field }) => (
                    <StyledInput
                      {...field}
                      placeholder={t(placeholder, { lng: overrideLanguage })}
                    />
                  )}
                />
              </InputContainerStyled>
            ))}
          </ColumnStyled>
        </ContentContainerStyled>
      </CollapseStyled>
    </BankDetailsContainer>
  );
};

export default BankAccountDetails;
