import { useGetEmployeesQuery } from "store/employees/employeesServices";
import { useMemo } from "react";
import { EMPLOYEE_TYPE } from "utils/freelancer/constants";
import { useAuth } from "../useAuth";
import { useFreelancerDefaults } from "../useFreelancerDefaults";
import { type Employee } from "store/employees/employeesTypes";

interface EmployeesReturn {
  teammates: Employee[];
  clients: Employee[];
  refetchEmployees: () => void;
  isEmployeesLoading: boolean;
}
export const useEmployees = (): EmployeesReturn => {
  const { user } = useAuth();
  const { project_id, pipeline_id, employeeName } = useFreelancerDefaults();
  const { data, refetch, isLoading } = useGetEmployeesQuery({
    project_id,
    pipeline_id,
  });

  const teammates = useMemo(
    () =>
      data
        ?.filter(({ type }) => type === EMPLOYEE_TYPE.PARTNER)
        .map((employee) =>
          employee.name === user?.user_id
            ? { ...employee, name: employeeName }
            : employee
        ) ?? [],
    [data, user]
  );

  const clients = useMemo(
    () => data?.filter(({ type }) => type === EMPLOYEE_TYPE.CONTRACTOR) ?? [],
    [data]
  );

  return {
    teammates,
    clients,
    refetchEmployees: refetch,
    isEmployeesLoading: isLoading,
  };
};
