import { Typography } from "@mui/material";
import { type Control, useFieldArray, UseFormWatch } from "react-hook-form";
import {
  EmployeeWorkLabelRowStyled,
  ExpenseGroupContainerStyled,
  InputLabelStyled,
  InputLabelStyledEmployee,
  RowStyled,
} from "../styles";
import { palette } from "styles/theme/palette";
import React, { type FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDrawer } from "hooks/useDrawer";
import {
  type ProjectCreateForm,
  type ProjectPhaseEmployee,
  type ProjectPhaseCreate,
} from "interfaces/freelancer/project";
import { useScreenSize } from "hooks/useScreenSize";
import AddHoursModal from "./AddHoursModal";
import ConfirmHoursRemovalModal from "./ConfirmHoursRemovalModal";
import AddClockifyTimeTrackerModal from "./AddClockifyTimeTrackerModal/AddClockifyTimeTrackerModal";
import { type UseFormSetValue } from "react-hook-form/dist/types/form";
import CollapseEmployee from "./CollapseEmployee";
import ConfirmationModal from "components/ConfirmationModal";
import {
  useDeleteTimeLogMutation,
  useGetTimeLogByPhaseAndProjectQuery,
  useGetClockifyProjectsWorkspacesQuery,
} from "store/timeLog/timeLogServices";
import EditHoursModal from "./EditHoursModal";
import { useGetPaidInvoiceItemsByProjectQuery } from "store/invoices/invoicesServices";
import SaveProjectModal from "./SaveProjectModal";
import { ClockifyIntegrationConfig } from "store/productGroup/productGroupTypes";
import { useGetProductGroupTimeEntriesQuery } from "store/productGroup/productGroupServices";

interface EmployeesProps {
  parentIndex: number;
  control: Control<ProjectCreateForm>;
  initialEmployees?: ProjectPhaseEmployee[];
  watch: UseFormWatch<ProjectCreateForm>;
  setValue: UseFormSetValue<ProjectCreateForm>;
  initialPhaseId?: string;
  phase: ProjectPhaseCreate;
  projectId?: string;
  clockifyIntegrationConfig?: ClockifyIntegrationConfig;
  fixTypeProject?: boolean;
  handleSave?: () => Promise<void>;
}

export interface TimeLogToEdit {
  created_at: Date;
  timelog_id: string;
  value: number;
}

const WorkEmployees: FC<EmployeesProps> = ({
  parentIndex,
  control,
  initialEmployees,
  watch,
  setValue,
  initialPhaseId,
  phase,
  projectId,
  clockifyIntegrationConfig,
  fixTypeProject,
  handleSave,
}) => {
  const { t } = useTranslation();
  const {
    isOpen: isConfirmationModalOpen,
    open: openConfirmationModal,
    close: closeConfirmationModal,
  } = useDrawer();
  const {
    isOpen: isEditModalOpen,
    open: openEditModal,
    close: closeEditModal,
  } = useDrawer();
  const {
    isOpen: isOpenHoursModal,
    open: openHoursModal,
    close: closeHoursModal,
  } = useDrawer();
  const {
    isOpen: isOpenClockifyTimeTrackerModal,
    open: openClockifyTimeTrackerModal,
    close: closeClockifyTimeTrackerModal,
  } = useDrawer();
  const {
    isOpen: isOpenConfirmHoursRemovalModal,
    open: openConfirmHoursRemovalModal,
    close: closeConfirmHoursRemovalModal,
  } = useDrawer();
  const {
    isOpen: isSaveProjectModalOpen,
    open: openSaveProjectModal,
    close: closeSaveProjectModal,
  } = useDrawer();
  const { isMobile } = useScreenSize();
  const { fields, replace } = useFieldArray({
    control,
    name: `workPhases.${parentIndex}.expenses.employees`,
  });
  const [timeLogToDelete, setTimeLogToDelete] = useState<string>("");
  const [timeLogToEdit, setTimeLogToEdit] = useState<TimeLogToEdit>();
  const [deleteTimeLog] = useDeleteTimeLogMutation();
  const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState<number>(0);
  const watchEmployees = watch(`workPhases.${parentIndex}.expenses.employees`);
  const controlledEmployees = fields.map((field, index) => {
    return {
      ...field,
      ...watchEmployees[index],
    };
  });
  const { data: timeLogData, refetch: refetchTimeLogData } =
    useGetTimeLogByPhaseAndProjectQuery(
      {
        phase_id: phase?.id ?? "",
        project_id: projectId ?? "",
      },
      { refetchOnMountOrArgChange: true }
    );
  const {
    data: clockifyEmployeesTimeEntries,
    refetch: refetchClockifyEmployeesTimeEntries,
  } = useGetProductGroupTimeEntriesQuery({
    id: phase.id ?? "",
  });
  const { data: paidExpenses } = useGetPaidInvoiceItemsByProjectQuery({
    project_id: projectId,
  });
  const { data: clockifyProjectsWorkspaces } =
    useGetClockifyProjectsWorkspacesQuery({});

  useEffect(() => {
    if (!!initialEmployees?.length) {
      replace(initialEmployees);
    }
  }, []);

  const getEmployeeTimeEntries = ({
    employeeId,
    name,
  }: (typeof controlledEmployees)[0]) => {
    if (clockifyEmployeesTimeEntries !== undefined) {
      return (
        clockifyEmployeesTimeEntries[employeeId] ??
        clockifyEmployeesTimeEntries[name]
      );
    }
  };

  const handleOpenHoursModal = useCallback(({ currentTarget }) => {
    const fieldIndex = currentTarget.getAttribute("data-index");
    if (fieldIndex !== undefined) {
      setSelectedEmployeeIndex(fieldIndex);
      openHoursModal();
    }
  }, []);

  const handleOpenConfirmHoursRemovalModal = useCallback(
    ({ currentTarget }) => {
      const fieldIndex = currentTarget.getAttribute("data-index");

      if (fieldIndex !== undefined) {
        setSelectedEmployeeIndex(fieldIndex);
        openConfirmHoursRemovalModal();
      }
    },
    []
  );
  const handleOpenClockifyTimeTrackerModal = useCallback(
    ({ currentTarget }) => {
      const fieldIndex = currentTarget.getAttribute("data-index");

      if (fieldIndex !== undefined) {
        setSelectedEmployeeIndex(fieldIndex);
        openClockifyTimeTrackerModal();
      }
    },
    []
  );

  const handleOpenConfirmationModal = useCallback((timeLogId: string) => {
    if (timeLogId) {
      setTimeLogToDelete(timeLogId);
      openConfirmationModal();
    }
  }, []);

  const handleOpenEditModal = useCallback(
    (created_at: Date, timeLogId: string, value: number) => {
      if (timeLogId) {
        setTimeLogToEdit({ created_at, timelog_id: timeLogId, value });
        openEditModal();
      }
    },
    []
  );

  const handleDeleteTimeLog = useCallback(async () => {
    if (timeLogToDelete) {
      try {
        await deleteTimeLog(timeLogToDelete);
        await refetchTimeLogData();
        closeConfirmationModal();
      } catch (e) {
        console.error("Error on delete time log, ", e);
      }
    }
  }, [timeLogToDelete]);

  const onSaveSubmit = useCallback(() => {
    handleSave?.();
    closeSaveProjectModal();
  }, [handleSave]);
  const onConfirmHoursRemoval = useCallback(() => {
    closeConfirmHoursRemovalModal();

    if (
      Object.keys(clockifyIntegrationConfig?.employees || {}).includes(
        controlledEmployees[selectedEmployeeIndex]?.employeeId
      ) ||
      Object.keys(clockifyIntegrationConfig?.employees || {}).includes(
        controlledEmployees[selectedEmployeeIndex]?.name
      )
    ) {
      openHoursModal();
    } else {
      openClockifyTimeTrackerModal();
    }
  }, [clockifyIntegrationConfig, controlledEmployees, selectedEmployeeIndex]);

  if (!controlledEmployees?.length) {
    return null;
  }

  const checkIfAddingClockify = () => {
    return (
      !Object.keys(clockifyIntegrationConfig?.employees || {}).includes(
        controlledEmployees[selectedEmployeeIndex]?.employeeId
      ) &&
      !Object.keys(clockifyIntegrationConfig?.employees || {}).includes(
        controlledEmployees[selectedEmployeeIndex]?.name
      )
    );
  };

  return (
    <ExpenseGroupContainerStyled>
      <Typography variant="h1">{t("team")}</Typography>
      {!isMobile && (
        <EmployeeWorkLabelRowStyled>
          <Typography
            variant="regularText"
            color={palette.custom.primary.silver}
          >
            {t("freelancer.page.addEditProject.employee.name.label")}
          </Typography>
          <RowStyled>
            <InputLabelStyledEmployee variant="regularText">
              {t("freelancer.page.addEditProject.employee.amount.label")}
            </InputLabelStyledEmployee>
            <InputLabelStyled variant="regularText">
              {t("freelancer.page.addEditProject.employee.totalHours.label")}
            </InputLabelStyled>
            {!fixTypeProject && (
              <InputLabelStyled variant="regularText">
                {t("freelancer.page.addEditProject.employee.billedHours.label")}
              </InputLabelStyled>
            )}
          </RowStyled>
        </EmployeeWorkLabelRowStyled>
      )}
      {controlledEmployees.map((item, nestedIndex) => (
        <>
          <CollapseEmployee
            key={item.id}
            item={item}
            nestedIndex={nestedIndex}
            parentIndex={parentIndex}
            handleOpenHoursModal={handleOpenHoursModal}
            handleOpenClockifyTimeTrackerModal={
              handleOpenClockifyTimeTrackerModal
            }
            handleOpenConfirmHoursRemovalModal={
              handleOpenConfirmHoursRemovalModal
            }
            control={control}
            setValue={setValue}
            handleOpenConfirmationModal={handleOpenConfirmationModal}
            handleOpenEditModal={handleOpenEditModal}
            timeLogData={timeLogData}
            ableToAddHours={!!initialPhaseId}
            fixTypeProject={fixTypeProject}
            paidExpenses={paidExpenses}
            handleOpenSaveProjectModal={openSaveProjectModal}
            clockifyTimeEntries={getEmployeeTimeEntries(item)}
          />
          <AddHoursModal
            onClose={closeHoursModal}
            isOpen={isOpenHoursModal}
            phase={phase}
            projectId={projectId}
            selectedEmployee={controlledEmployees[selectedEmployeeIndex]}
            refetchTimeLogData={refetchTimeLogData}
            clockifyTimeEntries={getEmployeeTimeEntries(item)}
            refetchClockifyEmployeesTimeEntries={
              refetchClockifyEmployeesTimeEntries
            }
            clockifyIntegrationConfig={clockifyIntegrationConfig}
          />
          <ConfirmHoursRemovalModal
            isAddingClockify={checkIfAddingClockify()}
            isOpen={isOpenConfirmHoursRemovalModal}
            onClose={closeConfirmHoursRemovalModal}
            submitHandler={onConfirmHoursRemoval}
          />
          <AddClockifyTimeTrackerModal
            clockifyProjectsWorkspaces={clockifyProjectsWorkspaces || []}
            phase={phase}
            selectedEmployee={controlledEmployees[selectedEmployeeIndex]}
            onClose={closeClockifyTimeTrackerModal}
            isOpen={isOpenClockifyTimeTrackerModal}
            clockifyIntegrationConfig={clockifyIntegrationConfig}
            timeLogData={timeLogData}
            refetchTimeLogData={refetchTimeLogData}
            refetchClockifyEmployeesTimeEntries={
              refetchClockifyEmployeesTimeEntries
            }
          />
          <EditHoursModal
            timeLogToEdit={timeLogToEdit}
            onClose={closeEditModal}
            isOpen={isEditModalOpen}
            refetchTimeLogData={refetchTimeLogData}
          />
        </>
      ))}
      <ConfirmationModal
        deleteHandler={handleDeleteTimeLog}
        onClose={closeConfirmationModal}
        isOpen={isConfirmationModalOpen}
        title={t("deleteConfirmation")}
      />
      <SaveProjectModal
        submitHandler={onSaveSubmit}
        onClose={closeSaveProjectModal}
        isOpen={isSaveProjectModalOpen}
      />
    </ExpenseGroupContainerStyled>
  );
};

export default WorkEmployees;
