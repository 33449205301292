import { BaseQueryParams } from "store/baseQuery";
import {
  IPipeline,
  IPlanSales,
  IUpdateSalesMonts,
  IUpdateProduct,
  IPipelineCalculate,
  IPlanSalesPipeline,
} from "./dashboardTypes";
import { type IGetProject } from "../projects/projectsTypes";
import { type ProductAsService } from "interfaces/product";

export const dashboardServices = BaseQueryParams("dashboard/calculate", [
  "DASHBOARD",
  "SALES_PLAN",
  "SALES_PLAN_PIPELINE",
  "PIPELINE_PRODUCTS",
]).injectEndpoints({
  endpoints: (builder) => ({
    // GET
    getPipelineById: builder.query<
      IPipeline,
      { project_id: string; pipeline_id: string }
    >({
      query: ({ project_id, pipeline_id }) => ({
        url: `/projects/${project_id}/pipelines/${pipeline_id}`,
        method: "GET",
      }),

      providesTags: () => ["DASHBOARD"],
    }),

    getSalesPlan: builder.query<
      IPlanSalesPipeline[],
      { pipeline_id: string; from: string; to: string }
    >({
      query: ({ pipeline_id, from, to }) => ({
        url: `/pipelines/${pipeline_id}/sales-plan/?limit=12&skip=0&from=${from}&to=${to}`,
        method: "GET",
      }),
      providesTags: () => ["SALES_PLAN_PIPELINE"],
    }),

    getSalesPlanProducts: builder.query<
      IPlanSales[],
      { pipeline_id: string; from: string; to: string }
    >({
      query: ({ pipeline_id, from, to }) => ({
        url: `/pipelines/${pipeline_id}/sales-plan/products/?limit=12&skip=0&from=${from}&to=${to}`,
        method: "GET",
      }),
      providesTags: () => ["SALES_PLAN"],
    }),

    getPipelineCalculate: builder.query<
      IPipelineCalculate,
      { project_id: string; pipeline_id: string }
    >({
      query: ({ project_id, pipeline_id }) => ({
        url: `/projects/${project_id}/pipelines/${pipeline_id}/calculate`,
        method: "POST",
      }),
    }),

    getProjectById: builder.query<IGetProject, { project_id: string }>({
      query: ({ project_id }) => ({
        url: `/projects/${project_id}`,
        method: "GET",
      }),
    }),

    getPipelineProducts: builder.query<
      ProductAsService[],
      { project_id: string; pipeline_id: string }
    >({
      query: ({ project_id, pipeline_id }) => ({
        url: `/projects/${project_id}/pipelines/${pipeline_id}/products`,
        method: "GET",
      }),
      providesTags: () => ["PIPELINE_PRODUCTS"],
    }),

    // PATCH
    updateSalesMonth: builder.mutation<unknown, IUpdateSalesMonts>({
      query: ({ pipeline_id, sales_plan_id, completion_percent }) => ({
        url: `/pipelines/${pipeline_id}/sales-plan/${sales_plan_id}`,
        method: "PATCH",
        body: { completion_percent },
      }),
      invalidatesTags: () => ["SALES_PLAN", "DASHBOARD", "SALES_PLAN_PIPELINE"],
    }),

    updateProduct: builder.mutation<unknown, IUpdateProduct>({
      query: ({ body, product_id }) => ({
        url: `products/${product_id}`,
        method: "PATCH",
        body,
      }),

      invalidatesTags: () => ["DASHBOARD", "SALES_PLAN", "SALES_PLAN_PIPELINE"],
    }),

    //
    deleteProduct: builder.mutation<unknown, string>({
      query: (id) => ({
        url: `/products/${id}`,
        method: "DELETE",
      }),

      invalidatesTags: () => ["DASHBOARD"],
    }),
  }),
});

export const {
  useDeleteProductMutation,
  useGetPipelineByIdQuery,
  useGetSalesPlanQuery,
  useUpdateSalesMonthMutation,
  useUpdateProductMutation,
  useGetSalesPlanProductsQuery,
  useGetProjectByIdQuery,
  useGetPipelineProductsQuery,
  useGetPipelineCalculateQuery,
} = dashboardServices;
