import { type FC } from "react";
import { Controller } from "react-hook-form";
import Modal from "components/UI/Modal";
import { useTranslation } from "react-i18next";
import {
  Button,
  Container,
  ContainerButton,
  ContainerForm,
  InlineFormWrap,
  StyledInput,
  Title,
} from "pages/Teams/AddEmployeeModal/styles";
import { type ProductAsService } from "interfaces/product";
import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";
import {
  ContainerFormStyled,
  TextAreaLabelStyled,
  TextareaStyled,
} from "./styles";
import { useAddEditServiceForm } from "hooks/useAddEditServiceForm";

export interface ServiceForm {
  name: string;
  price: number;
  description: string;
}

interface AddEditServiceModalProps {
  onClose: () => void;
  isOpen: boolean;
  service?: ProductAsService | null;
  refetch?: () => void;
}

const AddEditServiceModal: FC<AddEditServiceModalProps> = ({
  onClose,
  isOpen,
  service,
  refetch,
}) => {
  const { t } = useTranslation();
  const { currency } = useFreelancerDefaults();
  const { control, errors, handleSave, handleClose, isValid } =
    useAddEditServiceForm(service, onClose, refetch);

  const title = service
    ? t("freelancer.page.services.edit")
    : t("freelancer.page.services.create");

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Container>
        <Title variant="h1">{title}</Title>
        <ContainerForm>
          <Controller
            render={({ field }) => (
              <StyledInput
                label={`${t("freelancer.page.services.name.label")} *`}
                {...field}
                isRequired
                isEmpty={(field.value || "").length === 0}
                placeholder={t("freelancer.page.services.name.placeholder")}
                className="name-input"
                error={!!errors?.name?.message}
                helperText={errors?.name?.message}
              />
            )}
            control={control}
            name="name"
            rules={{
              required: { value: true, message: t("fieldIsRequired") },
            }}
          />
        </ContainerForm>
        <ContainerFormStyled className="halfWidth">
          <InlineFormWrap className="inline-input">
            <Controller
              render={({ field }) => (
                <StyledInput
                  fullWidth={false}
                  isRequired
                  isEmpty={`${field.value}`.length < 1}
                  label={`${t("freelancer.page.services.price.label")} *`}
                  {...field}
                  placeholder={t("freelancer.page.services.price.placeholder")}
                  error={!!errors?.price?.message}
                  helperText={errors?.price?.message}
                  type="number"
                />
              )}
              control={control}
              name="price"
              rules={{
                required: {
                  value: true,
                  message: t("fieldIsRequired"),
                },
              }}
            />
          </InlineFormWrap>
          <InlineFormWrap className="inline-input">
            <StyledInput
              fullWidth={false}
              disabled
              label={t("currency")}
              value={currency}
            />
          </InlineFormWrap>
        </ContainerFormStyled>
        <ContainerForm>
          <TextAreaLabelStyled>
            {t("freelancer.page.services.description.label")}
          </TextAreaLabelStyled>
          <Controller
            render={({ field }) => (
              <TextareaStyled
                minRows={4}
                {...field}
                placeholder={t(
                  "freelancer.page.services.description.placeholder"
                )}
              />
            )}
            control={control}
            name="description"
          />
        </ContainerForm>
        <ContainerButton>
          <Button
            disabled={!isValid}
            variant="contained"
            component="span"
            onClick={handleSave}
          >
            {service ? t("Зберегти") : t("Додати")}
          </Button>

          <Button variant="light" onClick={handleClose}>
            {t("Скасувати")}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default AddEditServiceModal;
