import { type FC } from "react";
import { TableBody } from "@mui/material";
import { type Order } from "types/global";
import { getComparator, stableSort } from "utils/teams/helpers";
import { type TableInvoice } from "interfaces/freelancer/invoice";
import InvoiceTableRow from "./InvoiceTableRow";

interface InvoicesTableBodyProps {
  order: Order;
  orderBy: any;
  invoices: TableInvoice[];
  isActive: boolean;
}

const InvoicesTableBody: FC<InvoicesTableBodyProps> = ({
  invoices,
  order,
  orderBy,
  isActive,
}) => {
  return (
    <TableBody>
      {stableSort<TableInvoice>(invoices, getComparator(order, orderBy)).map(
        (invoice) => (
          <InvoiceTableRow invoice={invoice} isActive={isActive} />
        )
      )}
    </TableBody>
  );
};

export default InvoicesTableBody;
