import { FC, useCallback, useEffect } from "react";
import Modal from "components/UI/Modal";
import { Typography } from "@mui/material";
import { Logo } from "assets/logos";
import { useTranslation } from "react-i18next";
import {
  ModalContainerStyled,
  ModalTitleStyled,
  ModalSubtitleStyled,
  ModalOldPriceStyled,
  ModalInputStyled,
  ModalButtonStyled,
} from "layouts/FreelancerLayout/styles";
import { To, useNavigate } from "react-router-dom";
import { freelanceUserRoutesPath } from "routes/routePath";
import { useDrawer } from "hooks/useDrawer";
import { useTrialUser } from "hooks/useTrialUser";

export const EndTrialModal: FC = () => {
  const { t } = useTranslation();
  const { isOpen, open, close } = useDrawer();
  const navigate = useNavigate();
  const { limitedAccess } = useTrialUser();

  const navigateToSubscription = useCallback(() => {
    close();
    navigate(`${freelanceUserRoutesPath.SETTINGS}#plans` as To);
  }, [close, navigate]);

  useEffect(() => {
    if (limitedAccess) {
      open();
    }
  }, [limitedAccess]);

  return (
    <Modal onClose={close} isOpen={isOpen}>
      <ModalContainerStyled>
        <Logo />
        <ModalTitleStyled variant="h1">
          {t("freelancer.endTrialModal.title")}
        </ModalTitleStyled>
        <ModalSubtitleStyled variant="subtitle1">
          {t("freelancer.endTrialModal.subtitle")}
        </ModalSubtitleStyled>
        <ModalOldPriceStyled>
          {t("freelancer.saleModal.oldPrice")}
        </ModalOldPriceStyled>
        <ModalTitleStyled variant="h1">
          {t("freelancer.saleModal.newPrice")}
        </ModalTitleStyled>
        <Typography>{t("freelancer.saleModal.billedMonthly")}</Typography>
        <ModalInputStyled
          type="text"
          disabled
          label={t("freelancer.saleModal.promo.label")}
          value={t("freelancer.saleModal.promo.value")}
          fullWidth
        />
        <ModalButtonStyled variant="contained" onClick={navigateToSubscription}>
          {t("freelancer.endTrialModal.buttonLabel")}
        </ModalButtonStyled>
      </ModalContainerStyled>
    </Modal>
  );
};
