import {
  type ProjectCreateForm,
  ProjectPhaseCreate,
} from "interfaces/freelancer/project";
import { useCallback, useEffect, useState } from "react";
import { type UseFormSetValue } from "react-hook-form/dist/types/form";

interface ProjectDatePickerDataReturn {
  startDate: Date;
  endDate?: Date;
  handleStartDateChange: (date: Date) => void;
  handleEndDateChange: (date: Date) => void;
  disableEndDate: boolean;
  handleDisableEndDate: () => void;
}

export const useProjectDatePickerData = (
  setValue: UseFormSetValue<ProjectCreateForm>,
  index: number,
  phaseName: "phases" | "workPhases",
  initialPhase?: ProjectPhaseCreate
): ProjectDatePickerDataReturn => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [disableEndDate, setDisableEndDate] = useState<boolean>(true);

  const handleDisableEndDate = useCallback(() => {
    setDisableEndDate((prev) => !prev);
  }, []);

  const handleStartDateChange = useCallback(
    (date: Date) => {
      setStartDate(date);
      setValue(`${phaseName}.${index}.startDate`, date);
    },
    [setValue, phaseName, index]
  );

  const handleEndDateChange = useCallback(
    (date: Date) => {
      setEndDate(date);
      setValue(`${phaseName}.${index}.endDate`, date);
    },
    [setValue, phaseName, index]
  );

  useEffect(() => {
    if (disableEndDate) {
      setEndDate(undefined);
      setValue(`${phaseName}.${index}.endDate`, undefined);
    }
  }, [disableEndDate, phaseName, index]);

  useEffect(() => {
    if (initialPhase) {
      setStartDate(new Date(initialPhase.startDate));
      if (initialPhase.endDate) {
        setEndDate(new Date(initialPhase.endDate));
      }
      setDisableEndDate(!initialPhase.endDate);
    }
  }, []);

  return {
    startDate,
    endDate,
    handleStartDateChange,
    handleEndDateChange,
    disableEndDate,
    handleDisableEndDate,
  };
};
