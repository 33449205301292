import { type TFunction } from "i18next";

export const getEmployeeTableLabel = (
  t: TFunction,
  isClient: boolean,
  employeeId?: string
): string => {
  switch (true) {
    case isClient && !employeeId?.length:
      return t("addClient");
    case isClient && !!employeeId?.length:
      return t("editClient");
    case !isClient && !!employeeId?.length:
      return t("editTeammate");
    default:
      return t("addTeammate");
  }
};
