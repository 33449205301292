export interface EmploymentField {
  name:
    | "credentials.employment.executor.name"
    | "credentials.employment.executor.email"
    | "credentials.employment.executor.edrpo"
    | "credentials.employment.executor.address"
    | "credentials.employment.client.name"
    | "credentials.employment.client.email"
    | "credentials.employment.client.edrpo"
    | "credentials.employment.client.address"
    | "credentials.bank.executor.beneficiary.name"
    | "credentials.bank.executor.beneficiary.address"
    | "credentials.bank.executor.beneficiary.swift"
    | "credentials.bank.executor.beneficiary.account"
    | "credentials.bank.executor.correspondent.name"
    | "credentials.bank.executor.correspondent.address"
    | "credentials.bank.executor.correspondent.swift"
    | "credentials.bank.executor.correspondent.account"
    | "credentials.bank.client.beneficiary.name"
    | "credentials.bank.client.beneficiary.address"
    | "credentials.bank.client.beneficiary.swift"
    | "credentials.bank.client.beneficiary.account"
    | "credentials.bank.client.correspondent.name"
    | "credentials.bank.client.correspondent.address"
    | "credentials.bank.client.correspondent.swift"
    | "credentials.bank.client.correspondent.account";
  label: string;
  placeholder: string;
  isRequired?: boolean;
  rules?: {
    pattern: {
      value: RegExp;
      message: string;
    };
  };
}

export const executorBankFields: EmploymentField[] = [
  {
    name: "credentials.bank.executor.beneficiary.name",
    label:
      "freelancer.page.invoices.add.bankDetails.bank.beneficiary.name.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.bank.beneficiary.name.placeholder",
  },
  {
    name: "credentials.bank.executor.beneficiary.address",
    label:
      "freelancer.page.invoices.add.bankDetails.bank.beneficiary.address.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.bank.beneficiary.address.placeholder",
  },
  {
    name: "credentials.bank.executor.beneficiary.swift",
    label:
      "freelancer.page.invoices.add.bankDetails.bank.beneficiary.swift.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.bank.beneficiary.swift.placeholder",
  },
  {
    name: "credentials.bank.executor.beneficiary.account",
    label:
      "freelancer.page.invoices.add.bankDetails.bank.beneficiary.account.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.bank.beneficiary.account.placeholder",
  },
  {
    name: "credentials.bank.executor.correspondent.name",
    label:
      "freelancer.page.invoices.add.bankDetails.bank.correspondent.name.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.bank.correspondent.name.placeholder",
  },
  {
    name: "credentials.bank.executor.correspondent.swift",
    label:
      "freelancer.page.invoices.add.bankDetails.bank.correspondent.swift.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.bank.correspondent.swift.placeholder",
  },
  {
    name: "credentials.bank.executor.correspondent.account",
    label:
      "freelancer.page.invoices.add.bankDetails.bank.correspondent.account.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.bank.correspondent.account.placeholder",
  },
];

export const clientBankFields: EmploymentField[] = [
  {
    name: "credentials.bank.client.beneficiary.name",
    label:
      "freelancer.page.invoices.add.bankDetails.bank.beneficiary.name.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.bank.beneficiary.name.placeholder",
  },
  {
    name: "credentials.bank.client.beneficiary.address",
    label:
      "freelancer.page.invoices.add.bankDetails.bank.beneficiary.address.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.bank.beneficiary.address.placeholder",
  },
  {
    name: "credentials.bank.client.beneficiary.swift",
    label:
      "freelancer.page.invoices.add.bankDetails.bank.beneficiary.swift.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.bank.beneficiary.swift.placeholder",
  },
  {
    name: "credentials.bank.client.beneficiary.account",
    label:
      "freelancer.page.invoices.add.bankDetails.bank.beneficiary.account.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.bank.beneficiary.account.placeholder",
  },
  {
    name: "credentials.bank.client.correspondent.name",
    label:
      "freelancer.page.invoices.add.bankDetails.bank.correspondent.name.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.bank.correspondent.name.placeholder",
  },
  {
    name: "credentials.bank.client.correspondent.swift",
    label:
      "freelancer.page.invoices.add.bankDetails.bank.correspondent.swift.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.bank.correspondent.swift.placeholder",
  },
  {
    name: "credentials.bank.client.correspondent.account",
    label:
      "freelancer.page.invoices.add.bankDetails.bank.correspondent.account.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.bank.correspondent.account.placeholder",
  },
];
