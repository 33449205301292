import { styled } from "@mui/material/styles";
import {
  Box,
  type Breakpoint,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { palette } from "styles/theme/palette";
import { HeaderRow } from "pages/ProductsAndServices/TableHeaderSection/styles";
import { PAGE_CONTENT_WIDTH } from "styles/theme/constants";
import { Button } from "components/ConfirmationModal/styles";

interface ContainerStyledProps {
  withBorder?: boolean;
}
export const ContainerStyled = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "withBorder",
})<ContainerStyledProps>(({ withBorder = false }) => ({
  cursor: "pointer",
  "& > *": {
    borderBottom: withBorder ? `1px solid ${palette.primary.main}` : "unset",
  },
}));

export const TableCellStyled = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "color",
})<{ color?: string }>(({ color = palette.primary.dark }) => ({
  color,
  "& .MuiSelect-select": {
    borderRadius: "8px",
  },
  "&.created": {
    "fieldset, .MuiInputBase-root:focus fieldset, .MuiInputBase-root:hover fieldset":
      {
        borderColor: palette.custom.pink.main,
      },
    "& .MuiSelect-select": {
      color: palette.custom.pink.main,
    },
  },
  "&.paid": {
    "fieldset, .MuiInputBase-root:focus fieldset, .MuiInputBase-root:hover fieldset":
      {
        borderColor: palette.custom.green.chartGreen,
      },
    "& .MuiSelect-select": {
      color: palette.custom.green.chartGreen,
      background: palette.custom.green.light,
    },
  },
  "&.expired": {
    "fieldset, .MuiInputBase-root:focus fieldset, .MuiInputBase-root:hover fieldset":
      {
        borderColor: palette.custom.pink.main,
      },
    "& .MuiSelect-select": {
      color: palette.custom.primary.white,
      background: palette.custom.pink.main,
    },
  },
  "&.canceled": {
    "fieldset, .MuiInputBase-root:focus fieldset, .MuiInputBase-root:hover fieldset":
      {
        borderColor: palette.primary.main,
      },
    "& .MuiSelect-select": {
      color: palette.custom.primary.silver,
      background: palette.primary.main,
    },
  },
}));

export const HeaderRowStyled = styled(HeaderRow)(({ theme }) => ({
  [theme.breakpoints.down("md" as Breakpoint)]: {
    paddingLeft: 0,
  },
}));

export const TableStyled = styled(Table)(({ theme }) => ({
  [theme.breakpoints.down("md" as Breakpoint)]: {
    minWidth: PAGE_CONTENT_WIDTH,
  },
}));

export const InvoiceTableContainerStyled = styled(TableCell)(() => ({
  padding: 0,
  background: palette.custom.silver.main,
  borderBottom: "none",
}));

export const InvoiceTableStyled = styled(Table)(() => ({
  padding: 0,
  "th, td": {
    borderBottom: "none",
  },
}));

export const TableCellCustom = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "minWidth",
})<{ minWidth?: number }>(({ minWidth }) => ({
  minWidth: minWidth ? `${minWidth}px` : "auto",
}));

export const ProjectStatusStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "background",
})<{ background?: string }>(({ background }) => ({
  color: palette.common.white,
  background,
  borderRadius: "4px",
  textAlign: "center",
  fontSize: "14px",
  padding: "4px",
}));

export const DueDateContainerStyled = styled(Box)(() => ({
  minHeight: "380px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  h1: {
    marginBottom: "30px",
  },
}));

export const WarningButtonStyled = styled(Button)(() => ({
  margin: "40px 0 20px",
}));
