import {
  type ProjectGraphExpenses,
  type ProjectPhaseCreate,
} from "interfaces/freelancer/project";
import { getNumber } from "./getNumber";
import { TimeLog } from "../../../store/timeLog/timeLogTypes";
import { ClockifyEmployeesTimeEntries } from "store/productGroup/productGroupTypes";
import dayjs from "dayjs";

export const getFixedProjectExpenses = (
  estimationPhases?: ProjectPhaseCreate[],
  factPhases?: ProjectPhaseCreate[],
  userEmployeeName?: string,
  timeLogData?: TimeLog[],
  clockifyEmployeesByPhasesTimeEntries?: {
    [phaseId: string]: ClockifyEmployeesTimeEntries;
  }
): ProjectGraphExpenses => {
  let estimatedBudget = 0;
  let estimatedCosts = 0;
  let estimatedCostsMe = 0;
  let estimatedCostsTeammate = 0;
  let estimatedCostsAdditional = 0;
  let budgetUsed = 0;
  let budgetUsedMe = 0;
  let budgetUsedTeammates = 0;
  let budgetUsedAdditional = 0;
  let estimatedBudgetServices: { name: string; price: number }[] = [];

  estimationPhases?.forEach(({ expenses }) => {
    expenses.employees.forEach(({ amount, hours }) => {
      estimatedCosts += amount * hours;
    });
    expenses.additionalExpenses.forEach(({ amount }) => {
      estimatedCosts += amount;
      estimatedCostsAdditional += amount;
    });
    expenses.services.forEach(({ price }) => (estimatedBudget += price));

    estimatedBudgetServices = expenses.services.map(({ name, price }) => ({
      name,
      price,
    }));
    const [userEmployee] = expenses.employees.filter(
      ({ name }) => name === userEmployeeName
    );
    if (userEmployee) {
      estimatedCostsMe += userEmployee.hours * userEmployee.amount ?? 0;
    }
    estimatedCostsTeammate =
      estimatedCosts - estimatedCostsMe - estimatedCostsAdditional;
  });

  factPhases?.forEach(({ expenses, id }) => {
    expenses.employees.forEach(({ amount, employeeId }) => {
      const phaseTimeLogsByUserEmployee = timeLogData?.filter(
        ({ employee_id, phase_id }) =>
          phase_id === id &&
          employee_id !== userEmployeeName &&
          employee_id === employeeId
      );
      const phaseTimeLogsOrTimeEntriesByUserEmployee =
        phaseTimeLogsByUserEmployee?.length
          ? phaseTimeLogsByUserEmployee
          : clockifyEmployeesByPhasesTimeEntries?.[id ?? ""]?.[employeeId]?.map(
              ({ end, start }) => ({
                value: dayjs(end).diff(dayjs(start), "m", true),
              })
            ) ?? [];

      const employeeHours = +(
        phaseTimeLogsOrTimeEntriesByUserEmployee.reduce(
          (partialSum, { value }) => partialSum + value,
          0
        ) / 60
      ).toFixed(1);
      budgetUsedTeammates += amount * employeeHours;
    });
    expenses.additionalExpenses.forEach(({ amount }) => {
      budgetUsedAdditional += amount;
    });
    const [userEmployee] = expenses.employees.filter(
      ({ name }) => name === userEmployeeName
    );
    const phaseTimeLogsByUserEmployee = timeLogData?.filter(
      ({ employee_id, phase_id }) =>
        phase_id === id && employee_id === userEmployeeName
    );
    const phaseTimeLogsOrTimeEntriesByUserEmployee =
      phaseTimeLogsByUserEmployee?.length
        ? phaseTimeLogsByUserEmployee
        : clockifyEmployeesByPhasesTimeEntries?.[id ?? ""]?.[
            userEmployeeName ?? ""
          ]?.map(({ end, start }) => ({
            value: dayjs(end).diff(dayjs(start), "m", true),
          })) ?? [];
    const userEmployeeHours = +(
      phaseTimeLogsOrTimeEntriesByUserEmployee.reduce(
        (partialSum, { value }) => partialSum + value,
        0
      ) / 60
    );
    budgetUsedMe += userEmployee ? userEmployeeHours * userEmployee.amount : 0;
    budgetUsed = budgetUsedMe + budgetUsedAdditional + budgetUsedTeammates;
  });

  return {
    budgetUsed: {
      me: getNumber(budgetUsedMe),
      teammates: getNumber(budgetUsedTeammates),
      additional: budgetUsedAdditional,
      total: Math.round(budgetUsed * 100) / 100,
    },
    estimatedCosts: {
      me: getNumber(estimatedCostsMe),
      teammates: getNumber(estimatedCostsTeammate),
      additional: estimatedCostsAdditional,
      total: estimatedCosts,
    },
    estimatedBudget: {
      services: estimatedBudgetServices,
      total: estimatedBudget,
    },
  };
};
