import { type FC, type MouseEvent } from "react";
import { TableBody } from "@mui/material";
import CollapseTableRow from "./CollapseTableRow";
import { type Order } from "types/global";
import { type ProjectWithPhases } from "interfaces/freelancer/project";
import { getComparator, stableSort } from "utils/teams/helpers";

interface ProductsTableBodyProps {
  handleRequestSort: (
    event: MouseEvent,
    property: keyof ProjectWithPhases
  ) => void;
  order: Order;
  orderBy: any;
  projects: ProjectWithPhases[] | [];
}

const ProjectsTableBody: FC<ProductsTableBodyProps> = ({
  projects,
  order,
  orderBy,
}) => {
  return (
    <TableBody>
      {stableSort<ProjectWithPhases>(
        projects,
        getComparator(order, orderBy)
      ).map((project) => (
        <CollapseTableRow key={project.id} withBorder project={project} />
      ))}
    </TableBody>
  );
};

export default ProjectsTableBody;
