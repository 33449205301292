import { type FC } from "react";
import { ContainerStyled, TableCellCustom, TableCellStyled } from "./styles";
import EditDeleteTooltip from "components/Freelancer/EditDeleteTooltip";
import type { TableInvoice } from "interfaces/freelancer/invoice";
import ConfirmationModal from "../../../ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useDrawer } from "hooks/useDrawer";
import Select from "components/UI/Select";
import { getClassNameByState } from "utils/freelancer/helpers/getClassNameByState";
import { useInvoiceDelete } from "hooks/freelancer/useInvoiceDelete";
import { useInvoiceChangeState } from "hooks/freelancer/useInvoiceChangeState";
import { getStateData } from "../../Invoices/data";
import AdditionalTooltipItems from "../../Invoices/InvoicesTable/AdditionalTooltipItems";
import { INVOICE_STATE } from "utils/freelancer/constants";
import { useTrialUser } from "hooks/useTrialUser";
import LimitedAccessTooltip from "components/Freelancer/LimitedAccessTooltip";

interface InvoiceTableRowProps {
  invoice: TableInvoice;
  isActive: boolean;
  onInvoiceDueDateChange: (invoice: TableInvoice) => void;
}

const ProjectInvoiceTableRow: FC<InvoiceTableRowProps> = ({
  invoice,
  isActive,
  onInvoiceDueDateChange,
}) => {
  const { t } = useTranslation();
  const { isOpen, open, close } = useDrawer();
  const handleDeleteInvoice = useInvoiceDelete(invoice.id);
  const { handleChangeState, invoiceState } = useInvoiceChangeState(
    invoice.id,
    invoice.state
  );
  const stateData = getStateData(t, isActive);
  const classNameByState = getClassNameByState(invoiceState);
  const billed = invoiceState === INVOICE_STATE.PAID ? invoice.invoiced : 0;
  const { limitedAccess } = useTrialUser();

  return (
    <>
      <ContainerStyled withBorder={false}>
        <TableCellCustom minWidth={190} component="th" scope="row">
          {invoice.name}
        </TableCellCustom>
        <TableCellStyled width={160} className={classNameByState}>
          {limitedAccess ? (
            <LimitedAccessTooltip>
              <Select
                disabled
                data={stateData}
                value={invoiceState}
                onChange={handleChangeState}
              />
            </LimitedAccessTooltip>
          ) : (
            <Select
              disabled={!isActive}
              data={stateData}
              value={invoiceState}
              onChange={handleChangeState}
            />
          )}
        </TableCellStyled>
        <TableCellCustom minWidth={110}>{invoice.clientName}</TableCellCustom>
        <TableCellCustom minWidth={100}>
          {invoice.startDateFormatted}
        </TableCellCustom>
        <TableCellCustom minWidth={100}>
          {invoice.dueDateFormatted}
        </TableCellCustom>
        <TableCellCustom minWidth={80} />
        <TableCellCustom minWidth={90}>{invoice.invoiced}</TableCellCustom>
        <TableCellCustom minWidth={90}>{billed}</TableCellCustom>
        <TableCellCustom minWidth={30} />
        <EditDeleteTooltip
          onDelete={open}
          showEdit={false}
          showDelete={false}
          additionalItems={
            <AdditionalTooltipItems
              isActive={isActive}
              invoice={invoice}
              onInvoiceDueDateChange={onInvoiceDueDateChange}
            />
          }
        />
        <TableCellCustom minWidth={30} />
      </ContainerStyled>
      <ConfirmationModal
        deleteHandler={handleDeleteInvoice}
        onClose={close}
        isOpen={isOpen}
        title={t("deleteConfirmation")}
      />
    </>
  );
};

export default ProjectInvoiceTableRow;
