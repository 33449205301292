import { styled } from "@mui/material/styles";
import { Typography, Box, Button } from "@mui/material";

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.black?.main};
  width: 100%;
  margin-bottom: 7px;
`;

export const Container = styled(Box)<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile = false }) =>
    isMobile ? "column-reverse" : "row"};
  gap: 10px;
`;

export const Label = styled("span")`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
`;

export const StyledButton = styled(Button)`
  height: 50px;
  padding: 0 20px;
`;
