import { type FC, useCallback, useState } from "react";
import Modal from "components/UI/Modal";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  ContainerButton,
  Title,
} from "pages/Teams/AddEmployeeModal/styles";
import {
  ContainerInput,
  Input as DateInput,
} from "components/UI/DatePicker/styles";
import { DueDateContainerStyled } from "./Projects/ProjectsTable/styles";
import { type TableInvoice } from "interfaces/freelancer/invoice";
import { useUpdateInvoiceMutation } from "store/invoices/invoicesServices";
import { Box } from "@mui/material";
interface DueDateModalProps {
  onClose: () => void;
  isOpen: boolean;
  invoice?: TableInvoice | null;
}
const InvoiceDueDateModal: FC<DueDateModalProps> = ({
  onClose,
  isOpen,
  invoice,
}) => {
  const { t } = useTranslation();
  const [update] = useUpdateInvoiceMutation();
  const defaultDueDate = invoice?.dueDate
    ? new Date(invoice.dueDate)
    : new Date();
  const [dueDate, setDueDate] = useState<Date>(defaultDueDate);

  const handleChangeDueDate = useCallback((newDate: Date) => {
    setDueDate(newDate);
  }, []);

  const updateDueDate = useCallback(async () => {
    if (invoice) {
      try {
        await update({
          invoice_id: invoice?.id,
          body: { valid_until: dueDate },
        });
        onClose();
      } catch (e) {
        console.error("Error on update invoice due date, ", e);
      }
    }
  }, [dueDate, invoice]);

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <DueDateContainerStyled>
        <Box>
          <Title variant="h1">
            {t("freelancer.page.invoices.dueDateUpdate.title")}
          </Title>
          <ContainerInput>
            <DateInput selected={dueDate} onChange={handleChangeDueDate} />
          </ContainerInput>
        </Box>
        <ContainerButton>
          <Button variant="contained" component="span" onClick={updateDueDate}>
            {t("save")}
          </Button>

          <Button variant="light" onClick={onClose}>
            {t("Скасувати")}
          </Button>
        </ContainerButton>
      </DueDateContainerStyled>
    </Modal>
  );
};

export default InvoiceDueDateModal;
