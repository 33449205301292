import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";

// Store
import { useLoginMutation } from 'store/auth/authService';
// Global Components
import AuthBackground from 'components/AuthBackgroud';
// Interfaces
import { ILogin } from 'interfaces/auth';
// Utils
import { defaulLoginValues } from 'utils/constants/auth';
import { errorByType } from 'utils/helpers/index';
// Images
import LoginImage from 'assets/images/login-image.svg';
// Components
import LoginForm from './LoginForm';
// Styles
import { Container } from './styles';
import { useDispatch } from 'react-redux';
import { addEvent } from '../../store/analytics/analyticsService';
import type { SegmentEventPayload } from '../../store/analytics/analyticsTypes';
import { SEGMENT_EVENT_NAME } from '../../utils/constants/analytics';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [login, { isLoading }] = useLoginMutation();
  const formState = useForm<ILogin>({ defaultValues: defaulLoginValues, mode: 'all' });
  const dispatch = useDispatch();

  const onSubmit: SubmitHandler<ILogin> = async (values) => {
    try {
      await login(values).unwrap();
      const eventPayload: SegmentEventPayload = {
        eventName: SEGMENT_EVENT_NAME.LOG_IN,
        payload: { email: values.email },
      };
      dispatch(addEvent(eventPayload));
    } catch (error: any) {
      formState.setError('email', {
        type: 'custom',
        message: t(errorByType(error?.data?.message)),
      });
    }
  };

  return (
    <Container>
      <LoginForm
        formState={formState}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />

      <AuthBackground image={LoginImage} />
    </Container>
  );
};

export default Login;
