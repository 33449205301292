import { type ProjectWithPhases } from "interfaces/freelancer/project";
import { useMemo } from "react";
import {
  EXPENSE_STATUS,
  INVOICE_STATE,
  PROJECT_TYPE,
} from "utils/freelancer/constants";
import { getNumber } from "utils/freelancer/helpers/getNumber";
import { useAllInvoices } from "./useAllInvoices";

interface TableProjectCalculationsReturn {
  total: string;
  billed: string;
  paid: string;
  toBePaid: string;
}

export const useTableProjectCalculations = (
  project: ProjectWithPhases
): TableProjectCalculationsReturn => {
  const { activeInvoices } = useAllInvoices();
  const projectInvoices = useMemo(
    () => activeInvoices?.filter(({ projectId }) => projectId === project.id),
    [project.id, activeInvoices]
  );
  const total = useMemo(() => {
    let totalAmount = 0;
    project?.phases
      ?.filter(({ extras }) => extras.phaseStatus === EXPENSE_STATUS.DRAFT)
      .forEach(({ expenses }) => {
        if (project.type === PROJECT_TYPE.FIX) {
          expenses
            .filter(({ expense_group }) => expense_group.name === "services")
            .forEach(({ value }) => (totalAmount += getNumber(value)));
        } else {
          expenses.forEach(
            ({ amount, value }) => (totalAmount += amount * parseInt(value, 10))
          );
        }
      });

    return totalAmount;
  }, [project]);

  const billed = useMemo((): number => {
    if (!projectInvoices) {
      return 0;
    }
    return projectInvoices
      .filter(({ state }) => state !== INVOICE_STATE.PAID)
      .reduce(
        (partialSum, { invoiced }) => partialSum + getNumber(invoiced),
        0
      );
  }, [projectInvoices]);

  const paid = useMemo((): number => {
    if (!projectInvoices) {
      return 0;
    }
    return projectInvoices
      .filter(({ state }) => state === INVOICE_STATE.PAID)
      .reduce(
        (partialSum, { invoiced }) => partialSum + getNumber(invoiced),
        0
      );
  }, [projectInvoices]);

  return {
    total: total.toFixed(1),
    paid: paid.toFixed(1),
    billed: billed.toFixed(1),
    toBePaid: (total - billed - paid).toFixed(1),
  };
};
