import { FC, useCallback } from "react";
import Modal from "components/UI/Modal";
import { Typography } from "@mui/material";
import { Logo } from "assets/logos";
import { useTranslation } from "react-i18next";
import {
  ModalContainerStyled,
  ModalTitleStyled,
  ModalSubtitleStyled,
  ModalOldPriceStyled,
  ModalInputStyled,
  ModalButtonStyled,
} from "./styles";
import { To, useNavigate } from "react-router-dom";
import { freelanceUserRoutesPath } from "routes/routePath";

interface SaleModalProps {
  onClose: () => void;
  isOpen: boolean;
}

export const SaleModal: FC<SaleModalProps> = ({ onClose, isOpen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToSubscription = useCallback(() => {
    onClose();
    navigate(`${freelanceUserRoutesPath.SETTINGS}#plans` as To);
  }, [onClose, navigate]);

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalContainerStyled>
        <Logo />
        <ModalTitleStyled variant="h1">
          {t("freelancer.saleModal.title")}
        </ModalTitleStyled>
        <ModalSubtitleStyled variant="subtitle1">
          {t("freelancer.saleModal.subtitle")}
        </ModalSubtitleStyled>
        <ModalOldPriceStyled>
          {t("freelancer.saleModal.oldPrice")}
        </ModalOldPriceStyled>
        <ModalTitleStyled variant="h1">
          {t("freelancer.saleModal.newPrice")}
        </ModalTitleStyled>
        <Typography>{t("freelancer.saleModal.billedMonthly")}</Typography>
        <ModalInputStyled
          type="text"
          disabled
          label={t("freelancer.saleModal.promo.label")}
          value={t("freelancer.saleModal.promo.value")}
          fullWidth
        />
        <ModalButtonStyled variant="contained" onClick={navigateToSubscription}>
          {t("freelancer.saleModal.buttonLabel")}
        </ModalButtonStyled>
      </ModalContainerStyled>
    </Modal>
  );
};
