import { type FC, type MouseEvent, useCallback } from "react";
import { TableCell, TableRow, TableSortLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledTableHeader } from "pages/Teams/TeamTable/styles";
import { headCells } from "../data";
import { type Order } from "types/global";
import { useScreenSize } from "hooks/useScreenSize";
import { type TableInvoice } from "interfaces/freelancer/invoice";

interface InvoicesTableProps {
  onRequestSort: (event: MouseEvent, property: keyof TableInvoice) => void;
  order: Order;
  orderBy: string;
}

const InvoicesTableHead: FC<InvoicesTableProps> = ({
  order,
  orderBy,
  onRequestSort,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useScreenSize();
  const createSortHandler = useCallback(
    (property: keyof TableInvoice) => (event: MouseEvent) => {
      onRequestSort(event, property);
    },
    [onRequestSort]
  );

  return (
    <StyledTableHeader>
      <TableRow>
        {headCells.map(({ label, id, width }) => (
          <TableCell
            key={id}
            sortDirection={orderBy === id ? order : false}
            width={width ?? "auto"}
          >
            <TableSortLabel
              active={orderBy === id}
              direction={orderBy === id ? order : "asc"}
              onClick={createSortHandler(id)}
            >
              {t(label)}
            </TableSortLabel>
          </TableCell>
        ))}
        {isDesktop && <TableCell />}
      </TableRow>
    </StyledTableHeader>
  );
};

export default InvoicesTableHead;
