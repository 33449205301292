import { type FC } from "react";
import { Typography } from "@mui/material";
import { TooltipRowStyled, TooltipStyled } from "./styles";
import { type TooltipProps } from "recharts";
import {
  type NameType,
  type ValueType,
} from "recharts/types/component/DefaultTooltipContent";

interface CustomTooltipProps {
  tooltipProps: TooltipProps<ValueType, NameType>;
}
const CustomTooltip: FC<CustomTooltipProps> = ({ tooltipProps }) => {
  const tooltipData: { name: string; value: number }[] =
    tooltipProps?.payload?.[0]?.payload?.tooltip;
  if (!!tooltipData?.length) {
    return (
      <TooltipStyled>
        <Typography
          variant="body1"
          color={tooltipProps?.payload?.[0]?.payload?.color}
        >
          {tooltipProps?.label}
        </Typography>
        {tooltipData?.map(({ name, value }) => (
          <TooltipRowStyled key={`${name}-${value}`}>
            <Typography variant="body1">{name}</Typography>
            <Typography variant="body1">{value?.toFixed(1)}</Typography>
          </TooltipRowStyled>
        ))}
      </TooltipStyled>
    );
  }

  return null;
};

export default CustomTooltip;
