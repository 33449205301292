export enum USER_EXPENSE_TYPE {
  WITHDRAWAL = "withdrawal",
  OTHER = "other",
  HARDWARE = "hardware",
  SOFTWARE = "software",
  ADMINISTRATIVE = "administrative",
}
export enum USER_EXPENSE_DETAILS {
  PERSONAL = "personal",
  OTHER = "other",
}
export interface UserExpense {
  id: string;
  amount: number;
  type: USER_EXPENSE_TYPE;
  details: USER_EXPENSE_DETAILS;
  created_at: Date;
}

export interface UserExpenseUpdate {
  amount: number;
  created_at: Date;
}

export type UserExpenseCreate = Omit<UserExpense, "id" | "created_at">;
