import {
  Container,
  Title,
  SubTitle,
  Wrapper,
  Button,
  Label,
} from "../../TariffPlanCard/TariffPlanCardFooter/styles";
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks/useAuth";
import { Link } from "@mui/material";
import { SecondaryButtonStyled } from "./styles";

interface TariffPlanCardFooterProps {
  onSubmit: () => void;
  typeSubscribe: string;
  price: number;
  isActive: boolean;
  isDisabled: boolean;
  onClose: () => void;
  isUnSubscribe: boolean;
  dateString: string;
  name: string;
}

const TariffPlanCardFooter: React.FC<TariffPlanCardFooterProps> = ({
  onSubmit,
  typeSubscribe,
  isActive,
  isDisabled,
  price,
  onClose,
  dateString,
  isUnSubscribe,
  name,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const currency = user?.currency ?? "$";

  if (!isActive) {
    return (
      <Container>
        <Wrapper>
          <Title variant="h6">
            {t("components.TariffPlanCard.TariffPlanCardFooter.tarriffPrice")}
          </Title>

          <SubTitle isDisabled={isDisabled}>
            {price}$ / {typeSubscribe}.
          </SubTitle>
        </Wrapper>
        <Button disabled={isDisabled} onClick={onSubmit} variant="contained">
          {t("components.TariffPlanCard.TariffPlanCardFooter.Оплатити")}
        </Button>
      </Container>
    );
  }
  return (
    <Container>
      {isActive && !isUnSubscribe && (
        <Label>
          {`${t(
            "components.TariffPlanCard.TariffPlanCardFooter.validUntil"
          )} ${dateString}`}
        </Label>
      )}
      <Wrapper>
        <Title variant="h6">
          {t("components.TariffPlanCard.TariffPlanCardFooter.tarriffPrice")}
        </Title>

        <SubTitle isDisabled={isDisabled}>
          {`${price}${currency} / ${t(typeSubscribe)}.`}
        </SubTitle>
      </Wrapper>
      <SecondaryButtonStyled variant="textIcon">
        <Link
          href={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL ?? ""}
          color="inherit"
          target="_blank"
          underline="none"
        >
          {t("Керувати підпискою")}
        </Link>
      </SecondaryButtonStyled>
    </Container>
  );
};

export default TariffPlanCardFooter;
