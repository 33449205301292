import React, { useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  ToggleButton,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import DatePicker from "react-datepicker";
// Global components
import Modal from "components/UI/Modal";
import Input from "components/UI/Input";
import Select from "components/UI/Select";
import { Label, Container as InputContainer } from "components/UI/Input/styles";
import { CalendarIcon } from "assets/icons";

// Styles
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Title,
  ContainerButton,
  ContainerForm,
  Button,
  InlineFormWrap,
  DatePickerWrapper,
  IconWrap,
  StyledToggleButtonGroup,
  ToggleFormWrap,
  FieldWrap,
} from "./styles";

interface EditSourceModalProps {
  onClose: () => void;
  isOpen: boolean;
  id: string;
}

interface EmployeeForm {
  source: string;
  startDate: Date;
  endDate: Date;
  shareOfsales: string;
  unit: string;
}

const EditSource: React.FC<EditSourceModalProps> = ({
  onClose,
  isOpen,
  id,
}) => {
  const {
    control,
    formState: { errors },
  } = useForm<EmployeeForm>({
    mode: "all",
    defaultValues: {
      source: "1",
      startDate: new Date(),
      endDate: new Date(),
      shareOfsales: "",
      unit: "Шт.",
    },
  });

  const watched = useWatch({
    control,
  });

  const [startDate, setStartDate] = useState(watched.startDate);
  const [endDate, setEndDate] = useState(watched.endDate);

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Container>
        <Title variant="h1">Редагування групи</Title>
        <ContainerForm>
          <FieldWrap key={watched.source}>
            <Controller
              render={({ field }) => (
                <Select
                  label="Джерело"
                  data={[]}
                  {...field}
                  className="amount-input"
                  fullWidth
                />
              )}
              control={control}
              name="source"
              rules={{ required: "fieldIsRequired" }}
            />
          </FieldWrap>
          <InlineFormWrap>
            <InputContainer className="inline-input time-input">
              <Label>Дата початку</Label>
              <DatePickerWrapper>
                <DatePicker
                  selected={startDate}
                  onChange={(date: Date) => setStartDate(date)}
                />
                <IconWrap>
                  <CalendarIcon />
                </IconWrap>
              </DatePickerWrapper>
            </InputContainer>
            <InputContainer className="inline-input">
              <Label>Дата завершення</Label>
              <DatePickerWrapper>
                <DatePicker
                  selected={endDate}
                  onChange={(date: Date) => setEndDate(date)}
                />
                <IconWrap>
                  <CalendarIcon />
                </IconWrap>
              </DatePickerWrapper>
            </InputContainer>
          </InlineFormWrap>
          <InlineFormWrap className="checkbox-container">
            <InputContainer>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="Не визначено" />
              </FormGroup>
            </InputContainer>
          </InlineFormWrap>
          <FieldWrap className="toggle-container">
            <InputContainer>
              <Controller
                render={({ field }) => (
                  <Input
                    label="Доля продажів"
                    {...field}
                    className="amount-input"
                  />
                )}
                control={control}
                name="shareOfsales"
                rules={{ required: "fieldIsRequired" }}
              />
            </InputContainer>
            <InputContainer>
              <Controller
                render={({ field }) => (
                  <StyledToggleButtonGroup {...field} exclusive>
                    <ToggleButton value="%">%</ToggleButton>
                    <ToggleButton value="Шт">Шт.</ToggleButton>
                  </StyledToggleButtonGroup>
                )}
                control={control}
                name="unit"
                rules={{ required: "fieldIsRequired" }}
              />
            </InputContainer>
          </FieldWrap>
        </ContainerForm>
        <ContainerButton>
          <Button variant="contained" component="span">
            Зберегти
          </Button>

          <Button variant="light" onClick={onClose}>
            Скасувати
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default EditSource;
