import { Modal, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

interface BodyProps {
  maxWidth: number | undefined;
  radius?: number;
  visibleOverflow?: boolean;
}

export const Container = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

export const IconContainer = styled(Box)`
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.5;
    transition: all 0.5s ease;
  }
`;

export const Body = styled(Box, {
  shouldForwardProp: (prop) => prop !== "radius" && prop !== "visibleOverflow",
})<BodyProps>`
  outline: none;
  background-color: #ffffff;
  padding: 40px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.01);
  border-radius: ${({ radius }) => (radius ? `${radius}px` : "20px")};
  position: relative;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || "500px"};
  max-height: 95%;
  overflow-y: ${({ visibleOverflow }) =>
    visibleOverflow ? "visible" : "auto"};

  @media (max-width: 768px) {
    padding: 25px;
  }
`;
