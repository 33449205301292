import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useUpdateUserMutation } from "store/auth/authService";
// Interface
import { IUserProfile } from "interfaces/user-profile";
// Hooks
import { useAuth } from "hooks/useAuth";
// Constants
import { INFO_INPUTS_VALUES } from "utils/constants/user-profile";
import Footer from "components/Footer";
import UserTitle from "./UserTitle";
import UserInfo from "./UserInfo";
import UserProfileLanguage from "./UserProfileLanguage";
import UserSubscriptionOptions from "./UserSubscriptionOptions";
import UserTariffPlans from "./UserTariffPlans";
import TransactionHistory from "./TransactionHistory";
import UserGenerateApiKey from "./UserGenerateApiKey";
import SuccessSubscribeModal from "./SuccessSubscribeModal";
// Styles
import { Container } from "./styles";

const UserProfile: FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const [openModal, setOpenModal] = useState(false);
  const [searchParams] = useSearchParams();
  const subscribe = searchParams.get("session_id");

  const onClose = () => {
    setOpenModal((prev) => !prev);

    if (openModal) {
      navigate("/profile");
    }
  };

  const formState = useForm<IUserProfile>({
    defaultValues: INFO_INPUTS_VALUES,
    mode: "all",
  });

  const onSubmit: SubmitHandler<IUserProfile> = async (values) => {
    try {
      const { firstname, lastname, phone_number, residence } = values;

      await updateUser({
        body: {
          firstname,
          lastname,
          phone_number,
          residence,
          settings: {
            language: user?.settings?.language || "",
            clockifyApiKey: user?.settings?.clockifyApiKey || "",
          },
        },
      }).unwrap();

      if ((user && !user?.firstname) || !user?.lastname) {
        navigate("/video/lesson");
      }
    } catch (err) {
      formState.setError("firstname", {
        type: "custom",
        message: "Помилка сервера",
      });
    }
  };

  useEffect(() => {
    setOpenModal(!!subscribe);
  }, [subscribe]);

  useEffect(() => {
    if (user && user?.user_id) {
      const entries = Object.entries(user);

      entries.forEach(([name, value]) =>
        formState.setValue(name as keyof IUserProfile, value)
      );
    }
  }, [user]);

  useEffect(() => {
    if ((user && !user?.firstname) || !user?.lastname) {
      formState.trigger();
    }
  }, [user]);

  return (
    <Container>
      <UserTitle />

      <UserInfo
        formState={formState}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />

      <UserProfileLanguage />

      {/* <RequestHistory /> */}

      <UserSubscriptionOptions />

      <UserTariffPlans
        isUnSubscribe={user?.status === 2}
        membershipStatus={user?.membership_status as string}
      />

      <TransactionHistory />

      <UserGenerateApiKey />

      <SuccessSubscribeModal onClose={onClose} open={openModal} />

      <Footer isSpace />
    </Container>
  );
};

export default UserProfile;
