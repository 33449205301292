import { styled } from "@mui/material/styles";
import { ToggleButtonGroup, ToggleButton, Box } from "@mui/material";

export const Wrapper = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Container = styled(ToggleButtonGroup)`
  height: fit-content;
  background-color: #f6f6f6;
  width: fit-content;
`;

export const Button = styled(ToggleButton)`
  && {
    width: fit-content;
    height: 40px;
    padding: 10px 20px;
    min-height: 32px;
  }

  &.Mui-selected {
    color: ${({ theme }) => theme?.palette?.primary.contrastText};
  }
  &.Mui-disabled.Mui-selected {
    background: ${({ theme }) => theme?.palette?.custom.grey.light};
    color: ${({ theme }) => theme?.palette?.custom.grey.lightIco};
  }
`;

export const ButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "20px",
  "& > .MuiButton-root": {
    margin: 0,
  },
});

export const Label = styled("span")`
  color: #838383;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
  margin-bottom: 10px;
`;
