import {
  LoadingContainerStyled,
  TransactionsContentBoxStyled,
} from "../styles";
import { type FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { ContentContainerStyled } from "../../Projects/AddEdit/styles";
import TransactionsTable from "./TransactionsTable";
import { useGetTransactionsQuery } from "store/transactions/transactionsServices";
import Loading from "components/UI/Loading";
import { type UserTransaction } from "store/transactions/transactionsTypes";
import dayjs from "dayjs";

interface TransactionsProps {
  startDate: Date | null;
  endDate: Date | null;
}
const Transactions: FC<TransactionsProps> = ({ startDate, endDate }) => {
  const { t } = useTranslation();
  const {
    data: transactions,
    isLoading,
    refetch: refetchTransactions,
  } = useGetTransactionsQuery(null);
  const [filteredTransactions, setFilteredTransactions] = useState<
    UserTransaction[]
  >([]);

  useEffect(() => {
    refetchTransactions();

    if (startDate && endDate) {
      const withinInterval =
        transactions?.filter(
          ({ created_at }) =>
            dayjs(created_at).isAfter(dayjs(startDate)) &&
            dayjs(created_at).isBefore(dayjs(endDate))
        ) ?? [];
      setFilteredTransactions(withinInterval);
    }
  }, [startDate, endDate, transactions]);

  return (
    <TransactionsContentBoxStyled>
      <Typography variant="h1">
        {t("freelancer.page.dashboard.transactions.title")}
      </Typography>
      <ContentContainerStyled>
        {isLoading ? (
          <LoadingContainerStyled>
            <Loading size={40} />
          </LoadingContainerStyled>
        ) : (
          <TransactionsTable transactions={filteredTransactions} />
        )}
      </ContentContainerStyled>
    </TransactionsContentBoxStyled>
  );
};

export default Transactions;
