import type {
  ProjectPhaseAdditionalExpense,
  ProjectPhaseEmployee,
  ProjectPhaseService,
  ProjectWithPhases,
} from "interfaces/freelancer/project";
import type { PhaseExpense } from "interfaces/product";
import { EXPENSE_STATUS, INVOICE_STATE, PROJECT_STATUS } from "./constants";
import { palette } from "styles/theme/palette";
import { getNumber } from "./helpers/getNumber";

interface MapExpensesReturn {
  employees: ProjectPhaseEmployee[];
  services: ProjectPhaseService[];
  additionalExpenses: ProjectPhaseAdditionalExpense[];
}

export const mapExpenses = (expenses: PhaseExpense[]): MapExpensesReturn => {
  const employeeExpenses = expenses.filter(
    ({ expense_group }) => expense_group.name === "employees"
  );
  const additionalExpensesSource = expenses.filter(
    ({ expense_group }) => expense_group.name === "additionalExpenses"
  );
  const serviceExpenses = expenses.filter(
    ({ expense_group }) => expense_group.name === "services"
  );
  const employees: ProjectPhaseEmployee[] = employeeExpenses?.map(
    ({ name, amount, value, status, expense_group, extras, id }) => ({
      name,
      id,
      expenseId: id,
      employeeId: extras?.employeeId ?? "",
      amount: +value,
      billableHours: 0,
      hours: amount,
      status: status ?? EXPENSE_STATUS.DRAFT,
      expense_group_id: expense_group.expense_group_id,
    })
  );

  const additionalExpenses: ProjectPhaseAdditionalExpense[] =
    additionalExpensesSource?.map(
      ({ name, value, status, expense_group, id }) => ({
        name,
        id,
        expenseId: id,
        amount: +value,
        status: status ?? EXPENSE_STATUS.DRAFT,
        expense_group_id: expense_group.expense_group_id,
      })
    );

  const services: ProjectPhaseService[] = serviceExpenses?.map(
    ({ name, value, product_id, status, expense_group, id }) => ({
      name,
      id,
      expenseId: id,
      price: +value,
      serviceId: product_id,
      status: status ?? EXPENSE_STATUS.DRAFT,
      expense_group_id: expense_group.expense_group_id,
    })
  );

  return { employees, additionalExpenses, services };
};

export const getProjectStatus = (
  project: ProjectWithPhases,
  projectTotalCost: string
): PROJECT_STATUS => {
  let status = PROJECT_STATUS.FIRST_MEETING;
  if (project.phases) {
    const hasPromotedPhase = project.phases.some(
      ({ extras }) => extras.phaseStatus === EXPENSE_STATUS.SUBMITTED
    );
    if (hasPromotedPhase) {
      status = PROJECT_STATUS.IN_WORK;
    }
  }

  if (project.invoices) {
    const hasUnpaidInvoice = project.invoices.some(
      ({ state }) =>
        state !== INVOICE_STATE.PAID && state !== INVOICE_STATE.CANCELED
    );
    if (hasUnpaidInvoice) {
      status = PROJECT_STATUS.PAYMENT_PENDING;
    }
    const paidTotal = project.invoices
      .filter(({ state }) => state === INVOICE_STATE.PAID)
      .reduce(
        (partialSum, { invoiced }) => partialSum + getNumber(invoiced),
        0
      );

    if (
      getNumber(projectTotalCost) > 0 &&
      paidTotal >= getNumber(projectTotalCost)
    ) {
      status = PROJECT_STATUS.FINISHED;
    }
  }

  return status;
};

export const getProjectStatusColor = (status: PROJECT_STATUS): string => {
  const { green, yellow, pink, grey } = palette.custom;
  switch (status) {
    case PROJECT_STATUS.FINISHED:
      return green.chartGreen;
    case PROJECT_STATUS.FIRST_MEETING:
      return yellow.annotations;
    case PROJECT_STATUS.IN_WORK:
      return pink.main;
    case PROJECT_STATUS.PAYMENT_PENDING:
      return grey.secondary;
    default:
      return palette.custom.yellow.annotations;
  }
};
