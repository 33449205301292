import {
  GRAPH_DATA_TYPE,
  ProjectGraphData,
  ProjectGraphExpenses,
  ProjectGraphNumbers,
  type ProjectPhaseCreate,
} from "interfaces/freelancer/project";
import { useTranslation } from "react-i18next";
import { getFixedProjectExpenses } from "utils/freelancer/helpers/getExpenses";
import { palette } from "styles/theme/palette";
import { useMemo } from "react";
import { useFreelancerDefaults } from "../useFreelancerDefaults";
import {
  INVOICE_STATE,
  PROJECT_TYPE,
  URL_PARAMS,
} from "utils/freelancer/constants";
import { type TFunction } from "i18next";
import { getUserEmployeeCalculations } from "utils/freelancer/helpers/getUserEmployeeCalculations";
import { useAuth } from "../useAuth";
import { getNumber } from "utils/freelancer/helpers/getNumber";
import { useProjectInvoices } from "./useProjectInvoices";
import { type InvoiceItem } from "store/invoices/invoicesTypes";
import { useGetTimeLogByProjectQuery } from "store/timeLog/timeLogServices";
import { useSearchParams } from "react-router-dom";
import { useGetProductGroupTimeEntriesQuery } from "store/productGroup/productGroupServices";
import { ClockifyEmployeesTimeEntries } from "store/productGroup/productGroupTypes";

export interface ProjectCalculationsReturn {
  graphData: ProjectGraphData[];
  numbers: ProjectGraphNumbers[];
}

export const useGraphDataFixedProject = (
  { budgetUsed, estimatedBudget, estimatedCosts }: ProjectGraphExpenses,
  paymentsReceived: number,
  t: TFunction
) => {
  const { purple, green, red } = palette.custom;
  return useMemo(
    () => [
      {
        type: GRAPH_DATA_TYPE.ESTIMATED_BUDGET,
        name: t("estimatedBudget"),
        value: estimatedBudget.total,
        color: purple.dark,
        tooltip:
          estimatedBudget.services?.map(({ name, price }) => ({
            name,
            value: +price,
          })) ?? [],
      },
      {
        type: GRAPH_DATA_TYPE.PAYMENT_RECEIVED,
        name: t("paymentReceived"),
        value: paymentsReceived,
        color: green.chartGreen,
        tooltip: [],
      },
      {
        type: GRAPH_DATA_TYPE.ESTIMATED_COSTS,
        name: t("estimatedCosts"),
        value: estimatedCosts.total,
        color: red.dark,
        tooltip: [
          { name: t("me"), value: estimatedCosts.me },
          {
            name: t("teammates"),
            value: estimatedCosts.teammates,
          },
          {
            name: t("additionalExpenses"),
            value: estimatedCosts.additional,
          },
        ],
      },
      {
        type: GRAPH_DATA_TYPE.BUDGET_USED,
        name: t("budgetUsed"),
        value: budgetUsed.total,
        color: red.silver,
        tooltip: [
          { name: t("me"), value: budgetUsed.me },
          {
            name: t("teammates"),
            value: budgetUsed.teammates,
          },
          { name: t("additionalExpenses"), value: budgetUsed.additional },
        ],
      },
    ],
    [estimatedCosts, estimatedBudget, budgetUsed]
  );
};

export const useGraphDataTMProject = (
  { budgetUsed, estimatedBudget, estimatedCosts }: ProjectGraphExpenses,
  paymentsReceived: number,
  t: TFunction
) => {
  const { green, red, purple } = palette.custom;
  return useMemo(
    () => [
      {
        type: GRAPH_DATA_TYPE.ESTIMATED_BUDGET,
        name: t("estimatedBudget"),
        value: estimatedCosts.total,
        color: purple.dark,
        tooltip: [
          { name: t("me"), value: estimatedCosts.me },
          {
            name: t("teammates"),
            value: estimatedCosts.teammates,
          },
          {
            name: t("additionalExpenses"),
            value: estimatedCosts.additional,
          },
        ],
      },
      {
        type: GRAPH_DATA_TYPE.PAYMENT_RECEIVED,
        name: t("paymentReceived"),
        value: paymentsReceived,
        color: green.chartGreen,
        tooltip: [],
      },
      {
        type: GRAPH_DATA_TYPE.BUDGET_USED,
        name: t("budgetUsed"),
        value: budgetUsed.total,
        color: red.silver,
        tooltip: [
          { name: t("me"), value: budgetUsed.me },
          {
            name: t("teammates"),
            value: budgetUsed.teammates,
          },
          { name: t("additionalExpenses"), value: budgetUsed.additional },
        ],
      },
    ],
    [estimatedCosts, estimatedBudget, budgetUsed]
  );
};

export const useProjectCalculations = (
  phases?: ProjectPhaseCreate[],
  workPhases?: ProjectPhaseCreate[],
  projectType?: PROJECT_TYPE,
  projectId?: string
): ProjectCalculationsReturn => {
  const { t } = useTranslation();
  const { currency } = useFreelancerDefaults();
  const { user } = useAuth();
  const { paymentsReceived, projectInvoices } = useProjectInvoices(projectId);
  const [searchParams] = useSearchParams();
  const projectIdFromUrl = searchParams.get(URL_PARAMS.PROJECT_ID);
  const { data: timeLogData } = useGetTimeLogByProjectQuery({
    project_id: projectIdFromUrl ?? "",
  });

  const { data: phase0TimeEntries } = useGetProductGroupTimeEntriesQuery({
    id: workPhases?.[0]?.id ?? "",
  });
  const { data: phase1TimeEntries } = useGetProductGroupTimeEntriesQuery({
    id: workPhases?.[1]?.id ?? "",
  });
  const { data: phase2TimeEntries } = useGetProductGroupTimeEntriesQuery({
    id: workPhases?.[2]?.id ?? "",
  });
  const { data: phase3TimeEntries } = useGetProductGroupTimeEntriesQuery({
    id: workPhases?.[3]?.id ?? "",
  });
  const { data: phase4TimeEntries } = useGetProductGroupTimeEntriesQuery({
    id: workPhases?.[4]?.id ?? "",
  });
  const clockifyEmployeesByPhasesTimeEntries: {
    [phaseId: string]: ClockifyEmployeesTimeEntries;
  } =
    [
      {
        id: workPhases?.[0]?.id ?? 0,
        entries: phase0TimeEntries,
      },
      {
        id: workPhases?.[1]?.id ?? 1,
        entries: phase1TimeEntries,
      },
      {
        id: workPhases?.[2]?.id ?? 2,
        entries: phase2TimeEntries,
      },
      {
        id: workPhases?.[3]?.id ?? 3,
        entries: phase3TimeEntries,
      },
      {
        id: workPhases?.[4]?.id ?? 4,
        entries: phase4TimeEntries,
      },
    ]?.reduce(
      (
        allEmployeesByPhaseEntries: {
          [phaseId: string]: ClockifyEmployeesTimeEntries;
        },
        { id: phaseId, entries: phaseTimeEntries }
      ) => {
        const updatedEmployeesTimeEntries: ClockifyEmployeesTimeEntries =
          Object.entries(phaseTimeEntries ?? []).reduce(
            (allPhaseEmployeesTimeEntries, [employeeId, entries]) =>
              Object.assign({}, allPhaseEmployeesTimeEntries, {
                [employeeId]: entries.concat(
                  allEmployeesByPhaseEntries[phaseId]?.[employeeId] ?? []
                ),
              }),
            {}
          );

        return Object.assign({}, allEmployeesByPhaseEntries, {
          [phaseId]: updatedEmployeesTimeEntries,
        });
      },
      {}
    ) ?? {};
  const clockifyEmployeesTimeEntries: ClockifyEmployeesTimeEntries =
    [
      phase0TimeEntries,
      phase1TimeEntries,
      phase2TimeEntries,
      phase3TimeEntries,
      phase4TimeEntries,
    ]?.reduce(
      (allEmployeesEntries: ClockifyEmployeesTimeEntries, phaseTimeEntries) => {
        const updatedEmployeesTimeEntries: ClockifyEmployeesTimeEntries =
          Object.entries(phaseTimeEntries ?? []).reduce(
            (allPhaseEmployeesTimeEntries, [employeeId, entries]) =>
              Object.assign({}, allPhaseEmployeesTimeEntries, {
                [employeeId]: entries.concat(
                  allEmployeesEntries[employeeId] ?? []
                ),
              }),
            {}
          );

        return Object.assign(
          {},
          allEmployeesEntries,
          updatedEmployeesTimeEntries
        );
      },
      {}
    ) ?? {};
  const { budgetUsed, estimatedBudget, estimatedCosts } =
    getFixedProjectExpenses(
      phases,
      workPhases,
      user?.user_id,
      timeLogData,
      clockifyEmployeesByPhasesTimeEntries
    );
  const graphDataFixedProject = useGraphDataFixedProject(
    { budgetUsed, estimatedBudget, estimatedCosts },
    paymentsReceived,
    t
  );
  const graphDataTMProject = useGraphDataTMProject(
    { budgetUsed, estimatedBudget, estimatedCosts },
    paymentsReceived,
    t
  );

  const invoiceItems: InvoiceItem[] = [];
  projectInvoices
    .filter((invoice) => invoice.state === INVOICE_STATE.PAID)
    .forEach(({ items }) => {
      const itemsByMe = items?.filter(
        (item) => item?.name && item.name === user?.user_id
      );
      if (itemsByMe?.length) {
        invoiceItems.push(...itemsByMe);
      }
    });
  const projectIncomeByMe: number = invoiceItems.reduce(
    (acc, { price, quantity }) => acc + price * quantity,
    0
  );
  const { myHours, myRate } = getUserEmployeeCalculations(
    projectIncomeByMe,
    user?.user_id,
    phases,
    workPhases,
    timeLogData,
    projectId,
    clockifyEmployeesTimeEntries
  );

  const graphData =
    projectType === PROJECT_TYPE.HOURLY
      ? graphDataTMProject
      : graphDataFixedProject;

  const estimatedProfitability =
    estimatedBudget.total < 1
      ? 0
      : ((estimatedBudget.total - estimatedCosts.total) /
          estimatedBudget.total) *
        100;

  const realProfitability =
    paymentsReceived < 1
      ? 0
      : ((paymentsReceived - budgetUsed.total) / paymentsReceived) * 100;

  const fixedPriceNumbers = [
    {
      label: t("profit"),
      data: [
        {
          label: t("estimated"),
          value: estimatedBudget.total - estimatedCosts.total,
          unit: currency,
        },
        {
          label: t("real"),
          value: paymentsReceived - budgetUsed.total,
          unit: currency,
        },
      ],
    },
    {
      label: t("profitability"),
      data: [
        {
          label: t("estimated"),
          value: getNumber(estimatedProfitability).toFixed(2),
          unit: "%",
        },
        {
          label: t("real"),
          value: getNumber(realProfitability).toFixed(2),
          unit: "%",
        },
      ],
    },
  ];

  const tmPriceNumbers = [
    {
      label: t("myRate", { currency }),
      data: [
        {
          label: t("estimated"),
          value: getNumber(myRate.estimated).toFixed(2),
          unit: currency,
        },
        {
          label: t("real"),
          value: getNumber(myRate.real).toFixed(2),
          unit: currency,
        },
      ],
    },
  ];

  const numbers = [
    ...(projectType === PROJECT_TYPE.FIX ? fixedPriceNumbers : []),
    {
      label: t("myHours"),
      data: [
        {
          label: t("estimated"),
          value: myHours.estimated,
        },
        {
          label: t("worked"),
          value: myHours.real.toFixed(1),
        },
      ],
    },
    ...(projectType === PROJECT_TYPE.HOURLY ? tmPriceNumbers : []),
  ];

  return { graphData, numbers };
};
