import React                     from 'react';
import { Logo }        from 'assets/logos';
import { ArrowLeftIcon }         from 'assets/icons';
// Styles
import { Container, BackButton, StyledLogoText  } from './styles';

interface SideBarHeaderProps {
  expanded: boolean;
  setExpanded: () => void;
}

const SideBarHeader: React.FC<SideBarHeaderProps> = ({ expanded, setExpanded }) => (
  <Container
    expanded={expanded}
  >
    <Logo
      className="logo"
      onClick={expanded ? undefined : setExpanded}
    />
    {expanded && (
      <>
        <StyledLogoText />
        <BackButton
          onClick={setExpanded}
        >
          <ArrowLeftIcon />
        </BackButton>
      </>
    )}
  </Container>
);

export default SideBarHeader;
