import { type UseFormWatch } from "react-hook-form";
import { type InvoiceCreateForm } from "interfaces/freelancer/invoice";

export const getInvoiceFieldsAbleToPreview = (
  watch: UseFormWatch<InvoiceCreateForm>,
  isValid: boolean
): boolean => {
  const watchedClientName = watch("credentials.employment.client.name");
  const watchedClientEdrpo = watch("credentials.employment.client.edrpo");
  const watchedClientAddress = watch("credentials.employment.client.address");
  const watchedClientEmail = watch("credentials.employment.client.email");
  const watchedName = watch("credentials.employment.executor.name");
  const watchedEdrpo = watch("credentials.employment.executor.edrpo");
  const watchedAddress = watch("credentials.employment.executor.address");
  const watchedEmail = watch("credentials.employment.executor.email");

  return (
    !!watchedClientName?.length &&
    !!watchedClientAddress?.length &&
    !!watchedClientEdrpo?.length &&
    !!watchedClientEmail?.length &&
    !!watchedName?.length &&
    !!watchedAddress?.length &&
    !!watchedEdrpo?.length &&
    !!watchedEmail?.length &&
    isValid
  );
};
