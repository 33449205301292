import { type ChangeEvent, type MouseEvent, useState } from "react";
import type { TableData } from "../types/tables";
import type { IProduct, ProductAsService } from "../interfaces/product";
import type { Employee } from "../store/employees/employeesTypes";

export const useSelectTableData = (
  tableData: TableData<IProduct | Employee | ProductAsService>[]
) => {
  const [selected, setSelected] = useState<string[]>([]);
  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData?.reduce((acc: string[], { rows }) => {
        acc = [...acc, ...rows.map((n) => n.name)];
        return acc;
      }, []);
      setSelected(newSelecteds || []);
      return;
    }
    setSelected([]);
  };
  const handleSelectAllDepartmentClick = (
    { target }: ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    if (target.checked) {
      const selectedItem = tableData.find((d) => d.name === name);
      const newSelecteds = selectedItem?.rows.map((n) => n.name);
      setSelected(newSelecteds || []);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event: MouseEvent, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected || []);
  };
  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return {
    selected,
    isSelected,
    handleSelectAllDepartmentClick,
    handleClick,
    handleSelectAllClick,
  };
};
