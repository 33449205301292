import { FC } from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/UI/Modal";
import {
  Button,
  Container,
  ContainerButton,
  Title,
} from "components/ConfirmationModal/styles";

interface ModalProps {
  isAddingClockify: boolean;
  submitHandler?: () => void;
  onClose: () => void;
  isOpen: boolean;
}

const ConfirmHoursRemovalModal: FC<ModalProps> = ({
  isAddingClockify,
  submitHandler,
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation();
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Container>
        <Title variant="h1">
          {isAddingClockify
            ? t(
                "freelancer.page.addEditProject.employee.confirmHoursRemoval.title-manual-hours"
              )
            : t(
                "freelancer.page.addEditProject.employee.confirmHoursRemoval.title-clockify-hours"
              )}
        </Title>

        <ContainerButton>
          <Button
            isDelete={false}
            onClick={submitHandler}
            variant="contained"
            component="span"
          >
            {t(
              "freelancer.page.addEditProject.employee.confirmHoursRemoval.submitTitle"
            )}
          </Button>
          <Button variant="light" onClick={onClose}>
            {t(
              "freelancer.page.addEditProject.employee.confirmHoursRemoval.cancelTitle"
            )}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default ConfirmHoursRemovalModal;
