import type {
  ProjectExpense,
  ProjectPhaseCreate,
} from "interfaces/freelancer/project";

export const getPhaseExpenses = (
  phase: ProjectPhaseCreate,
  currency: string,
  isUpdate = false
): ProjectExpense[] => {
  const employeeExpenses = phase.expenses.employees?.map(
    ({ hours, amount, employeeId, name, expense_group_id, id, expenseId }) => {
      const value = isUpdate ? `=${employeeId}.salary` : `${amount}`;
      return {
        amount: hours,
        name: name,
        id: expenseId ?? id ?? "",
        value,
        expense_group_id,
        extras: {
          dimension: currency,
          employeeId: employeeId,
        },
        group: {
          name: "employees",
          parent_id: "",
        },
        product_id: phase.id,
      };
    }
  );

  const serviceExpenses = phase.expenses.services?.map((service) => {
    return {
      amount: 1,
      name: service.name,
      id: service.expenseId ?? service.id ?? "",
      value: `${service.price}`,
      expense_group_id: service.expense_group_id,
      extras: {
        dimension: currency,
      },
      group: {
        name: "services",
        parent_id: "",
      },
      product_id: phase.id,
    };
  });
  const additionalExpenses = phase.expenses.additionalExpenses?.map(
    (expense) => {
      return {
        amount: 1,
        name: expense.name,
        id: expense.expenseId ?? expense.id ?? "",
        expense_group_id: expense.expense_group_id,
        value: `${expense.amount}`,
        extras: {
          dimension: currency,
        },
        group: {
          name: "additionalExpenses",
          parent_id: "",
        },
        product_id: phase.id,
      };
    }
  );

  return [...employeeExpenses, ...serviceExpenses, ...additionalExpenses];
};
