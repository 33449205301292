import { type FC } from "react";
import { Container, StyledLoading } from "./styles";

interface LoadingProps {
  size?: number;
}

const Loading: FC<LoadingProps> = ({ size = 80 }) => (
  <Container>
    <StyledLoading size={size} />
  </Container>
);

export default Loading;
