import React from "react";
// Icons
import { CloseIcon } from "assets/icons";
// Styles
import { Container, IconContainer, Body } from "./styles";

interface ModalProps {
  children: React.ReactElement;
  isOpen: boolean;
  maxWidth?: number;
  onClose: () => void;
  radius?: number;
  visibleOverflow?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  maxWidth,
  isOpen,
  children,
  onClose,
  radius,
  visibleOverflow = false,
}) => (
  <Container open={isOpen} onClose={onClose}>
    <Body
      visibleOverflow={visibleOverflow}
      maxWidth={maxWidth}
      radius={radius}
      className="body"
    >
      <IconContainer onClick={onClose}>
        <CloseIcon />
      </IconContainer>

      {children}
    </Body>
  </Container>
);

export default Modal;
