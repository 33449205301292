import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { BaseInfoContainerStyled } from "../BaseInfo/styles";
import { H1 } from "pages/Teams/styles";

export const ExpensesContainerStyled = styled(BaseInfoContainerStyled)(() => ({
  padding: "25px 0 15px",
}));
export const TitleStyled = styled(H1)(() => ({
  margin: "20px 25px",
}));

export const ColumnContainerStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const RowStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 40px 14px 40px",
}));

export const LabelsContainerStyled = styled(Box)(() => ({
  display: "flex",
  margin: "0 20px",
  "& > span": {
    display: "flex",
    overflow: "hidden",
    margin: "10px 0",
  },
}));
