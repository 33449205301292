/* eslint-disable no-mixed-operators */
import React, { useEffect } from "react";
import { useRoutes, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// Components
import Loading from "components/UI/Loading";
// Store
import { useGetUserQuery } from "store/auth/authService";
import { errorReducer } from "store/error/errorService";
// Hooks
import { useAuth } from "hooks/useAuth";

import {
  userRoutes,
  authRoutes,
  tutorRoutes,
  userProfileRoutes,
  freelanceUserRoutes,
  freelancerFirstLoginRoutes,
} from "./routes";
import { USER_ROLE } from "utils/constants/auth";

const Router: React.FC = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { token, user } = useAuth();
  const [searchParams] = useSearchParams();
  const recoveryToken = searchParams.get("token")!;
  const router = useRoutes([userRoutes, tutorRoutes]);
  const freelancerRouter = useRoutes([freelanceUserRoutes]);
  const authRouter = useRoutes([authRoutes]);
  const profileRouter = useRoutes([userProfileRoutes]);
  const freelancerFirstLoginRouter = useRoutes([freelancerFirstLoginRoutes]);
  const { error } = useSelector(errorReducer);

  const { isLoading, refetch } = useGetUserQuery({}, { skip: !token });

  useEffect(() => {
    if (error) {
      navigate("/error-page");
      return;
    }
  }, [error]);

  useEffect(() => {
    if (token && !user) {
      refetch();
    }
  }, [token, user]);

  useEffect(() => {
    if (!user) {
      return;
    }
    i18n.changeLanguage(user.settings.language);
  }, [user]);

  if (isLoading) {
    return <Loading />;
  }

  if (recoveryToken) {
    return authRouter;
  }

  if (!token && !user) {
    return authRouter;
  }

  if (token && user && user?.firstname && user?.lastname) {
    switch (user.role) {
      case USER_ROLE.FREELANCER:
        return freelancerRouter;
      default:
        return router;
    }
  }

  if (user && (!user?.firstname || !user?.lastname)) {
    switch (user?.role) {
      case USER_ROLE.FREELANCER:
        return freelancerFirstLoginRouter;
      default:
        return profileRouter;
    }
  }

  return authRouter;
};

export default Router;
