import { type FC, type SyntheticEvent, useCallback, useState } from "react";
import Footer from "components/Footer";
import TeamTable from "./TeamTable";
import { useTranslation } from "react-i18next";
import { PageContainerStyled, TitleContainer } from "../styles";
import { TableContainerStyled } from "./styles";
import { useDrawer } from "hooks/useDrawer";
import AddEditMemberModal from "./AddEditMemberModal";
import { EMPLOYEE_TYPE } from "utils/freelancer/constants";
import Loading from "components/UI/Loading";
import TabPanel from "components/UI/TabPanel";
import TableHeaderSection from "./TeamTable/TableHeaderSection";
import { useScreenSize } from "hooks/useScreenSize";
import { useEmployees } from "hooks/freelancer/useEmployees";
import { Box, Typography } from "@mui/material";

const Members: FC = () => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState<EMPLOYEE_TYPE>(
    EMPLOYEE_TYPE.PARTNER
  );
  const { isDesktop } = useScreenSize();
  const { teammates, clients, isEmployeesLoading, refetchEmployees } =
    useEmployees();
  const { isOpen: isAddEmployeeOpen, toggle: toggleAddEmployeeOpen } =
    useDrawer();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const handleChangeTabMode = (e: SyntheticEvent, newValue: number) =>
    setTabIndex(newValue);

  const handleButtonClick = useCallback(
    (type: EMPLOYEE_TYPE) => {
      setSelectedType(type);
      toggleAddEmployeeOpen();
    },
    [toggleAddEmployeeOpen]
  );

  const tabContent = [
    {
      id: 1,
      data: clients,
    },
    {
      id: 2,
      data: teammates,
    },
  ];

  if (isEmployeesLoading) {
    return (
      <PageContainerStyled>
        <Loading />
      </PageContainerStyled>
    );
  }

  return (
    <PageContainerStyled>
      <Box>
        <TitleContainer>
          <Typography variant="jumboTitle">
            {t("freelancer.page.members.title")}
          </Typography>
        </TitleContainer>
        <TableContainerStyled>
          <TableHeaderSection
            handleChangeTabMode={handleChangeTabMode}
            tabMode={tabIndex}
            handleButtonClick={handleButtonClick}
          />
          {tabContent.map(({ data, id }, index) => (
            <TabPanel key={id} value={tabIndex} index={index}>
              <TeamTable
                employeeType={
                  id === 1 ? EMPLOYEE_TYPE.CONTRACTOR : EMPLOYEE_TYPE.PARTNER
                }
                employees={data}
                refetch={refetchEmployees}
                openAddMemberModal={handleButtonClick}
              />
            </TabPanel>
          ))}
          <AddEditMemberModal
            isOpen={isAddEmployeeOpen}
            onClose={toggleAddEmployeeOpen}
            selectedType={selectedType}
          />
        </TableContainerStyled>
      </Box>
      {isDesktop && <Footer isSpace isSticki={false} />}
    </PageContainerStyled>
  );
};

export default Members;
