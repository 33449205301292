import { ContentBoxStyled, LinkStyled } from "../styles";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { ContentContainerStyled } from "../../Projects/AddEdit/styles";
import PendingInvoicesTable from "./PendingInvoicesTable";
import { useAllInvoices } from "hooks/freelancer/useAllInvoices";
import { freelanceUserRoutesPath } from "routes/routePath";
import Loading from "components/UI/Loading";
import { INVOICE_STATE } from "utils/freelancer/constants";
import dayjs from "dayjs";

const invoicesCount = 4;
const loadingSpinnerSize = 40;

const PendingInvoices: FC = () => {
  const { t } = useTranslation();
  const { isLoading, allInvoices } = useAllInvoices();
  const invoicesToShow = useMemo(
    () =>
      allInvoices
        ?.filter(({ state }) => state === INVOICE_STATE.CREATED)
        .sort(
          (a, b) =>
            dayjs(a.dueDate).millisecond() - dayjs(b.dueDate).millisecond()
        )
        .slice(0, invoicesCount),
    [allInvoices]
  );

  return (
    <ContentBoxStyled>
      <Typography variant="h1">
        {t("freelancer.page.dashboard.invoices.title")}
      </Typography>
      <ContentContainerStyled>
        {isLoading ? (
          <Loading size={loadingSpinnerSize} />
        ) : (
          <PendingInvoicesTable invoices={invoicesToShow} />
        )}
      </ContentContainerStyled>
      {!!invoicesToShow?.length && (
        <LinkStyled underline="none" href={freelanceUserRoutesPath.INVOICES}>
          {t("freelancer.page.dashboard.invoices.viewMore")}
        </LinkStyled>
      )}
    </ContentBoxStyled>
  );
};

export default PendingInvoices;
