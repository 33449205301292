import { Typography } from "@mui/material";
import { type Control, Controller, useFieldArray } from "react-hook-form";
import {
  DeleteIconContainerStyled, EmployeeWorkLabelRowStyled,
  ExpenseGroupContainerStyled, ExpensesRowStyled, IconContainerStyled,
  InputLabelStyled,
  InputRowStyled,
  InputStyled,
  NameStyled,
  RowStyled,
  WorkLabelRowStyled,
} from "../styles";
import { palette } from "styles/theme/palette";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { type ProjectCreateForm } from "interfaces/freelancer/project";
import { useScreenSize } from "hooks/useScreenSize";
import {DeleteIcon} from "../../../../../../assets/icons";
import {Arrow} from "../../../../../../pages/ProductsAndServices/ProductsTable/styles";

interface ServicesProps {
  parentIndex: number;
  control: Control<ProjectCreateForm>;
}

const WorkServices: FC<ServicesProps> = ({ parentIndex, control }) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const { fields } = useFieldArray({
    control,
    name: `workPhases.${parentIndex}.expenses.services`,
  });

  return (
    <ExpenseGroupContainerStyled>
      <Typography variant="h1">{t("services")}</Typography>
      {!!fields?.length && !isMobile && (
        <EmployeeWorkLabelRowStyled>
          <Typography
            variant="regularText"
            color={palette.custom.primary.silver}
          >
            {t("freelancer.page.addEditProject.services.name.label")}
          </Typography>
          <RowStyled>
            <InputLabelStyled variant="regularText">
              {t("freelancer.page.addEditProject.services.price.label")}
            </InputLabelStyled>
          </RowStyled>
        </EmployeeWorkLabelRowStyled>
      )}
      {!!fields?.length &&
        fields.map((item, nestedIndex) => (
          <ExpensesRowStyled key={item.id}>
            <NameStyled variant="body1">{item.name}</NameStyled>
            <InputRowStyled>
              <Controller
                name={`workPhases.${parentIndex}.expenses.services.${nestedIndex}.price`}
                control={control}
                render={({ field }) => <InputStyled {...field} disabled />}
              />
            </InputRowStyled>

          </ExpensesRowStyled>
        ))}
    </ExpenseGroupContainerStyled>
  );
};

export default WorkServices;
