import React, { type FC, useCallback, useEffect, useState } from "react";
import Modal from "components/UI/Modal";
import { useTranslation } from "react-i18next";
import {
  Button,
  ContainerButton,
  Title,
} from "pages/Teams/AddEmployeeModal/styles";
import {
  AddHoursContainerStyled,
  DatePickerContainerInput,
  FormContainerStyled,
  InputStyled,
} from "../styles";
import { TimeLogToEdit } from "./workEmployees";
import { getNumber } from "utils/freelancer/helpers/getNumber";
import {
  ContainerInput,
  Input as DateInput,
} from "components/UI/DatePicker/styles";
import { useUpdateTimeLogMutation } from "store/timeLog/timeLogServices";
import { Label } from "components/UI/Input/styles";

interface EditHoursModalProps {
  onClose: () => void;
  isOpen: boolean;
  timeLogToEdit?: TimeLogToEdit;
  refetchTimeLogData: () => void;
}

const EditHoursModal: FC<EditHoursModalProps> = ({
  onClose,
  isOpen,
  timeLogToEdit,
  refetchTimeLogData,
}) => {
  const { t } = useTranslation();
  const initialHours = getNumber(getNumber(timeLogToEdit?.value) / 60).toFixed(
    1
  );
  const initialDate = timeLogToEdit?.created_at
    ? new Date(timeLogToEdit.created_at)
    : new Date();
  const [hours, setHours] = useState<string | undefined>(initialHours);
  const [hoursDate, setHoursDate] = useState<Date | undefined>();
  const [update] = useUpdateTimeLogMutation();
  const ableToSubmitHours = !!hours && hours !== initialHours;
  const ableToSubmitHoursDate =
    !!hoursDate &&
    hoursDate.getTime() !== initialDate.getTime() &&
    hoursDate.getTime() !== new Date(initialDate).setMilliseconds(0);
  const ableToSubmit = ableToSubmitHours || ableToSubmitHoursDate;

  const handleEditHours = useCallback(
    ({ target }) => {
      setHours(target.value);
    },
    [onClose]
  );

  const submitHoursUpdate = useCallback(async () => {
    if (timeLogToEdit?.timelog_id && hours) {
      try {
        await update({
          body: {
            value: parseFloat(hours) * 60,
            created_at: hoursDate ?? timeLogToEdit.created_at,
          },
          id: timeLogToEdit.timelog_id,
        });
        refetchTimeLogData();
        onClose();
      } catch (e) {
        console.error("Error on update hours, ", e);
      }
    }
  }, [onClose, refetchTimeLogData, hours, hoursDate]);

  const handleChangeDate = useCallback((newDate: Date) => {
    setHoursDate(newDate);
  }, []);

  useEffect(() => {
    if (timeLogToEdit) {
      setHours(initialHours);
      setHoursDate(initialDate);
    }
  }, [timeLogToEdit]);

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <AddHoursContainerStyled>
        <Title variant="h1">
          {t("freelancer.page.addEditProject.employee.editHours.title")}
        </Title>
        <FormContainerStyled>
          <InputStyled
            type="number"
            label={t("freelancer.page.addEditProject.employee.addHours.label")}
            placeholder={t(
              "freelancer.page.addEditProject.employee.addHours.placeholder"
            )}
            fullWidth
            value={hours}
            onChange={handleEditHours}
          />
          <DatePickerContainerInput>
            <Label>
              {t("freelancer.page.addEditProject.employee.editHours.dateTitle")}
            </Label>
            <ContainerInput>
              <DateInput selected={hoursDate} onChange={handleChangeDate} />
            </ContainerInput>
          </DatePickerContainerInput>
        </FormContainerStyled>
        <ContainerButton>
          <Button
            disabled={!ableToSubmit}
            variant="contained"
            component="span"
            onClick={submitHoursUpdate}
          >
            {t("Додати")}
          </Button>

          <Button variant="light" onClick={onClose}>
            {t("Скасувати")}
          </Button>
        </ContainerButton>
      </AddHoursContainerStyled>
    </Modal>
  );
};

export default EditHoursModal;
