import { TableStyled } from "../../../Projects/ProjectsTable/styles";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { TableContainerStyled } from "./styles";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { type InvoicePreviewTableRow } from "utils/freelancer/helpers/invoiceHelpers";

interface TableProps {
  tableHeadCells: string[];
  tableBodyRows: InvoicePreviewTableRow[];
}
const Table: FC<TableProps> = ({ tableBodyRows, tableHeadCells }) => {
  const { t } = useTranslation();
  return (
    <TableContainerStyled>
      <TableStyled aria-labelledby="tableTitle">
        <TableHead>
          <TableRow>
            {tableHeadCells.map((label, index) => (
              <TableCell
                style={{ width: `${index === 0 ? "60%" : "auto"}` }}
                key={label}
                align={index === 0 ? "left" : "center"}
              >
                {t(label)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBodyRows.map(
            ({ name, quantity, amount, cost, type }, index) => (
              <TableRow key={`${name}-${index}`}>
                <TableCell style={{ width: "60%" }} align="left">
                  {name}
                </TableCell>
                <TableCell align="center">{t(type)}</TableCell>
                <TableCell align="center">{quantity}</TableCell>
                <TableCell align="center">{cost}</TableCell>
                <TableCell align="center">{amount}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </TableStyled>
    </TableContainerStyled>
  );
};

export default Table;
