import { Action, combineReducers, Store } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { ThunkAction } from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
// Reducers
import authReducer from "store/auth/authSlice";
import dashboardReducer from "store/dashboard/dashboardSlice";
import { authService } from "store/auth/authService";
import { projectServices } from "store/project/projectServices";
import { projectsServices } from "store/projects/projectsServices";
import { pipelineServices } from "store/pipeline/pipelineServices";
import { tutorServices } from "store/tutor/tutorServices";
import { paymentsServicers } from "store/payments/paymentsServicers";
import { tutorsServices } from "store/tutors/tutorsServices";
import { dashboardServices } from "store/dashboard/dashboardServices";
import { errorSlice } from "store/error/errorService";
// Interface
import { AuthState } from "store/auth/authTypes";
import { TutorState } from "store/tutor/tutorTypes";
import { PipelineState } from "store/pipeline/pipelineTypes";
import { ProjectState } from "store/projects/projectsTypes";
import projectReducer from "store/projects/projectSlice";
import { DashboardState } from "store/dashboard/dashboardTypes";
import pipelineReducer from "./pipeline/pipelineSlice";
import tutorReducer from "./tutor/tutorSlice";
import tutorsReducer from "./tutors/tutorsSlice";
import { IRootStateTutor } from "./tutors/tutorsTypes";
import {
  analyticsSlice,
  type AnalyticsState,
} from "./analytics/analyticsService";
import { employeesServices } from "store/employees/employeesServices";
import { productGroupServices } from "./productGroup/productGroupServices";
import { invoicesServices } from "./invoices/invoicesServices";
import { userExpenseServices } from "./userExpense/userExpenseServices";
import { timeLogServices } from "./timeLog/timeLogServices";
import { transactionsServices } from "./transactions/transactionsServices";

interface RootStates {
  auth: AuthState;
  tutors: TutorState;
  dashboard: PipelineState;
  oneProject: ProjectState;
  rootTutor: IRootStateTutor;
  dashboardCalculate: DashboardState;
  error: any;
  analytics: AnalyticsState;
}

const authPersistConfig = {
  key: "auth",
  storage,
  blacklist: ["auth"],
};

// Reducers
const rootState = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  tutors: tutorReducer,
  dashboard: pipelineReducer,
  oneProject: projectReducer,
  rootTutor: tutorsReducer,
  dashboardCalculate: dashboardReducer,
  error: errorSlice.reducer,
  analytics: analyticsSlice.reducer,
  [authService.reducerPath]: authService.reducer,
  [projectServices.reducerPath]: projectServices.reducer,
  [pipelineServices.reducerPath]: pipelineServices.reducer,
  [tutorServices.reducerPath]: tutorServices.reducer,
  [paymentsServicers.reducerPath]: paymentsServicers.reducer,
  [projectsServices.reducerPath]: projectsServices.reducer,
  [employeesServices.reducerPath]: employeesServices.reducer,
  [productGroupServices.reducerPath]: productGroupServices.reducer,
  [tutorsServices.reducerPath]: tutorsServices.reducer,
  [dashboardServices.reducerPath]: dashboardServices.reducer,
  [invoicesServices.reducerPath]: invoicesServices.reducer,
  [userExpenseServices.reducerPath]: userExpenseServices.reducer,
  [timeLogServices.reducerPath]: timeLogServices.reducer,
  [transactionsServices.reducerPath]: transactionsServices.reducer,
});

export const store: Store<RootStates> = configureStore({
  reducer: rootState,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      authService.middleware,
      projectServices.middleware,
      pipelineServices.middleware,
      tutorServices.middleware,
      paymentsServicers.middleware,
      projectsServices.middleware,
      tutorsServices.middleware,
      dashboardServices.middleware,
      employeesServices.middleware,
      productGroupServices.middleware,
      invoicesServices.middleware,
      timeLogServices.middleware,
      userExpenseServices.middleware,
      transactionsServices.middleware
    ),

  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store, null);

// Store types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const isFetchBaseQueryErrorType = (
  error: any
): error is FetchBaseQueryError => "status" in error;

// Redux thunk type
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
