import { useAuth } from "./useAuth";
import { useTranslation } from "react-i18next";

interface FreelancerDefaultIdsReturn {
  project_id: string;
  pipeline_id: string;
  currency: string;
  employeeName: string;
  userId: string;
}
export const useFreelancerDefaults = (): FreelancerDefaultIdsReturn => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const { default_pipeline_id, default_project_id, currency } = user ?? {};
  return {
    project_id: default_project_id ?? "",
    pipeline_id: default_pipeline_id ?? "",
    currency: currency ?? "$",
    employeeName: `${user?.firstname} (${t("me")})`,
    userId: user?.user_id ?? "",
  };
};
