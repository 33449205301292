import { styled } from "@mui/material/styles";
import {
  alpha,
  Box,
  Breakpoint,
  Button,
  Collapse,
  Link,
  TableContainer,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { HeaderRow } from "pages/ProductsAndServices/TableHeaderSection/styles";
import { Container } from "pages/Teams/AddEmployeeModal/styles";
import { palette } from "styles/theme/palette";
import { BaseContainerStyled } from "../styles";
import { defaultTheme } from "styles/theme/theme";
import { Label } from "components/UI/Input/styles";
import Input from "components/UI/Input";

export const HeaderRowStyled = styled(HeaderRow)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 25px",
  margin: 0,
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    flexWrap: "wrap",
    padding: 0,
    ".MuiButton-root": {
      margin: "0 0 20px",
    },
  },
}));

export const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  padding: 0,
  minHeight: "500px",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    minHeight: "300px",
  },
}));

export const ExpensesContainerStyled = styled(BaseContainerStyled)(() => ({
  padding: 0,
  overflow: "hidden",
}));

export const StatusStyled = styled(Typography)(() => ({
  marginRight: "10px",
  color: palette.custom.black.silver,
}));

export const IconContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "disabled",
})<{ disabled?: boolean }>(({ disabled }) => ({
  padding: "10px 20px",
  cursor: "pointer",
  pointerEvents: `${disabled ? "none" : "auto"}`,
  opacity: `${disabled ? ".4" : "1"}`,
}));

export const HeaderStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isPromoted",
})<{ isPromoted?: boolean }>(({ theme, isPromoted = false }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: "50px",
  padding: "0 20px 0 30px",
  width: "100%",
  cursor: "pointer",
  backgroundColor: isPromoted
    ? alpha(palette.custom.grey.light, 0.5)
    : palette.custom.green.light,
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    padding: "0 0 0 10px",
    maxWidth: "calc(100vw - 30px)",
    flexWrap: "wrap",
    height: isPromoted ? "40px" : "auto",
  },
}));

export const LabelStyled = styled(Label)<{ isRequired?: boolean }>(
  ({ theme, isRequired = false, isEmpty = true }) => ({
    margin: "40px 30px 10px",
    display: "inline-block",
    [theme.breakpoints.down("lg" as Breakpoint)]: {
      margin: "15px 0",
    },
    maxWidth: "calc(100% - 60px)",
    color:
      isRequired && isEmpty
        ? palette.custom.red.main
        : palette.custom.grey.main,
  })
);

export const TitleContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isPromoted",
})<{ isPromoted?: boolean }>(({ theme, isPromoted = false }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flex: "1 1 80%",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    flexBasis: "60%",
    paddingTop: isPromoted ? "12px" : 0,
  },
}));

export const OverlayStyled = styled(Box)(() => ({
  display: "flex",
  position: "absolute",
  zIndex: 10,
  top: "50px",
  left: 0,
  right: 0,
  bottom: 0,
  background: alpha(palette.custom.grey.light, 0.3),
}));

export const DescriptionContainerStyled = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    padding: "0 20px",
  },
}));

export const CollapseStyled = styled(Collapse)(() => ({
  position: "relative",
  borderBottom: `1px solid ${palette.custom.primary.white}`,
  paddingBottom: "20px",
}));

export const ArrowContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isPromoted",
})<{ isPromoted?: boolean }>(({ theme, isPromoted = false }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  flex: "1 1 20%",
  height: "46px",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    flexBasis: "40%",
    svg: {
      margin: "0 20px",
    },
    width: "100%",
    height: "auto",
    "& > div": {
      margin: 0,
    },
    position: isPromoted ? "relative" : "static",
    top: isPromoted ? "-32px" : "auto",
  },
  button: {
    width: "240px",
    maxHeight: "40px",
  },
}));

export const ExpenseGroupContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  borderRadius: "8px",
  backgroundColor: palette.custom.grey.darkLite,
  margin: "18px 30px",
  minHeight: "150px",
  justifyContent: "space-between",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    margin: "18px 0",
  },
}));

export const DatePickerContainerStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
}));

export const LinkContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "40px",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    flexWrap: "wrap",
  },
}));

export const LinkStyled = styled(Link, {
  shouldForwardProp: (prop) => prop !== "disabled",
})<{ disabled?: boolean }>(({ disabled = false }) => ({
  cursor: disabled ? "default" : "pointer",
  opacity: disabled ? "0.5" : "1",
}));

export const CreateNewLinkContainerStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "10px",
}));

export const DateContainerStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  "& > div": {
    marginRight: "30px",
  },
}));

export const TextareaAutosizeStyled = styled(TextareaAutosize)(({ theme }) => ({
  width: "calc(100% - 60px)",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  transition: "all 300ms ease",
  resize: "none",
  borderRadius: "8px",
  margin: "10px 30px 0",
  padding: "10px",
  outline: "none",
  "&:hover, &:focus": {
    borderColor: palette.primary.light,
  },
  "&::placeholder": {
    color: palette.primary.light,
  },
  ...defaultTheme.typography.h5,
  fontFamily: defaultTheme.typography.fontFamily,
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    margin: 0,
    width: "100%",
  },
}));

export const RowStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    flexDirection: "column",
  },
}));

export const ExpensesRowStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginRight: "52px",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    flexDirection: "column",
  },
}));

export const TimeLogRowStyled = styled(RowStyled)(({ theme }) => ({
  justifyContent: "flex-end",
  alignItems: "center",
  backgroundColor: palette.custom.grey.background,
  color: palette.custom.primary.silver,
  "&:not(:last-of-type)": {
    borderBottom: `1px solid ${palette.primary.main}`,
  },
  ".MuiTableCell-root": {
    borderBottom: "none",
  },
  "& > p": {
    display: "inline-flex",
    minWidth: "100px",
    textAlign: "left",
  },
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    flexDirection: "column",
  },
  gap: "15%",
}));

export const CollapseEmployeeStyled = styled(Collapse)(() => ({
  ".MuiCollapse-wrapperInner": {
    display: "flex",
    flexDirection: "column",
    paddingTop: "5px",
  },
}));
export const NameStyled = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    margin: "10px 0",
  },
}));

export const InputRowStyled = styled(RowStyled)(({ theme }) => ({
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    flexDirection: "row",
  },
}));

export const EmployeeDataRowStyled = styled(RowStyled)(({ theme }) => ({
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    flexDirection: "column",
  },
}));

export const LabelRowStyled = styled(RowStyled)(() => ({
  margin: "0 60px 20px 0",
}));

export const ExpensesLabelRowStyled = styled(RowStyled)(() => ({
  margin: "0 40px 20px 0",
}));

export const WorkLabelRowStyled = styled(RowStyled)(() => ({
  margin: "0 0 20px 0",
}));

export const EmployeeWorkLabelRowStyled = styled(RowStyled)(() => ({
  margin: "0 40px 20px 0",
}));

export const ButtonStyled = styled(Button)(({ variant }) => ({
  borderRadius: "8px",
  padding: "0 17px",
  maxHeight: "50px",
  textTransform: "none",
  marginRight: "20px",
  "& + &": {
    marginRight: "100px",
  },
  ...(variant === "outlined"
    ? {
        "&, &:hover": {
          border: `1px solid ${palette.custom.green.chartGreen}`,
          color: palette.custom.green.chartGreen,
        },
      }
    : {}),
}));

export const InputStyled = styled(Input)(({ theme }) => ({
  margin: "0 10px",
  width: "120px",
  justifySelf: "center",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    margin: 0,
  },
  "& input:disabled": {
    background: palette.primary.main,
  },
}));

export const ExpenseInputStyled = styled(Input)(({ theme }) => ({
  margin: 0,
  width: "325px",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    width: "100%",
    marginTop: "10px",
  },
}));

export const InputLabelStyled = styled(Typography)(() => ({
  width: "130px",
  color: palette.custom.primary.silver,
}));

export const InputLabelStyledEmployee = styled(Typography)(() => ({
  width: "140px",
  color: palette.custom.primary.silver,
}));

export const DeleteIconContainerStyled = styled(Box)(() => ({
  margin: "20px",
  cursor: "pointer",
}));

export const DeletePhaseIconContainerStyled = styled(Box)(() => ({
  margin: "0 20px",
  cursor: "pointer",
}));

export const AddHoursContainerStyled = styled(Container)(() => ({
  width: "100%",
  ".MuiFormControl-root": {
    width: "100%",
    marginLeft: 0,
  },
}));
export const FormContainerStyled = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  "& > .MuiFormControl-root": {
    maxWidth: "150px",
    marginRight: "30px",
  },
}));
export const DatePickerContainerInput = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flex: "1 1 100%",
  ".react-datepicker-wrapper": {
    width: "100%",
  },
}));
export const PromoteButtonStyled = styled(Button)(({ theme }) => ({
  padding: "18px",
  "&.MuiButtonBase-root": {
    width: "280px",
  },
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    "&.MuiButtonBase-root": {
      width: "200px",
      padding: "30px 10px",
      margin: "20px 0",
    },
  },
}));
