import {
  type SubmitHandler,
  useForm,
  type UseFormReturn,
} from "react-hook-form";
import { type IUserProfile } from "../interfaces/user-profile";
import { INFO_INPUTS_VALUES } from "../utils/constants/user-profile";
import { useEffect } from "react";
import { useUpdateUserMutation } from "../store/auth/authService";
import { useAuth } from "./useAuth";
import {
  useEditEmployeeMutation,
  useGetEmployeesQuery,
} from "../store/employees/employeesServices";
import {
  type Employee,
  type EmployeeEdit,
} from "../store/employees/employeesTypes";
import { PROJECT_TYPE } from "../utils/freelancer/constants";

interface UseProfileInfoReturn {
  formState: UseFormReturn<IUserProfile>;
  onSubmit: SubmitHandler<IUserProfile>;
  isLoading: boolean;
}

export const useProfileInfo = (): UseProfileInfoReturn => {
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const { user } = useAuth();
  const { data } = useGetEmployeesQuery({
    project_id: user?.default_project_id ?? "",
    pipeline_id: user?.default_pipeline_id ?? "",
  });
  const [editEmployee] = useEditEmployeeMutation();

  const formState = useForm<IUserProfile>({
    defaultValues: INFO_INPUTS_VALUES,
    mode: "all",
  });

  const onSubmit: SubmitHandler<IUserProfile> = async (values) => {
    try {
      const [userEmployee] =
        data?.filter(
          ({ name, id }) => name === user?.user_id || id === user?.user_id
        ) ?? [];
      const { firstname, lastname, phone_number, residence, email } = values;
      const updateDataEmployee: EmployeeEdit = {
        email,
        department: "",
        wage: 0,
        amount_type: PROJECT_TYPE.FIX,
      };

      await updateUser({
        body: {
          firstname,
          lastname,
          phone_number,
          residence,
          settings: {
            language: user?.settings?.language || "",
            clockifyApiKey: user?.settings?.clockifyApiKey || "",
          },
        },
      }).unwrap();

      if (userEmployee?.id) {
        await editEmployee({
          body: updateDataEmployee as Employee,
          project_id: user?.default_project_id ?? "",
          pipeline_id: user?.default_pipeline_id ?? "",
          product_id: userEmployee.id,
        }).unwrap();
      }
    } catch (err) {
      formState.setError("firstname", {
        type: "custom",
        message: "Помилка сервера",
      });
    }
  };

  useEffect(() => {
    if (user?.user_id) {
      const entries = Object.entries(user);

      entries.forEach(([name, value]) =>
        formState.setValue(name as keyof IUserProfile, value)
      );
    }
  }, [user]);

  useEffect(() => {
    if ((user && !user?.firstname) || !user?.lastname) {
      formState.trigger();
    }
  }, [user]);

  return { formState, onSubmit, isLoading };
};
