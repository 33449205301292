import { Typography } from "@mui/material";
import {
  type Control,
  Controller,
  useFieldArray,
  UseFieldArrayAppend,
} from "react-hook-form";
import {
  DeleteIconContainerStyled,
  ExpenseGroupContainerStyled,
  InputLabelStyled,
  InputRowStyled,
  InputStyled,
  LabelRowStyled,
  LinkStyled,
  NameStyled,
  RowStyled,
  LinkContainerStyled,
  CreateNewLinkContainerStyled,
} from "../styles";
import { palette } from "styles/theme/palette";
import React, { type FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { type ProjectCreateForm } from "interfaces/freelancer/project";
import { DeleteIcon, InfoIcon } from "assets/icons";
import { useScreenSize } from "hooks/useScreenSize";
import { NoTableDataTextStyled } from "../../../../styles";
import { useDrawer } from "hooks/useDrawer";
import SelectServiceModal from "./SelectServiceModal";
import { type ProductAsService } from "interfaces/product";
import { EXPENSE_STATUS } from "utils/freelancer/constants";
import { getNumber } from "utils/freelancer/helpers/getNumber";
import { v4 as uuidv4 } from "uuid";
import AddEditServiceModal from "components/Freelancer/Services/AddEditServiceModal";
import Tooltip from "components/UI/Tooltip";
interface ServicesProps {
  parentIndex: number;
  control: Control<ProjectCreateForm>;
  phasesName?: "phases" | "workPhases";
  onExpenseRemove: UseFieldArrayAppend<ProjectCreateForm, "removedExpenses">;
  ableToSaveProject?: boolean;
  saveProject?: () => void;
}

const Services: FC<ServicesProps> = ({
  parentIndex,
  control,
  phasesName = "phases",
  onExpenseRemove,
  ableToSaveProject,
  saveProject,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${phasesName}.${parentIndex}.expenses.services`,
  });
  const {
    isOpen: isServiceModalOpen,
    open: openServiceModal,
    close: closeServiceModal,
  } = useDrawer();
  const removeService = useCallback(
    ({ currentTarget }) => {
      const serviceIndex = currentTarget.getAttribute("data-service-index");
      if (serviceIndex) {
        remove(serviceIndex);
        if (fields[serviceIndex].expenseId) {
          onExpenseRemove({ id: fields[serviceIndex].expenseId ?? "" });
        }
      }
    },
    [remove, onExpenseRemove, fields]
  );

  const handleCloseServiceModal = useCallback(
    (selectedService?: ProductAsService) => {
      if (selectedService?.id) {
        append({
          name: selectedService.name,
          price: selectedService.price,
          serviceId: selectedService.id,
          id: uuidv4(),
          status: EXPENSE_STATUS.DRAFT,
        });
      }
      closeServiceModal();
    },
    []
  );

  const serviceIdsToExclude = fields.map(({ serviceId }) => serviceId);
  const { isOpen: isAddServiceOpen, toggle: toggleAddServiceOpen } =
    useDrawer();

  const handleAddServiceClick = useCallback(() => {
    if (ableToSaveProject) {
      toggleAddServiceOpen();
    }
  }, [ableToSaveProject]);

  return (
    <ExpenseGroupContainerStyled>
      <Typography variant="h1">{t("services")}</Typography>
      {!!fields?.length && !isMobile && (
        <LabelRowStyled>
          <Typography
            variant="regularText"
            color={palette.custom.primary.silver}
          >
            {t("freelancer.page.addEditProject.services.name.label")}
          </Typography>
          <RowStyled>
            <InputLabelStyled variant="regularText">
              {t(
                "freelancer.page.addEditProject.services.estimatedPrice.label"
              )}
            </InputLabelStyled>
          </RowStyled>
        </LabelRowStyled>
      )}
      {!!fields?.length ? (
        fields.map((item, nestedIndex) => (
          <RowStyled key={item.id}>
            <NameStyled variant="body1">{item.name}</NameStyled>
            <InputRowStyled>
              <Controller
                name={`${phasesName}.${parentIndex}.expenses.services.${nestedIndex}.price`}
                control={control}
                rules={{ required: t("fieldIsRequired") }}
                render={({ field }) => (
                  <InputStyled
                    {...field}
                    type="number"
                    placeholder={t(
                      "freelancer.page.addEditProject.services.estimatedPrice.placeholder"
                    )}
                  />
                )}
              />
              <DeleteIconContainerStyled
                data-service-index={nestedIndex}
                onClick={removeService}
              >
                <DeleteIcon />
              </DeleteIconContainerStyled>
            </InputRowStyled>
          </RowStyled>
        ))
      ) : (
        <NoTableDataTextStyled variant="h2">
          {t("freelancer.page.addEditProject.services.noData")}
        </NoTableDataTextStyled>
      )}
      <LinkContainerStyled>
        <LinkStyled
          underline="none"
          onClick={openServiceModal}
          color={palette.custom.green.dark}
        >
          {t("selectService")}
        </LinkStyled>
        <CreateNewLinkContainerStyled>
          <LinkStyled
            disabled={!ableToSaveProject}
            underline="none"
            onClick={handleAddServiceClick}
            color={palette.custom.green.dark}
          >
            {t("createService")}
          </LinkStyled>
          <Tooltip
            tooltipText={t(
              "freelancer.page.addEditProject.services.tooltip.title"
            )}
            placement="bottom-end"
          >
            <InfoIcon />
          </Tooltip>
        </CreateNewLinkContainerStyled>
      </LinkContainerStyled>
      <SelectServiceModal
        idsToExclude={serviceIdsToExclude}
        isOpen={isServiceModalOpen}
        onClose={handleCloseServiceModal}
      />
      <AddEditServiceModal
        isOpen={isAddServiceOpen}
        onClose={toggleAddServiceOpen}
        refetch={saveProject}
      />
    </ExpenseGroupContainerStyled>
  );
};

export default Services;
