import { styled } from "@mui/material/styles";
import { Box, Button, type Breakpoint } from "@mui/material";
import { Section } from "pages/UserProfile/styles";
import Input from "../../UI/Input";
import { palette } from "styles/theme/palette";
import { PAGE_CONTENT_WIDTH } from "styles/theme/constants";

export const ContentContainerStyled = styled(Box)(({ theme }) => ({
  maxWidth: PAGE_CONTENT_WIDTH,
  [theme.breakpoints.down("md" as Breakpoint)]: {
    maxWidth: "calc(100vw - 30px)",
  },
}));

export const EmploymentInfoStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "70%",
  paddingRight: "27px",
  borderRight: `1px solid ${palette.primary.main}`,
  minHeight: "436px",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    width: "100%",
    paddingRight: 0,
    paddingBottom: "27px",
    borderRight: "none",
    borderBottom: `1px solid ${palette.primary.main}`,
    minHeight: "605px",
  },
}));

export const AccountingInfoStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "calc(30% - 40px)",
  marginLeft: "40px",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    width: "100%",
    marginLeft: 0,
    marginTop: "20px",
  },
}));

export const SectionStyled = styled(Section)(({ theme }) => ({
  flexDirection: "row",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    flexDirection: "column",
  },
}));

export const FullWidthContainerStyled = styled(Box)(() => ({
  width: "100%",
}));

export const InputStyled = styled(Input)(({ theme }) => ({
  width: "calc((100% - 40px) / 2)",
  marginRight: "20px",
  ".MuiFormControl-root": {
    width: "100%",
  },
  [theme.breakpoints.down("md" as Breakpoint)]: {
    width: "100%",
    marginRight: 0,
  },
}));

export const RowStyled = styled("p")(() => ({
  borderBottom: `1px solid ${palette.custom.green.chartGreen}`,
  marginBottom: "20px",
  marginRight: "20px",
}));

export const SecondaryButtonStyled = styled(Button)(() => ({
  maxHeight: "40px",
}));

export const ImageStyled = styled("img")(({ theme }) => ({
  height: "60px",
  position: "absolute",
  top: 0,
  right: "60px",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    right: "30px",
  },
}));
