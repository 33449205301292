import { styled } from "@mui/material/styles";
import { Box, type Breakpoint, Collapse, Typography } from "@mui/material";
import { palette } from "styles/theme/palette";
import { PAGE_CONTENT_WIDTH } from "styles/theme/constants";
import Select from "components/UI/Select";

export const BaseContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: palette.custom.primary.white,
  borderRadius: "8px",
  padding: "25px 25px 15px",
  maxWidth: PAGE_CONTENT_WIDTH,
  marginBottom: "20px",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    maxWidth: "calc(100vw - 30px)",
    padding: "15px",
  },
}));

export const PageContainerStyled = styled(Box)(({ theme }) => ({
  margin: "0 34px",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    margin: "0 15px",
  },
}));

export const HeaderStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "30px 0",
}));

export const BudgetStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: "26px 90px",
  borderRadius: "8px",
  backgroundColor: palette.custom.green.light,
  width: "100%",
  maxWidth: PAGE_CONTENT_WIDTH,
  marginBottom: "30px",
  ".MuiTypography-root": {
    color: palette.custom.green.dark,
  },
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    maxWidth: "calc(100vw - 30px)",
    padding: "26px 15px",
  },
}));
export const FooterStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "20px 0",
  width: "100%",
  maxWidth: PAGE_CONTENT_WIDTH,
  marginBottom: "30px",
  borderTop: `1px solid ${palette.primary.main}`,
  ".MuiButton-root": {
    marginRight: "30px",
    minWidth: "238px",
    minHeight: "60px",
  },
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    maxWidth: "calc(100vw - 30px)",
    flexDirection: "column",
    ".MuiButton-root": {
      margin: "0 0 30px",
      minWidth: "100%",
      flexWrap: "wrap",
    },
  },
}));

export const TypeChipStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "4px 20px",
  marginLeft: "25px",
  background: palette.custom.green.light,
  color: palette.custom.green.dark,
  borderRadius: "8px",
}));

export const CollapseStyled = styled(Collapse)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: palette.custom.primary.white,
  borderRadius: "8px",
  padding: "25px 25px 15px",
  maxWidth: PAGE_CONTENT_WIDTH,
  marginBottom: "20px",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    maxWidth: "calc(100vw - 30px)",
    padding: "15px",
  },
}));

export const CollapseHeaderStyled = styled(Box)(() => ({
  height: "50px",
  display: "flex",
  justifyContent: "flex-end",
}));

export const FormFieldsContainerStyled = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns:
    "minmax(400px, 100%) minmax(300px, 100%) minmax(50px, 100%)",
  gridGap: "15px",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    display: "flex",
    flexDirection: "column",
    gridGap: 0,
  },
}));

export const ColumnStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "& input.Mui-disabled": {
    backgroundColor: palette.primary.main,
  },
}));

export const SelectWithMargin = styled(Select)(({ theme }) => ({
  "&.Mui-disabled": {
    background: palette.primary.main,
  },
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    marginBottom: "10px",
  },
}));

export const ContentContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  borderTop: `1px solid ${palette.custom.grey.light}`,
  marginTop: "10px",
  gap: "40px",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    flexWrap: "wrap",
    gap: 0,
  },
}));

export const LinkContainerStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${palette.custom.grey.light}`,
  marginTop: "10px",
}));

export const GraphContainerStyled = styled(Box)(({ theme }) => ({
  flex: "0 0 80%",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    flexBasis: "100%",
  },
}));

export const CalculationsContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  textAlign: "right",
  flex: "0 0 calc(20% - 40px)",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    textAlign: "center",
    margin: "10px",
    flexWrap: "wrap",
    borderTop: `1px solid ${palette.primary.main}`,
    flexBasis: "100%",
  },
}));

export const CalculationRowStyled = styled(Box)(() => ({
  display: "flex",
}));

export const CalculationItemStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginTop: "20px",
}));

export const CalculationTitleStyled = styled(Typography)(() => ({
  fontWeight: 700,
}));

export const CalculationValueStyled = styled(Typography)(() => ({
  fontSize: "24px",
  fontWeight: 700,
  textAlign: "left",
  marginRight: "5px",
}));

export const CalculationSubtitleStyled = styled(Typography)(() => ({
  fontWeight: 400,
  color: palette.custom.grey.lightIco,
  textAlign: "left",
}));
