import React from "react";
// Translation
import { useTranslation } from "react-i18next";
import { TitleContainer } from "components/Freelancer/styles";
import { Typography } from "@mui/material";

const UserTitle: React.FC = () => {
  const { t } = useTranslation();
  return (
    <TitleContainer>
      <Typography variant="jumboTitle">
        {t("pages.UserProfile.UserTitle.Налаштування профіля")}
      </Typography>
    </TitleContainer>
  );
};

export default UserTitle;
