/* eslint-disable react/no-unstable-nested-components */
import React, { forwardRef } from "react";

import { SelectProps, MenuItem, FormControl } from "@mui/material";

import { PlusIcon, SelectArrowIcon } from "assets/icons";

import {
  Label,
  FormHelperText,
  MaterialUISelect,
  ButtonStyled,
} from "./styles";
// Translation
import { useTranslation } from "react-i18next";

export interface InputProps {
  data: Array<{ value: string | number; label: string }>;
  errorMessage?: string;
  fullWidth?: boolean;
  button?: {
    label: string;
    onClick: () => void;
  };
}

export interface IconProps {
  className: string;
}

const Select = forwardRef(
  (
    {
      data,
      error,
      label,
      errorMessage,
      defaultValue = "",
      fullWidth,
      button,
      required,
      ...props
    }: InputProps & SelectProps,
    ref
  ) => {
    const { t } = useTranslation();
    return (
      <FormControl
        ref={ref as React.RefObject<HTMLDivElement>}
        className={props.className || ""}
        sx={{ width: "100%" }}
        fullWidth={fullWidth}
      >
        {label && (
          <Label
            required={required}
            isEmpty={(`${props.value}` || "").length === 0}
          >
            {label}
          </Label>
        )}

        <MaterialUISelect
          defaultValue={defaultValue}
          IconComponent={(iconProps) => <SelectArrowIcon {...iconProps} />}
          variant="outlined"
          displayEmpty
          fullWidth
          error={error}
          {...props}
        >
          {data.map((item) => (
            <MenuItem key={item.label} value={item.value}>
              {t(item.label)}
            </MenuItem>
          ))}
          {!!button && (
            <ButtonStyled startIcon={<PlusIcon />} onClick={button.onClick}>
              {button.label}
            </ButtonStyled>
          )}
        </MaterialUISelect>

        <FormHelperText isError={error}>{errorMessage}</FormHelperText>
      </FormControl>
    );
  }
);

export default Select;
