import { useAllInvoices } from "./useAllInvoices";
import { palette } from "styles/theme/palette";
import { EXPENSE_STATUS, INVOICE_STATE } from "utils/freelancer/constants";
import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";
import { useProjectsWithPhases } from "./useProjectsWithPhases";
import { useTranslation } from "react-i18next";
import { getNumber } from "utils/freelancer/helpers/getNumber";
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useGetAllUserExpensesQuery } from "../../store/userExpense/userExpenseServices";

interface NumberValue {
  label: string;
  value: string;
  color: string;
}

export const useImportantNumbers = (
  startDate: Date | null,
  endDate: Date | null
): NumberValue[] => {
  const { allInvoices } = useAllInvoices();
  const { currency, userId } = useFreelancerDefaults();
  const { t } = useTranslation();
  const [myHoursWorked, setMyHoursWorked] = useState<number>(0);
  const { data: allExpenses } = useGetAllUserExpensesQuery(null);

  const outcomeValue = useMemo(
    () =>
      getNumber(
        allExpenses
          ?.filter(
            ({ created_at }) =>
              dayjs(created_at).isAfter(startDate) &&
              dayjs(created_at).isBefore(endDate)
          )
          .reduce((partialSum, { amount }) => partialSum + amount, 0)
      ),
    [allExpenses, startDate, endDate]
  );

  const incomeValue = useMemo(
    () =>
      getNumber(
        allInvoices
          .filter(
            ({ state, startDate: invoiceStartDate, dueDate }) =>
              state === INVOICE_STATE.PAID &&
              dayjs(invoiceStartDate).isAfter(startDate) &&
              dayjs(dueDate).isBefore(endDate)
          )
          .reduce(
            (partialSum, { invoiced }) => partialSum + getNumber(invoiced),
            0
          )
      ),
    [allInvoices, startDate, endDate]
  );
  const { activeProjectsWithPhases } = useProjectsWithPhases();

  useEffect(() => {
    setMyHoursWorked(0);
    activeProjectsWithPhases?.forEach((project) => {
      project.phases
        .filter(
          ({ extras }) =>
            dayjs(extras.startDate).isAfter(startDate) &&
            dayjs(extras.endDate).isBefore(endDate)
        )
        .forEach(({ extras, expenses }) => {
          if (extras.phaseStatus === EXPENSE_STATUS.SUBMITTED) {
            expenses
              .filter(
                ({ expense_group, name }) =>
                  expense_group.name === "employees" && name === userId
              )
              .forEach(({ amount }) =>
                setMyHoursWorked((prevAmount) => prevAmount + amount)
              );
          }
        });
    });
  }, [activeProjectsWithPhases, startDate, endDate]);

  const averageRate = useMemo(
    () =>
      getNumber(
        myHoursWorked > 0 ? (incomeValue - outcomeValue) / myHoursWorked : 0
      ).toFixed(2),
    [myHoursWorked, incomeValue, outcomeValue]
  );

  return [
    {
      label: "freelancer.page.dashboard.numbers.averageRate",
      value: `${currency}${averageRate}`,
      color: palette.common.black,
    },
    {
      label: "freelancer.page.dashboard.numbers.hoursWorked",
      value: `${myHoursWorked.toFixed(2)}${t("hoursShort")}`,
      color: palette.common.black,
    },
    {
      label: "freelancer.page.dashboard.numbers.income",
      value: `${currency}${incomeValue.toFixed(2)}`,
      color: palette.custom.green.chartGreenText,
    },
    {
      label: "freelancer.page.dashboard.numbers.outcome",
      value: `${currency}${outcomeValue.toFixed(2)}`,
      color: palette.custom.red.light,
    },
  ];
};
