import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";

export const Container = styled(Box)`
  display: flex;
`;

export const Wrapper = styled(Box)`
  flex-grow: 1;
  padding-top: 20px;
`;

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const ButtonStyled = styled(Button)(() => ({
  height: "40px",
}));
