import { type FC } from "react";
import { Link } from "react-router-dom";
import { freelanceUserRoutesPath } from "routes/routePath";
import { Logo } from "assets/logos";
import { HeaderContainerStyled } from "./styles";
import { HamburgerIcon } from "assets/icons";
import { IconContainerStyled } from "../styles";

interface MobileHeaderProps {
  handleDrawer: () => void;
}

const MobileHeader: FC<MobileHeaderProps> = ({ handleDrawer }) => (
  <HeaderContainerStyled>
    <Link to={freelanceUserRoutesPath.DASHBOARD}>
      <Logo />
    </Link>
    <IconContainerStyled onClick={handleDrawer}>
      <HamburgerIcon />
    </IconContainerStyled>
  </HeaderContainerStyled>
);

export default MobileHeader;
