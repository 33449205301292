import { BaseQueryParams } from "store/baseQuery";
import { type UserTransaction } from "./transactionsTypes";

export const transactionsServices = BaseQueryParams("transactions", [
  "TRANSACTIONS",
]).injectEndpoints({
  endpoints: (builder) => ({
    getTransactions: builder.query<UserTransaction[], null>({
      query: () => ({
        url: "/user/transaction",
        method: "GET",
      }),

      providesTags: () => ["TRANSACTIONS"],
    }),
  }),
});

export const { useGetTransactionsQuery } = transactionsServices;
