import React, { FC } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormTrigger,
  UseFieldArrayUpdate,
} from "react-hook-form";
import { FormControl } from "@mui/material";
import { CancelIcon } from "assets/icons";
import { ICreateAdditionalCosts } from "store/tutors/tutorsTypes";
// Components
import Select from "components/UI/Select";
import Input from "components/UI/Input";
import { Label } from "components/UI/Input/styles";
import CreatableSelect from "../CreatableSelect";
// Styles
import { Container, DeleteBtn } from "./styles";
// Translation
import { useTranslation } from "react-i18next";

interface AdditionalCostItemProps {
  index: number;
  update: UseFieldArrayUpdate<{ additionalCosts: ICreateAdditionalCosts[] }>;
  trigger: UseFormTrigger<{ additionalCosts: ICreateAdditionalCosts[] }>;
  deleteItem: (id: number, additional_id?: string) => void;
  additionalCosts: ICreateAdditionalCosts;
  control: Control<{ additionalCosts: ICreateAdditionalCosts[] }>;
  errors: FieldErrors<{ additionalCosts: ICreateAdditionalCosts[] }>;
}

const AMOUNT_DATA_SELECT = [
  { value: 0, label: "Oдноразово" },
  { value: 4, label: "Раз на тиждень" },
  { value: 1, label: "Раз на місяць" },
];

const AdditionalCostItem: FC<AdditionalCostItemProps> = ({
  additionalCosts,
  control,
  errors,
  index,
  deleteItem,
  trigger,
  update,
}) => {
  const deleteProduct = () => {
    deleteItem(index, additionalCosts?.additional_id);
  };
  const { t } = useTranslation();
  return (
    <Container>
      <Controller
        render={({ field }) => (
          <Input
            label={t("На що витрачємо")}
            {...field}
            placeholder={t("Тип витрат")}
            className="name-input"
            error={!!errors?.additionalCosts?.[index]?.name?.message}
            helperText={errors?.additionalCosts?.[index]?.name?.message}
          />
        )}
        control={control}
        name={`additionalCosts.${index}.name`}
        rules={{ required: { value: true, message: t("fieldIsRequired") } }}
      />

      <FormControl className="select-form-control">
        <Label>{t("Категорія")}</Label>

        <CreatableSelect
          errors={errors}
          control={control}
          index={index}
          update={update}
          trigger={trigger}
        />
      </FormControl>

      <Controller
        render={({ field }) => (
          <Select
            label={t("Повторюваність")}
            data={AMOUNT_DATA_SELECT}
            {...field}
            disabled
            error={!!errors?.additionalCosts?.[index]?.amount?.message}
            errorMessage={errors?.additionalCosts?.[index]?.amount?.message}
            className="amount-input"
          />
        )}
        control={control}
        name={`additionalCosts.${index}.amount`}
        rules={{ required: t("fieldIsRequired") }}
      />

      <Controller
        render={({ field }) => (
          <Input
            type="number"
            label={t("Сума")}
            {...field}
            placeholder={t("Cумма")}
            className="frequency-input"
            error={!!errors?.additionalCosts?.[index]?.value?.message}
            helperText={errors?.additionalCosts?.[index]?.value?.message}
          />
        )}
        control={control}
        name={`additionalCosts.${index}.value`}
        rules={{ required: { value: true, message: t("fieldIsRequired") } }}
      />

      <DeleteBtn variant="icon" onClick={deleteProduct}>
        <CancelIcon />
      </DeleteBtn>
    </Container>
  );
};

export default AdditionalCostItem;
