interface Data {
  name: string;
  status: string;
  customer: string;
  startDate: string;
  endDate: string;
  total: number;
  billed: number;
  paid: number;
  paymentLeft: number;
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
  width?: number;
}

export const headCells: HeadCell[] = [
  {
    id: "name",
    numeric: false,
    label: "freelancer.page.projects.columnTitle",
  },
  {
    id: "status",
    numeric: false,
    label: "freelancer.page.projects.columnStatus",
    width: 160,
  },
  {
    id: "customer",
    numeric: false,
    label: "freelancer.page.projects.columnCustomer",
  },
  {
    id: "startDate",
    numeric: false,
    label: "freelancer.page.projects.columnStartDate",
  },
  {
    id: "endDate",
    numeric: false,
    label: "freelancer.page.projects.columnEndDate",
  },
  {
    id: "total",
    numeric: true,
    label: "freelancer.page.projects.columnTotal",
  },
  {
    id: "billed",
    numeric: true,
    label: "freelancer.page.projects.columnBilled",
  },
  {
    id: "paid",
    numeric: true,
    label: "freelancer.page.projects.columnPaid",
  },
];

export enum PRODUCT_GROUP_NAME {
  PROJECTS = "projects",
  PHASES = "phases",
  SERVICE = "service",
}
