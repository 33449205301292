import {
  ButtonStyled,
  CollapseEmployeeStyled,
  EmployeeDataRowStyled,
  IconContainerStyled,
  InputRowStyled,
  InputStyled,
  NameStyled,
  RowStyled,
  TimeLogRowStyled,
} from "../styles";
import { PlusIcon, MinusIcon } from "assets/icons";
import { type Control, Controller } from "react-hook-form";
import { Arrow } from "pages/ProductsAndServices/ProductsTable/styles";
import { Typography } from "@mui/material";
import { useDrawer } from "hooks/useDrawer";
import { useTranslation } from "react-i18next";
import {
  type FC,
  type MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useAuth } from "hooks/useAuth";
import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";
import {
  type ProjectCreateForm,
  type ProjectPhaseEmployee,
} from "interfaces/freelancer/project";
import dayjs from "dayjs";
import { getNumber } from "utils/freelancer/helpers/getNumber";
import EditDeleteTooltip from "components/Freelancer/EditDeleteTooltip";
import { type UseFormSetValue } from "react-hook-form/dist/types/form";
import { type TimeLog } from "store/timeLog/timeLogTypes";
import { InvoicePaidItem } from "store/invoices/invoicesTypes";
import { INVOICE_ITEM_KIND } from "utils/freelancer/constants";
import Tooltip from "components/UI/Tooltip";
import { ClockifyTimeEntry } from "store/productGroup/productGroupTypes";

interface CollapseEmployeeProps {
  item: ProjectPhaseEmployee;
  nestedIndex: number;
  parentIndex: number;
  handleOpenHoursModal: MouseEventHandler<HTMLButtonElement>;
  handleOpenClockifyTimeTrackerModal: MouseEventHandler<HTMLButtonElement>;
  handleOpenConfirmHoursRemovalModal: MouseEventHandler<HTMLButtonElement>;
  control: Control<ProjectCreateForm>;
  handleOpenConfirmationModal: (timeLogId: string) => void;
  handleOpenSaveProjectModal?: () => void;
  handleOpenEditModal: (
    created_at: Date,
    timelog_id: string,
    value: number
  ) => void;
  setValue: UseFormSetValue<ProjectCreateForm>;
  timeLogData?: TimeLog[];
  ableToAddHours: boolean;
  fixTypeProject?: boolean;
  paidExpenses?: InvoicePaidItem[];
  clockifyTimeEntries?: ClockifyTimeEntry[];
}
const CollapseEmployee: FC<CollapseEmployeeProps> = ({
  item,
  nestedIndex,
  parentIndex,
  handleOpenHoursModal,
  handleOpenClockifyTimeTrackerModal,
  handleOpenConfirmHoursRemovalModal,
  control,
  handleOpenConfirmationModal,
  handleOpenEditModal,
  setValue,
  timeLogData,
  ableToAddHours,
  fixTypeProject,
  paidExpenses = [],
  handleOpenSaveProjectModal,
  clockifyTimeEntries,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { employeeName } = useFreelancerDefaults();
  const getEmployeeName = useCallback(
    (name: string) => (user?.user_id === name ? employeeName : name),
    [user, employeeName]
  );
  const { isOpen, toggle } = useDrawer(true);
  const [employeeTimeLogData, setEmployeeTimeLogData] = useState<
    TimeLog[] | undefined
  >();
  const isClockifyIntegrationAlreadyAdded =
    (clockifyTimeEntries?.length || 0) > 0;

  useEffect(() => {
    setEmployeeTimeLogData(
      timeLogData?.filter(
        ({ employee_id }) =>
          item.name === employee_id || item.employeeId === employee_id
      )
    );
  }, [timeLogData]);

  useEffect(() => {
    if (!employeeTimeLogData?.length && !clockifyTimeEntries?.length) {
      setValue(
        `workPhases.${parentIndex}.expenses.employees.${nestedIndex}.hours`,
        0
      );
    } else {
      if (employeeTimeLogData?.length) {
        const hoursTotal = +(
          employeeTimeLogData.reduce(
            (partialSum, { value }) => partialSum + value,
            0
          ) / 60
        ).toFixed(1);
        setValue(
          `workPhases.${parentIndex}.expenses.employees.${nestedIndex}.hours`,
          hoursTotal
        );
      } else if (clockifyTimeEntries?.length) {
        const hoursTotal =
          clockifyTimeEntries.reduce(
            (partialSum, { start, end }) =>
              partialSum + dayjs(end).diff(dayjs(start), "m", true),
            0
          ) / 60;

        setValue(
          `workPhases.${parentIndex}.expenses.employees.${nestedIndex}.hours`,
          parseFloat(hoursTotal.toFixed(1))
        );
      }
    }
  }, [employeeTimeLogData, clockifyTimeEntries]);

  useEffect(() => {
    if (!paidExpenses?.length) return;
    const billedHours = paidExpenses
      .filter(
        ({ id, kind }) =>
          (item.employeeId === id || item.name === id) &&
          kind === INVOICE_ITEM_KIND.TEAM
      )
      .reduce((partialSum, { total }) => partialSum + total, 0);
    setValue(
      `workPhases.${parentIndex}.expenses.employees.${nestedIndex}.billableHours`,
      billedHours
    );
  }, [paidExpenses, item.employeeId]);

  const onAddHoursButtonClick = useCallback(
    (e) => {
      if (ableToAddHours) {
        if (isClockifyIntegrationAlreadyAdded) {
          handleOpenConfirmHoursRemovalModal(e);
        } else {
          handleOpenHoursModal(e);
        }
      } else {
        handleOpenSaveProjectModal?.();
      }
    },
    [ableToAddHours, isClockifyIntegrationAlreadyAdded]
  );
  const onAddTimeTrackerButtonClick = useCallback(
    (e) => {
      if (ableToAddHours) {
        if ((employeeTimeLogData?.length || 0) === 0) {
          handleOpenClockifyTimeTrackerModal(e);
        } else {
          handleOpenConfirmHoursRemovalModal(e);
        }
      } else {
        handleOpenSaveProjectModal?.();
      }
    },
    [employeeTimeLogData]
  );

  return (
    <CollapseEmployeeStyled collapsedSize={60} in={isOpen}>
      <RowStyled>
        <NameStyled variant="body1">{getEmployeeName(item.name)}</NameStyled>
        <EmployeeDataRowStyled>
          {!user?.settings.clockifyApiKey ? (
            <Tooltip
              tooltipText={t(
                "freelancer.page.addEditProject.employee.addTimeTracker.setupClockifyIntegrationFirst"
              )}
            >
              <span>
                <ButtonStyled
                  data-index={nestedIndex}
                  variant="light"
                  startIcon={<PlusIcon />}
                  disabled={true}
                  onClick={onAddTimeTrackerButtonClick}
                >
                  {t(
                    "freelancer.page.addEditProject.employee.addTimeTracker.buttonLabel"
                  )}
                </ButtonStyled>
              </span>
            </Tooltip>
          ) : (
            <ButtonStyled
              disabled={isClockifyIntegrationAlreadyAdded}
              data-index={nestedIndex}
              variant="light"
              startIcon={
                isClockifyIntegrationAlreadyAdded ? <MinusIcon /> : <PlusIcon />
              }
              onClick={onAddTimeTrackerButtonClick}
            >
              {t(
                "freelancer.page.addEditProject.employee.addTimeTracker.buttonLabel"
              )}
            </ButtonStyled>
          )}
          <ButtonStyled
            onClick={onAddHoursButtonClick}
            data-index={nestedIndex}
            variant="outlined"
            startIcon={<PlusIcon />}
          >
            {t("freelancer.page.addEditProject.employee.addHours.buttonLabel")}
          </ButtonStyled>
          <InputRowStyled>
            <Controller
              name={`workPhases.${parentIndex}.expenses.employees.${nestedIndex}.amount`}
              control={control}
              render={({ field }) => (
                <InputStyled {...field} disabled type="number" />
              )}
            />
            <Controller
              name={`workPhases.${parentIndex}.expenses.employees.${nestedIndex}.hours`}
              control={control}
              render={({ field }) => (
                <InputStyled {...field} disabled type="number" />
              )}
            />
            {!fixTypeProject && (
              <Controller
                name={`workPhases.${parentIndex}.expenses.employees.${nestedIndex}.billableHours`}
                control={control}
                render={({ field }) => (
                  <InputStyled {...field} disabled type="number" />
                )}
              />
            )}
          </InputRowStyled>
          <IconContainerStyled
            disabled={!employeeTimeLogData?.length}
            onClick={toggle}
          >
            <Arrow $isOpen={!!employeeTimeLogData?.length && isOpen} />
          </IconContainerStyled>
        </EmployeeDataRowStyled>
      </RowStyled>
      {!!employeeTimeLogData?.length &&
        employeeTimeLogData.map(({ created_at, value, timelog_id }) => (
          <TimeLogRowStyled key={timelog_id}>
            <Typography>{dayjs(created_at).format("DD.MM.YY")}</Typography>
            <Typography>{`${getNumber(value / 60).toFixed(1)} ${t(
              "hrs"
            )}`}</Typography>
            <EditDeleteTooltip
              onDelete={() => handleOpenConfirmationModal(timelog_id)}
              onEdit={() => handleOpenEditModal(created_at, timelog_id, value)}
            />
          </TimeLogRowStyled>
        ))}
      {!!clockifyTimeEntries?.length &&
        clockifyTimeEntries.map(({ duration, end, start }) => (
          <TimeLogRowStyled key={`${duration}-${end}-${start}`}>
            <Typography>{dayjs(start).format("DD.MM.YY")}</Typography>
            <Typography>{`${dayjs(end)
              .diff(dayjs(start), "h", true)
              .toFixed(1)} ${t("hrs")}`}</Typography>
            <EditDeleteTooltip disabled />
          </TimeLogRowStyled>
        ))}
    </CollapseEmployeeStyled>
  );
};

export default CollapseEmployee;
