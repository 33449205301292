import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/UI/Modal";
import { Button, Container, ContainerButton, Title } from "./styles";

interface ConfirmationModalProps {
  deleteHandler?: () => void;
  onClose: () => void;
  isOpen: boolean;
  title: string;
  deleteButtonLabel?: string;
  cancelButtonLabel?: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  deleteHandler,
  onClose,
  isOpen,
  title,
  deleteButtonLabel,
  cancelButtonLabel,
}) => {
  const { t } = useTranslation();
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Container>
        <Title variant="h1">{title}</Title>

        <ContainerButton>
          <Button
            isDelete
            onClick={deleteHandler}
            variant="contained"
            component="span"
          >
            {t(
              deleteButtonLabel ??
                "components.Tutor.DeletePipelineModal.Видалити"
            )}
          </Button>

          <Button variant="light" onClick={onClose}>
            {t(
              cancelButtonLabel ??
                "components.Tutor.DeletePipelineModal.Скасувати"
            )}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default ConfirmationModal;
