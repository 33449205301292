import { styled } from "@mui/material/styles";
import { Box, type Breakpoint, TableContainer } from "@mui/material";
import { PAGE_CONTENT_WIDTH } from "styles/theme/constants";
import { palette } from "styles/theme/palette";
import { HeaderStyled } from "../../../Projects/AddEdit/styles";

export const PreviewContainerStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
}));

export const PreviewHeaderStyled = styled(HeaderStyled)(() => ({
  paddingBottom: "10px",
}));

export const CredentialsContainerStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginTop: "35px",
}));

export const ColumnStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "calc((100% - 20px) / 2)",
  "&:not(:last-of-type)": {
    borderRight: `1px solid ${palette.primary.main}`,
  },
}));

export const CredentialsRowStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  width: "100%",
  marginTop: "10px",
  "& > span": {
    margin: "0 20px 0 0",
  },
  "& > *": {
    width: "50%",
  },
}));

export const DocumentContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  margin: "30px 0",
  maxWidth: PAGE_CONTENT_WIDTH,
  backgroundColor: palette.custom.primary.white,
  borderRadius: "8px",
  width: "100%",
  minHeight: "870px",
  padding: "30px",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    minHeight: "200vh",
  },
}));

export const MainDocumentInfoStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
}));

export const DatesContainerStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
}));

export const DateStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  "&:not(:last-of-type)": {
    marginRight: "50px",
  },
}));

export const TableContainerStyled = styled(TableContainer)(() => ({
  maxWidth: PAGE_CONTENT_WIDTH,
  marginTop: "40px",
  ".MuiTable-root": {
    borderRadius: "8px",
    border: `1px solid ${palette.primary.main}`,
    backgroundColor: palette.custom.grey.darkLite,
  },
  ".MuiTableHead-root": {
    "th:not(:last-of-type)": {
      borderRight: `1px solid ${palette.primary.main}`,
    },
    ".MuiTableCell-root": {
      fontSize: "16px",
      fontWeight: 500,
      color: palette.custom.primary.silver,
      height: "64px",
    },
  },
  ".MuiTableBody-root": {
    "td:not(:last-of-type)": {
      borderRight: `1px solid ${palette.primary.main}`,
    },
    ".MuiTableCell-root": {
      height: "73px",
    },
  },
}));

export const AdditionalInfoContainerStyled = styled(Box)(() => ({
  marginTop: "18px",
  display: "flex",
  width: "100%",
  maxWidth: PAGE_CONTENT_WIDTH,
  justifyContent: "space-between",
  alignItems: "flex-start",
}));

export const TotalContainerStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
}));

export const LabelsContainerStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginRight: "30px",
}));
