import { styled, css } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import { LogoText } from "assets/logos";

interface ContainerProps {
  expanded: boolean;
}

export const Container = styled("div")<ContainerProps>`
  display: flex;
  align-items: center;
  padding: ${({ expanded }) => (expanded ? "25px 20px" : "25px 15px")};
  margin-bottom: ${({ expanded }) => (expanded ? "60px" : "70px")};

  & .logo {
    ${({ expanded }) =>
      expanded
        ? css`
            margin-right: 10px;
          `
        : css`
            cursor: pointer;
            transition: opacity 0.3s;

            &:hover {
              opacity: 0.5;
            }
          `}
  }
`;

export const BackButton = styled(ButtonBase)`
  width: 34px;
  height: 34px;
  border-radius: 8px;
  background-color: rgba(51, 51, 51, 1);

  color: rgba(255, 255, 255, 1);

  margin-left: auto;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.5;
  }
`;

export const StyledLogoText = styled(LogoText)`
  color: white;
  margin-right: 10px;
`;
