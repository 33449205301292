import { type ProductAsPhase } from "../product";
import { type Employee } from "store/employees/employeesTypes";
import {
  EXPENSE_STATUS,
  PRODUCT_GROUP_STATUS,
  PRODUCT_GROUP_VISIBILITY,
} from "utils/freelancer/constants";
import { type TableInvoice } from "./invoice";
import { ClockifyIntegrationConfig } from "store/productGroup/productGroupTypes";

export interface ProjectPhaseEmployee {
  name: string;
  employeeId: string;
  id?: string;
  expenseId?: string;
  amount: number;
  hours: number;
  billableHours: number;
  status: EXPENSE_STATUS;
  twin_id?: string;
  expense_group_id?: string;
}
export interface ProjectPhaseService {
  name: string;
  price: number;
  id?: string;
  expenseId?: string;
  serviceId: string;
  status: EXPENSE_STATUS;
  twin_id?: string;
  expense_group_id?: string;
}
export interface ProjectPhaseAdditionalExpense {
  name: string;
  amount: number;
  id?: string;
  expenseId?: string;
  status: EXPENSE_STATUS;
  twin_id?: string;
  expense_group_id?: string;
}
export interface ProjectPhaseCreate {
  name: string;
  description?: string;
  startDate: Date;
  endDate?: Date;
  clientId?: string;
  groupId?: string;
  id?: string;
  status: EXPENSE_STATUS;
  isPromoted?: boolean;
  expenses: {
    employees: ProjectPhaseEmployee[];
    services: ProjectPhaseService[];
    additionalExpenses: ProjectPhaseAdditionalExpense[];
  };
  calculations?: PhaseCalculation;
  twin_id?: string;
  clockify?: ClockifyIntegrationConfig;
}
export interface PhaseCalculationExpense {
  [id: string]: {
    name: string;
    amount: number;
    cost: number;
  };
}

interface PhaseCalculationTotal {
  income: number;
  loss: number;
  profit: number;
}

export interface PhaseCalculation {
  phaseId: string;
  name: string;
  expenses: PhaseCalculationExpense[];
  raw: {
    amount: number;
    total: PhaseCalculationTotal;
  };
  real: {
    amount: number;
    total: PhaseCalculationTotal;
  };
}

export interface ProjectWithPhases {
  id: string;
  name: string;
  groupId: string;
  client?: Employee;
  clockify?: ClockifyIntegrationConfig;
  startDate?: Date;
  endDate?: Date;
  type?: string;
  visibility?: PRODUCT_GROUP_VISIBILITY;
  status?: PRODUCT_GROUP_STATUS;
  phases: ProductAsPhase[];
  workPhases?: ProductAsPhase[];
  calculations?: PhaseCalculation[];
  invoices?: TableInvoice[];
}

export interface ProjectCreateForm {
  projectName: string;
  clientId: string;
  phases: ProjectPhaseCreate[];
  workPhases: ProjectPhaseCreate[];
  removedPhases: ProjectPhaseCreate[];
  removedExpenses: { id: string }[];
}

export interface ProjectExpense {
  amount: number;
  cost?: number;
  name: string;
  value?: string;
  id: string;
  expense_group_id?: string;
  extras: {
    dimension: string;
    employeeId?: string;
  };
  group: {
    name: string;
    parent_id: string;
  };
  product_id?: string;
}

export interface ProjectCreate {
  name: string;
  price: number;
  amount: number;
  expenses?: ProjectExpense[];
  group?: string;
  twin_id?: string;
  extras?: {
    clientId?: string;
    phaseStatus?: EXPENSE_STATUS;
    startDate?: Date;
    endDate?: Date;
    twin_id?: string;
    type?: string;
    phaseDescription?: string;
  };
}
export interface ProjectCreateResponse extends ProjectCreate {
  id: string;
  group_id?: string;
}

export interface ProjectUpdate extends ProjectCreate {
  pipeline_id: string;
  id: string;
  group_id: string;
}

export enum GRAPH_DATA_TYPE {
  ESTIMATED_BUDGET = "estimatedBudget",
  ESTIMATED_COSTS = "estimatedCosts",
  BUDGET_USED = "budgetUsed",
  PAYMENT_RECEIVED = "paymentReceived",
}

export interface ProjectGraphNumbers {
  label: string;
  data: {
    label: string;
    value: number | string;
    unit?: string;
  }[];
}

export interface ProjectGraphData {
  type?: GRAPH_DATA_TYPE;
  name: string;
  value: number;
  color: string;
  tooltip?: {
    name: string;
    value: number;
  }[];
}

export interface ProjectGraphBaseExpense {
  me: number;
  teammates: number;
  additional: number;
  total: number;
}
export interface ProjectGraphExpenses {
  budgetUsed: ProjectGraphBaseExpense;
  estimatedCosts: ProjectGraphBaseExpense;
  estimatedBudget: {
    services: { name: string; price: number }[];
    total: number;
  };
}

export interface ProductExpenseUpdate {
  name: string;
  value: string;
  amount: number;
  extras: {
    dimension: string;
    employeeId?: string;
    serviceId?: string;
  };
  group?: {
    parent_id: string;
    name: string;
  };
}
