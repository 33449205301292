import React, { type FC, useCallback, useEffect, useState } from "react";
import Modal from "components/UI/Modal";
import { useTranslation } from "react-i18next";
import {
  Button,
  ContainerButton,
  Title,
} from "pages/Teams/AddEmployeeModal/styles";

import {
  ContainerInput,
  Input as DateInput,
} from "components/UI/DatePicker/styles";
import { Label } from "components/UI/Input/styles";
import {
  TRANSACTION_TYPE,
  type UserTransaction,
} from "store/transactions/transactionsTypes";
import { useUpdateInvoiceMutation } from "store/invoices/invoicesServices";
import { useUpdateUserExpenseMutation } from "store/userExpense/userExpenseServices";
import { getNumber } from "utils/freelancer/helpers/getNumber";
import {
  AddHoursContainerStyled,
  DatePickerContainerInput,
  FormContainerStyled,
  InputStyled,
} from "../../../Projects/AddEdit/Expenses/styles";
import { EditTransactionContentContainerStyled } from "../../styles";

interface EditModalProps {
  onClose: () => void;
  isOpen: boolean;
  item: UserTransaction | null;
  refetch: () => void;
}

const EditModal: FC<EditModalProps> = ({ onClose, isOpen, item, refetch }) => {
  const { t } = useTranslation();
  const initialAmount = item?.amount ? `${item.amount}` : "0";
  const initialDate = item?.created_at ? new Date(item.created_at) : new Date();
  const [amount, setAmount] = useState<string>(initialAmount);
  const [itemDate, setItemDate] = useState<Date>(initialDate);
  const [updateInvoice] = useUpdateInvoiceMutation();
  const [updateUserExpense] = useUpdateUserExpenseMutation();

  const handleChangeAmount = useCallback(({ target }) => {
    setAmount(target.value);
  }, []);
  const title =
    item?.type === TRANSACTION_TYPE.INVOICE
      ? "freelancer.page.invoices.dueDateUpdate.title"
      : "freelancer.page.dashboard.transactions.expense.editTitle";

  const submitUpdate = useCallback(async () => {
    if (item) {
      if (item.type === TRANSACTION_TYPE.INVOICE && itemDate) {
        try {
          await updateInvoice({
            body: {
              valid_until: itemDate,
            },
            invoice_id: item.id,
          });
          refetch();
          onClose();
        } catch (e) {
          console.error("Error on invoice update, ", e);
        }
      }
      if (
        item.type === TRANSACTION_TYPE.USER_EXPENSE &&
        amount !== undefined &&
        itemDate
      ) {
        try {
          await updateUserExpense({
            body: {
              amount: getNumber(amount),
              created_at: itemDate,
            },
            id: item.id,
          });
          refetch();
          onClose();
        } catch (e) {
          console.error("Error on invoice update, ", e);
        }
      }
    }
  }, [onClose, refetch, amount, itemDate]);

  const handleChangeDate = useCallback((newDate: Date) => {
    setItemDate(newDate);
  }, []);

  useEffect(() => {
    if (item) {
      setAmount(initialAmount);
      setItemDate(initialDate);
    }
  }, [item]);

  return (
    <Modal visibleOverflow onClose={onClose} isOpen={isOpen}>
      <AddHoursContainerStyled>
        <EditTransactionContentContainerStyled>
          <Title variant="h1">{t(title)}</Title>
          <FormContainerStyled>
            {item?.type === TRANSACTION_TYPE.USER_EXPENSE && (
              <InputStyled
                type="number"
                label={t("freelancer.page.dashboard.transactions.amount")}
                placeholder={t(
                  "freelancer.page.addEditProject.employee.addHours.placeholder"
                )}
                fullWidth
                value={amount}
                onChange={handleChangeAmount}
              />
            )}
            <DatePickerContainerInput>
              <Label>
                {t(
                  "freelancer.page.addEditProject.employee.editHours.dateTitle"
                )}
              </Label>
              <ContainerInput>
                <DateInput selected={itemDate} onChange={handleChangeDate} />
              </ContainerInput>
            </DatePickerContainerInput>
          </FormContainerStyled>
        </EditTransactionContentContainerStyled>
        <ContainerButton>
          <Button variant="contained" component="span" onClick={submitUpdate}>
            {t("save")}
          </Button>

          <Button variant="light" onClick={onClose}>
            {t("Скасувати")}
          </Button>
        </ContainerButton>
      </AddHoursContainerStyled>
    </Modal>
  );
};

export default EditModal;
