import { type InvoiceCreateForm } from "interfaces/freelancer/invoice";
import { type TFunction } from "react-i18next";
import { EMAIL_PATTERN } from "../constants";
import { type EmploymentField } from "components/Freelancer/Invoices/AddEdit/Expenses/BankAccountDetails/data";
import { getNumber } from "./getNumber";

interface Credential {
  label: string;
  value: string | number;
}

export interface InvoicePreviewTableRow {
  name: string;
  type: "service" | "additionalExpense";
  quantity: number;
  cost: string;
  amount: string;
}

export const getBilledToData = (data: InvoiceCreateForm): Credential[] => [
  {
    label: "freelancer.page.invoices.add.preview.executorName",
    value: data.credentials?.employment?.client?.name ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.edrpo",
    value: data.credentials?.employment?.client?.edrpo ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.address",
    value: data.credentials?.employment?.client?.address ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.email",
    value: data.credentials?.employment?.client?.email ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.bank.beneficiaryName",
    value: data.credentials?.bank?.client?.beneficiary?.name ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.bank.beneficiaryAddress",
    value: data.credentials?.bank?.client?.beneficiary?.address ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.bank.beneficiarySwift",
    value: data.credentials?.bank?.client?.beneficiary?.swift ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.bank.beneficiaryAccount",
    value: data.credentials?.bank?.client?.beneficiary?.account ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.bank.correspondentName",
    value: data.credentials?.bank?.client?.correspondent?.name ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.bank.correspondentSwift",
    value: data.credentials?.bank?.client?.correspondent?.swift ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.bank.correspondentAccount",
    value: data.credentials?.bank?.client?.correspondent?.account ?? "",
  },
];

export const getFromData = (data: InvoiceCreateForm): Credential[] => [
  {
    label: "freelancer.page.invoices.add.preview.executorName",
    value: data.credentials?.employment?.executor?.name ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.edrpo",
    value: data.credentials?.employment?.executor?.edrpo ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.address",
    value: data.credentials?.employment?.executor?.address ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.email",
    value: data.credentials?.employment?.executor?.email ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.bank.beneficiaryName",
    value: data.credentials?.bank?.executor?.beneficiary?.name ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.bank.beneficiaryAddress",
    value: data.credentials?.bank?.executor?.beneficiary?.address ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.bank.beneficiarySwift",
    value: data.credentials?.bank?.executor?.beneficiary?.swift ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.bank.beneficiaryAccount",
    value: data.credentials?.bank?.executor?.beneficiary?.account ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.bank.correspondentName",
    value: data.credentials?.bank?.executor?.correspondent?.name ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.bank.correspondentSwift",
    value: data.credentials?.bank?.executor?.correspondent?.swift ?? "",
  },
  {
    label: "freelancer.page.invoices.add.preview.bank.correspondentAccount",
    value: data.credentials?.bank?.executor?.correspondent?.account ?? "",
  },
];

export const getExecutorFields = (t: TFunction): EmploymentField[] => [
  {
    name: "credentials.employment.executor.name",
    label: "freelancer.page.invoices.add.bankDetails.employment.name.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.employment.name.placeholder",
    isRequired: true,
  },
  {
    name: "credentials.employment.executor.email",
    label: "freelancer.page.invoices.add.bankDetails.employment.email.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.employment.email.placeholder",
    isRequired: true,
    rules: {
      pattern: {
        value: EMAIL_PATTERN,
        message: t("emailValidationError"),
      },
    },
  },
  {
    name: "credentials.employment.executor.edrpo",
    label: "freelancer.page.invoices.add.bankDetails.employment.edrpo.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.employment.edrpo.placeholder",
    isRequired: true,
  },
  {
    name: "credentials.employment.executor.address",
    label: "freelancer.page.invoices.add.bankDetails.employment.address.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.employment.address.placeholder",
    isRequired: true,
  },
];

export const getClientFields = (t: TFunction): EmploymentField[] => [
  {
    name: "credentials.employment.client.name",
    label: "freelancer.page.invoices.add.bankDetails.employment.name.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.employment.name.placeholder",
    isRequired: true,
  },
  {
    name: "credentials.employment.client.email",
    label: "freelancer.page.invoices.add.bankDetails.employment.email.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.employment.email.placeholder",
    isRequired: true,
    rules: {
      pattern: {
        value: EMAIL_PATTERN,
        message: t("emailValidationError"),
      },
    },
  },
  {
    name: "credentials.employment.client.edrpo",
    label: "freelancer.page.invoices.add.bankDetails.employment.edrpo.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.employment.edrpo.placeholder",
    isRequired: true,
  },
  {
    name: "credentials.employment.client.address",
    label: "freelancer.page.invoices.add.bankDetails.employment.address.label",
    placeholder:
      "freelancer.page.invoices.add.bankDetails.employment.address.placeholder",
    isRequired: true,
  },
];

export const getInvoicePreviewTableData = (
  data: InvoiceCreateForm,
  currency: string,
  t: TFunction
): InvoicePreviewTableRow[] => {
  const { phases } = data;
  const tableData: InvoicePreviewTableRow[] = [];
  const selectedExpenses = phases.map(
    ({
      employees,
      services,
      description,
      isDownPaymentExpenses,
      isDownPaymentService,
      isDownPaymentEmployees,
    }) => ({
      employees: employees?.filter(({ hoursToBePaid }) => hoursToBePaid > 0),
      services: services?.filter(({ invoicedAmount }) => invoicedAmount > 0),
      serviceDescription: description,
      isDownPaymentExpenses,
      isDownPaymentService,
      isDownPaymentEmployees,
    })
  );
  selectedExpenses?.forEach(
    ({
      employees,
      services,
      serviceDescription,
      isDownPaymentService,
      isDownPaymentEmployees,
    }) => {
      let totalPhaseEmployeesCost = 0;
      employees?.forEach(({ hoursToBePaid, rate }) => {
        totalPhaseEmployeesCost += getNumber(hoursToBePaid * rate);
      });
      const employeeData: InvoicePreviewTableRow = {
        name: isDownPaymentEmployees
          ? `${t("freelancer.page.invoices.add.preview.table.downPayment", {
              name: serviceDescription,
            })}`
          : serviceDescription ?? "",
        type: "service",
        quantity: 1,
        cost: `${totalPhaseEmployeesCost.toFixed(1)} ${currency}`,
        amount: `${totalPhaseEmployeesCost.toFixed(1)} ${currency}`,
      };
      const servicesData: InvoicePreviewTableRow[] =
        services?.map(({ name, invoicedAmount }) => ({
          name: isDownPaymentService
            ? `${t("freelancer.page.invoices.add.preview.table.downPayment", {
                name,
              })}`
            : name,
          type: "service",
          quantity: 1,
          cost: `${invoicedAmount} ${currency}`,
          amount: `${invoicedAmount} ${currency}`,
        })) ?? [];
      if (totalPhaseEmployeesCost > 0) {
        tableData.push(employeeData);
      }
      tableData.push(...servicesData);
    }
  );

  return tableData;
};

export const getInvoicePreviewExpensesTableData = (
  data: InvoiceCreateForm,
  currency: string,
  t: TFunction
): InvoicePreviewTableRow[] => {
  const { phases } = data;
  const tableData: InvoicePreviewTableRow[] = [];
  const selectedExpenses = phases.map(
    ({ additionalExpenses, description, isDownPaymentExpenses }) => ({
      additionalExpenses: additionalExpenses?.filter(
        ({ invoicedAmount }) => invoicedAmount > 0
      ),
      serviceDescription: description,
      isDownPaymentExpenses,
    })
  );
  selectedExpenses?.forEach(({ additionalExpenses, isDownPaymentExpenses }) => {
    const expensesData: InvoicePreviewTableRow[] =
      additionalExpenses?.map(({ name, invoicedAmount }) => ({
        name: isDownPaymentExpenses
          ? `${t("freelancer.page.invoices.add.preview.table.downPayment", {
              name,
            })}`
          : name,
        type: "additionalExpense",
        quantity: 1,
        cost: `${invoicedAmount} ${currency}`,
        amount: `${invoicedAmount} ${currency}`,
      })) ?? [];
    tableData.push(...expensesData);
  });

  return tableData;
};
