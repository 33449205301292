export const servicesAndEmployeesTableHeadCells = [
  "freelancer.page.invoices.add.preview.table.description",
  "freelancer.page.invoices.add.preview.table.type",
  "freelancer.page.invoices.add.preview.table.quantity",
  "freelancer.page.invoices.add.preview.table.cost",
  "freelancer.page.invoices.add.preview.table.amount",
];

export const expensesTableHeadCells = [
  "freelancer.page.invoices.add.preview.table.additionalExpenses",
  "freelancer.page.invoices.add.preview.table.type",
  "freelancer.page.invoices.add.preview.table.quantity",
  "freelancer.page.invoices.add.preview.table.cost",
  "freelancer.page.invoices.add.preview.table.amount",
];

export const calculationLabels = [
  "freelancer.page.invoices.add.preview.table.subTotal",
  "freelancer.page.invoices.add.preview.table.discount",
  "freelancer.page.invoices.add.preview.table.tax",
  "freelancer.page.invoices.add.preview.table.total",
];
