import { type FC, useCallback, useState } from "react";
import { TableCell } from "@mui/material";
import {
  ContainerStyled,
  ProjectStatusStyled,
  TableCellCustom,
  TableCellStyled,
} from "./styles";
import { Arrow } from "pages/ProductsAndServices/ProductsTable/styles";
import { type ProjectWithPhases } from "interfaces/freelancer/project";
import { PRODUCT_GROUP_STATUS } from "utils/freelancer/constants";
import EditDeleteTooltip from "components/Freelancer/EditDeleteTooltip";
import AdditionalTooltipItems from "./AdditionalTooltipItems";
import dayjs from "dayjs";
import ConfirmationModal from "../../../ConfirmationModal";
import { useDrawer } from "hooks/useDrawer";
import { useTranslation } from "react-i18next";
import { useProject } from "hooks/freelancer/useProject";
import { palette } from "styles/theme/palette";
import ProjectInvoiceTable from "./ProjectInvoiceTable";
import { useTableProjectCalculations } from "hooks/freelancer/useTableProjectCalculations";
import InvoiceDueDateModal from "../../InvoiceDueDateModal";
import type { TableInvoice } from "interfaces/freelancer/invoice";
import WarningModal from "./WarningModal";
import {
  getProjectStatus,
  getProjectStatusColor,
} from "utils/freelancer/projects";
import { InvoiceIcon } from "assets/icons";
import { useInvoiceAdd } from "hooks/freelancer/useInvoiceAdd";
import { useTrialUser } from "hooks/useTrialUser";
import LimitedAccessTooltip from "components/Freelancer/LimitedAccessTooltip";

interface CollapseTableRowProps {
  project: ProjectWithPhases;
  withBorder?: boolean;
}

const DATE_FORMAT = "DD.MM.YYYY";

const CollapseTableRow: FC<CollapseTableRowProps> = ({
  project,
  withBorder = false,
}) => {
  const { t } = useTranslation();
  const { isOpen, open, close } = useDrawer();
  const { isOpen: expanded, toggle } = useDrawer();
  const {
    isOpen: isWarningModalOpen,
    close: closeWarningModal,
    open: openWarningModal,
  } = useDrawer();
  const {
    isOpen: isDueDateModalOpen,
    open: openDueDateModal,
    close: closeDueDateModal,
  } = useDrawer();
  const [invoiceError, setInvoiceError] = useState<string>("");
  const { changeProjectStatus, deleteProject, navigateToEditProjectPage } =
    useProject(project);
  const { billed, total, paid } = useTableProjectCalculations(project);
  const [invoiceToChange, setInvoiceToChange] = useState<TableInvoice | null>(
    null
  );

  const projectStatus = getProjectStatus(project, total);
  const projectStatusColor = getProjectStatusColor(projectStatus);

  const startDate = project?.startDate
    ? dayjs(project?.startDate).format(DATE_FORMAT)
    : "";
  const endDate = project?.endDate
    ? dayjs(project?.endDate).format(DATE_FORMAT)
    : "";

  const handleCellClick = useCallback(() => {
    if (project.status === PRODUCT_GROUP_STATUS.ACTIVE) {
      navigateToEditProjectPage();
    }
  }, [project]);

  //TODO: check calculations and uncomment
  // const ableToCreateInvoice = useMemo(
  //   () => getNumber(total) - getNumber(paid) > 0,
  //   [total, paid]
  // );

  const onInvoiceDueDateChange = useCallback(
    (invoice: TableInvoice) => {
      if (invoice) {
        setInvoiceToChange(invoice);
        openDueDateModal();
      }
    },
    [invoiceToChange]
  );

  const { shouldShowInvoiceIcon, handleCreateInvoiceClick } = useInvoiceAdd(
    project,
    openWarningModal,
    setInvoiceError
  );
  const { limitedAccess } = useTrialUser();

  return (
    <>
      <ContainerStyled withBorder={withBorder}>
        <TableCellCustom
          minWidth={100}
          component="th"
          scope="row"
          onClick={handleCellClick}
        >
          {project.name}
        </TableCellCustom>
        <TableCellCustom minWidth={160} onClick={handleCellClick}>
          <ProjectStatusStyled background={projectStatusColor}>
            {t(projectStatus)}
          </ProjectStatusStyled>
        </TableCellCustom>
        <TableCellCustom minWidth={120} onClick={handleCellClick}>
          {project?.client?.name ?? ""}
        </TableCellCustom>
        <TableCellCustom minWidth={100} onClick={handleCellClick}>
          {startDate}
        </TableCellCustom>
        <TableCellCustom minWidth={100} onClick={handleCellClick}>
          {endDate}
        </TableCellCustom>
        <TableCellStyled
          width={80}
          color={palette.custom.green.chartGreenText}
          onClick={handleCellClick}
        >
          {total}
        </TableCellStyled>
        <TableCellCustom minWidth={80} onClick={handleCellClick}>
          {billed}
        </TableCellCustom>
        <TableCellCustom minWidth={80} onClick={handleCellClick}>
          {paid}
        </TableCellCustom>
        {limitedAccess ? (
          <TableCellStyled width={40}>
            {shouldShowInvoiceIcon && (
              <LimitedAccessTooltip>
                <InvoiceIcon title={t("createInvoice")} />
              </LimitedAccessTooltip>
            )}
          </TableCellStyled>
        ) : (
          <TableCellStyled width={40}>
            {shouldShowInvoiceIcon && (
              <InvoiceIcon
                title={t("createInvoice")}
                onClick={handleCreateInvoiceClick}
              />
            )}
          </TableCellStyled>
        )}
        <EditDeleteTooltip
          onDelete={open}
          data-projectId={project.id}
          onEdit={navigateToEditProjectPage}
          showEdit={project.status === PRODUCT_GROUP_STATUS.ACTIVE}
          additionalItems={
            <AdditionalTooltipItems
              project={project}
              onStatusChange={changeProjectStatus}
              ableToCreateInvoice={false}
            />
          }
        />
        {!!project?.invoices?.length ? (
          <TableCell onClick={toggle}>
            <Arrow $isOpen={expanded} />
          </TableCell>
        ) : (
          <TableCell />
        )}
      </ContainerStyled>
      <ProjectInvoiceTable
        expanded={expanded}
        invoices={project?.invoices}
        onInvoiceDueDateChange={onInvoiceDueDateChange}
      />
      <ConfirmationModal
        deleteHandler={deleteProject}
        onClose={close}
        isOpen={isOpen}
        title={t("deleteConfirmation")}
      />
      <InvoiceDueDateModal
        onClose={closeDueDateModal}
        isOpen={isDueDateModalOpen}
        invoice={invoiceToChange}
      />
      <WarningModal
        onClose={closeWarningModal}
        isOpen={isWarningModalOpen}
        error={invoiceError}
      />
    </>
  );
};
export default CollapseTableRow;
