import { Collapse, TableBody } from "@mui/material";
import InvoiceTableRow from "./ProjectInvoiceTableRow";
import { type TableInvoice } from "interfaces/freelancer/invoice";
import { type FC } from "react";
import { InvoiceTableContainerStyled, InvoiceTableStyled } from "./styles";

interface InvoiceTableProps {
  invoices?: TableInvoice[];
  expanded: boolean;
  onInvoiceDueDateChange: (invoice: TableInvoice) => void;
}
const ProjectInvoiceTable: FC<InvoiceTableProps> = ({
  invoices,
  expanded,
  onInvoiceDueDateChange,
}) => {
  if (!invoices?.length) {
    return null;
  }
  return (
    <InvoiceTableContainerStyled colSpan={11}>
      <Collapse in={expanded} collapsedSize={0}>
        <InvoiceTableStyled aria-label="invoiceTable">
          <TableBody>
            {invoices.map((invoice) => (
              <InvoiceTableRow
                key={invoice.id}
                invoice={invoice}
                isActive
                onInvoiceDueDateChange={onInvoiceDueDateChange}
              />
            ))}
          </TableBody>
        </InvoiceTableStyled>
      </Collapse>
    </InvoiceTableContainerStyled>
  );
};

export default ProjectInvoiceTable;
