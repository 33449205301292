import { useDrawer } from "../useDrawer";
import { type SyntheticEvent, useCallback, useState } from "react";
import { ALERT_SEVERITY } from "utils/freelancer/constants";
import { useTranslation } from "react-i18next";

interface AlertReturn {
  alertMessage: string;
  alertSeverity: ALERT_SEVERITY;
  setAlertMessage: (message: string) => void;
  setAlertSeverity: (severity: ALERT_SEVERITY) => void;
  isAlertOpen: boolean;
  openAlert: () => void;
  handleAlertClose: (event?: SyntheticEvent | Event, reason?: string) => void;
}
export const useAlert = (): AlertReturn => {
  const { t } = useTranslation();
  const { isOpen, open, close } = useDrawer();
  const [alertMessage, setAlertMessage] = useState<string>(t("defaultError"));
  const [alertSeverity, setAlertSeverity] = useState<ALERT_SEVERITY>(
    ALERT_SEVERITY.ERROR
  );
  const handleClose = useCallback(
    (event?: SyntheticEvent | Event, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      close();
    },
    []
  );

  return {
    alertMessage,
    alertSeverity,
    setAlertMessage,
    setAlertSeverity,
    isAlertOpen: isOpen,
    openAlert: open,
    handleAlertClose: handleClose,
  };
};
