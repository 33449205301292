import React from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { InputAdornment } from "@mui/material";
// Store
import { useUpdateLaborMutation } from "store/tutors/tutorsServices";
// Components
import Modal from "components/UI/Modal";
import Input from "components/UI/Input";
import { ClockIcon } from "assets/icons";
// Styles
import { Container, Title, ContainerButton, Button } from "./styles";
// Translation
import { useTranslation } from "react-i18next";
interface EditEmployeeModalProps {
  updateEmployeeAmount: (value: string) => void;
  departmen: string;
  name: string;
  onClose: () => void;
  isOpen: boolean;
  amount: string;
  labor_id?: string;
}

const EditEmployeeModal: React.FC<EditEmployeeModalProps> = ({
  onClose,
  isOpen,
  departmen,
  name,
  amount,
  labor_id,
  updateEmployeeAmount,
}) => {
  const { t } = useTranslation();
  const [updateLabor] = useUpdateLaborMutation();
  const formState = useForm({
    defaultValues: {
      amount,
    },
    mode: "all",
  });

  const onSubmit: SubmitHandler<{ amount: string }> = async ({ amount }) => {
    updateEmployeeAmount(amount);

    if (labor_id) {
      await updateLabor({
        id: labor_id,
        amount,
      }).unwrap();
    }

    onClose();

    formState.reset();
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Container>
        <Title variant="h1">{t("Додати співробітника")}</Title>

        <Input fullWidth label={t("Підрозділ")} value={departmen} />

        <Input fullWidth label="Співробітник" value={name} />

        <Controller
          render={({ field }) => (
            <Input
              label="Витрачено годин"
              type="number"
              placeholder="0"
              className="inline-input time-input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClockIcon />
                  </InputAdornment>
                ),
              }}
              error={!!formState.formState.errors?.amount?.message}
              helperText={formState.formState.errors?.amount?.message}
              {...field}
            />
          )}
          control={formState.control}
          name="amount"
          rules={{ required: t("fieldIsRequired") }}
        />

        <ContainerButton>
          <Button
            variant="contained"
            onClick={formState.handleSubmit(onSubmit)}
            disabled={!formState.formState.isValid}
          >
            {t("Зберегти")}
          </Button>

          <Button variant="light" onClick={onClose}>
            {t("Скасувати")}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default EditEmployeeModal;
