import { Title } from "pages/UserProfile/UserTariffPlans/styles";
import { RowContainer, CardContainer } from "components/TariffPlanCard/styles";
import { type FC, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "pages/UserProfile/styles";
import TariffPlanCardHeader from "./TariffPlanCardHeader";
import TariffPlanCardBody from "components/TariffPlanCard/TariffPlanCardBody";
import ToggleButtons from "./ToggleButtons";
import { useAuth } from "hooks/useAuth";
import TariffPlanCardFooter from "./TariffPlancardFooter";
import {
  useCreateSubscribeMutation,
  useGetTariffsQuery,
} from "store/payments/paymentsServicers";
import dayjs from "dayjs";
import { useGetInvoicesQuery } from "store/auth/authService";
import {
  PRICE_TYPE,
  SUBSCRIPTION_PERIOD,
} from "interfaces/freelancer/settings";
import { ITariff } from "store/payments/paymentsTypes";
import { getNumber } from "utils/freelancer/helpers/getNumber";
import { MEMBERSHIP_STATUS } from "utils/constants/user-profile";

interface TariffPlansProps {
  onClose?: () => void;
}

const TariffPlans: FC<TariffPlansProps> = ({ onClose = () => {} }) => {
  const { t } = useTranslation();
  const [subscriptionPeriod, setSubscriptionPeriod] = useState<string>("month");
  const { user } = useAuth();

  const isActive = MEMBERSHIP_STATUS.PREMIUM1 === user?.membership_status;

  const [createSubscribe] = useCreateSubscribeMutation();
  const { data } = useGetInvoicesQuery({});
  const { data: tariffs } = useGetTariffsQuery({});
  const typeSubscribe =
    subscriptionPeriod === SUBSCRIPTION_PERIOD.MONTH
      ? t("monthShort")
      : t("yearShort");
  const filteredTariffs = useMemo(
    (): ITariff[] =>
      tariffs
        ?.filter(
          ({ endpoint, recurrency }) =>
            endpoint.payload.metadata?.type === PRICE_TYPE.FREELANCER &&
            recurrency === subscriptionPeriod
        )
        ?.sort(
          (tariffA, tariffB) =>
            getNumber(tariffA.endpoint?.payload?.metadata?.order) -
            getNumber(tariffB.endpoint?.payload?.metadata?.order)
        ) ?? [],
    [subscriptionPeriod, tariffs]
  );

  const endDate = dayjs
    .unix(data?.[0]?.description?.[0]?.period?.end as number)
    .format("DD.MM.YYYY");

  const onSubmit = useCallback(async (price_id) => {
    if (!price_id) return;
    const res = await createSubscribe({ price_id }).unwrap();

    if (res?.url) {
      window.location.href = res.url;
    }
  }, []);

  return (
    <Section isSpaceBottom={30} id="plans">
      <Title variant="h2">
        {t("pages.UserProfile.UserTariffPlans.Тарифні плани")}
      </Title>

      <ToggleButtons
        value={subscriptionPeriod}
        setValue={setSubscriptionPeriod}
      />

      <RowContainer>
        {filteredTariffs?.map(
          ({ name, description, amountCents, endpoint }) => (
            <CardContainer isSubscribe={isActive} isDisabled={false}>
              <TariffPlanCardHeader isActive={isActive} title={name} />
              <TariffPlanCardBody
                isDisabled={false}
                countPipelines={description}
              />
              <TariffPlanCardFooter
                onClose={onClose}
                isActive={isActive}
                isDisabled={false}
                price={getNumber(amountCents / 100)}
                typeSubscribe={typeSubscribe}
                onSubmit={() => onSubmit(endpoint?.payload.price_id)}
                dateString={endDate}
                name={name}
                isUnSubscribe={user?.status === 2}
              />
            </CardContainer>
          )
        )}
      </RowContainer>
    </Section>
  );
};

export default TariffPlans;
