import {
  MenuListItem,
  MenuListItemButton,
  Text,
} from "pages/Teams/TeamTable/styles";
import { type FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  EXPENSE_STATUS,
  PRODUCT_GROUP_STATUS,
  PROJECT_TYPE,
  URL_PARAMS,
} from "utils/freelancer/constants";
import { useNavigate } from "react-router";
import { freelanceUserRoutesPath } from "routes/routePath";
import { getUrlWithParams } from "utils/helpers/getUrlWithParams";
import { type ProjectWithPhases } from "interfaces/freelancer/project";
import { useInvoiceCreateErrorMessage } from "hooks/freelancer/useInvoiceCreateErrorMessage";

interface AdditionalTooltipItemsProps {
  onStatusChange: () => void;
  onClickInvoiceWithErrors?: (errorMessage: string) => void;
  project: ProjectWithPhases;
  ableToCreateInvoice: boolean;
}

const AdditionalTooltipItems: FC<AdditionalTooltipItemsProps> = ({
  onStatusChange,
  project,
  ableToCreateInvoice,
  onClickInvoiceWithErrors,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isActive = project.status === PRODUCT_GROUP_STATUS.ACTIVE;
  const errorMessage = useInvoiceCreateErrorMessage(project);
  const navigateToCreateInvoicePage = useCallback(() => {
    const invoiceUrl = getUrlWithParams(freelanceUserRoutesPath.INVOICES_ADD, [
      { key: URL_PARAMS.PROJECT_ID, value: project.id },
      { key: URL_PARAMS.TYPE, value: project.type ?? PROJECT_TYPE.FIX },
    ]);
    navigate(invoiceUrl);
  }, [project]);
  const statusLabel = isActive ? t("archive") : t("returnToActive");

  const hasPromotedPhases = useMemo(
    () =>
      !!project.phases.filter(
        ({ extras }) => extras.phaseStatus === EXPENSE_STATUS.SUBMITTED
      )?.length,
    [`${project.phases}`]
  );

  const handleCreateInvoiceClick = useCallback(() => {
    if (errorMessage.length) {
      onClickInvoiceWithErrors?.(errorMessage);
    } else {
      navigateToCreateInvoicePage();
    }
  }, [onClickInvoiceWithErrors, errorMessage, navigateToCreateInvoicePage]);

  return (
    <>
      {isActive && hasPromotedPhases && ableToCreateInvoice && (
        <MenuListItem disablePadding>
          <MenuListItemButton onClick={handleCreateInvoiceClick}>
            <Text variant="regularText">{t("createInvoice")}</Text>
          </MenuListItemButton>
        </MenuListItem>
      )}
      <MenuListItem disablePadding>
        <MenuListItemButton onClick={onStatusChange}>
          <Text variant="regularText">{statusLabel}</Text>
        </MenuListItemButton>
      </MenuListItem>
    </>
  );
};

export default AdditionalTooltipItems;
