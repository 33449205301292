import { useState, type FC, useCallback } from "react";
import { ContentContainerStyled } from "./styles";
import { PageContainerStyled } from "../styles";
import UserTitle from "pages/UserProfile/UserTitle";
import UserProfileLanguage from "pages/UserProfile/UserProfileLanguage";
import UserSubscriptionOptions from "pages/UserProfile/UserSubscriptionOptions";
import UserInfo from "pages/UserProfile/UserInfo";
import { useProfileInfo } from "hooks/useProfileInfo";
import EmploymentInfo from "./EmploymentInfo";
import TariffPlans from "./TariffPlans";
import ClockifyIntegration from "./ClockifyIntegration";
import CancelSubscribeModal from "pages/UserProfile/UserTariffPlans/CancelSubscribeModal";

const Settings: FC = () => {
  const { formState, onSubmit, isLoading } = useProfileInfo();
  const [isOpenSubscribe, setIsOpenSubscribe] = useState<boolean>(false);
  const onClose = useCallback(() => setIsOpenSubscribe((prev) => !prev), []);

  return (
    <PageContainerStyled>
      <ContentContainerStyled>
        <UserTitle />
        <UserInfo
          formState={formState}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
        <EmploymentInfo />
        <UserProfileLanguage />
        <ClockifyIntegration />
        <UserSubscriptionOptions />
        <TariffPlans />
      </ContentContainerStyled>
      <CancelSubscribeModal onClose={onClose} open={isOpenSubscribe} />
    </PageContainerStyled>
  );
};

export default Settings;
