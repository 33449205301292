import { type Control, type FieldErrors, useForm } from "react-hook-form";
import { useCallback } from "react";
import { useFreelancerDefaults } from "./useFreelancerDefaults";
import {
  type AddTransactionForm,
  OPERATION_TYPE,
} from "../interfaces/transaction";
import { useTranslation } from "react-i18next";
import { getTransactionOptions } from "../utils/freelancer/helpers/getTransactionOptions";
import { useCreateUserExpenseMutation } from "../store/userExpense/userExpenseServices";
import { getNumber } from "../utils/freelancer/helpers/getNumber";
import {
  USER_EXPENSE_DETAILS,
  USER_EXPENSE_TYPE,
} from "../store/userExpense/userExpenseTypes";
import { useGetTransactionsQuery } from "../store/transactions/transactionsServices";
import { ALERT_SEVERITY } from "../utils/freelancer/constants";

interface AddTransactionFormReturn {
  control: Control<AddTransactionForm>;
  errors: FieldErrors<AddTransactionForm>;
  isValid: boolean;
  handleSave: () => void;
  currency: string;
}

export const useAddTransactionForm = (
  setAlertSeverity: (severity: ALERT_SEVERITY) => void,
  setAlertMessage: (message: string) => void,
  openAlert: () => void
): AddTransactionFormReturn => {
  const { currency } = useFreelancerDefaults();
  const { t } = useTranslation();
  const { typeOptions, operationTypeOptions, groupOptions } =
    getTransactionOptions(t);
  const [createUserExpense] = useCreateUserExpenseMutation();
  const { refetch } = useGetTransactionsQuery(null);
  const {
    control,
    formState: { errors, isValid },
    getValues,
  } = useForm<AddTransactionForm>({
    mode: "all",
    defaultValues: {
      operationType: operationTypeOptions[0].value as OPERATION_TYPE,
      type: typeOptions[0].value as USER_EXPENSE_TYPE,
      group: groupOptions[0].value as USER_EXPENSE_DETAILS,
      amount: 100,
    },
  });

  const handleSave = useCallback(async () => {
    const { amount, type, operationType, group } = getValues();
    if (operationType === OPERATION_TYPE.OUTCOME) {
      try {
        await createUserExpense({
          amount: getNumber(amount),
          type,
          details: group,
        });
        refetch();
        setAlertSeverity(ALERT_SEVERITY.SUCCESS);
        setAlertMessage(t("expenseAddedSuccess"));
      } catch (e) {
        setAlertMessage(`${e}`);
        console.error("Error on create user expense, ", e);
      } finally {
        openAlert();
      }
    }
  }, [getValues]);

  return { isValid, control, errors, handleSave, currency };
};
