import { type FC, useEffect } from "react";
import AuthBackground from "../../AuthBackgroud";
import FirstLoginImage from "assets/images/first-login-image.png";
import { Container } from "pages/Login/styles";
import { useNavigate } from "react-router";
import { useAuth } from "hooks/useAuth";
import { useUpdateUserMutation } from "store/auth/authService";
import { type SubmitHandler, useForm, Controller } from "react-hook-form";
import {
  FreelancerUserProfile,
  type IUserProfile,
} from "interfaces/user-profile";
import {
  INFO_INPUTS_VALUES,
  USER_PROFILE_INPUTS,
} from "utils/constants/user-profile";
import { useTranslation } from "react-i18next";
import { Section } from "pages/UserProfile/styles";
import { Title } from "pages/UserProfile/UserInfo/styles";
import {
  FormContainerStyled,
  ContainerStyled,
  CheckboxContainerStyled,
  PolicyTextContainerStyled,
  PolicyLink,
} from "./styles";
import { InputsRules } from "utils/constants/auth";
import SelectCountries from "components/UI/SelectCountries";
import {
  Button,
  InputStyled,
  InputWrap,
  Container as FormContainer,
} from "pages/UserProfile/UserInfoInputs/styles";
import { useScreenSize } from "hooks/useScreenSize";
import Loading from "components/UI/Loading";
import { Checkbox, Typography } from "@mui/material";
import { palette } from "styles/theme/palette";
import { freelanceUserRoutesPath } from "routes/routePath";

const FirstLogin: FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const formState = useForm<FreelancerUserProfile>({
    defaultValues: INFO_INPUTS_VALUES,
    mode: "all",
  });

  const onSubmit: SubmitHandler<FreelancerUserProfile> = async (values) => {
    try {
      const { firstname, lastname, phone_number, residence } = values;

      await updateUser({
        body: {
          firstname,
          lastname,
          phone_number,
          residence,
          settings: {
            language: user?.settings?.language || "",
            clockifyApiKey: user?.settings?.clockifyApiKey || "",
          },
        },
      });

      navigate("/");
    } catch (err) {
      formState.setError("firstname", {
        type: "custom",
        message: "Помилка сервера",
      });
    }
  };

  useEffect(() => {
    if (user && user?.user_id) {
      const entries = Object.entries(user);

      entries.forEach(([name, value]) =>
        formState.setValue(name as keyof IUserProfile, value)
      );
    }
  }, [user]);

  useEffect(() => {
    if ((user && !user?.firstname) || !user?.lastname) {
      formState.trigger();
    }
  }, [user]);

  return (
    <Container>
      <ContainerStyled>
        <Section isSpaceBottom={30} id="info">
          <Title variant="h2">{t("freelancer.page.firstLogin.title")}</Title>
          <FormContainerStyled>
            {isLoading ? (
              <FormContainer>
                <Loading />
              </FormContainer>
            ) : (
              <FormContainer>
                {USER_PROFILE_INPUTS.map((input) => (
                  <InputWrap fullWidth>
                    <Controller
                      key={input.name}
                      name={input.name as keyof IUserProfile}
                      control={formState.control}
                      rules={{
                        required: t(input.rules.required.message),
                      }}
                      render={({ field }) => (
                        <InputStyled
                          disabled={input.name === "email"}
                          error={
                            !!formState.formState.errors?.[
                              input.name as keyof IUserProfile
                            ]?.message
                          }
                          helperText={
                            formState.formState.errors?.[
                              input.name as keyof IUserProfile
                            ]?.message
                          }
                          placeholder={t(`${input.placeholder}`)}
                          label={t(
                            `utils.constants.user-profile.${input.label}`
                          )}
                          type={input.type}
                          {...field}
                        />
                      )}
                    />
                  </InputWrap>
                ))}

                <InputWrap fullWidth>
                  <Controller
                    rules={{ required: InputsRules.required }}
                    control={formState.control}
                    name="residence"
                    render={({ field }) => (
                      <SelectCountries
                        errorMessage={
                          formState.formState.errors?.residence?.message
                        }
                        error={!!formState.formState.errors?.residence?.message}
                        label={t(
                          "pages.UserProfile.UserInfoInputs.Країна вашого резидентства"
                        )}
                        {...field}
                      />
                    )}
                  />
                </InputWrap>
                <InputWrap fullWidth>
                  <CheckboxContainerStyled>
                    <Controller
                      rules={{ required: InputsRules.required }}
                      control={formState.control}
                      name="acceptPolicy"
                      render={({ field: CheckboxField }) => (
                        <Checkbox
                          checked={CheckboxField.value}
                          {...CheckboxField}
                        />
                      )}
                    />
                    <PolicyTextContainerStyled>
                      <Typography color={palette.custom.grey.main}>
                        {t("acceptThe")}
                      </Typography>
                      <PolicyLink to={freelanceUserRoutesPath.PUBLIC_OFFER}>
                        {t("publicOffer")}
                      </PolicyLink>
                      <Typography color={palette.custom.grey.main}>
                        {t("and")}
                      </Typography>
                      <PolicyLink to={freelanceUserRoutesPath.PRIVACY_POLICY}>
                        {t("privacyPolicy")}
                      </PolicyLink>
                    </PolicyTextContainerStyled>
                  </CheckboxContainerStyled>
                </InputWrap>

                <Button
                  onClick={formState.handleSubmit(onSubmit)}
                  disabled={!formState.formState.isValid}
                  variant="contained"
                  fullWidth={isMobile}
                >
                  {t("freelancer.page.firstLogin.buttonLabel")}
                </Button>
              </FormContainer>
            )}
          </FormContainerStyled>
        </Section>
      </ContainerStyled>
      <AuthBackground image={FirstLoginImage} />
    </Container>
  );
};

export default FirstLogin;
