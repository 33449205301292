import { BaseQueryParams } from "store/baseQuery";
import {
  type TimeLog,
  type TimeLogCreate,
  TimeLogUpdate,
  type ClockifyProjectWorkspace,
} from "./timeLogTypes";

const getTimeLogUrl = (
  project_id?: string,
  phase_id?: string,
  employee_id?: string
) => {
  let baseUrl = "/timelog";
  switch (true) {
    case !!project_id?.length && !!phase_id?.length && !!employee_id?.length:
      return `${baseUrl}?project_id=${project_id}&phase_id=${phase_id}&employee_id=${employee_id}`;
    case !!phase_id?.length && !!employee_id?.length:
      return `${baseUrl}?project_id=${project_id}&phase_id=${phase_id}&employee_id=${employee_id}`;
    case !!project_id?.length && !!employee_id?.length:
      return `${baseUrl}?project_id=${project_id}&employee_id=${employee_id}`;
    case !!project_id?.length && !!phase_id?.length:
      return `${baseUrl}?project_id=${project_id}&phase_id=${phase_id}`;
    case !!project_id?.length:
      return `${baseUrl}?project_id=${project_id}`;
    case !!phase_id?.length:
      return `${baseUrl}?phase_id=${phase_id}`;
    case !!employee_id?.length:
      return `${baseUrl}?employee_id=${employee_id}`;
    default:
      return baseUrl;
  }
};

export const timeLogServices = BaseQueryParams("timeLog", [
  "TIME_LOGS",
  "CLOCKIFY_PROJECTS_WORKSPACES",
  "CLOCKIFY_USERS",
]).injectEndpoints({
  endpoints: (builder) => ({
    getTimeLog: builder.query<
      TimeLog[],
      { project_id?: string; phase_id?: string; employee_id?: string }
    >({
      query: ({ project_id, phase_id, employee_id }) => ({
        url: getTimeLogUrl(project_id, phase_id, employee_id),
        method: "GET",
      }),

      providesTags: ["TIME_LOGS"],
    }),
    getTimeLogByProject: builder.query<TimeLog[], { project_id?: string }>({
      query: ({ project_id }) => ({
        url: getTimeLogUrl(project_id),
        method: "GET",
      }),

      providesTags: ["TIME_LOGS"],
    }),
    getTimeLogByPhaseAndProject: builder.query<
      TimeLog[],
      { project_id: string; phase_id: string }
    >({
      query: ({ project_id, phase_id }) => ({
        url: `/timelog?project_id=${project_id}&phase_id=${phase_id}`,
        method: "GET",
      }),

      providesTags: ["TIME_LOGS"],
    }),
    addTimeLog: builder.mutation<unknown, TimeLogCreate>({
      query: (body) => ({
        url: "/timelog",
        method: "POST",
        body,
      }),

      invalidatesTags: ["TIME_LOGS"],
    }),
    updateTimeLog: builder.mutation<
      unknown,
      { body: TimeLogUpdate; id: string }
    >({
      query: ({ body, id }) => ({
        url: `/timelog/${id}`,
        method: "PATCH",
        body,
      }),

      invalidatesTags: ["TIME_LOGS"],
    }),
    deleteTimeLog: builder.mutation<unknown, string>({
      query: (id) => ({
        url: `/timelog/${id}`,
        method: "DELETE",
      }),

      invalidatesTags: ["TIME_LOGS"],
    }),
    getClockifyProjectsWorkspaces: builder.query<
      ClockifyProjectWorkspace[],
      {}
    >({
      query: () => ({
        url: "/clockify/projects",
        method: "GET",
      }),

      providesTags: ["CLOCKIFY_PROJECTS_WORKSPACES"],
    }),
    getClockifyEmployees: builder.query<
      [],
      { workspace: string; project?: string }
    >({
      query: ({ workspace, project }) => ({
        url: `/clockify/users?workspace=${workspace}${
          project ? `&project=${project}` : ""
        }`,
        method: "GET",
      }),

      providesTags: ["CLOCKIFY_USERS"],
    }),
  }),
});

export const {
  useAddTimeLogMutation,
  useDeleteTimeLogMutation,
  useUpdateTimeLogMutation,
  useGetTimeLogQuery,
  useGetTimeLogByProjectQuery,
  useGetTimeLogByPhaseAndProjectQuery,
  useGetClockifyProjectsWorkspacesQuery,
  useLazyGetClockifyEmployeesQuery,
} = timeLogServices;
