import { type FC } from "react";
import { TableCell } from "@mui/material";
import {
  ContainerStyled,
  TableCellStyled,
} from "../../Projects/ProjectsTable/styles";
import EditDeleteTooltip from "components/Freelancer/EditDeleteTooltip";
import AdditionalTooltipItems from "./AdditionalTooltipItems";
import type { TableInvoice } from "interfaces/freelancer/invoice";
import ConfirmationModal from "../../../ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useDrawer } from "hooks/useDrawer";
import Select from "components/UI/Select";
import { getClassNameByState } from "utils/freelancer/helpers/getClassNameByState";
import { getStateData } from "../data";
import { useInvoiceDelete } from "hooks/freelancer/useInvoiceDelete";
import { useInvoiceChangeState } from "hooks/freelancer/useInvoiceChangeState";
import InvoiceDueDateModal from "../../InvoiceDueDateModal";
import { useTrialUser } from "hooks/useTrialUser";
import LimitedAccessTooltip from "components/Freelancer/LimitedAccessTooltip";

interface InvoiceTableRowProps {
  invoice: TableInvoice;
  isActive: boolean;
}

const InvoiceTableRow: FC<InvoiceTableRowProps> = ({ invoice, isActive }) => {
  const { t } = useTranslation();
  const { limitedAccess } = useTrialUser();
  const { isOpen, open, close } = useDrawer();
  const {
    isOpen: isDueDateModalOpen,
    open: openDueDateModal,
    close: closeDueDateModal,
  } = useDrawer();
  const handleDeleteInvoice = useInvoiceDelete(invoice.id);
  const { handleChangeState } = useInvoiceChangeState(
    invoice.id,
    invoice.state
  );
  const stateData = getStateData(t, isActive);
  const classNameByState = getClassNameByState(invoice.state);

  return (
    <>
      <ContainerStyled withBorder>
        <TableCell width={140} component="th" scope="row">
          {invoice.name}
        </TableCell>
        <TableCellStyled className={classNameByState}>
          {limitedAccess ? (
            <LimitedAccessTooltip>
              <Select
                disabled
                data={stateData}
                defaultValue={invoice.state}
                onChange={handleChangeState}
              />
            </LimitedAccessTooltip>
          ) : (
            <Select
              disabled={!isActive}
              data={stateData}
              defaultValue={invoice.state}
              onChange={handleChangeState}
            />
          )}
        </TableCellStyled>
        <TableCell>{invoice.projectName}</TableCell>
        <TableCell>{invoice.clientName}</TableCell>
        <TableCell>{invoice.startDateFormatted}</TableCell>
        <TableCell>{invoice.dueDateFormatted}</TableCell>
        <TableCellStyled>
          {`${invoice.currency}${invoice.invoiced}`}
        </TableCellStyled>
        <EditDeleteTooltip
          onDelete={open}
          showEdit={false}
          showDelete={false}
          additionalItems={
            <AdditionalTooltipItems
              isActive={isActive}
              invoice={invoice}
              onInvoiceDueDateChange={openDueDateModal}
            />
          }
        />
      </ContainerStyled>
      <ConfirmationModal
        deleteHandler={handleDeleteInvoice}
        onClose={close}
        isOpen={isOpen}
        title={t("deleteConfirmation")}
      />
      <InvoiceDueDateModal
        onClose={closeDueDateModal}
        isOpen={isDueDateModalOpen}
        invoice={invoice}
      />
    </>
  );
};

export default InvoiceTableRow;
