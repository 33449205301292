import { BaseQueryParams } from "store/baseQuery";
import {
  type ProductGroup,
  type ProductGroupCreate,
  type ProductGroupUpdate,
} from "./productGroupTypes";
import { type IProductCreateRequest, type Product } from "interfaces/product";
import {
  type ProductExpenseUpdate,
  type ProjectCreateResponse,
  type ProjectUpdate,
} from "interfaces/freelancer/project";
import { ClockifyEmployeesTimeEntries } from "store/productGroup/productGroupTypes";
export const productGroupServices = BaseQueryParams("productGroup", [
  "PRODUCT_GROUPS",
  "PRODUCT_GROUP",
  "PRODUCT_GROUP_TIME_ENTRIES",
  "PRODUCT",
  "PRODUCTS",
]).injectEndpoints({
  endpoints: (builder) => ({
    getProductGroups: builder.query<ProductGroup[], null>({
      query: () => ({
        url: "/product-groups",
        method: "GET",
      }),

      providesTags: () => ["PRODUCT_GROUPS", "PRODUCTS"],
    }),
    getProductGroupById: builder.query<ProductGroup, { id: string }>({
      query: ({ id }) => ({
        url: `/product-groups/${id}`,
        method: "GET",
      }),

      providesTags: () => ["PRODUCT_GROUP"],
    }),
    createProductGroups: builder.mutation<unknown, ProductGroupCreate>({
      query: ({ body }) => ({
        url: "/product-groups",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["PRODUCT_GROUPS"],
    }),
    deleteProductGroup: builder.mutation<unknown, { id: string }>({
      query: ({ id }) => ({
        url: `/product-groups/${id}`,
        method: "DELETE",
      }),

      invalidatesTags: () => ["PRODUCT_GROUPS", "PRODUCTS"],
    }),
    getProductGroupTimeEntries: builder.query<
      ClockifyEmployeesTimeEntries,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/clockify/product/${id}/time-entries`,
        method: "GET",
      }),

      providesTags: ["PRODUCT_GROUP_TIME_ENTRIES"],
    }),

    updateProductGroup: builder.mutation<
      unknown,
      { id: string; body: ProductGroupUpdate }
    >({
      query: ({ id, body }) => ({
        url: `/product-groups/${id}`,
        method: "PATCH",
        body,
      }),

      invalidatesTags: () => ["PRODUCT_GROUPS"],
    }),
    createProduct: builder.mutation<
      ProjectCreateResponse[],
      IProductCreateRequest
    >({
      query: ({ body, project_id }) => ({
        url: `/projects/${project_id}/pipelines/products`,
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["PRODUCT_GROUPS", "PRODUCTS"],
    }),
    updateProduct: builder.mutation<unknown, { products: ProjectUpdate[] }>({
      query: ({ products }) => ({
        url: "/products",
        method: "PATCH",
        body: products,
      }),

      invalidatesTags: () => ["PRODUCT_GROUPS", "PRODUCTS"],
    }),
    updateProductById: builder.mutation<
      unknown,
      { product: ProjectUpdate; id: string }
    >({
      query: ({ product, id }) => ({
        url: `/products/${id}`,
        method: "PATCH",
        body: product,
      }),

      invalidatesTags: () => ["PRODUCT_GROUPS", "PRODUCTS"],
    }),
    getAllProducts: builder.query<
      Product[],
      { project_id: string; pipeline_id: string }
    >({
      query: ({ project_id, pipeline_id }) => ({
        url: `/projects/${project_id}/pipelines/${pipeline_id}/products`,
        method: "GET",
      }),
      providesTags: () => ["PRODUCTS"],
    }),
    getProductById: builder.query<Product, { id: string }>({
      query: ({ id }) => ({
        url: `/products/${id}`,
        method: "GET",
      }),
      providesTags: () => ["PRODUCT"],
    }),
    deleteProduct: builder.mutation<unknown, string>({
      query: (id) => ({
        url: `/products/${id}`,
        method: "DELETE",
      }),

      invalidatesTags: () => ["PRODUCTS"],
    }),
    updateProductExpenseById: builder.mutation<
      unknown,
      { id: string; expense: ProductExpenseUpdate }
    >({
      query: ({ id, expense }) => ({
        url: `/expenses/${id}`,
        method: "PATCH",
        body: expense,
      }),

      invalidatesTags: () => ["PRODUCTS", "PRODUCT_GROUPS"],
    }),
    createProductExpense: builder.mutation<
      unknown,
      [{ product_id: string; expenses: ProductExpenseUpdate[] }]
    >({
      query: (body) => ({
        url: "/products/expenses",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["PRODUCTS"],
    }),
  }),
});

export const {
  useGetProductGroupsQuery,
  useUpdateProductGroupMutation,
  useUpdateProductMutation,
  useDeleteProductGroupMutation,
  useGetProductGroupTimeEntriesQuery,
  useLazyGetProductGroupTimeEntriesQuery,
  useCreateProductMutation,
  useGetAllProductsQuery,
  useDeleteProductMutation,
  useGetProductGroupByIdQuery,
  useGetProductByIdQuery,
  useUpdateProductByIdMutation,
  useUpdateProductExpenseByIdMutation,
  useCreateProductExpenseMutation,
} = productGroupServices;
