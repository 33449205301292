import React, { useState } from "react";
import { Outlet } from "react-router-dom";
// Components
import NavBars from "components/NavBars";
import SideBar from "components/SideBar";
// Styles
import { Container, Wrapper, DrawerHeader } from "./styles";

const MainLayout: React.FC = () => {
  const [open, setOpen] = useState(true);

  const handleDrawer = () => setOpen((prev) => !prev);

  return (
    <Container>
      <NavBars handleDrawer={handleDrawer} isOpen={open} />

      <SideBar handleDrawer={handleDrawer} isOpen={open} />

      <Wrapper>
        <DrawerHeader />

        <Outlet />
      </Wrapper>
    </Container>
  );
};

export default MainLayout;
