import { type FC, useCallback, useRef } from "react";
import { type InvoiceCreateForm } from "interfaces/freelancer/invoice";
import { Box, Button, Typography } from "@mui/material";
import { FooterStyled } from "../../../Projects/AddEdit/styles";
import { useTranslation } from "react-i18next";
import {
  ColumnStyled,
  CredentialsContainerStyled,
  CredentialsRowStyled,
  DatesContainerStyled,
  DateStyled,
  DocumentContainerStyled,
  MainDocumentInfoStyled,
  PreviewContainerStyled,
  PreviewHeaderStyled,
  TotalContainerStyled,
  LabelsContainerStyled,
  AdditionalInfoContainerStyled,
} from "./styles";
import { H1 } from "pages/HelpPage/styles";
import {
  getBilledToData,
  getFromData,
  getInvoicePreviewExpensesTableData,
  getInvoicePreviewTableData,
} from "utils/freelancer/helpers/invoiceHelpers";
import { LabelStyled } from "components/Freelancer/Projects/AddEdit/Expenses/styles";
import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";
import dayjs from "dayjs";
import {
  calculationLabels,
  expensesTableHeadCells,
  servicesAndEmployeesTableHeadCells,
} from "./data";
import Table from "./Table";
import { freelanceUserRoutesPath } from "routes/routePath";
import { useCreateInvoiceMutation } from "store/invoices/invoicesServices";
import { getMappedInvoiceValues } from "utils/freelancer/helpers/getMappedInvoiceValues";
import { useNavigate } from "react-router";
import { getNumber } from "utils/freelancer/helpers/getNumber";
import { jsPDF } from "jspdf";
import { useScreenSize } from "hooks/useScreenSize";
import "assets/fonts/Roboto-Regular-normal";

interface PreviewProps {
  values: InvoiceCreateForm;
  onCancel: () => void;
  from: string;
  projectId?: string;
}

const Preview: FC<PreviewProps> = ({ values, onCancel, from, projectId }) => {
  const { t } = useTranslation();
  const { currency } = useFreelancerDefaults();
  const navigate = useNavigate();
  const billedToData = getBilledToData(values).filter(({ value }) => !!value);
  const fromData = getFromData(values).filter(({ value }) => !!value);
  const { contractNumber, name } = values;
  const { isMobile } = useScreenSize();
  const servicesAndEmployeesTableBodyRows = getInvoicePreviewTableData(
    values,
    currency,
    t
  );
  const expensesTableBodyRows = getInvoicePreviewExpensesTableData(
    values,
    currency,
    t
  );
  const issueDate = dayjs(values.invoiceDate).format("DD/MM/YYYY");
  const dueDate = dayjs(values.dueDate).format("DD/MM/YYYY");
  const documentComponentRef = useRef(null);

  const getPdfContent = (html: String) => {
    const pdfId = "PipelinerPDF";
    const pdfStyles = `
      #${pdfId} * {
        letter-spacing: 0.1px !important;
        font-family: Roboto-Regular !important;
        font-weight: normal !important;
      }
    `;

    return `<div id="${pdfId}">
      <style>${pdfStyles}</style>
      ${html}
    </div>`;
  };
  const handlePrint = useCallback(async () => {
    if (documentComponentRef?.current && window) {
      const invoiceElement = documentComponentRef.current as HTMLElement;
      const { width: invoiceElementWidth } =
        invoiceElement.getBoundingClientRect();
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
      });
      const pdfContent = getPdfContent(invoiceElement.outerHTML);

      pdf.html(pdfContent, {
        callback(generatedPDFDocument) {
          generatedPDFDocument.save(`${name}.pdf`);
        },
        margin: [10, 0, 10, 0],
        width: 210, // width of A4 paper in mm (we've set the mm unit in jsPDF options)
        windowWidth: invoiceElementWidth,
        x: 0,
        y: 0,
      });
    }
  }, [name]);
  const [create] = useCreateInvoiceMutation();

  const createInvoice = useCallback(async () => {
    const invoicePayload = getMappedInvoiceValues(values, currency, projectId);
    try {
      await create(invoicePayload);
      navigate(freelanceUserRoutesPath.PROJECTS);
    } catch (e) {
      console.error("Error on invoice create, ", e);
    }
  }, [values]);

  const handleClick = useCallback(() => {
    if (from === freelanceUserRoutesPath.INVOICES_ADD) {
      createInvoice();
    }

    handlePrint();
  }, [from]);

  const calculationValues = [
    getNumber(values.subTotal).toFixed(1),
    getNumber(values.discount).toFixed(1),
    getNumber(values.tax).toFixed(1),
    getNumber(values.total).toFixed(1),
  ];
  const buttonLabel =
    from !== freelanceUserRoutesPath.INVOICES_ADD
      ? "freelancer.page.invoices.add.download.buttonLabel"
      : "freelancer.page.invoices.add.buttonLabel";

  const cancelButtonLabel =
    from !== freelanceUserRoutesPath.INVOICES_ADD
      ? "freelancer.page.invoices.add.cancel.buttonLabel"
      : "freelancer.page.invoices.add.edit.buttonLabel";

  return (
    <PreviewContainerStyled>
      <DocumentContainerStyled ref={documentComponentRef}>
        <MainDocumentInfoStyled>
          <PreviewHeaderStyled>
            <H1>{name}</H1>
          </PreviewHeaderStyled>
          <Typography variant="h2">{contractNumber}</Typography>
          <CredentialsContainerStyled>
            <ColumnStyled>
              <Typography variant="regularText" fontWeight={500}>
                {t("freelancer.page.invoices.add.preview.billedTo")}
              </Typography>
              {billedToData.map(({ label, value }) => (
                <CredentialsRowStyled key={value}>
                  <LabelStyled>{t(label)}</LabelStyled>
                  <Typography variant="regularText">{value}</Typography>
                </CredentialsRowStyled>
              ))}
            </ColumnStyled>
            <ColumnStyled>
              <Typography variant="regularText" fontWeight={500}>
                {t("freelancer.page.invoices.add.preview.from")}
              </Typography>
              {fromData.map(({ label, value }) => (
                <CredentialsRowStyled key={value}>
                  <LabelStyled>{t(label)}</LabelStyled>
                  <Typography variant="regularText">{value}</Typography>
                </CredentialsRowStyled>
              ))}
            </ColumnStyled>
          </CredentialsContainerStyled>
          {!!servicesAndEmployeesTableBodyRows?.length && (
            <Table
              tableHeadCells={servicesAndEmployeesTableHeadCells}
              tableBodyRows={servicesAndEmployeesTableBodyRows}
            />
          )}
          {!!expensesTableBodyRows?.length && (
            <Table
              tableHeadCells={expensesTableHeadCells}
              tableBodyRows={expensesTableBodyRows}
            />
          )}
          <AdditionalInfoContainerStyled>
            <Typography variant="regularText">{values.notes}</Typography>
            <TotalContainerStyled>
              <LabelsContainerStyled>
                {calculationLabels.map((label) => (
                  <Typography variant="regularText">{t(label)}</Typography>
                ))}
              </LabelsContainerStyled>
              <Box>
                {calculationValues.map((value) => (
                  <Typography variant="regularText" component="p">
                    {`${currency} ${value}`}
                  </Typography>
                ))}
              </Box>
            </TotalContainerStyled>
          </AdditionalInfoContainerStyled>
        </MainDocumentInfoStyled>
        <DatesContainerStyled>
          <DateStyled>
            <Typography variant="regularText">
              {t("freelancer.page.invoices.add.preview.issueDate")}
            </Typography>
            <Typography variant="regularText">{issueDate}</Typography>
          </DateStyled>
          <DateStyled>
            <Typography variant="regularText">
              {t("freelancer.page.invoices.add.preview.dueDate")}
            </Typography>
            <Typography variant="regularText">{dueDate}</Typography>
          </DateStyled>
        </DatesContainerStyled>
      </DocumentContainerStyled>
      <FooterStyled>
        <Button
          disabled={isMobile}
          onClick={handleClick}
          variant="contained"
          className="toolbar-btns create-btn"
        >
          {t(buttonLabel)}
        </Button>
        <Button
          onClick={onCancel}
          variant="textIcon"
          className="toolbar-btns create-btn"
        >
          {t(cancelButtonLabel)}
        </Button>
      </FooterStyled>
    </PreviewContainerStyled>
  );
};

export default Preview;
