import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const HeaderContainerStyled = styled(Box)(() => ({
  height: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "100vw",
  overflow: "hidden",
}));
