import { ProjectWithPhases } from "interfaces/freelancer/project";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useInvoiceCreateErrorMessage } from "./useInvoiceCreateErrorMessage";
import {
  EXPENSE_STATUS,
  PRODUCT_GROUP_STATUS,
  PROJECT_TYPE,
  URL_PARAMS,
} from "utils/freelancer/constants";
import { getUrlWithParams } from "utils/helpers/getUrlWithParams";
import { freelanceUserRoutesPath } from "routes/routePath";
import { useNavigate } from "react-router-dom";

export const useInvoiceAdd = (
  project: ProjectWithPhases,
  openWarningModal: () => void,
  setInvoiceError: Dispatch<SetStateAction<string>>
): {
  shouldShowInvoiceIcon: boolean;
  handleCreateInvoiceClick: () => void;
} => {
  const navigate = useNavigate();
  const hasPromotedPhases = useMemo(
    () =>
      !!project.phases.filter(
        ({ extras }) => extras.phaseStatus === EXPENSE_STATUS.SUBMITTED
      )?.length,
    [`${project.phases}`]
  );
  const handleInvoiceWithErrors = useCallback((errorMessage: string) => {
    if (errorMessage?.length) {
      setInvoiceError(errorMessage);
      openWarningModal();
    }
  }, []);
  const isActive = project.status === PRODUCT_GROUP_STATUS.ACTIVE;
  const shouldShowInvoiceIcon = isActive && hasPromotedPhases;
  const errorMessage = useInvoiceCreateErrorMessage(project);
  const handleCreateInvoiceClick = useCallback(() => {
    if (errorMessage.length) {
      handleInvoiceWithErrors(errorMessage);
    } else {
      const invoiceUrl = getUrlWithParams(
        freelanceUserRoutesPath.INVOICES_ADD,
        [
          { key: URL_PARAMS.PROJECT_ID, value: project.id },
          { key: URL_PARAMS.TYPE, value: project.type ?? PROJECT_TYPE.FIX },
        ]
      );
      navigate(invoiceUrl);
    }
  }, [errorMessage, project]);

  return { shouldShowInvoiceIcon, handleCreateInvoiceClick };
};
