import { PATTERN_EMAIL, PATTERN_PASSWORD } from 'utils/validations/patterns';
import {
  PATTERN_EMAIL_MESSAGE,
  PATTERN_PASSWORD_MESSAGE,
  REQUIRED_MESSAGE,
} from 'utils/constants/input-errors-message';

export const InputsRules = {
  required: {
    value: true,
    message: REQUIRED_MESSAGE,
  },
  patternPassword: {
    value: PATTERN_PASSWORD,
    message: PATTERN_PASSWORD_MESSAGE,
  },
  patternEmail: {
    value: PATTERN_EMAIL,
    message: PATTERN_EMAIL_MESSAGE,
  },
};

export const LoginInputs = [
  {
    label: 'Пошта',
    name: 'email',
    type: 'text',
    rules: {
      required: InputsRules.required,
      pattern: InputsRules.patternEmail,
    },
  },
  {
    label: 'Пароль',
    name: 'password',
    type: 'password',
    rules: {
      required: InputsRules.required,
      pattern: InputsRules.patternPassword,
    },
  },
];

export enum USER_ROLE {
  ADMIN = 'admin',
  PARTNER = 'partner',
  FREELANCER = 'freelancer'
}

export const defaulLoginValues = {
  email: '',
  password: '',
};

export const defaultForgotPasswordValues = {
  email: '',
};

export const defaultPasswordRecoveryValues = {
  confirmPassword: '',
  password: '',
};

export const defaulRegistrationValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  phoneNumber: '',
  status: '',
  role: USER_ROLE.FREELANCER,
  /** TODO:fix residence country & register button */
  country: 'Україна',
  residence: '',
  newCountry: '',
  confirmPassword: '',
};
