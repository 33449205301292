import { type FC, type SyntheticEvent, useCallback } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import { PlusIcon } from "assets/icons";
import {
  HeaderRightBlock,
  StyledTab,
  StyledTabs,
} from "pages/ProductsAndServices/TableHeaderSection/styles";
import { HeaderRowStyled } from "./styles";
import Tooltip from "components/UI/Tooltip";
import { MAX_PROJECT_PHASE_COUNT } from "utils/constants/add-project";
import { useTrialUser } from "hooks/useTrialUser";
import LimitedAccessTooltip from "components/Freelancer/LimitedAccessTooltip";
import { ButtonDisabledStyled } from "../../styles";

interface TableHeaderSectionProps {
  handleChangeTabMode: (event: SyntheticEvent, newValue: number) => void;
  tabMode: number;
  disabled: boolean;
  phasesNumber: number;
  onAddPhaseClick: () => void;
}

const TableHeaderSection: FC<TableHeaderSectionProps> = ({
  handleChangeTabMode,
  tabMode,
  disabled,
  phasesNumber,
  onAddPhaseClick,
}) => {
  const { t } = useTranslation();
  const { limitedAccess } = useTrialUser();
  const handleButtonClick = useCallback(() => {
    onAddPhaseClick();
  }, [tabMode, onAddPhaseClick]);

  return (
    <HeaderRowStyled className="tabs-row">
      <StyledTabs value={tabMode} onChange={handleChangeTabMode}>
        <StyledTab label={t("estimation")} />
        <StyledTab label={t("workingProcess")} disabled={disabled} />
      </StyledTabs>
      {limitedAccess ? (
        <HeaderRightBlock className="right-block">
          <LimitedAccessTooltip>
            <ButtonDisabledStyled
              disabled
              onClick={handleButtonClick}
              variant="textIcon"
              className="toolbar-btns create-btn"
              startIcon={<PlusIcon />}
            >
              {t("freelancer.page.addEditProject.phase.add")}
            </ButtonDisabledStyled>
          </LimitedAccessTooltip>
        </HeaderRightBlock>
      ) : (
        <HeaderRightBlock className="right-block">
          {phasesNumber >= MAX_PROJECT_PHASE_COUNT ? (
            <Tooltip
              tooltipText={t(
                "freelancer.page.addEditProject.phase.phases-limit-reached"
              )}
            >
              <span>
                <Button
                  disabled={true}
                  onClick={handleButtonClick}
                  variant="textIcon"
                  className="toolbar-btns create-btn"
                  startIcon={<PlusIcon />}
                >
                  {t("freelancer.page.addEditProject.phase.add")}
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
              disabled={tabMode !== 0}
              onClick={handleButtonClick}
              variant="textIcon"
              className="toolbar-btns create-btn"
              startIcon={<PlusIcon />}
            >
              {t("freelancer.page.addEditProject.phase.add")}
            </Button>
          )}
        </HeaderRightBlock>
      )}
    </HeaderRowStyled>
  );
};

export default TableHeaderSection;
