import { FC } from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/UI/Modal";
import { Button, Container, Title } from "components/ConfirmationModal/styles";
import { Typography } from "@mui/material";

interface ModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const RequiredFieldsMissedModal: FC<ModalProps> = ({ onClose, isOpen }) => {
  const { t } = useTranslation();
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Container>
        <Title variant="h1">
          {t("freelancer.page.addEditProject.requiredFieldsMissed.title")}
        </Title>
        <Typography variant="body1">
          {t("freelancer.page.addEditProject.requiredFieldsMissed.text")}
        </Typography>
        <Button
          isDelete={false}
          onClick={onClose}
          variant="contained"
          component="span"
        >
          {t("ok")}
        </Button>
      </Container>
    </Modal>
  );
};

export default RequiredFieldsMissedModal;
