import { type FC, type SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledTabs,
  StyledTab,
  HeaderRightBlock,
} from "pages/ProductsAndServices/TableHeaderSection/styles";
import { HeaderRowStyled } from "./styles";
import Buttons from "../Buttons";
import { EMPLOYEE_TYPE } from "utils/freelancer/constants";

interface TableHeaderSectionProps {
  handleChangeTabMode: (event: SyntheticEvent, newValue: number) => void;
  tabMode: number;
  handleButtonClick: (type: EMPLOYEE_TYPE) => void;
}

const TableHeaderSection: FC<TableHeaderSectionProps> = ({
  handleChangeTabMode,
  tabMode,
  handleButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <HeaderRowStyled>
      <StyledTabs value={tabMode} onChange={handleChangeTabMode}>
        <StyledTab label={t("customers")} />
        <StyledTab label={t("employees")} />
      </StyledTabs>
      <HeaderRightBlock className="right-block">
        <Buttons handleClick={handleButtonClick} />
      </HeaderRightBlock>
    </HeaderRowStyled>
  );
};

export default TableHeaderSection;
