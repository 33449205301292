import { type FC, type MouseEvent, useCallback } from "react";
import { TableCell, TableSortLabel } from "@mui/material";
import { StyledTableHeader } from "pages/Teams/TeamTable/styles";
import { useTranslation } from "react-i18next";
import type { Order } from "types/global";
import { headCells } from "./data";
import { useScreenSize } from "hooks/useScreenSize";
import type { ServicesTableData } from "./index";
import { TableRowStyled } from "./styles";

interface ServicesTableHeadProps {
  onRequestSort: (event: MouseEvent, property: keyof ServicesTableData) => void;
  order: Order;
  orderBy: string;
  collapse?: boolean;
}

const ServicesTableHead: FC<ServicesTableHeadProps> = ({
  order,
  orderBy,
  onRequestSort,
  collapse,
}) => {
  const createSortHandler = useCallback(
    (property: keyof ServicesTableData) => (event: MouseEvent) => {
      onRequestSort(event, property);
    },
    [onRequestSort]
  );
  const { isMobile } = useScreenSize();
  const { t } = useTranslation();
  const cellWidth = isMobile ? 200 : 400;

  return (
    <StyledTableHeader className={collapse ? "collapse" : ""}>
      <TableRowStyled withBorder>
        {headCells.map((headCell) => {
          if (!headCell && isMobile) return null;
          if (!headCell) return <TableCell width={400} />;
          return (
            <TableCell
              width={cellWidth}
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {t(headCell.label)}
              </TableSortLabel>
            </TableCell>
          );
        })}
        <TableCell />
      </TableRowStyled>
    </StyledTableHeader>
  );
};

export default ServicesTableHead;
