import { type FC } from "react";
import SideBarFooter from "components/SideBar/SideBarFooter";
import SideBarBody from "./SideBarBody";
import { useLogout } from "hooks/useLogout";
import { useDrawer } from "hooks/useDrawer";
import SideBarHeader from "layouts/MainLayout/SideBar/SideBarHeader/SideBarHeader";
import { DrawerStyled } from "../styles";

const SideBar: FC = () => {
  const { isOpen, toggle } = useDrawer(true);
  const { logoutHandler } = useLogout();

  return (
    <DrawerStyled variant="permanent" open={isOpen}>
      <SideBarHeader expanded={isOpen} setExpanded={toggle} />
      <SideBarBody isOpen={isOpen} />
      <SideBarFooter logoutHandler={logoutHandler} isOpen={isOpen} />
    </DrawerStyled>
  );
};

export default SideBar;
