export const TaxeInputs = [
  {
    value: "fop",
    label: "ФОП",
  },
  {
    value: "tov",
    label: "ТОВ",
  },
  {
    value: "other",
    label: "Ще не реєстрував",
  },
];

export const TaxGroupInputs = [
  {
    value: "1",
    label: "Перша",
  },
  {
    value: "2",
    label: "Друга",
  },
  {
    value: "3",
    label: "Третя",
  },
  {
    value: "4",
    label: "Четверта",
  },
];

export const TaxationSystemInputs = [
  {
    value: "general",
    label: "Загальна",
  },
  {
    value: "simplified",
    label: "Спрощена",
  },
];

export const defaultValues = {
  project_name: "",
  tax_system_type: "",
  tax_type: "",
  tax_group: "",
  month_hour_limit: 0,
  currency: "$",
  residence: "",
};

export const MAX_PROJECT_PHASE_COUNT = 5;
