import { type FC } from "react";
import {
  Controller,
  type SubmitHandler,
  type UseFormReturn,
} from "react-hook-form";
// Global Components
import Input from "components/UI/Input";
// Interface
import { type IRegistration } from "interfaces/auth";
// Utils
import { InputsRules } from "utils/constants/auth";
import { isMatch } from "utils/validations/password-recovery";
// Components
import RegistrationEmailFooterButtons from "./RegistrationEmailFooterButtons";
// Styles
import { SubTitle } from "./styles";
// Translation
import { useTranslation } from "react-i18next";

interface RegistrationEmailStepProps {
  formState: UseFormReturn<IRegistration>;
  onSubmit: SubmitHandler<IRegistration>;
}

const RegistrationEmailStep: FC<RegistrationEmailStepProps> = ({
  onSubmit,
  formState,
}) => {
  const { isValid } = formState.formState;
  const newPassword = formState.watch("password");
  const { t } = useTranslation();

  return (
    <>
      <SubTitle variant="h3">
        {t("Створіть аккаунт для перевірки продуктових гіпотез")}
      </SubTitle>
      <Controller
        name="email"
        control={formState.control}
        rules={{
          required: {
            value: InputsRules.required.value,
            message: t(InputsRules.required.message),
          },
          pattern: {
            value: InputsRules.patternEmail.value,
            message: t(InputsRules.patternEmail.message),
          },
        }}
        render={({ field }) => (
          <Input
            error={!!formState.formState.errors?.email?.message}
            helperText={formState.formState.errors?.email?.message}
            label={t("Пошта")}
            type="text"
            {...field}
          />
        )}
      />
      <Controller
        name="password"
        control={formState.control}
        rules={{
          required: {
            value: InputsRules.required.value,
            message: t(InputsRules.required.message),
          },
          pattern: {
            value: InputsRules.patternPassword.value,
            message: t(InputsRules.patternPassword.message),
          },
        }}
        render={({ field }) => (
          <Input
            error={!!formState.formState.errors?.password?.message}
            helperText={formState.formState.errors?.password?.message}
            label={t("Пароль")}
            type="password"
            {...field}
          />
        )}
      />
      <Controller
        render={({ field }) => (
          <Input
            error={!!formState.formState.errors?.confirmPassword?.message}
            helperText={formState.formState.errors?.confirmPassword?.message}
            isSpaceBottom={10}
            label={t("Повторіть пароль")}
            type="password"
            {...field}
          />
        )}
        control={formState.control}
        name="confirmPassword"
        rules={{
          required: {
            value: InputsRules.required.value,
            message: t(InputsRules.required.message),
          },
          pattern: {
            value: InputsRules.patternPassword.value,
            message: t(InputsRules.patternPassword.message),
          },
          validate: (value) => {
            const result = isMatch(value, newPassword);
            if (typeof result === "string") {
              return t(result);
            } else {
              return result;
            }
          },
        }}
      />

      <RegistrationEmailFooterButtons
        isValid={isValid}
        formState={formState}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default RegistrationEmailStep;
