import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { Alert, Snackbar } from "@mui/material";

import { Section } from "pages/UserProfile/styles";
import { Label, Container, StyledButton, Title } from "./styles";
import Input from "components/UI/Input";

import { DEFAULT_ALERT_HIDE_DURATION } from "utils/freelancer/constants";

import { useClockifyIntegrationForm } from "hooks/useClockifyIntegrationForm";
import { useScreenSize } from "hooks/useScreenSize";

const ClockifyIntegration: React.FC = () => {
  const { t } = useTranslation();
  const {
    errors,
    control,
    handleSave,
    isValid,
    isLoading,
    watch,
    validateApiKey,
    isAlertOpen,
    handleAlertClose,
    alertSeverity,
    alertMessage,
  } = useClockifyIntegrationForm();
  const apiKeyWatched = watch("apiKey");
  const { isMobile } = useScreenSize();

  const isClockifyApiKeyUpdateButtonDisabled =
    !isValid || isLoading || !apiKeyWatched.length;

  return (
    <Section isSpaceBottom={30} id="clockify">
      <Title variant="h2">
        {t("pages.UserProfile.ClockifyIntegration.clockifyApiKey")}
      </Title>
      <Label
        dangerouslySetInnerHTML={{
          __html: t("pages.UserProfile.ClockifyIntegration.whereToFindApiKey"),
        }}
      />
      <Container isMobile={isMobile}>
        <StyledButton
          disabled={isClockifyApiKeyUpdateButtonDisabled}
          variant="contained"
          fullWidth={isMobile}
          onClick={handleSave}
        >
          {t("pages.UserProfile.ClockifyIntegration.Update")}
        </StyledButton>
        <Controller
          rules={{
            required: {
              value: true,
              message: t("fieldIsRequired"),
            },
            validate: validateApiKey,
          }}
          control={control}
          name="apiKey"
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors?.apiKey?.message}
              placeholder={t(
                "pages.UserProfile.ClockifyIntegration.clockifyApiKey"
              )}
              helperText={errors?.apiKey?.message}
              disabled={isLoading}
            />
          )}
        />
        <Snackbar
          open={isAlertOpen}
          autoHideDuration={DEFAULT_ALERT_HIDE_DURATION}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={handleAlertClose}
        >
          <Alert severity={alertSeverity}>{alertMessage}</Alert>
        </Snackbar>
      </Container>
    </Section>
  );
};

export default ClockifyIntegration;
