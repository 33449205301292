import { type FC } from "react";
import { Table, TableContainer } from "@mui/material";

import PendingInvoicesTableBody from "./PendingInvoicesTableBody";
import { useTranslation } from "react-i18next";
import { NoTableDataTextStyled } from "../../../styles";
import { type TableInvoice } from "interfaces/freelancer/invoice";

interface PendingInvoicesTableProps {
  invoices: TableInvoice[] | [];
}

const PendingInvoicesTable: FC<PendingInvoicesTableProps> = ({ invoices }) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table aria-labelledby="tableTitle">
        {!!invoices?.length && <PendingInvoicesTableBody invoices={invoices} />}
      </Table>
      {!invoices?.length && (
        <NoTableDataTextStyled variant="h2">
          {t("freelancer.page.dashboard.invoices.noData")}
        </NoTableDataTextStyled>
      )}
    </TableContainer>
  );
};

export default PendingInvoicesTable;
