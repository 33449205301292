import { styled } from "@mui/material/styles";
import { TableContainer, type Breakpoint, TableRow } from "@mui/material";
import { palette } from "styles/theme/palette";

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  [theme.breakpoints.down("md" as Breakpoint)]: {
    "th, td": {
      fontSize: "12px",
    },
  },
}));

export const TableRowStyled = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "withBorder",
})<{ withBorder?: boolean }>(({ withBorder = false }) => ({
  cursor: "pointer",
  "& > *": {
    borderBottom: withBorder ? `1px solid ${palette.primary.main}` : "unset",
  },
}));
