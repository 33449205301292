import { type Employee } from "store/employees/employeesTypes";
import { type UseFormSetValue } from "react-hook-form/dist/types/form";
import { type InvoiceCreateForm } from "interfaces/freelancer/invoice";
import { IUser } from "store/auth/authTypes";

export const getTableData = (employees?: Employee[]) => [
  {
    name: "",
    rows: employees ?? [],
  },
];

export const setClientDefaults = (
  setValue: UseFormSetValue<InvoiceCreateForm>,
  client?: Employee
) => {
  if (client) {
    const { edrpo, address, email, name, payment_info } = client;
    if (name?.length) setValue("credentials.employment.client.name", name);
    if (email?.length) setValue("credentials.employment.client.email", email);
    if (address?.length)
      setValue("credentials.employment.client.address", address);
    if (edrpo?.length) setValue("credentials.employment.client.edrpo", edrpo);
    setValue(
      "credentials.bank.client.beneficiary.account",
      payment_info?.account_number
    );
    setValue(
      "credentials.bank.client.beneficiary.name",
      payment_info?.bank_name
    );
    setValue(
      "credentials.bank.client.beneficiary.address",
      payment_info?.bank_address
    );
    setValue(
      "credentials.bank.client.beneficiary.swift",
      payment_info?.swift_code
    );
    setValue(
      "credentials.bank.client.correspondent.name",
      payment_info?.correspondent_bank
    );
    setValue(
      "credentials.bank.client.correspondent.account",
      payment_info?.correspondent_account_number
    );
    setValue(
      "credentials.bank.client.correspondent.swift",
      payment_info?.correspondent_swift_code
    );
  }
};

export const setUserDefaults = (
  setValue: UseFormSetValue<InvoiceCreateForm>,
  userEmployee?: Employee,
  user?: IUser
) => {
  if (userEmployee && user) {
    const { edrpo, address, email, name, payment_info, legal_name } =
      userEmployee;
    const userName = user.user_id === name ? legal_name : name;
    const userEmail = email?.length ? email : user.email;
    if (userName?.length)
      setValue("credentials.employment.executor.name", userName);
    if (userEmail?.length)
      setValue("credentials.employment.executor.email", userEmail);
    if (address?.length)
      setValue("credentials.employment.executor.address", address);
    if (edrpo?.length) setValue("credentials.employment.executor.edrpo", edrpo);
    setValue(
      "credentials.bank.executor.beneficiary.account",
      payment_info?.account_number
    );
    setValue(
      "credentials.bank.executor.beneficiary.name",
      payment_info?.bank_name
    );
    setValue(
      "credentials.bank.executor.beneficiary.address",
      payment_info?.bank_address
    );
    setValue(
      "credentials.bank.executor.beneficiary.swift",
      payment_info?.swift_code
    );
    setValue(
      "credentials.bank.executor.correspondent.name",
      payment_info?.correspondent_bank
    );
    setValue(
      "credentials.bank.executor.correspondent.account",
      payment_info?.correspondent_account_number
    );
    setValue(
      "credentials.bank.executor.correspondent.swift",
      payment_info?.correspondent_swift_code
    );
  }
};
