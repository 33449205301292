import { useSearchParams } from "react-router-dom";
import {
  INVOICE_ITEM_KIND,
  URL_PARAMS,
} from "../../utils/freelancer/constants";
import { useGetPaidInvoiceItemsByProjectQuery } from "store/invoices/invoicesServices";
import { useCallback } from "react";
import { type InvoicePaidItem } from "store/invoices/invoicesTypes";
import { type InvoiceExpense } from "interfaces/freelancer/invoice";
import { getNumber } from "utils/freelancer/helpers/getNumber";

interface BillableHoursReturn {
  paidExpensesByProject: InvoicePaidItem[];
  getAmount: (serviceField: InvoiceExpense) => {};
}

export const useServiceBillableAmount = (): BillableHoursReturn => {
  const [searchParams] = useSearchParams();
  const projectIdFromUrl = searchParams.get(URL_PARAMS.PROJECT_ID);
  const { data: paidExpenses = [] } = useGetPaidInvoiceItemsByProjectQuery({
    project_id: `${projectIdFromUrl}`,
  });

  const getAmount = useCallback(
    (serviceField): string => {
      const billedAmount = paidExpenses
        .filter(
          ({ id, kind }) =>
            serviceField.itemId === id && kind === INVOICE_ITEM_KIND.SERVICE
        )
        .reduce((partialSum, { total }) => partialSum + total, 0);
      return (getNumber(serviceField.billedPrice) - billedAmount).toFixed(1);
    },
    [paidExpenses]
  );

  return { paidExpensesByProject: paidExpenses, getAmount };
};
