import React, { useState } from "react";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import DatePicker from "react-datepicker";
import { InputAdornment } from "@mui/material";
import { CalendarIcon, CloseFactIcon } from "assets/icons";
import Input from "components/UI/Input";
import { Label, Container as InputContainer } from "components/UI/Input/styles";

// Styles
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  DatePickerWrapper,
  IconWrap,
  InlineFormWrap,
} from "./styles";

import { SourcesForm } from "../SourceModal";

interface IFormRow {
  control: Control<SourcesForm>;
  index: number;
  errors: FieldErrors<SourcesForm>;
  update: any;
  remove: any;
  watched: FieldValues;
}

const FormRow: React.FC<IFormRow> = ({ control, index, watched }) => {
  const [startDate, setStartDate] = useState(watched.sources[index].startDate);
  const [endDate, setEndDate] = useState(watched.sources[index].startDate);

  return (
    <Container>
      <InlineFormWrap key={watched.sources[index].expenseName}>
        <Controller
          render={({ field }) => (
            <Input
              label="Назва витрати"
              disabled
              {...field}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CloseFactIcon />
                  </InputAdornment>
                ),
              }}
              className="inline-input time-input"
              fullWidth
            />
          )}
          control={control}
          name={`sources.${index}.expenseName`}
          rules={{ required: "fieldIsRequired" }}
        />
        <Controller
          render={({ field }) => (
            <Input
              label="Об'єм інвестицій"
              {...field}
              className="inline-input"
              fullWidth
            />
          )}
          control={control}
          name={`sources.${index}.investmentSize`}
          rules={{ required: "fieldIsRequired" }}
        />
      </InlineFormWrap>
      <InlineFormWrap>
        <InputContainer className="inline-input time-input">
          <Label>Дата початку</Label>
          <DatePickerWrapper>
            <DatePicker
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
            />
            <IconWrap>
              <CalendarIcon />
            </IconWrap>
          </DatePickerWrapper>
        </InputContainer>
        <InputContainer className="inline-input">
          <Label>Дата завершення</Label>
          <DatePickerWrapper>
            <DatePicker
              selected={endDate}
              onChange={(date: Date) => setEndDate(date)}
            />
            <IconWrap>
              <CalendarIcon />
            </IconWrap>
          </DatePickerWrapper>
        </InputContainer>
      </InlineFormWrap>
    </Container>
  );
};

export default FormRow;
