import { type ServicesTableData } from "./index";
import { type IProduct } from "interfaces/product";
import { PRODUCT_GROUP } from "utils/freelancer/constants";

interface HeadCell {
  id: keyof ServicesTableData;
  label: string;
  numeric: boolean;
}

export const headCells: (HeadCell | null)[] = [
  {
    id: "name",
    numeric: false,
    label: "service",
  },
  null,
  null,
  null,
  null,
  {
    id: "price",
    numeric: false,
    label: "price",
  },
];

export const mockServices: IProduct[] = [
  {
    name: "Service 1",
    group: PRODUCT_GROUP.SERVICE,
    amount: 1000,
    income: 100,
    loss: 100,
    cost: 100,
    created_at: "",
    id: "1",
    pipeline_id: "",
    price: 1000,
    updated_at: null,
    expenses: {},
    expenseGroups: {},
  },
  {
    name: "Service 2",
    group: PRODUCT_GROUP.SERVICE,
    amount: 1000,
    income: 100,
    loss: 100,
    cost: 100,
    created_at: "",
    id: "1",
    pipeline_id: "",
    price: 1200,
    updated_at: null,
    expenses: {},
    expenseGroups: {},
  },
];
