import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { palette } from "styles/theme/palette";

export const TooltipStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "16px 20px",
  background: palette.custom.primary.white,
  color: palette.primary.dark,
  borderRadius: "10px",
  minWidth: "273px",
  border: `1px solid ${palette.primary.main}`,
}));

export const TooltipRowStyled = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "12px",
}));
