import { INVOICE_STATE } from "../constants";

export const getClassNameByState = (state: INVOICE_STATE): string => {
  switch (state) {
    case INVOICE_STATE.PAID:
      return "paid";
    case INVOICE_STATE.EXPIRED:
      return "expired";
    case INVOICE_STATE.CANCELED:
      return "canceled";
    case INVOICE_STATE.CREATED:
    default:
      return "created";
  }
};
