import { type FC, type SyntheticEvent, useState } from "react";
import Loading from "components/UI/Loading";
import TabPanel from "components/UI/TabPanel";
import TableHeaderSection from "./TableHeaderSection";
import { TabContainer } from "pages/ProductsAndServices/styles";
import Footer from "components/Footer";
import { useTranslation } from "react-i18next";
import { TableContainerStyled } from "./styles";
import ProjectsTable from "./ProjectsTable";
import { useScreenSize } from "hooks/useScreenSize";
import { useProjectsWithPhases } from "hooks/freelancer/useProjectsWithPhases";
import { useAllInvoices } from "hooks/freelancer/useAllInvoices";
import { Typography, Box } from "@mui/material";
import { PageContainerStyled, TitleContainer } from "../styles";

const Projects: FC = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { t } = useTranslation();
  const { isDesktop } = useScreenSize();

  const handleChangeTabMode = (e: SyntheticEvent, newValue: number) =>
    setTabIndex(newValue);
  const { activeInvoices } = useAllInvoices();
  const { isLoading, activeProjectsWithPhases, archiveProjectsWithPhases } =
    useProjectsWithPhases(activeInvoices);

  const tabContent = [
    {
      id: 1,
      data: activeProjectsWithPhases ?? [],
    },
    {
      id: 2,
      data: archiveProjectsWithPhases ?? [],
    },
  ];

  if (isLoading) {
    return (
      <PageContainerStyled>
        <Loading />
      </PageContainerStyled>
    );
  }

  return (
    <PageContainerStyled>
      <Box>
        <TitleContainer>
          <Typography variant="jumboTitle">{t("Проекти")}</Typography>
        </TitleContainer>
        <TableContainerStyled className="tabs">
          <TableHeaderSection
            handleChangeTabMode={handleChangeTabMode}
            tabMode={tabIndex}
          />
          <TabContainer>
            {tabContent.map(({ data, id }, index) => (
              <TabPanel key={id} value={tabIndex} index={index}>
                <ProjectsTable projects={data} isActive={index === 0} />
              </TabPanel>
            ))}
          </TabContainer>
        </TableContainerStyled>
      </Box>
      {isDesktop && <Footer isSpace isSticki={false} />}
    </PageContainerStyled>
  );
};

export default Projects;
