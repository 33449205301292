import { styled } from "@mui/material/styles";
import { HeaderRightBlock, TableContainer } from "pages/Teams/styles";
import { type Breakpoint } from "@mui/material";
import { PAGE_CONTENT_WIDTH } from "styles/theme/constants";

export const TableContainerStyled = styled(TableContainer)(() => ({
  padding: "10px 30px",
  maxWidth: PAGE_CONTENT_WIDTH,
}));

export const ButtonsContainerStyled = styled(HeaderRightBlock)(({ theme }) => ({
  "button:not(:first-of-type)": {
    marginLeft: "20px",
  },
  [theme.breakpoints.down("md" as Breakpoint)]: {
    margin: "20px 0",
  },
}));
