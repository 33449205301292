import { type FC, type SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledTabs,
  StyledTab,
} from "pages/ProductsAndServices/TableHeaderSection/styles";
import { HeaderRowStyled } from "components/Freelancer/Projects/ProjectsTable/styles";

interface TableHeaderSectionProps {
  handleChangeTabMode: (event: SyntheticEvent, newValue: number) => void;
  tabMode: number;
}

const TableHeaderSection: FC<TableHeaderSectionProps> = ({
  handleChangeTabMode,
  tabMode,
}) => {
  const { t } = useTranslation();
  return (
    <HeaderRowStyled className="tabs-row">
      <StyledTabs value={tabMode} onChange={handleChangeTabMode}>
        <StyledTab label={t("Активні")} />
        <StyledTab label={t("canceled")} />
      </StyledTabs>
    </HeaderRowStyled>
  );
};

export default TableHeaderSection;
