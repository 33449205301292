import React, { type FC } from "react";
import { useEmploymentInfo } from "hooks/useEmploymentInfo";
import { Title } from "../../../pages/UserProfile/UserInfo/styles";
import { Alert, Box, Button, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { employmentInputsData } from "./data";
import {
  AccountingInfoStyled,
  EmploymentInfoStyled,
  InputStyled,
  RowStyled,
  SectionStyled,
} from "./styles";
import { NoTableDataTextStyled } from "../styles";
import { useScreenSize } from "hooks/useScreenSize";
import { Container } from "pages/UserProfile/UserInfoInputs/styles";
import Loading from "../../UI/Loading";
import { DEFAULT_ALERT_HIDE_DURATION } from "utils/freelancer/constants";
import { useAlert } from "hooks/freelancer/useAlert";

const EmploymentInfo: FC = () => {
  const { control, errors, onSubmit, handleSubmit, watch, isLoading } =
    useEmploymentInfo();
  const watchedName = watch("legal_name");
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const {
    isAlertOpen,
    alertSeverity,
    alertMessage,
    handleAlertClose,
    openAlert,
  } = useAlert();

  return (
    <SectionStyled isSpaceBottom={30} id="employmentInfo">
      <EmploymentInfoStyled>
        <Title variant="h2">
          {t("pages.UserProfile.EmploymentInfo.Label")}
        </Title>
        {isLoading ? (
          <Container height="100%" width="100%">
            <Loading />
          </Container>
        ) : (
          <Box>
            {employmentInputsData.map(
              ({ name, label, placeholder, isRequired, type }, index) => (
                <>
                  <Controller
                    key={name}
                    name={name}
                    control={control}
                    rules={{
                      required: {
                        value: isRequired ?? false,
                        message: t("fieldIsRequired"),
                      },
                    }}
                    render={({ field }) => (
                      <InputStyled
                        {...field}
                        placeholder={t(placeholder)}
                        label={t(label)}
                        type={type ?? "text"}
                        error={!!errors?.[name]?.message}
                        helperText={errors?.[name]?.message}
                      />
                    )}
                  />
                  {index === 1 || index === 3 || index === 7 ? (
                    <RowStyled />
                  ) : null}
                </>
              )
            )}
            <RowStyled />
            <Button
              onClick={handleSubmit(onSubmit, openAlert)}
              disabled={isLoading || !watchedName?.length}
              variant="contained"
              fullWidth={isMobile}
            >
              {t("pages.UserProfile.UserInfoInputs.Зберегти")}
            </Button>
          </Box>
        )}
      </EmploymentInfoStyled>
      <AccountingInfoStyled>
        <Title variant="h2">
          {t("pages.UserProfile.EmploymentInfo.AccountingLabel")}
        </Title>
        <NoTableDataTextStyled variant="h2">
          {t("pages.UserProfile.EmploymentInfo.NoAccounting")}
        </NoTableDataTextStyled>
      </AccountingInfoStyled>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={DEFAULT_ALERT_HIDE_DURATION}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity={alertSeverity}>{alertMessage}</Alert>
      </Snackbar>
    </SectionStyled>
  );
};

export default EmploymentInfo;
