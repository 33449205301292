import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { palette } from "styles/theme/palette";
import { PAGE_CONTENT_WIDTH } from "styles/theme/constants";

export const NotesAndCalculationsContainerStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: "30px",
  maxWidth: PAGE_CONTENT_WIDTH,
}));

export const ContentContainerStyled = styled(Box)(() => ({
  display: "flex",
}));

export const CalculationsContainerStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flex: "0 0 30%",
  marginTop: "40px",
}));

export const TextAreaContainerStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flex: "0 0 70%",
  "& > span, textarea": {
    marginLeft: 0,
  },
}));

export const CalculationRowStyled = styled(Box)(() => ({
  display: "flex",
  marginBottom: "10px",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
  ".MuiFormControl-root .MuiFormHelperText-root": {
    minHeight: 0,
    height: 0,
    margin: 0,
  },
}));

export const GreenLabelStyled = styled(Typography)(() => ({
  color: palette.custom.green.chartGreenText,
  fontSize: "20px",
  fontWeight: 400,
}));

export const GreenValueStyled = styled(Typography)(() => ({
  color: palette.custom.green.chartGreenText,
  fontSize: "24px",
  fontWeight: 700,
}));

export const TotalContainerStyled = styled(Box)(() => ({
  backgroundColor: palette.custom.green.light,
  padding: "15px 40px",
  borderRadius: "8px",
  margin: "30px 0",
}));

export const TotalRowStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  "p:first-of-type": {
    marginRight: "20px",
  },
}));
