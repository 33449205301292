import { type FC, useCallback, useEffect, useMemo, useState } from "react";
import Modal from "components/UI/Modal";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  Container,
  ContainerButton,
  Title,
} from "pages/Teams/AddEmployeeModal/styles";
import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";
import Select from "components/UI/Select";
import { PRODUCT_GROUP } from "utils/freelancer/constants";
import { type Option } from "types/select";
import { type SelectChangeEvent } from "@mui/material";
import { useGetAllProductsQuery } from "store/productGroup/productGroupServices";
import { type ProductAsService } from "interfaces/product";

interface SelectServiceModalProps {
  onClose: (addedService?: ProductAsService) => void;
  isOpen: boolean;
  idsToExclude: string[];
}

const SelectServiceModal: FC<SelectServiceModalProps> = ({
  onClose,
  isOpen,
  idsToExclude,
}) => {
  const { t } = useTranslation();
  const { project_id, pipeline_id } = useFreelancerDefaults();
  const { data, refetch } = useGetAllProductsQuery({
    project_id,
    pipeline_id,
  });

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen]);

  const services = useMemo(
    () =>
      data?.filter(
        ({ group_entity, id }) =>
          group_entity.name === PRODUCT_GROUP.SERVICE &&
          !idsToExclude.includes(id)
      ),
    [data, idsToExclude]
  );

  const serviceOptions: Option[] = useMemo(
    () => services?.map(({ name, id }) => ({ label: name, value: id })) ?? [],
    [services]
  );
  const [selectedService, setSelectedService] = useState<ProductAsService>();

  const selectService = useCallback(() => {
    if (selectedService) {
      onClose(selectedService);
      setSelectedService(undefined);
    }
  }, [selectedService]);

  const handleSelectService = useCallback(
    ({ target }: SelectChangeEvent<unknown>) => {
      if (target?.value) {
        const [selected] =
          services?.filter(({ id }) => target.value === id) ?? [];
        setSelectedService(selected);
      }
    },
    [services]
  );
  const closeModal = useCallback(() => {
    setSelectedService(undefined);
    onClose();
  }, []);

  return (
    <Modal onClose={closeModal} isOpen={isOpen}>
      <Container>
        <Title variant="h1">{t("services")}</Title>
        <Select
          data={serviceOptions}
          label={t(
            "freelancer.page.addEditProject.services.selectService.label"
          )}
          fullWidth
          onChange={handleSelectService}
        />
        <ContainerButton>
          <Button
            disabled={!selectedService}
            variant="contained"
            component="span"
            onClick={selectService}
          >
            {t("Додати")}
          </Button>

          <Button variant="light" onClick={closeModal}>
            {t("Скасувати")}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default SelectServiceModal;
