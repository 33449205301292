import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const InputContainerStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: "0 0 calc((100% - 40px) / 5)",
  marginLeft: "20px",
  ".MuiFormHelperText-root": {
    minHeight: 0,
    height: 0,
    margin: 0,
  },
}));

export const CheckboxNameContainerStyled = styled(Box)(() => ({
  display: "flex",
  flex: "0 0 calc((100% - 40px) / 5 * 2)",
  alignItems: "center",
  justifyContent: "center",
  ".MuiFormHelperText-root": {
    minHeight: 0,
    height: 0,
    margin: 0,
  },
}));
