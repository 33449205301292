import { type FC, useCallback, useState } from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { useScreenSize } from "hooks/useScreenSize";
import EditDeleteTooltip from "components/Freelancer/EditDeleteTooltip";
import { type TableInvoice } from "interfaces/freelancer/invoice";
import { useTranslation } from "react-i18next";
import AdditionalTooltipItems from "components/Freelancer/Invoices/InvoicesTable/AdditionalTooltipItems";
import { useDrawer } from "hooks/useDrawer";
import InvoiceDueDateModal from "../../../InvoiceDueDateModal";

interface PendingInvoicesTableBodyProps {
  invoices: TableInvoice[];
}

const PendingInvoicesTableBody: FC<PendingInvoicesTableBodyProps> = ({
  invoices,
}) => {
  const { isDesktop } = useScreenSize();
  const { t } = useTranslation();
  const { open, isOpen, close } = useDrawer();
  const [invoiceToChange, setInvoiceToChange] = useState<TableInvoice | null>(
    null
  );
  const handleDueDateModalOpen = useCallback(
    (invoice: TableInvoice) => {
      if (invoice) {
        setInvoiceToChange(invoice);
        open();
      }
    },
    [invoiceToChange]
  );
  return (
    <TableBody>
      {!!invoices?.length &&
        invoices.map((invoice) => (
          <TableRow hover role="checkbox" tabIndex={-1} key={invoice.id}>
            <TableCell>{invoice.name}</TableCell>
            {isDesktop && (
              <>
                <TableCell>{invoice.projectName}</TableCell>
                <TableCell>{`${invoice.currency} ${invoice.invoiced}`}</TableCell>
              </>
            )}
            <TableCell>{`${t("due")} ${invoice.dueDateFormatted}`}</TableCell>
            <EditDeleteTooltip
              showDelete={false}
              showEdit={false}
              additionalItems={
                <AdditionalTooltipItems
                  isActive
                  invoice={invoice}
                  onInvoiceDueDateChange={handleDueDateModalOpen}
                />
              }
            />
          </TableRow>
        ))}
      <InvoiceDueDateModal
        onClose={close}
        isOpen={isOpen}
        invoice={invoiceToChange}
      />
    </TableBody>
  );
};

export default PendingInvoicesTableBody;
