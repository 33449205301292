import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { palette } from "styles/theme/palette";

export const GoogleButton = styled(Button)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  background-color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  border: 1px solid ${({ theme }) => theme?.palette?.primary?.main};
  height: 55px;
  margin-top: 10px;
  margin-bottom: 40px;

  &:hover {
    background-color: ${({ theme }) => theme?.palette?.primary?.contrastText};
    color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  }
`;

export const TextContainerStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
}));

export const TextStyled = styled(Typography)(() => ({
  margin: "20px",
  color: palette.custom.grey.dark,
  textAlign: "center",
}));
