import {
  type FC,
  type SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { EXPENSE_STATUS, PROJECT_TYPE } from "utils/freelancer/constants";
import TableHeaderSection from "./TableHeaderSection";
import { TabContainer } from "pages/ProductsAndServices/styles";
import TabPanel from "components/UI/TabPanel";
import { ExpensesContainerStyled, TableContainerStyled } from "./styles";
import Phase from "./Phase";
import { useTranslation } from "react-i18next";
import { palette } from "styles/theme/palette";
import { NoTableDataTextStyled } from "components/Freelancer/styles";
import { type Control, useFieldArray, UseFormWatch } from "react-hook-form";
import { type UseFormSetValue } from "react-hook-form/dist/types/form";
import {
  GRAPH_DATA_TYPE,
  type ProjectCreateForm,
  type ProjectPhaseCreate,
} from "interfaces/freelancer/project";
import WorkPhase from "./WorkPhase";
import GraphWithCalculations from "../Graph";
import { Typography } from "@mui/material";
import { BudgetStyled } from "../styles";
import { useProjectCalculations } from "hooks/freelancer/useProjectCalculations";
import { getNumber } from "utils/freelancer/helpers/getNumber";
import { v4 as uuidv4 } from "uuid";
import RequiredFieldsMissedModal from "./RequiredFieldsMissedModal";
import { useDrawer } from "hooks/useDrawer";

interface ExpensesProps {
  type: PROJECT_TYPE;
  control: Control<ProjectCreateForm>;
  setValue: UseFormSetValue<ProjectCreateForm>;
  initialPhases: ProjectPhaseCreate[];
  initialWorkPhases: ProjectPhaseCreate[];
  watch: UseFormWatch<ProjectCreateForm>;
  projectId: string;
  handleSave?: () => Promise<void>;
  onAddNewTeammateClick?: () => void;
  ableToSaveProject?: boolean;
}
const Expenses: FC<ExpensesProps> = ({
  type,
  setValue,
  control,
  initialPhases,
  initialWorkPhases,
  watch,
  projectId,
  handleSave,
  onAddNewTeammateClick,
  ableToSaveProject = false,
}) => {
  const { t } = useTranslation();
  const defaultTab = initialWorkPhases?.length ? 1 : 0;
  const [tabIndex, setTabIndex] = useState<number>(defaultTab);
  const {
    isOpen: isRequiredFieldsModalOpen,
    open: openRequiredFieldsModal,
    close: closeRequiredFieldsModal,
  } = useDrawer();
  const handleChangeTabMode = (e: SyntheticEvent, newValue: number) =>
    setTabIndex(newValue);
  const { fields, append, remove, replace, update } = useFieldArray({
    control,
    name: "phases",
  });

  const { append: appendRemoved } = useFieldArray({
    control,
    name: "removedPhases",
  });

  const {
    fields: workPhaseFields,
    append: workPhaseAppend,
    replace: workPhaseReplace,
  } = useFieldArray({
    control,
    name: "workPhases",
  });

  const watchPhases = watch("phases");
  const controlledPhases = fields.map((field, index) => {
    return {
      ...field,
      ...watchPhases[index],
    };
  });

  const watchWorkPhases = watch("workPhases");
  const controlledWorkPhases = workPhaseFields.map((field, index) => {
    return {
      ...field,
      ...watchWorkPhases[index],
    };
  });
  const watchName = watch("projectName");
  const watchClientId = watch("clientId");

  useEffect(() => {
    if (!!initialPhases?.length) {
      replace(initialPhases);
    }
  }, [initialPhases]);

  useEffect(() => {
    if (!!initialWorkPhases?.length) {
      workPhaseReplace(initialWorkPhases);
    }
  }, [initialWorkPhases]);

  const handleAddPhase = useCallback(() => {
    append({
      name: `${t("phase")} ${controlledPhases.length + 1}`,
      startDate: new Date(),
      status: EXPENSE_STATUS.DRAFT,
      expenses: { employees: [], additionalExpenses: [], services: [] },
      isPromoted: false,
    });
  }, [controlledPhases]);

  const promote = useCallback(
    (phaseIndex: number) => {
      if (watchName?.length && watchClientId) {
        const updatedPhase = {
          ...controlledPhases[phaseIndex],
          isPromoted: true,
        };
        update(phaseIndex, updatedPhase);
        const employeesToPromote = updatedPhase.expenses.employees.map(
          (employee) => ({
            ...employee,
            hours: 0,
            id: uuidv4(),
            expenseId: uuidv4(),
          })
        );
        const servicesToPromote = updatedPhase.expenses.services.map(
          (service) => ({
            ...service,
            id: uuidv4(),
            expenseId: uuidv4(),
          })
        );
        const workPhaseToAppend = {
          ...updatedPhase,
          expenses: {
            services: servicesToPromote,
            employees: employeesToPromote,
            additionalExpenses: [],
          },
          status: EXPENSE_STATUS.SUBMITTED,
          twin_id: controlledPhases[phaseIndex].id,
        };
        workPhaseAppend(workPhaseToAppend);
        setTabIndex(1);
      } else {
        openRequiredFieldsModal();
      }
    },
    [controlledPhases, openRequiredFieldsModal, watchName]
  );
  const calculationData = useProjectCalculations(
    controlledPhases,
    controlledWorkPhases,
    type,
    projectId
  );

  const budgetLabel = tabIndex === 0 ? t("estimatedBudget") : t("budgetUsed");

  const [estimatedBudget] = calculationData.graphData.filter(
    (data) => data?.type === GRAPH_DATA_TYPE.ESTIMATED_BUDGET
  );
  const [budgetUsed] = calculationData.graphData.filter(
    (data) => data?.type === GRAPH_DATA_TYPE.BUDGET_USED
  );

  const total = getNumber(
    tabIndex === 0 ? estimatedBudget?.value ?? 0 : budgetUsed?.value ?? 0
  );

  const checkPromotedStatus = useCallback(
    (initialPhase): boolean => {
      if (controlledWorkPhases && initialPhase) {
        return !!controlledWorkPhases.filter(
          ({ twin_id }) => twin_id && twin_id === initialPhase.id
        )?.length;
      }
      return false;
    },
    [controlledWorkPhases]
  );

  const handleRemovePhase = useCallback(
    (index: number) => {
      appendRemoved(controlledPhases[index]);
      remove(index);
    },
    [controlledPhases]
  );

  return (
    <>
      <GraphWithCalculations data={calculationData} />
      <ExpensesContainerStyled>
        <TableContainerStyled className="tabs">
          <TableHeaderSection
            handleChangeTabMode={handleChangeTabMode}
            tabMode={tabIndex}
            disabled={false}
            phasesNumber={controlledPhases.length}
            onAddPhaseClick={handleAddPhase}
          />
          <TabContainer>
            <TabPanel value={tabIndex} index={0}>
              {!!controlledPhases?.length ? (
                controlledPhases.map((field, ind) => (
                  <Phase
                    key={field.id}
                    initialPhase={initialPhases?.[ind]}
                    field={field}
                    index={ind}
                    type={type}
                    control={control}
                    setValue={setValue}
                    removePhase={handleRemovePhase}
                    promotePhase={promote}
                    alreadyPromoted={checkPromotedStatus(initialPhases?.[ind])}
                    watch={watch}
                    onAddNewTeammateClick={onAddNewTeammateClick}
                    ableToSaveProject={ableToSaveProject}
                    saveProject={handleSave}
                  />
                ))
              ) : (
                <NoTableDataTextStyled
                  color={palette.primary.main}
                  variant="h1"
                >
                  {t("freelancer.page.addEditProject.noPhaseText")}
                </NoTableDataTextStyled>
              )}
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              {!!controlledWorkPhases?.length ? (
                controlledWorkPhases.map((field, ind) => (
                  <WorkPhase
                    key={field.id}
                    initialPhase={initialWorkPhases?.[ind]}
                    field={field}
                    index={ind}
                    type={type}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    projectId={projectId}
                    handleSave={handleSave}
                  />
                ))
              ) : (
                <NoTableDataTextStyled
                  color={palette.primary.main}
                  variant="h1"
                >
                  {t("freelancer.page.addEditProject.noWorkPhaseText")}
                </NoTableDataTextStyled>
              )}
            </TabPanel>
          </TabContainer>
        </TableContainerStyled>
      </ExpensesContainerStyled>
      <BudgetStyled>
        <Typography variant="h3">{`${budgetLabel}: `}</Typography>
        <Typography variant="h2">{`$ ${total.toFixed(2)}`}</Typography>
      </BudgetStyled>
      <RequiredFieldsMissedModal
        onClose={closeRequiredFieldsModal}
        isOpen={isRequiredFieldsModalOpen}
      />
    </>
  );
};

export default Expenses;
