import { type ChangeEvent, useCallback, useEffect, useState } from "react";
import { type Control, useWatch } from "react-hook-form";
import { getNumber } from "utils/freelancer/helpers/getNumber";
import { type InvoiceCreateForm } from "interfaces/freelancer/invoice";
import { type UseFormSetValue } from "react-hook-form/dist/types/form";

interface InvoiceTotalCalculationsReturn {
  total?: string;
  subtotal?: string;
  tax?: string;
  discount?: string;
  onTaxChange: ({
    currentTarget,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onDiscountChange: ({
    currentTarget,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const useInvoiceTotalCalculations = (
  control: Control<InvoiceCreateForm>,
  setValue: UseFormSetValue<InvoiceCreateForm>
): InvoiceTotalCalculationsReturn => {
  const [tax, setTax] = useState<string>("0");
  const [discount, setDiscount] = useState<string>("0");
  const [subtotal, setSubtotal] = useState<string>("0");
  const [total, setTotal] = useState<string>();
  const phases = useWatch({ control, name: "phases" });

  const onTaxChange = useCallback(
    ({
      currentTarget,
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const taxInMoney =
        ((getNumber(subtotal) - getNumber(discount)) / 100) *
        getNumber(currentTarget.value);
      setTax(currentTarget.value);
      setValue("tax", taxInMoney);
    },
    [subtotal, discount]
  );
  const onDiscountChange = useCallback(
    ({
      currentTarget,
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const discountAmount = getNumber(currentTarget.value);
      setDiscount(currentTarget.value);
      setValue("discount", discountAmount);
    },
    []
  );

  useEffect(() => {
    if (phases?.length) {
      setSubtotal("0");
      phases.forEach(({ employees, services, additionalExpenses }) => {
        employees?.forEach(({ rate, hoursToBePaid }) =>
          setSubtotal((prev) =>
            getNumber(
              getNumber(prev) + getNumber(rate * hoursToBePaid)
            ).toFixed(1)
          )
        );
        services?.forEach(({ invoicedAmount }) =>
          setSubtotal((prev) =>
            getNumber(getNumber(prev) + getNumber(invoicedAmount)).toFixed(1)
          )
        );
        additionalExpenses?.forEach(({ invoicedAmount }) =>
          setSubtotal((prev) =>
            getNumber(getNumber(prev) + getNumber(invoicedAmount)).toFixed(1)
          )
        );
      });
    }
  }, [phases]);

  useEffect(() => {
    setValue("subTotal", getNumber(subtotal));
  }, [subtotal]);

  useEffect(() => {
    const taxInMoney =
      ((getNumber(subtotal) - getNumber(discount)) / 100) * getNumber(tax);
    const totalAmount = getNumber(subtotal) - getNumber(discount) + taxInMoney;
    setTotal(`${totalAmount.toFixed(1)}`);
    setValue("total", totalAmount);
  }, [tax, subtotal, discount]);

  return { total, subtotal, tax, discount, onTaxChange, onDiscountChange };
};
