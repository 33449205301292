import { ProjectPhaseCreate } from "interfaces/freelancer/project";
import { getNumber } from "./getNumber";
import { TimeLog } from "../../../store/timeLog/timeLogTypes";
import { ClockifyEmployeesTimeEntries } from "store/productGroup/productGroupTypes";
import dayjs from "dayjs";

interface UserEmployeeCalculationsReturn {
  myHours: {
    estimated: number;
    real: number;
  };
  myRate: {
    estimated: number;
    real: number;
  };
}

export const getUserEmployeeCalculations = (
  projectIncomeByMe: number,
  userEmployeeName?: string,
  estimationPhases?: ProjectPhaseCreate[],
  factPhases?: ProjectPhaseCreate[],
  timeLogs?: TimeLog[],
  projectId?: string,
  clockifyEmployeesTimeEntries?: ClockifyEmployeesTimeEntries
): UserEmployeeCalculationsReturn => {
  let myHoursEstimated = 0;
  let myExpensesEstimated = 0;

  estimationPhases?.forEach(({ expenses }) => {
    const [userEmployee] = expenses.employees.filter(
      ({ name }) => name === userEmployeeName
    );
    if (userEmployee) {
      myHoursEstimated += userEmployee.hours;
      myExpensesEstimated += userEmployee.hours * userEmployee.amount;
    }
  });
  const myRateEstimated =
    myHoursEstimated < 1 ? 0 : myExpensesEstimated / myHoursEstimated;
  const employeeTimeLogs = timeLogs?.filter(
    ({ employee_id, project_id }) =>
      employee_id === userEmployeeName && project_id === projectId
  );
  const employeeTimeLogsWorkedMinutes = employeeTimeLogs?.length
    ? employeeTimeLogs.reduce((partialSum, { value }) => partialSum + value, 0)
    : 0;
  const employeeClockifyWorkedMinutes =
    clockifyEmployeesTimeEntries?.[userEmployeeName ?? ""]?.reduce(
      (partialSum, { start, end }) =>
        partialSum + dayjs(end).diff(dayjs(start), "m", true),
      0
    ) ?? 0;
  const workedMinutes =
    employeeTimeLogsWorkedMinutes + employeeClockifyWorkedMinutes;

  const myHoursReal = workedMinutes > 0 ? workedMinutes / 60 : 0;
  const myRateReal =
    projectIncomeByMe < 1 && myHoursReal < 1
      ? 0
      : projectIncomeByMe / myHoursReal;

  return {
    myHours: {
      estimated: getNumber(myHoursEstimated),
      real: getNumber(myHoursReal),
    },
    myRate: {
      estimated: getNumber(myRateEstimated),
      real: getNumber(myRateReal),
    },
  };
};
