import { BaseQueryParams } from "store/baseQuery";
import type {
  AddEmployeesRequest,
  EditEmployeeRequest,
  GetEmployeesRequest,
  Employee,
} from "./employeesTypes";

export const employeesServices = BaseQueryParams("employees", [
  "EMPLOYEES",
  "EMPLOYEES_ADD",
  "EMPLOYEES_EDIT",
]).injectEndpoints({
  endpoints: (builder) => ({
    getEmployees: builder.query<Employee[], GetEmployeesRequest>({
      query: ({ project_id, pipeline_id }) => ({
        url: `/projects/${project_id}/pipelines/${pipeline_id}/employees`,
        method: "GET",
      }),
      providesTags: () => ["EMPLOYEES"],
    }),
    addEmployees: builder.mutation<unknown, AddEmployeesRequest>({
      query: ({ project_id, body }) => ({
        url: `/projects/${project_id}/pipelines/employees`,
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["EMPLOYEES"],
    }),
    editEmployee: builder.mutation<unknown, EditEmployeeRequest>({
      query: ({ project_id, body, pipeline_id, product_id }) => ({
        url: `/projects/${project_id}/pipelines/${pipeline_id}/employees/${product_id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: () => ["EMPLOYEES"],
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useAddEmployeesMutation,
  useEditEmployeeMutation,
} = employeesServices;
