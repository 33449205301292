import { INVOICE_ITEM_KIND } from "utils/freelancer/constants";
import { useGetInvoiceQuery } from "store/invoices/invoicesServices";
import {
  type InvoiceCreateForm,
  InvoiceEmployeeExpense,
  InvoiceExpense,
} from "interfaces/freelancer/invoice";

export const useExistingInvoice = (
  invoiceId: string
): InvoiceCreateForm | null => {
  const { data: invoice } = useGetInvoiceQuery({
    invoice_id: invoiceId,
  });
  if (!invoice) return null;
  const employees: InvoiceEmployeeExpense[] = invoice.items
    .filter(({ kind }) => kind === INVOICE_ITEM_KIND.TEAM)
    .map(({ name, id, quantity, price, project_item_id }) => ({
      name,
      id,
      itemId: project_item_id,
      hoursToBePaid: quantity,
      rate: price,
    }));
  const services: InvoiceExpense[] = invoice.items
    .filter(({ kind }) => kind === INVOICE_ITEM_KIND.SERVICE)
    .map(({ name, id, price, project_item_id }) => ({
      name,
      id,
      itemId: project_item_id,
      invoicedAmount: price,
      billedPrice: price,
    }));
  const additionalExpenses: InvoiceExpense[] = invoice.items
    .filter(({ kind }) => kind === INVOICE_ITEM_KIND.ADDITIONAL)
    .map(({ name, id, price, project_item_id }) => ({
      name,
      id,
      itemId: project_item_id,
      invoicedAmount: price,
      billedPrice: price,
    }));
  const phaseName = invoice.items[0].group ?? "";
  const phaseDescription = invoice.items[0].description ?? "";
  return {
    name: invoice.name,
    contractNumber: invoice.contract_name,
    dueDate: invoice.valid_until,
    invoiceDate: invoice.created_at ?? new Date(),
    phases: [
      {
        name: phaseName,
        employees,
        services,
        additionalExpenses,
        description: phaseDescription,
      },
    ],
    total: invoice.total,
    subTotal: invoice.sub_total,
    tax: invoice.tax,
    discount: invoice.discount,
    notes: invoice.discount_description,
    credentials: {
      employment: {
        executor: {
          name: invoice.contractor_info.name,
          edrpo: invoice.contractor_info.tax_id,
          email: invoice.contractor_info.email,
          address: invoice.contractor_info.address,
        },
        client: {
          name: invoice.customer_info.name,
          edrpo: invoice.customer_info.tax_id,
          email: invoice.customer_info.email,
          address: invoice.customer_info.address,
        },
      },
      bank: {
        executor: {
          beneficiary: {
            name: invoice.contractor_bank_info.bank_name,
            address: invoice.contractor_bank_info.bank_address,
            swift: invoice.contractor_bank_info.swift_code,
            account: invoice.contractor_bank_info.account_number,
          },
          correspondent: {
            name: invoice.contractor_bank_info.correspondent_bank,
            swift: invoice.contractor_bank_info.correspondent_swift_code,
            account: invoice.contractor_bank_info.correspondent_account_number,
          },
        },
        client: {},
      },
    },
  };
};
