import React from "react";
// import './internationalization';

import "./locales/i18n";
import Theme from "./styles/Theme";
import Router from "./routes";

const App: React.FC = () => (
  <Theme>
    <Router />
  </Theme>
);

export default App;
