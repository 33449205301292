import { type FC } from "react";
import { Outlet } from "react-router-dom";
import SideBar from "./SideBar";
import { Wrapper, ButtonStyled } from "layouts/MainLayout/styles";
import { useScreenSize } from "hooks/useScreenSize";
import MobileHeader from "./MobileHeader";
import MobileMenu from "./MobileMenu";
import { useDrawer } from "hooks/useDrawer";
import {
  ContainerStyled,
  SaleButtonContainerStyled,
  ImageStyled,
} from "./styles";
import { useTranslation } from "react-i18next";
import { useTrialUser } from "hooks/useTrialUser";
import {
  ContainerIcon,
  DateText,
} from "components/HeaderUserMenu/UserMembershipTooltip/styles";
import TooltipText from "components/HeaderUserMenu/UserMembershipTooltip/TooltipText";
import { Tooltip } from "@mui/material";
import PayWallIcon from "assets/icons/PayWallIcon.svg";
import ErrorPayWallIcon from "assets/icons/ErrorPayWallIcon.svg";
import { freelanceUserRoutesPath } from "routes/routePath";
import { SaleModal } from "./SaleModal";
import { PresentationIcon } from "assets/icons";

const FreelancerMainLayout: FC = () => {
  const { isMobile } = useScreenSize();
  const { t } = useTranslation();
  const {
    isOpen: isMenuOpen,
    open: openMenu,
    toggle: toggleMenu,
  } = useDrawer();
  const { trialSubscriptionEnd, trialDaysLeft, limitedAccess } = useTrialUser();
  const { isOpen: isSaleModalOpen, toggle: toggleSaleModal } = useDrawer();

  return (
    <ContainerStyled>
      {isMobile ? (
        <MobileMenu handleDrawer={toggleMenu} isOpen={isMenuOpen} />
      ) : (
        <SideBar />
      )}
      <Wrapper>
        {isMobile && <MobileHeader handleDrawer={openMenu} />}
        <SaleButtonContainerStyled>
          <a
            href="https://app.storylane.io/share/gwjlklrshb62"
            target="_blank"
            rel="noreferrer"
          >
            <ButtonStyled variant="light" startIcon={<PresentationIcon />}>
              {t("freelancer.watchInteractiveDemo")}
            </ButtonStyled>
          </a>
          {limitedAccess && (
            <>
              <Tooltip
                enterTouchDelay={0}
                title={
                  <TooltipText
                    navigateTo={`${freelanceUserRoutesPath.SETTINGS}#plans`}
                  />
                }
                placement="bottom"
              >
                <ContainerIcon>
                  <DateText isEndFreePeriod={trialSubscriptionEnd}>
                    {trialDaysLeft}
                  </DateText>
                  {trialSubscriptionEnd ? (
                    <ImageStyled src={ErrorPayWallIcon} />
                  ) : (
                    <ImageStyled src={PayWallIcon} />
                  )}
                </ContainerIcon>
              </Tooltip>
              <ButtonStyled onClick={toggleSaleModal} variant="contained">
                {t("freelancer.saleButtonLabel")}
              </ButtonStyled>
            </>
          )}
        </SaleButtonContainerStyled>
        <Outlet />
      </Wrapper>
      <SaleModal onClose={toggleSaleModal} isOpen={isSaleModalOpen} />
    </ContainerStyled>
  );
};

export default FreelancerMainLayout;
