import type { Option } from "types/select";
import { type TFunction } from "react-i18next";
import { OPERATION_TYPE } from "interfaces/transaction";
import {
  USER_EXPENSE_DETAILS,
  USER_EXPENSE_TYPE,
} from "store/userExpense/userExpenseTypes";

interface TransactionOptionsReturn {
  operationTypeOptions: Option[];
  typeOptions: Option[];
  groupOptions: Option[];
}
export const getTransactionOptions = (
  t: TFunction
): TransactionOptionsReturn => {
  const operationTypeOptions: Option[] = [
    { label: t("outcome"), value: OPERATION_TYPE.OUTCOME },
  ];
  const typeOptions: Option[] = [
    { label: t("hardware"), value: USER_EXPENSE_TYPE.HARDWARE },
    { label: t("software"), value: USER_EXPENSE_TYPE.SOFTWARE },
    { label: t("administrative"), value: USER_EXPENSE_TYPE.ADMINISTRATIVE },
    { label: t("withdrawal"), value: USER_EXPENSE_TYPE.WITHDRAWAL },
    { label: t("other"), value: USER_EXPENSE_TYPE.OTHER },
  ];
  const groupOptions: Option[] = [
    { label: t("personal"), value: USER_EXPENSE_DETAILS.PERSONAL },
    { label: t("other"), value: USER_EXPENSE_DETAILS.OTHER },
  ];

  return { operationTypeOptions, groupOptions, typeOptions };
};
