import { styled } from "@mui/material/styles";
import { TableContainer, type Breakpoint, TableRow } from "@mui/material";
import { HeaderRow } from "pages/Teams/styles";
import { palette } from "../../../../styles/theme/palette";

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  [theme.breakpoints.down("md" as Breakpoint)]: {
    maxWidth: "calc(100vw - 24px)",
    "th, td": {
      fontSize: "12px",
    },
  },
}));

export const HeaderRowStyled = styled(HeaderRow)(({ theme }) => ({
  paddingLeft: "30px",
  marginTop: "-10px",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: 0,
  },
}));

export const TableRowStyled = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "withBorder",
})<{ withBorder?: boolean }>(({ withBorder = false }) => ({
  cursor: "pointer",
  "& > *": {
    borderBottom: withBorder ? `1px solid ${palette.primary.main}` : "unset",
  },
}));
