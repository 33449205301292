import {
  useDeleteProductGroupMutation,
  useUpdateProductGroupMutation,
} from "store/productGroup/productGroupServices";
import { useCallback } from "react";
import {
  PRODUCT_GROUP_STATUS,
  PROJECT_TYPE,
  URL_PARAMS,
} from "utils/freelancer/constants";
import { getUrlWithParams } from "utils/helpers/getUrlWithParams";
import { freelanceUserRoutesPath } from "routes/routePath";
import { type ProjectWithPhases } from "interfaces/freelancer/project";
import { useNavigate } from "react-router";

export const useProject = (project: ProjectWithPhases) => {
  const navigate = useNavigate();
  const [deleteProductGroup] = useDeleteProductGroupMutation();
  const [updateProductGroup] = useUpdateProductGroupMutation();
  const deleteProject = useCallback(async () => {
    try {
      await deleteProductGroup({ id: project.id });
    } catch (e) {
      console.error("Error on deleteProject, ", e);
    }
  }, [project]);

  const changeProjectStatus = useCallback(async () => {
    const newStatus =
      project?.status === PRODUCT_GROUP_STATUS.ACTIVE
        ? PRODUCT_GROUP_STATUS.ARCHIVE
        : PRODUCT_GROUP_STATUS.ACTIVE;
    try {
      await updateProductGroup({
        id: project?.id,
        body: {
          name: project?.name,
          status: newStatus,
          extras: {
            clientId: project?.client?.id ?? "",
          },
        },
      });
    } catch (e) {
      console.error("Error on updateProject, ", e);
    }
  }, [project]);

  const navigateToEditProjectPage = useCallback(() => {
    const projectUrl = getUrlWithParams(freelanceUserRoutesPath.PROJECTS_EDIT, [
      { key: URL_PARAMS.PROJECT_ID, value: project.id },
      { key: URL_PARAMS.TYPE, value: project.type ?? PROJECT_TYPE.FIX },
    ]);
    navigate(projectUrl);
  }, [project]);

  return { changeProjectStatus, deleteProject, navigateToEditProjectPage };
};
