import { styled } from "@mui/material/styles";
import { TableContainer } from "pages/ProductsAndServices/styles";
import {
  type Breakpoint,
  RadioGroup,
  Button as MuiButton,
} from "@mui/material";
import { palette } from "styles/theme/palette";
import { Button } from "components/ConfirmationModal/styles";
import { PAGE_CONTENT_WIDTH } from "styles/theme/constants";

export const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  maxWidth: PAGE_CONTENT_WIDTH,
  [theme.breakpoints.down("md" as Breakpoint)]: {
    maxWidth: "calc(100vw - 30px)",
    overflow: "auto",
    padding: "0 20px 60px",
  },
}));

export const RadioGroupStyled = styled(RadioGroup)(() => ({
  display: "flex",
  width: "100%",
  flexDirection: "row",
  padding: "60px 0 50px",
  borderBottom: `1px solid ${palette.primary.main}`,
  "& > .MuiFormControlLabel-root": {
    marginRight: "60px",
  },
}));

export const ButtonWithMarginStyled = styled(Button)(() => ({
  marginRight: "40px",
}));

export const ButtonDisabledStyled = styled(MuiButton)(() => ({
  "&.Mui-disabled": {
    pointerEvents: "auto",
    "&:hover": {
      background: palette.custom.green.light,
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
}));
