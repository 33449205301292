import { type FC, useCallback } from "react";
import { Controller } from "react-hook-form";
import Modal from "components/UI/Modal";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  Container,
  ContainerButton,
  StyledInput,
  Title,
} from "pages/Teams/AddEmployeeModal/styles";
import type { Employee } from "store/employees/employeesTypes";
import { EMAIL_PATTERN, EMPLOYEE_TYPE } from "utils/freelancer/constants";
import { useAddEditEmployeeForm } from "hooks/useAddEditEmployeeForm";
import { getEmployeeTableLabel } from "utils/freelancer/helpers/getEmployeeTableLabel";
import { ContainerFormStyled, RowStyled } from "./styles";
import { useScreenSize } from "hooks/useScreenSize";

interface AddMembersModalProps {
  onClose: () => void;
  callback?: () => void;
  isOpen: boolean;
  employee?: Employee | null;
  selectedType?: EMPLOYEE_TYPE;
}

const AddEditMemberModal: FC<AddMembersModalProps> = ({
  onClose,
  isOpen,
  employee,
  selectedType = EMPLOYEE_TYPE.CONTRACTOR,
  callback,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const {
    errors,
    control,
    reset,
    isClient,
    update,
    isUserEmployee,
    ableToSubmit,
  } = useAddEditEmployeeForm(selectedType, onClose, employee, callback);
  const resetAndClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);
  const modalWidth = isMobile ? 350 : 800;
  const title = getEmployeeTableLabel(t, isClient, employee?.id);

  return (
    <Modal onClose={resetAndClose} isOpen={isOpen} maxWidth={modalWidth}>
      <Container>
        <Title variant="h1">{title}</Title>
        <ContainerFormStyled>
          <RowStyled withBorder>
            <Controller
              render={({ field }) => (
                <StyledInput
                  {...field}
                  isRequired
                  isEmpty={(field.value || "").length === 0}
                  label={`${t("pages.UserProfile.Name.Label")} *`}
                  disabled={isUserEmployee}
                  placeholder={t("pages.UserProfile.Name.Placeholder")}
                  className="name-input"
                  error={!!errors?.name?.message}
                  helperText={errors?.name?.message}
                />
              )}
              control={control}
              name="name"
              rules={{
                required: { value: true, message: t("fieldIsRequired") },
              }}
            />
            {!isClient && (
              <Controller
                render={({ field }) => (
                  <StyledInput
                    {...field}
                    label={t("pages.UserProfile.EmploymentInfo.Wage.Label")}
                    placeholder={t(
                      "pages.UserProfile.EmploymentInfo.Wage.Placeholder"
                    )}
                    error={!!errors?.wage?.message}
                    helperText={errors?.wage?.message}
                    fullWidth
                    type="number"
                  />
                )}
                control={control}
                name="wage"
              />
            )}
            {/* {!isClient && (
              <Controller
                render={({ field }) => (
                  <StyledInput
                    {...field}
                    label={t(
                      "pages.UserProfile.EmploymentInfo.Department.Label"
                    )}
                    placeholder={t(
                      "pages.UserProfile.EmploymentInfo.Department.Placeholder"
                    )}
                    error={!!errors?.department?.message}
                    helperText={errors?.department?.message}
                    fullWidth
                  />
                )}
                control={control}
                name="department"
              />
            )} */}
            <Controller
              render={({ field }) => (
                <StyledInput
                  {...field}
                  isRequired
                  isEmpty={(field.value || "").length === 0}
                  label={`${t(
                    "pages.UserProfile.EmploymentInfo.Email.Label"
                  )} *`}
                  placeholder={t(
                    "pages.UserProfile.EmploymentInfo.Email.Placeholder"
                  )}
                  required
                  error={!!errors?.email?.message}
                  helperText={errors?.email?.message}
                />
              )}
              control={control}
              name="email"
              rules={{
                pattern: {
                  value: EMAIL_PATTERN,
                  message: t("emailValidationError"),
                },
                required: { value: true, message: t("fieldIsRequired") },
              }}
            />
            <Controller
              render={({ field }) => (
                <StyledInput
                  {...field}
                  label={t("pages.UserProfile.EmploymentInfo.Phone.Label")}
                  placeholder={t(
                    "pages.UserProfile.EmploymentInfo.Phone.Placeholder"
                  )}
                  error={!!errors?.phone?.message}
                  helperText={errors?.phone?.message}
                  fullWidth
                />
              )}
              control={control}
              name="phone"
            />
            {isClient && (
              <Controller
                render={({ field }) => (
                  <StyledInput
                    {...field}
                    label={t("pages.UserProfile.EmploymentInfo.Link.Label")}
                    placeholder={t(
                      "pages.UserProfile.EmploymentInfo.Link.Placeholder"
                    )}
                    error={!!errors?.link?.message}
                    helperText={errors?.link?.message}
                    fullWidth
                  />
                )}
                control={control}
                name="link"
              />
            )}
            {isClient && (
              <Controller
                render={({ field }) => (
                  <StyledInput
                    {...field}
                    label={t(
                      "pages.UserProfile.EmploymentInfo.AdditionalInfo.Label"
                    )}
                    placeholder={t(
                      "pages.UserProfile.EmploymentInfo.AdditionalInfo.Placeholder"
                    )}
                    error={!!errors?.additional_info?.message}
                    helperText={errors?.additional_info?.message}
                    fullWidth
                  />
                )}
                control={control}
                name="additional_info"
              />
            )}
          </RowStyled>
          <RowStyled withBorder>
            <Controller
              render={({ field }) => (
                <StyledInput
                  {...field}
                  label={t("pages.UserProfile.EmploymentInfo.Edrpo.Label")}
                  placeholder={t(
                    "pages.UserProfile.EmploymentInfo.Edrpo.Placeholder"
                  )}
                  error={!!errors?.edrpo?.message}
                  helperText={errors?.edrpo?.message}
                  fullWidth
                  type="number"
                />
              )}
              control={control}
              name="edrpo"
            />
            <Controller
              render={({ field }) => (
                <StyledInput
                  {...field}
                  label={t("pages.UserProfile.EmploymentInfo.Address.Label")}
                  placeholder={t(
                    "pages.UserProfile.EmploymentInfo.Address.Placeholder"
                  )}
                  error={!!errors?.address?.message}
                  helperText={errors?.address?.message}
                  fullWidth
                />
              )}
              control={control}
              name="address"
            />
          </RowStyled>
          <RowStyled withBorder>
            <Controller
              render={({ field }) => (
                <StyledInput
                  {...field}
                  label={t("pages.UserProfile.EmploymentInfo.BankName.Label")}
                  placeholder={t(
                    "pages.UserProfile.EmploymentInfo.BankName.Placeholder"
                  )}
                  error={!!errors?.bank_name?.message}
                  helperText={errors?.bank_name?.message}
                  fullWidth
                />
              )}
              control={control}
              name="bank_name"
            />
            <Controller
              render={({ field }) => (
                <StyledInput
                  label={t(
                    "pages.UserProfile.EmploymentInfo.BankAddress.Label"
                  )}
                  placeholder={t(
                    "pages.UserProfile.EmploymentInfo.BankAddress.Placeholder"
                  )}
                  error={!!errors?.bank_address?.message}
                  helperText={errors?.bank_address?.message}
                  fullWidth
                  {...field}
                />
              )}
              control={control}
              name="bank_address"
            />
            <Controller
              render={({ field }) => (
                <StyledInput
                  label={t("pages.UserProfile.EmploymentInfo.BankSwift.Label")}
                  placeholder={t(
                    "pages.UserProfile.EmploymentInfo.BankSwift.Placeholder"
                  )}
                  error={!!errors?.swift_code?.message}
                  helperText={errors?.swift_code?.message}
                  fullWidth
                  {...field}
                />
              )}
              control={control}
              name="swift_code"
            />
            <Controller
              render={({ field }) => (
                <StyledInput
                  label={t("pages.UserProfile.EmploymentInfo.Account.Label")}
                  {...field}
                  placeholder={t(
                    "pages.UserProfile.EmploymentInfo.Account.Placeholder"
                  )}
                  error={!!errors?.account_number?.message}
                  helperText={errors?.account_number?.message}
                  fullWidth
                />
              )}
              control={control}
              name="account_number"
            />
          </RowStyled>
          <RowStyled withBorder={!isClient}>
            <Controller
              render={({ field }) => (
                <StyledInput
                  {...field}
                  label={t(
                    "pages.UserProfile.EmploymentInfo.CorrespondentBankName.Label"
                  )}
                  placeholder={t(
                    "pages.UserProfile.EmploymentInfo.CorrespondentBankName.Placeholder"
                  )}
                  error={!!errors?.correspondent_bank?.message}
                  helperText={errors?.correspondent_bank?.message}
                  fullWidth
                />
              )}
              control={control}
              name="correspondent_bank"
            />
            <Controller
              render={({ field }) => (
                <StyledInput
                  {...field}
                  label={t(
                    "pages.UserProfile.EmploymentInfo.CorrespondentBankAccount.Label"
                  )}
                  placeholder={t(
                    "pages.UserProfile.EmploymentInfo.CorrespondentBankAccount.Placeholder"
                  )}
                  error={!!errors?.correspondent_account_number?.message}
                  helperText={errors?.correspondent_account_number?.message}
                  fullWidth
                />
              )}
              control={control}
              name="correspondent_account_number"
            />
            <Controller
              render={({ field }) => (
                <StyledInput
                  {...field}
                  label={t(
                    "pages.UserProfile.EmploymentInfo.CorrespondentBankSwift.Label"
                  )}
                  placeholder={t(
                    "pages.UserProfile.EmploymentInfo.CorrespondentBankSwift.Placeholder"
                  )}
                  error={!!errors?.correspondent_swift_code?.message}
                  helperText={errors?.correspondent_swift_code?.message}
                  fullWidth
                />
              )}
              control={control}
              name="correspondent_swift_code"
            />
          </RowStyled>
        </ContainerFormStyled>
        <ContainerButton>
          <Button
            disabled={!ableToSubmit}
            variant="contained"
            component="span"
            onClick={update}
          >
            {employee ? t("Зберегти") : t("Додати")}
          </Button>

          <Button variant="light" onClick={resetAndClose}>
            {t("Скасувати")}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default AddEditMemberModal;
