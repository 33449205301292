import { styled } from "@mui/material/styles";
import { Box, Breakpoint } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    flexDirection: "column-reverse",
    overflowY: "auto",
  },
}));
