import type { ProjectWithPhases } from "interfaces/freelancer/project";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetProductGroupTimeEntriesQuery } from "store/productGroup/productGroupServices";
import { useGetTimeLogByProjectQuery } from "store/timeLog/timeLogServices";

export const useInvoiceCreateErrorMessage = (project: ProjectWithPhases) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { data: timeLogData } = useGetTimeLogByProjectQuery({
    project_id: project?.id,
  });

  const { data: phase0TimeEntries } = useGetProductGroupTimeEntriesQuery({
    id: project?.phases[0]?.id || "",
  });
  const { data: phase1TimeEntries } = useGetProductGroupTimeEntriesQuery({
    id: project?.phases[1]?.id || "",
  });
  const { data: phase2TimeEntries } = useGetProductGroupTimeEntriesQuery({
    id: project?.phases[2]?.id || "",
  });
  const { data: phase3TimeEntries } = useGetProductGroupTimeEntriesQuery({
    id: project?.phases[3]?.id || "",
  });
  const { data: phase4TimeEntries } = useGetProductGroupTimeEntriesQuery({
    id: project?.phases[4]?.id || "",
  });
  const hasClockifyEmployeesTimeEntries = [
    phase0TimeEntries,
    phase1TimeEntries,
    phase2TimeEntries,
    phase3TimeEntries,
    phase4TimeEntries,
  ].some((e) => e !== undefined);

  const { t } = useTranslation();
  useEffect(() => {
    if (project) {
      setErrorMessage("");
      switch (true) {
        case !project.client:
          setErrorMessage(
            t("freelancer.page.projects.invoiceCreateErrors.noClient")
          );
          return;
        case !timeLogData?.length && !hasClockifyEmployeesTimeEntries:
          setErrorMessage(
            t("freelancer.page.projects.invoiceCreateErrors.noBilledHours")
          );
          return;
        default:
          return;
      }
    }
  }, [project, timeLogData, hasClockifyEmployeesTimeEntries]);

  return errorMessage;
};
