import { FC } from "react";
import { Container, Title, SubTitle, Link } from "./styles";
import { useTranslation } from "react-i18next";
import { PUBLIC_OFFER_URL } from "utils/constants/urls";

interface TariffPlanCardBodyProps {
  isDisabled: boolean;
  countPipelines: string;
}

const TariffPlanCardBody: FC<TariffPlanCardBodyProps> = ({
  countPipelines,
  isDisabled,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title variant="h6">
        {t("components.TariffPlanCard.TariffPlanCardBody.Умови")}
      </Title>
      <SubTitle variant="h6">{t(countPipelines)}</SubTitle>
      {!isDisabled && (
        <Link target="blank" to={PUBLIC_OFFER_URL} isDisabled={isDisabled}>
          {t("components.TariffPlanCard.TariffPlanCardBody.Деталi")}
        </Link>
      )}
    </Container>
  );
};

export default TariffPlanCardBody;
