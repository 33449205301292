import { type FC, type SyntheticEvent, useState } from "react";
import Loading from "components/UI/Loading";
import TabPanel from "components/UI/TabPanel";
import TableHeaderSection from "./InvoicesTable/TableHeadSection";
import { TabContainer } from "pages/ProductsAndServices/styles";
import Footer from "components/Footer";
import { useTranslation } from "react-i18next";
import { TableContainerStyled } from "components/Freelancer/Projects/styles";
import { useScreenSize } from "hooks/useScreenSize";
import { useAllInvoices } from "hooks/freelancer/useAllInvoices";
import InvoicesTable from "./InvoicesTable";
import { Typography, Box } from "@mui/material";
import { TitleContainer, PageContainerStyled } from "../styles";

const Invoices: FC = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { t } = useTranslation();
  const { isDesktop } = useScreenSize();

  const handleChangeTabMode = (e: SyntheticEvent, newValue: number) =>
    setTabIndex(newValue);

  const { isLoading, activeInvoices, cancelledInvoices } = useAllInvoices();

  const tabContent = [
    {
      id: 1,
      data: activeInvoices,
    },
    {
      id: 2,
      data: cancelledInvoices,
    },
  ];

  if (isLoading) {
    return (
      <PageContainerStyled>
        <Loading />
      </PageContainerStyled>
    );
  }

  return (
    <PageContainerStyled>
      <Box>
        <TitleContainer>
          <Typography variant="jumboTitle">
            {t("freelancer.page.invoices.title")}
          </Typography>
        </TitleContainer>
        <TableContainerStyled className="tabs">
          <TableHeaderSection
            handleChangeTabMode={handleChangeTabMode}
            tabMode={tabIndex}
          />
          <TabContainer>
            {tabContent.map(({ data, id }, index) => (
              <TabPanel key={id} value={tabIndex} index={index}>
                <InvoicesTable invoices={data} isActive={index === 0} />
              </TabPanel>
            ))}
          </TabContainer>
        </TableContainerStyled>
      </Box>
      {isDesktop && <Footer isSpace isSticki={false} />}
    </PageContainerStyled>
  );
};

export default Invoices;
