import { ContentBoxStyled } from "../styles";
import { type FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { TabsStyled, TabStyled } from "./styles";
import { TabContainer } from "pages/ProductsAndServices/styles";
import TabPanel from "../../../UI/TabPanel";
import AddTransactionForm from "./AddTransactionForm";
// import AddHoursForm from "./AddHoursForm";

const AddInfo: FC = () => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const handleChangeTabMode = (e: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <ContentBoxStyled>
      <TabsStyled value={tabIndex} onChange={handleChangeTabMode}>
        {/* <TabStyled label={t("freelancer.page.dashboard.add.tabTitle.hours")} /> */}
        <TabStyled
          label={t("freelancer.page.dashboard.add.tabTitle.transaction")}
        />
      </TabsStyled>
      <TabContainer>
        {/* <TabPanel value={tabIndex} index={0}>
          <AddHoursForm />
        </TabPanel> */}
        <TabPanel value={tabIndex} index={0}>
          <AddTransactionForm />
        </TabPanel>
      </TabContainer>
    </ContentBoxStyled>
  );
};

export default AddInfo;
