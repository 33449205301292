import { styled } from "@mui/material/styles";
import { Box, type Breakpoint } from "@mui/material";
import { palette } from "styles/theme/palette";
import { PAGE_CONTENT_WIDTH } from "styles/theme/constants";

export const BaseInfoContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  background: palette.custom.primary.white,
  borderRadius: "8px",
  padding: "25px 25px 15px",
  maxWidth: PAGE_CONTENT_WIDTH,
  marginBottom: "20px",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    maxWidth: "calc(100vw - 30px)",
    padding: "15px",
  },
}));

export const BaseInfoRowStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  "& > .MuiFormControl-root": {
    maxWidth: "calc((100% - 20px) / 2)",
  },
}));

export const DateContainerStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  "& > div": {
    marginLeft: "20px",
    width: "calc((100% - 60px) / 4)",
  },
}));
