import { type FC, useState, type MouseEvent, useCallback } from "react";
import { Button, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { MoreSetingsIcon, PlusIcon } from "assets/icons";
import ConfirmationModal from "components/ConfirmationModal";
import { StyledTableContainer, TableRowStyled } from "./styles";
import {
  MenuList,
  MenuListItem,
  MenuListItemButton,
  Text,
  MenuButton,
} from "pages/Teams/TeamTable/styles";
import { useTranslation } from "react-i18next";
import { type Order } from "types/global";
import { getComparator, stableSort } from "utils/teams/helpers";
import { useScreenSize } from "hooks/useScreenSize";
import { useDrawer } from "hooks/useDrawer";
import Tooltip from "components/UI/Tooltip";
import { NoDataContainerStyled } from "../../styles";
import { type ProductAsService } from "interfaces/product";
import { useDeleteProductMutation } from "store/productGroup/productGroupServices";
import { useSelectTableData } from "hooks/useSelectTableData";
import ServicesTableHead from "./ServicesTableHead";
import { type TableData } from "types/tables";
import AddEditServiceModal from "../AddEditServiceModal";
import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";
import { useTrialUser } from "hooks/useTrialUser";
import LimitedAccessTooltip from "components/Freelancer/LimitedAccessTooltip";

export interface ServicesTableData {
  name: string;
  price: string;
}

interface ServicesTableProps {
  services?: ProductAsService[];
  refetch: () => void;
  openAddServiceModal: () => void;
}

const ServicesTable: FC<ServicesTableProps> = ({
  services,
  refetch,
  openAddServiceModal,
}) => {
  const [deleteServiceItem, setDeleteServiceItem] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof ServicesTableData>("name");
  const { t } = useTranslation();
  const [editing, setEditing] = useState<ProductAsService | null>(null);
  const { isMobile } = useScreenSize();
  const [deleteService] = useDeleteProductMutation();
  const { currency } = useFreelancerDefaults();
  const { limitedAccess } = useTrialUser();
  const {
    isOpen: isDeleteModalOpen,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useDrawer();

  const deleteServiceHandle = async () => {
    await deleteService(deleteServiceItem);
    closeDeleteModal();
    refetch();
  };

  const tableData: TableData<ProductAsService>[] = [
    {
      name: "",
      rows: services ?? [],
    },
  ];

  const handleRequestSort = (
    event: MouseEvent,
    property: keyof ServicesTableData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const { isSelected, handleClick } = useSelectTableData(tableData);

  const handleModalClose = useCallback(() => {
    setEditing(null);
    setOpenEditModal((prev) => !prev);
    refetch();
  }, [refetch]);
  const cellWidth = isMobile ? 200 : 400;

  return (
    <StyledTableContainer>
      <Table aria-labelledby="tableTitle">
        <ServicesTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        {!!services?.length && (
          <TableBody>
            {tableData?.map(({ rows }, index) => (
              <TableRowStyled withBorder={index !== 0}>
                <TableCell style={{ padding: 0 }} colSpan={12}>
                  <Table>
                    <ServicesTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      collapse
                    />
                    <TableBody>
                      {!!rows &&
                        stableSort<ProductAsService>(
                          rows,
                          getComparator(order, orderBy)
                        ).map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              onClick={(event) => handleClick(event, row.id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              <TableCell
                                width={cellWidth}
                                scope="row"
                                id={labelId}
                              >
                                {row.name}
                              </TableCell>
                              {!isMobile && (
                                <>
                                  <TableCell width={cellWidth} />
                                  <TableCell width={cellWidth} />
                                  <TableCell width={cellWidth} />
                                  <TableCell width={cellWidth} />
                                </>
                              )}
                              <TableCell width={cellWidth}>
                                {`${row.price} (${currency})`}
                              </TableCell>
                              <TableCell>
                                {limitedAccess ? (
                                  <LimitedAccessTooltip>
                                    <MenuButton>
                                      <MoreSetingsIcon />
                                    </MenuButton>
                                  </LimitedAccessTooltip>
                                ) : (
                                  <Tooltip
                                    arrow
                                    placement="bottom"
                                    tooltipText=""
                                    tooltipContent={
                                      <MenuList disablePadding>
                                        <MenuListItem disablePadding>
                                          <MenuListItemButton
                                            onClick={() => {
                                              setEditing(row);
                                              setOpenEditModal((prev) => !prev);
                                            }}
                                          >
                                            <Text variant="regularText">
                                              {t("Редагувати")}
                                            </Text>
                                          </MenuListItemButton>
                                        </MenuListItem>
                                        <MenuListItem disablePadding>
                                          <MenuListItemButton
                                            onClick={() => {
                                              setDeleteServiceItem(
                                                row?.id as string
                                              );
                                              openDeleteModal();
                                            }}
                                          >
                                            <Text
                                              variant="regularText"
                                              className="red"
                                            >
                                              {t("Видалити")}
                                            </Text>
                                          </MenuListItemButton>
                                        </MenuListItem>
                                      </MenuList>
                                    }
                                  >
                                    <MenuButton>
                                      <MoreSetingsIcon />
                                    </MenuButton>
                                  </Tooltip>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRowStyled>
            ))}
          </TableBody>
        )}
      </Table>
      {!services?.length && (
        <NoDataContainerStyled>
          <Button
            onClick={openAddServiceModal}
            variant="textIcon"
            className="toolbar-btns create-btn"
            startIcon={<PlusIcon />}
          >
            {t("freelancer.page.services.addFirst.label")}
          </Button>
        </NoDataContainerStyled>
      )}
      <AddEditServiceModal
        isOpen={openEditModal}
        service={editing}
        onClose={handleModalClose}
      />
      <ConfirmationModal
        deleteHandler={deleteServiceHandle}
        onClose={closeDeleteModal}
        isOpen={isDeleteModalOpen}
        title={t("Видалити") + "?"}
      />
    </StyledTableContainer>
  );
};

export default ServicesTable;
