import React, { FC } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { InputAdornment } from "@mui/material";
// Store
import {
  ICreateLaborEmployee,
  ICreateEmployeeForm,
} from "store/tutors/tutorsTypes";
// Components
import Modal from "components/UI/Modal";
import Input from "components/UI/Input";
import Select from "components/UI/Select";
import { ClockIcon } from "assets/icons";
// Styles
import {
  Container,
  Title,
  ContainerButton,
  Button,
  InlineFormWrap,
} from "./styles";
import "react-datepicker/dist/react-datepicker.css";
// Translation
import { useTranslation } from "react-i18next";
interface AddEmployeeModalProps {
  addedEmployee: (employee: ICreateLaborEmployee) => void;
  employees: ICreateEmployeeForm[];
  departmen: string;
  isOpen: boolean;
  onClose: () => void;
  formStateArray: any;
}

interface FormValues {
  employee_id: string;
  amount: string;
}

const AddEmployeeModal: FC<AddEmployeeModalProps> = ({
  isOpen,
  onClose,
  departmen,
  employees,
  addedEmployee,
  formStateArray,
}) => {
  const { t } = useTranslation();
  const formState = useForm({
    defaultValues: {
      employee_id: "",
      amount: "",
    },
    mode: "all",
  });

  const dataSelect = () => {
    const filterByReference = (arr1: Array<any>, arr2: Array<any>) => {
      let res = [];
      res = arr1.filter((el) => {
        return !arr2.find((element) => {
          return element.employee_id === el.employee_id;
        });
      });
      return res;
    };
    const filteredArr = filterByReference(employees, formStateArray.fields);
    const empls = filteredArr.map((item) => ({
      value: item.employee_id,
      label: item.name,
    }));

    return empls;
  };

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    const empl = employees.find(
      (item) => item.employee_id === values.employee_id
    );

    addedEmployee({
      employee_id: values.employee_id,
      amount: values.amount,
      name: empl?.name || "",
    });

    formState.reset();
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Container>
        <Title variant="h1">{t("Додати співробітника")}</Title>

        <Input fullWidth label={t("Підрозділ")} value={t(departmen)} />

        <Controller
          render={({ field }) => (
            <Select
              label={t("Співробітник")}
              data={dataSelect()}
              className="amount-input"
              error={!!formState.formState.errors?.employee_id?.message}
              errorMessage={formState.formState.errors?.employee_id?.message}
              {...field}
            />
          )}
          control={formState.control}
          name="employee_id"
          rules={{ required: t("fieldIsRequired") }}
        />

        <InlineFormWrap>
          <Controller
            render={({ field }) => (
              <Input
                label={t("Витрачено годин")}
                type="number"
                placeholder="0"
                className="inline-input time-input"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ClockIcon />
                    </InputAdornment>
                  ),
                }}
                error={!!formState.formState.errors?.amount?.message}
                helperText={formState.formState.errors?.amount?.message}
                {...field}
              />
            )}
            control={formState.control}
            name="amount"
            rules={{ required: t("Витрачено годин") }}
          />
        </InlineFormWrap>

        <ContainerButton>
          <Button
            variant="contained"
            onClick={formState.handleSubmit(onSubmit)}
            disabled={!formState.formState.isValid}
          >
            {t("Додати")}
          </Button>

          <Button variant="light" onClick={onClose}>
            {t("Скасувати")}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default AddEmployeeModal;
