import { type FC, type SyntheticEvent } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "assets/icons";
import {
  StyledTabs,
  StyledTab,
  HeaderRightBlock,
} from "pages/ProductsAndServices/TableHeaderSection/styles";
import { HeaderRowStyled } from "./ProjectsTable/styles";
import { useDrawer } from "hooks/useDrawer";
import ProjectTypeModal from "./ProjectTypeModal";
import { useTrialUser } from "hooks/useTrialUser";
import LimitedAccessTooltip from "../LimitedAccessTooltip";
import { ButtonDisabledStyled } from "./styles";

interface TableHeaderSectionProps {
  handleChangeTabMode: (event: SyntheticEvent, newValue: number) => void;
  tabMode: number;
}

const TableHeaderSection: FC<TableHeaderSectionProps> = ({
  handleChangeTabMode,
  tabMode,
}) => {
  const { t } = useTranslation();
  const { open, isOpen, close } = useDrawer();
  const { limitedAccess } = useTrialUser();

  return (
    <HeaderRowStyled className="tabs-row">
      <StyledTabs value={tabMode} onChange={handleChangeTabMode}>
        <StyledTab label={t("Активні")} />
        <StyledTab label={t("archive")} />
      </StyledTabs>
      <HeaderRightBlock className="right-block">
        {limitedAccess ? (
          <LimitedAccessTooltip>
            <ButtonDisabledStyled
              disabled
              onClick={open}
              variant="textIcon"
              className="toolbar-btns create-btn"
              startIcon={<PlusIcon />}
            >
              {t("Проекти")}
            </ButtonDisabledStyled>
          </LimitedAccessTooltip>
        ) : (
          <Button
            onClick={open}
            variant="textIcon"
            className="toolbar-btns create-btn"
            startIcon={<PlusIcon />}
          >
            {t("Проекти")}
          </Button>
        )}
      </HeaderRightBlock>
      <ProjectTypeModal isOpen={isOpen} onClose={close} />
    </HeaderRowStyled>
  );
};

export default TableHeaderSection;
