import type { ProjectPhaseCreate } from "interfaces/freelancer/project";

export const getPhasesToUpsert = (
  phases: ProjectPhaseCreate[],
  initialPhases: ProjectPhaseCreate[] = []
): {
  phasesToUpdate: ProjectPhaseCreate[];
  newPhases: ProjectPhaseCreate[];
} => {
  const phasesToUpdate: ProjectPhaseCreate[] = [];
  const newPhases: ProjectPhaseCreate[] = [];
  phases.forEach((phase) => {
    const [existingPhase] = initialPhases?.filter(({ id }) => phase.id === id);
    const existingPhaseWithPromotedFlag = {
      ...existingPhase,
      isPromoted: phase.isPromoted,
    };
    if (
      existingPhase &&
      JSON.stringify(phase) !== JSON.stringify(existingPhaseWithPromotedFlag)
    ) {
      phasesToUpdate.push(phase);
    }
    if (!existingPhase) {
      newPhases.push(phase);
    }
  });

  return { newPhases, phasesToUpdate };
};
