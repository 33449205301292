import { styled } from "@mui/material/styles";
import { Box, Button as StyledButton } from "@mui/material";

interface DateTextProps {
  isEndFreePeriod?: boolean;
}

export const Container = styled(Box)`
  display: flex;
  align-items: center;
`;

export const ContainerIcon = styled(Box)`
  height: 40px;
  margin-right: 10px;
  margin-left: 10px;
  position: relative;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export const DateText = styled(Box)<DateTextProps>`
  position: absolute;
  font-family: "Visuelt Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${({ isEndFreePeriod }) => (isEndFreePeriod ? "#D52727" : "#07B169")};
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Image = styled("img")`
  height: 40px;
`;

export const Button = styled(StyledButton)`
  margin-right: 10px;
  height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  width: 100px;
`;

export const WrapperTooltip = styled(Box)`
  margin-right: 10px;
  height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  width: 100px;
`;
