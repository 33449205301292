import { ThemeOptions } from '@mui/material';
import VisueltPro from 'assets/fonts/VisueltPro.woff';

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    regularText: React.CSSProperties;
    generalRegularText: React.CSSProperties;
    smallRegularText: React.CSSProperties;
    boldRegularText: React.CSSProperties;
    tableSubheaderText: React.CSSProperties;
    boldH6: React.CSSProperties;
    jumboTitle: React.CSSProperties;
  }

  interface TypographyOptions {
    regularText: React.CSSProperties;
    smallRegularText: React.CSSProperties;
    generalRegularText: React.CSSProperties;
    boldRegularText: React.CSSProperties;
    tableSubheaderText: React.CSSProperties;
    boldH6: React.CSSProperties;
    jumboTitle: React.CSSProperties;
  }
}

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    regularText: true;
    smallRegularText: true;
    generalRegularText: true;
    boldRegularText: true;
    tableSubheaderText: true;
    boldH6: true;
    jumboTitle: true;
  }
}

export const VisueltProFont = {
  fontFamily: 'Visuelt Pro',
  fontDisplay: 'swap',
  src: `local('Visuelt Pro'), url(${VisueltPro}) format('woff')`,
};

export const typography: ThemeOptions['typography'] = {
  fontFamily: 'Visuelt Pro',

  h1: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '34px',
  },
  h2: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '30px',
  },
  h3: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
  },
  h4: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '30px',
  },
  h5: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  h6: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  boldH6: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 700,
  },
  boldRegularText: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
  },

  regularText: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 'normal',
  },

  smallRegularText: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 'normal',
  },

  generalRegularText: {
    fontSize: '80px',
    lineHeight: '103px',
    fontWeight: 400,
  },
  tableSubheaderText: {
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: 700,
  },
  jumboTitle: {
    fontSize: '44px',
    lineHeight: '56px',
    fontWeight: 700,
  },
};
