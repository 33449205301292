import { Typography } from "@mui/material";
import {
  type Control,
  Controller,
  useFieldArray,
  UseFieldArrayAppend,
  UseFormWatch,
} from "react-hook-form";
import {
  DeleteIconContainerStyled,
  ExpenseGroupContainerStyled,
  InputLabelStyled,
  InputRowStyled,
  InputStyled,
  LabelRowStyled,
  LinkContainerStyled,
  LinkStyled,
  NameStyled,
  RowStyled,
  CreateNewLinkContainerStyled,
} from "../styles";
import { palette } from "styles/theme/palette";
import React, { type FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDrawer } from "hooks/useDrawer";
import { type Employee } from "store/employees/employeesTypes";
import {
  type ProjectCreateForm,
  type ProjectPhaseEmployee,
} from "interfaces/freelancer/project";
import SelectTeammateModal from "./SelectTeammateModal";
import { DeleteIcon, InfoIcon } from "assets/icons";
import { useScreenSize } from "hooks/useScreenSize";
import { NoTableDataTextStyled } from "../../../../styles";
import { useAuth } from "hooks/useAuth";
import { EXPENSE_STATUS } from "utils/freelancer/constants";
import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";
import { getNumber } from "utils/freelancer/helpers/getNumber";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "components/UI/Tooltip";

interface EmployeesProps {
  parentIndex: number;
  control: Control<ProjectCreateForm>;
  initialEmployees?: ProjectPhaseEmployee[];
  watch: UseFormWatch<ProjectCreateForm>;
  onExpenseRemove: UseFieldArrayAppend<ProjectCreateForm, "removedExpenses">;
  onAddNewTeammateClick?: () => void;
  ableToSaveProject?: boolean;
}

const Employees: FC<EmployeesProps> = ({
  parentIndex,
  control,
  initialEmployees,
  watch,
  onExpenseRemove,
  onAddNewTeammateClick,
  ableToSaveProject,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const { user } = useAuth();
  const { employeeName } = useFreelancerDefaults();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: `phases.${parentIndex}.expenses.employees` as const,
  });
  const watchEmployees = watch(`phases.${parentIndex}.expenses.employees`);
  const controlledEmployees = fields.map((field, index) => {
    return {
      ...field,
      ...watchEmployees[index],
    };
  });

  useEffect(() => {
    if (!!initialEmployees?.length) {
      replace(initialEmployees);
    }
  }, []);
  const {
    isOpen: isOpenEmployeesModal,
    open: openEmployeesModal,
    close: closeEmployeesModal,
  } = useDrawer();

  const handleCloseEmployeesModal = useCallback(
    (selectedEmployees?: Employee[]) => {
      selectedEmployees?.forEach(({ name, id, wage }) =>
        append({
          name,
          amount: wage ?? 0,
          hours: 0,
          billableHours: 0,
          employeeId: id,
          status: EXPENSE_STATUS.DRAFT,
          id: uuidv4(),
        })
      );

      closeEmployeesModal();
    },
    []
  );
  const removeEmployee = useCallback(
    ({ currentTarget }) => {
      const employeeIndex = currentTarget.getAttribute("data-employee-index");
      if (employeeIndex) {
        remove(employeeIndex);
        if (controlledEmployees[employeeIndex]?.expenseId) {
          onExpenseRemove({
            id: controlledEmployees[employeeIndex].expenseId ?? "",
          });
        }
      }
    },
    [remove, onExpenseRemove, controlledEmployees]
  );
  const employeeIdsToExclude = fields.map(({ employeeId }) => employeeId);

  const getEmployeeName = useCallback(
    (name: string) => (user?.user_id === name ? employeeName : name),
    [user, employeeName]
  );

  const handleNewTeammateClick = useCallback(() => {
    if (ableToSaveProject) {
      onAddNewTeammateClick?.();
    }
  }, [ableToSaveProject, onAddNewTeammateClick]);

  return (
    <ExpenseGroupContainerStyled>
      <Typography variant="h1">{t("team")}</Typography>
      {!!controlledEmployees?.length && !isMobile && (
        <LabelRowStyled>
          <Typography
            variant="regularText"
            color={palette.custom.primary.silver}
          >
            {t("freelancer.page.addEditProject.employee.name.label")}
          </Typography>
          <RowStyled>
            <InputLabelStyled variant="regularText">
              {t("freelancer.page.addEditProject.employee.amount.label")}
            </InputLabelStyled>
            <InputLabelStyled variant="regularText">
              {t("freelancer.page.addEditProject.employee.hours.label")}
            </InputLabelStyled>
          </RowStyled>
        </LabelRowStyled>
      )}
      {!!controlledEmployees?.length ? (
        controlledEmployees?.map((item, nestedIndex) => (
          <RowStyled key={item.id}>
            <NameStyled variant="body1">
              {getEmployeeName(item.name)}
            </NameStyled>
            <InputRowStyled>
              <Controller
                name={`phases.${parentIndex}.expenses.employees.${nestedIndex}.amount`}
                control={control}
                rules={{ required: t("fieldIsRequired") }}
                render={({ field }) => (
                  <InputStyled
                    {...field}
                    type="number"
                    placeholder={t(
                      "freelancer.page.addEditProject.employee.amount.placeholder"
                    )}
                  />
                )}
              />
              <Controller
                name={`phases.${parentIndex}.expenses.employees.${nestedIndex}.hours`}
                control={control}
                rules={{ required: t("fieldIsRequired") }}
                render={({ field }) => (
                  <InputStyled
                    {...field}
                    type="number"
                    placeholder={t(
                      "freelancer.page.addEditProject.employee.hours.placeholder"
                    )}
                  />
                )}
              />
              <DeleteIconContainerStyled
                data-employee-index={nestedIndex}
                onClick={removeEmployee}
              >
                <DeleteIcon />
              </DeleteIconContainerStyled>
            </InputRowStyled>
          </RowStyled>
        ))
      ) : (
        <NoTableDataTextStyled variant="h2">
          {t("freelancer.page.addEditProject.employee.noData")}
        </NoTableDataTextStyled>
      )}
      <LinkContainerStyled>
        <LinkStyled
          underline="none"
          onClick={openEmployeesModal}
          color={palette.custom.green.dark}
        >
          {t("selectTeammate")}
        </LinkStyled>
        <CreateNewLinkContainerStyled>
          <LinkStyled
            disabled={!ableToSaveProject}
            underline="none"
            onClick={handleNewTeammateClick}
            color={palette.custom.green.dark}
          >
            {t("createTeammate")}
          </LinkStyled>
          <Tooltip
            tooltipText={t(
              "freelancer.page.addEditProject.employee.tooltip.title"
            )}
            placement="bottom-end"
          >
            <InfoIcon />
          </Tooltip>
        </CreateNewLinkContainerStyled>
      </LinkContainerStyled>
      <SelectTeammateModal
        onClose={handleCloseEmployeesModal}
        isOpen={isOpenEmployeesModal}
        idsToExclude={employeeIdsToExclude}
      />
    </ExpenseGroupContainerStyled>
  );
};

export default Employees;
