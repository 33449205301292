import {
  type ProjectCreate,
  type ProjectPhaseCreate,
} from "interfaces/freelancer/project";
import { EXPENSE_STATUS } from "../constants";
import { getPhaseExpenses } from "./getPhaseExpenses";

export const mapPhases = (
  phases: ProjectPhaseCreate[],
  currency: string,
  clientId: string,
  productGroup: string,
  status: EXPENSE_STATUS,
  projectType?: string
): ProjectCreate[] =>
  phases.map((phase) => {
    const phaseExpenses = getPhaseExpenses(phase, currency);
    return {
      name: phase.name,
      group: productGroup,
      amount: 0,
      price: 0,
      expenses: phaseExpenses,
      extras: {
        type: projectType,
        phaseDescription: phase.description,
        startDate: phase.startDate,
        endDate: phase.endDate,
        clientId,
        phaseStatus: status,
        twin_id: phase.twin_id,
      },
    };
  });
