import { type FC, useCallback } from "react";
import { freelanceUserRoutesPath } from "routes/routePath";
import Preview from "../AddEdit/Preview";
import { useNavigate } from "react-router";
import { Container } from "pages/ProductsAndServices/styles";
import { useExistingInvoice } from "hooks/freelancer/useExistingInvoice";
import Loading from "components/UI/Loading";
import { useSearchParams } from "react-router-dom";
import { URL_PARAMS } from "utils/freelancer/constants";

const PreviewPage: FC = () => {
  const [searchParams] = useSearchParams();
  const invoiceId = searchParams.get(URL_PARAMS.INVOICE_ID) ?? "";
  const projectId = searchParams.get(URL_PARAMS.PROJECT_ID) ?? "";
  const existingInvoice = useExistingInvoice(invoiceId);
  const navigate = useNavigate();
  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (!existingInvoice) {
    return <Loading />;
  }

  return (
    <Container>
      <Preview
        values={existingInvoice}
        onCancel={goBack}
        from={freelanceUserRoutesPath.INVOICES}
        projectId={projectId}
      />
    </Container>
  );
};

export default PreviewPage;
