import { useTranslation } from "react-i18next";
import { type FC } from "react";

import Modal from "components/UI/Modal";
import {
  Button,
  ContainerButton,
  Title,
} from "pages/Teams/AddEmployeeModal/styles";
import { AddHoursContainerStyled } from "../../styles";
import {
  ClockifyProjectWorkspace,
  type TimeLog,
} from "store/timeLog/timeLogTypes";
import { StyledSelect } from "./styles";
import {
  ProjectPhaseEmployee,
  ProjectPhaseCreate,
} from "interfaces/freelancer/project";
import { ClockifyIntegrationConfig } from "store/productGroup/productGroupTypes";
import { useClockifyTimeTrackerModalConfig } from "hooks/useClockifyTimeTrackerModalConfig";

interface AddClockifyTimeTrackerModalModalProps {
  clockifyProjectsWorkspaces: ClockifyProjectWorkspace[];
  isOpen: boolean;
  selectedEmployee: ProjectPhaseEmployee;
  onClose: () => void;
  clockifyIntegrationConfig?: ClockifyIntegrationConfig;
  timeLogData?: TimeLog[];
  refetchTimeLogData: () => void;
  refetchClockifyEmployeesTimeEntries: () => void;
  phase: ProjectPhaseCreate;
}

const AddClockifyTimeTrackerModal: FC<
  AddClockifyTimeTrackerModalModalProps
> = ({
  clockifyProjectsWorkspaces,
  isOpen,
  selectedEmployee,
  onClose,
  clockifyIntegrationConfig,
  timeLogData,
  refetchTimeLogData,
  refetchClockifyEmployeesTimeEntries,
  phase,
}) => {
  const {
    workspaceOptions,
    projectOptions,
    employeeOptions,

    selectedWorkspace,
    selectedProject,
    selectedClockifyEmployee,

    handleWorkspaceChange,
    handleProjectChange,
    handleEmployeeChange,
    handleSubmit,
    closeModal,
  } = useClockifyTimeTrackerModalConfig({
    timeLogData,
    selectedEmployee,
    clockifyProjectsWorkspaces,
    onClose,
    refetchTimeLogData,
    refetchClockifyEmployeesTimeEntries,
    clockifyIntegrationConfig,
    phase,
  });

  const { t } = useTranslation();

  return (
    <Modal onClose={closeModal} isOpen={isOpen}>
      <AddHoursContainerStyled>
        <Title variant="h1">
          {t("freelancer.page.addEditProject.employee.addTimeTracker.title")}
        </Title>
        <StyledSelect
          data={workspaceOptions}
          label={t(
            "freelancer.page.addEditProject.employee.addTimeTracker.selectWorkspace"
          )}
          value={selectedWorkspace}
          onChange={handleWorkspaceChange}
        />
        <StyledSelect
          disabled={!projectOptions.length}
          data={projectOptions}
          label={t(
            "freelancer.page.addEditProject.employee.addTimeTracker.selectProject"
          )}
          value={selectedProject}
          onChange={handleProjectChange}
        />
        <StyledSelect
          disabled={!employeeOptions.length}
          data={employeeOptions}
          label={t(
            "freelancer.page.addEditProject.employee.addTimeTracker.selectEmployee"
          )}
          value={selectedClockifyEmployee}
          onChange={handleEmployeeChange}
        />
        <ContainerButton>
          <Button
            disabled={selectedClockifyEmployee === ""}
            variant="contained"
            component="span"
            onClick={handleSubmit}
          >
            {t(
              "freelancer.page.addEditProject.employee.addTimeTracker.confirmButtonLabel"
            )}
          </Button>

          <Button variant="light" onClick={closeModal}>
            {t("Скасувати")}
          </Button>
        </ContainerButton>
      </AddHoursContainerStyled>
    </Modal>
  );
};

export default AddClockifyTimeTrackerModal;
