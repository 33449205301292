import type { PhaseExpense, ProductAsPhase } from "interfaces/product";
import type {
  ProjectPhaseAdditionalExpense,
  ProjectPhaseEmployee,
  ProjectPhaseService,
} from "interfaces/freelancer/project";
import { getNumber } from "../helpers/getNumber";
import { EXPENSE_STATUS } from "../constants";

export const getExpenses = (
  expenses: PhaseExpense[]
): {
  employees: ProjectPhaseEmployee[];
  services: ProjectPhaseService[];
  additionalExpenses: ProjectPhaseAdditionalExpense[];
} => {
  return {
    employees: expenses
      .filter((expense) => expense.expense_group.name === "employees")
      .map((expense) => ({
        name: expense.name,
        employeeId: expense.extras?.employeeId ?? expense.product_id,
        status: expense.status,
        expense_group_id: expense.expense_group.expense_group_id,
        amount: getNumber(expense.value),
        hours: expense.amount,
        billableHours: expense.amount,
      })),
    services: expenses
      .filter((expense) => expense.expense_group.name === "services")
      .map((expense) => ({
        name: expense.name,
        price: getNumber(expense.value),
        status: expense.status,
        serviceId: expense.product_id,
        expense_group_id: expense.expense_group.expense_group_id,
      })),
    additionalExpenses: expenses
      .filter((expense) => expense.expense_group.name === "additionalExpenses")
      .map((expense) => ({
        name: expense.name,
        amount: getNumber(expense.value),
        status: expense.status,
        expense_group_id: expense.expense_group.expense_group_id,
      })),
  };
};

export const getMappedPhases = (phases: ProductAsPhase[]) =>
  phases?.map(({ name, expenses, id, group_entity, extras }) => {
    return {
      name,
      startDate: extras?.startDate ?? new Date(),
      endDate: extras?.endDate ?? new Date(),
      description: extras?.phaseDescription,
      id,
      groupId: group_entity?.id,
      status: extras?.phaseStatus ?? EXPENSE_STATUS.DRAFT,
      clientId: extras.clientId,
      expenses: getExpenses(expenses),
    };
  });
