import { useAllInvoices } from "./useAllInvoices";
import { type TableInvoice } from "interfaces/freelancer/invoice";
import { INVOICE_STATE } from "utils/freelancer/constants";
import { getNumber } from "utils/freelancer/helpers/getNumber";
import { useMemo } from "react";

interface ProjectInvoiceReturn {
  projectInvoices: TableInvoice[];
  paymentsReceived: number;
}
export const useProjectInvoices = (
  projectId?: string
): ProjectInvoiceReturn => {
  const { activeInvoices } = useAllInvoices();
  const projectInvoices = useMemo(
    () =>
      activeInvoices.filter(
        ({ projectId: invoiceProjectId }) => invoiceProjectId === projectId
      ) ?? [],
    [activeInvoices, projectId]
  );
  const paymentsReceived = useMemo(
    () =>
      projectInvoices
        ?.filter(({ state }) => state === INVOICE_STATE.PAID)
        .reduce(
          (partialSum, { invoiced }) => partialSum + getNumber(invoiced),
          0
        ),
    [projectInvoices]
  );

  return { projectInvoices, paymentsReceived };
};
