import { styled } from "@mui/material/styles";
import { Box, Breakpoint, Link } from "@mui/material";
import { palette } from "styles/theme/palette";
import { PAGE_CONTENT_WIDTH_NUMBER } from "styles/theme/constants";

export const NoDataContainerStyled = styled(Box)(() => ({
  minHeight: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

export const ColumnStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "50%",
  flex: "0 0 500px",
  padding: "0 8px",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    flexBasis: "calc(100vw - 16px)",
  },
}));

export const ContentContainerStyled = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  maxWidth: PAGE_CONTENT_WIDTH_NUMBER,
}));

export const ContentBoxStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: palette.common.white,
  borderRadius: "19px",
  padding: "14px 16px",
  border: `1px solid ${palette.primary.main}`,
  maxWidth: "100%",
  width: PAGE_CONTENT_WIDTH_NUMBER / 2,
  margin: "8px 0",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    width: "100%",
  },
}));

export const TransactionsContentBoxStyled = styled(ContentBoxStyled)(() => ({
  minHeight: "360px",
}));
export const LoadingContainerStyled = styled(Box)(() => ({
  margin: "120px auto",
}));
export const EditTransactionContentContainerStyled = styled(Box)(() => ({
  width: "100%",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
}));

export const GraphContainerStyled = styled(Box)(() => ({
  flex: "0 0 100%",
  minHeight: "360px",
}));

export const LinkStyled = styled(Link)(() => ({
  justifyContent: "flex-end",
  display: "flex",
  padding: "15px 15px 0",
  cursor: "pointer",
  color: palette.custom.grey.light,
  "&:hover": {
    transition: "color 300ms ease",
    color: palette.custom.grey.main,
  },
}));
