import dayjs from "dayjs";

interface DefaultDateValues {
  defaultStartDate: Date;
  defaultEndDate: Date;
}
export const getDefaultDateValues = (): DefaultDateValues => {
  const endOfMonth = dayjs().endOf("month").toDate();
  const startOfMonth = dayjs().startOf("month").toDate();
  const defaultStartDate: Date = localStorage.getItem("periodStartDate")
    ? dayjs(JSON.parse(localStorage.getItem("periodStartDate") ?? "")).toDate()
    : startOfMonth;
  const defaultEndDate: Date = localStorage.getItem("periodEndDate")
    ? dayjs(JSON.parse(localStorage.getItem("periodEndDate") ?? "")).toDate()
    : endOfMonth;

  return { defaultStartDate, defaultEndDate };
};
