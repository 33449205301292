import { useCallback, useEffect, useState } from "react";
import { freelanceUserRoutesPath } from "routes/routePath";
import {
  useCreateProductExpenseMutation,
  useCreateProductMutation,
  useDeleteProductMutation,
  useUpdateProductByIdMutation,
  useUpdateProductExpenseByIdMutation,
  useUpdateProductGroupMutation,
} from "store/productGroup/productGroupServices";
import { useNavigate } from "react-router";
import { useDeleteExpensesMutation } from "store/tutors/tutorsServices";
import type {
  ProjectCreate,
  ProjectCreateForm,
  ProjectPhaseCreate,
  ProjectWithPhases,
} from "interfaces/freelancer/project";
import { mapPhases } from "utils/freelancer/helpers/mapPhases";
import {
  ALERT_SEVERITY,
  EXPENSE_STATUS,
  PRODUCT_GROUP_STATUS,
  URL_PARAMS,
} from "utils/freelancer/constants";
import { useFreelancerDefaults } from "../useFreelancerDefaults";
import { type UseFormGetValues } from "react-hook-form";
import { type Option } from "types/select";
import { useTranslation } from "react-i18next";
import { projectCreate } from "utils/freelancer/helpers/projectCreate";
import { getPhasesToUpsert } from "utils/freelancer/helpers/getPhasesToUpsert";
import { updatePhases } from "utils/freelancer/helpers/updatePhases";
import { updateExpenses } from "utils/freelancer/helpers/updateExpenses";
import { useProjectsWithPhases } from "./useProjectsWithPhases";
import { useSearchParams } from "react-router-dom";

export const useProjectUpsert = (
  getValues: UseFormGetValues<ProjectCreateForm>,
  setAlertSeverity: (text: ALERT_SEVERITY) => void,
  setAlertMessage: (text: string) => void,
  openAlert: () => void,
  projectType?: Option,
  initialProject?: ProjectWithPhases,
  initialPhases?: ProjectPhaseCreate[],
  initialWorkPhases?: ProjectPhaseCreate[],
  navigateOut = false
) => {
  const { t } = useTranslation();
  const { project_id, pipeline_id, currency } = useFreelancerDefaults();
  const { activeProjectsWithPhases, archiveProjectsWithPhases } =
    useProjectsWithPhases();
  const navigate = useNavigate();
  const navigateToProjects = useCallback(
    () => navigate(freelanceUserRoutesPath.PROJECTS),
    [navigate, freelanceUserRoutesPath]
  );
  const [createPhase] = useCreateProductMutation();
  const [updatePhaseExpense] = useUpdateProductExpenseByIdMutation();
  const [createPhaseExpense] = useCreateProductExpenseMutation();
  const [updatePhaseById] = useUpdateProductByIdMutation();
  const [updateProject] = useUpdateProductGroupMutation();
  const [deletePhase] = useDeleteProductMutation();
  const [deletePhaseExpense] = useDeleteExpensesMutation();
  const [createdGroupId, setCreatedGroupId] = useState<string>();
  const [existingProject, setExistingProject] = useState<
    ProjectWithPhases | undefined
  >(initialProject);
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (createdGroupId) {
      const [created] = activeProjectsWithPhases.filter(
        ({ groupId }) => groupId === createdGroupId
      );
      setExistingProject(created);
      if (created?.id) {
        setSearchParams({ [URL_PARAMS.PROJECT_ID]: created.id });
      }
    }
  }, [createdGroupId, activeProjectsWithPhases]);

  return async () => {
    const {
      phases,
      workPhases,
      clientId,
      projectName,
      removedPhases,
      removedExpenses,
    } = getValues();
    const productGroup = `projects@${projectName}@phases`;
    const mappedPhases: ProjectCreate[] = mapPhases(
      phases,
      currency,
      clientId,
      productGroup,
      EXPENSE_STATUS.DRAFT,
      projectType?.value
    );
    const mappedWorkPhases: ProjectCreate[] = mapPhases(
      workPhases,
      currency,
      clientId,
      productGroup,
      EXPENSE_STATUS.SUBMITTED,
      projectType?.value
    );

    try {
      // Remove phases(DELETE product/id)
      if (existingProject?.id && removedPhases.length) {
        try {
          await Promise.all(
            removedPhases.map(({ id }) => (id ? deletePhase(id) : null))
          );
        } catch (e) {
          setAlertSeverity(ALERT_SEVERITY.ERROR);
          console.error("Error on remove phase, ", e);
        }
      }

      // Remove phase expenses(DELETE expenses/id)
      if (existingProject?.id && removedExpenses?.length) {
        try {
          await Promise.all(
            removedExpenses.map(({ id }) =>
              id ? deletePhaseExpense(id) : null
            )
          );
        } catch (e) {
          setAlertSeverity(ALERT_SEVERITY.ERROR);
          console.error("Error on remove phase expenses", e);
        }
      }

      // Update Project name(PATCH product-group/id)
      if (
        existingProject?.id &&
        (projectName !== existingProject?.name ||
          (clientId?.length && clientId !== existingProject.client?.id))
      ) {
        try {
          await updateProject({
            id: existingProject.id,
            body: {
              status: PRODUCT_GROUP_STATUS.ACTIVE,
              name: projectName,
              extras: { clientId },
            },
          });
        } catch (e) {
          setAlertSeverity(ALERT_SEVERITY.ERROR);
          console.error("Error on project name update, ", e);
        }
      }

      // Create new Project with Phases(POST products + product-group)
      if (!existingProject && !initialPhases?.length) {
        const allProjectNames = [
          ...activeProjectsWithPhases,
          ...archiveProjectsWithPhases,
        ].map(({ name }) => name.toLowerCase());
        const nameAlreadyExists = allProjectNames.includes(
          projectName.toLowerCase()
        );
        if (nameAlreadyExists) {
          setAlertMessage(t("projectNameAlreadyExistsError"));
          openAlert();
          return;
        } else {
          const createdGroupId = await projectCreate(
            mappedPhases,
            mappedWorkPhases,
            createPhase,
            setAlertSeverity,
            project_id,
            pipeline_id
          );
          setCreatedGroupId(createdGroupId);
        }
      } else {
        const { newPhases, phasesToUpdate } = getPhasesToUpsert(
          phases,
          initialPhases
        );
        const { newPhases: newWorkPhases, phasesToUpdate: workPhasesToUpdate } =
          getPhasesToUpsert(workPhases, initialWorkPhases);

        // Update phases(PATCH product/id) and update expenses (PATCH /expenses/id)
        if (phasesToUpdate?.length) {
          await updatePhases(
            phasesToUpdate,
            pipeline_id,
            updatePhaseById,
            setAlertSeverity,
            projectType?.value
          );
          await updateExpenses(
            phasesToUpdate,
            currency,
            updatePhaseExpense,
            createPhaseExpense,
            setAlertSeverity,
            initialPhases
          );
        }

        // Update work in process phases(PATCH product/id)
        if (workPhasesToUpdate?.length) {
          await updatePhases(
            workPhasesToUpdate,
            pipeline_id,
            updatePhaseById,
            setAlertSeverity,
            projectType?.value
          );
          await updateExpenses(
            workPhasesToUpdate,
            currency,
            updatePhaseExpense,
            createPhaseExpense,
            setAlertSeverity,
            initialWorkPhases
          );
        }

        // Create new project phases(POST products + product-group)
        if (newPhases?.length || newWorkPhases?.length) {
          const mappedNewPhases: ProjectCreate[] = mapPhases(
            newPhases,
            currency,
            clientId,
            productGroup,
            EXPENSE_STATUS.DRAFT,
            projectType?.value
          );
          const mappedNewWorkPhases: ProjectCreate[] = mapPhases(
            newWorkPhases,
            currency,
            clientId,
            productGroup,
            EXPENSE_STATUS.SUBMITTED,
            projectType?.value
          );
          await projectCreate(
            mappedNewPhases,
            mappedNewWorkPhases,
            createPhase,
            setAlertSeverity,
            project_id,
            pipeline_id
          );
        }
      }
      if (navigateOut) {
        navigateToProjects();
      }
    } catch (e) {
      console.error("Error on Product upsert", e);
      setAlertMessage(t("defaultError"));
      openAlert();
    }
  };
};
