import { styled } from "@mui/material/styles";
import { Box, Breakpoint } from "@mui/material";
import { Container } from "pages/Login/styles";
import { Link } from "react-router-dom";
import { palette } from "styles/theme/palette";

export const FormContainerStyled = styled(Container)(({ theme }) => ({
  alignItems: "center",
  "& > div": {
    width: "70%",
    [theme.breakpoints.down("md" as Breakpoint)]: {
      width: "100%",
    },
  },
}));

export const ContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "50%",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: "100px",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    paddingLeft: 0,
    width: "100%",
  },
}));

export const CheckboxContainerStyled = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "30px",
  "& > span": {
    paddingLeft: 0,
  },
}));

export const PolicyTextContainerStyled = styled(Box)(() => ({
  display: "inline-flex",
}));

export const PolicyLink = styled(Link)(() => ({
  textDecoration: "underline",
  color: palette.common.black,
  margin: "0 7px",
  transition: "opacity 300ms ease",
  "&:hover": {
    opacity: "0.8",
  },
}));
