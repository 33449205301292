import { useCallback, useEffect } from "react";
import { useAuth } from "./useAuth";
import {
  type Control,
  type DeepRequired,
  type FieldErrorsImpl,
  type FieldValues,
  useForm,
  type UseFormWatch,
} from "react-hook-form";
import type {
  Employee,
  EmployeeAdd,
  EmployeeAdditionalInfo,
  EmployeeAddForm,
  EditEmployeePayload,
} from "../store/employees/employeesTypes";
import { EMPLOYEE_TYPE, PROJECT_TYPE } from "../utils/freelancer/constants";
import {
  useAddEmployeesMutation,
  useEditEmployeeMutation,
} from "../store/employees/employeesServices";
import { useFreelancerDefaults } from "./useFreelancerDefaults";

interface AddEditEmployeeFormReturn<T extends FieldValues> {
  control: Control<T>;
  reset: () => void;
  errors: FieldErrorsImpl<DeepRequired<T>>;
  isClient: boolean;
  ableToSubmit: boolean;
  isUserEmployee: boolean;
  update: () => void;
  watch?: UseFormWatch<T>;
}

export const useAddEditEmployeeForm = (
  selectedType: EMPLOYEE_TYPE,
  onClose: () => void,
  employee?: Employee | null,
  callback?: () => void
): AddEditEmployeeFormReturn<EmployeeAddForm> => {
  const { user } = useAuth();
  const { project_id, employeeName, pipeline_id } = useFreelancerDefaults();
  const isUserEmployee = employee?.name === employeeName;
  const {
    control,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<EmployeeAddForm>({
    mode: "all",
    defaultValues: {
      type: selectedType || EMPLOYEE_TYPE.PARTNER,
      name: "",
      email: "",
      phone: "",
      department: "",
      account_number: "",
      edrpo: "",
      link: "",
      additional_info: "",
    },
  });

  const watchedName = watch("name");
  const watchedEmail = watch("email");

  const ableToSubmit =
    !!watchedName?.length && !!watchedEmail?.length && isValid;

  useEffect(() => {
    if (!employee) {
      setValue("type", selectedType);
    }
  }, [selectedType]);

  const isClient = employee
    ? employee?.type === EMPLOYEE_TYPE.CONTRACTOR
    : selectedType === EMPLOYEE_TYPE.CONTRACTOR;

  useEffect(() => {
    if (!employee) return;
    setValue("name", employee.name);
    setValue("email", employee.email ?? "");
    setValue("phone", employee.phone);
    setValue("department", employee.department ?? "");
    setValue("address", employee.address ?? "");
    setValue("account_number", employee.payment_info?.account_number);
    setValue("edrpo", employee.edrpo);
    setValue("additional_info", employee.additional_info?.notes);
    setValue("type", employee.type);
    setValue("wage", employee.wage ?? 0);
    setValue("bank_name", employee.payment_info?.bank_name ?? "");
    setValue("account_number", employee.payment_info?.account_number ?? "");
    setValue("bank_address", employee.payment_info?.bank_address ?? "");
    setValue("swift_code", employee.payment_info?.swift_code ?? "");
    setValue(
      "correspondent_bank",
      employee.payment_info?.correspondent_bank ?? ""
    );
    setValue(
      "correspondent_swift_code",
      employee.payment_info?.correspondent_swift_code ?? ""
    );
    setValue(
      "correspondent_account_number",
      employee.payment_info?.correspondent_account_number ?? ""
    );
  }, [employee]);

  const [editEmployee] = useEditEmployeeMutation();
  const [create] = useAddEmployeesMutation();

  const update = useCallback(async () => {
    const {
      email,
      department,
      name,
      type,
      phone,
      additional_info,
      account_number,
      edrpo,
      link,
      wage,
      address,
      bank_name,
      bank_address,
      correspondent_account_number,
      swift_code,
      correspondent_bank,
      correspondent_swift_code,
    } = getValues();
    const additional: EmployeeAdditionalInfo = {
      notes: additional_info ?? "",
    };

    if (!employee?.id) {
      const creatableData: EmployeeAdd[] = [
        {
          email: email ?? "",
          type,
          department,
          name,
          phone: phone ?? "",
          additional_info: additional,
          edrpo: edrpo ?? "",
          link,
          account_number: account_number ?? "",
          address,
          amount_type: PROJECT_TYPE.FIX,
          wage: wage ? +wage : 0,
          payment_info: {
            bank_name,
            bank_address,
            account_number,
            swift_code,
            correspondent_account_number,
            correspondent_bank,
            correspondent_swift_code,
          },
        },
      ];
      if (project_id && pipeline_id) {
        try {
          await create({
            project_id,
            body: [{ employees: creatableData, pipeline_id }],
          }).unwrap();
          callback?.();
        } catch (e) {
          console.error("Error on create employee, ", e);
        }
      }
      reset();
      onClose();
    }
    if (employee) {
      const updateDataEmployee: EditEmployeePayload = {
        name: isUserEmployee ? user?.user_id ?? "" : name,
        email,
        department,
        phone,
        additional_info: additional,
        edrpo,
        link,
        account_number,
        wage: wage ? +wage : 0,
        address,
        payment_info: {
          bank_name,
          bank_address,
          account_number,
          swift_code,
          correspondent_account_number,
          correspondent_bank,
          correspondent_swift_code,
        },
      };

      try {
        await editEmployee({
          body: updateDataEmployee,
          project_id: project_id as string,
          pipeline_id: pipeline_id as string,
          product_id: employee.id,
        }).unwrap();
        callback?.();
      } catch (e) {
        console.error("Error on edit employee, ", e);
      }
      reset();
      onClose();
    }
  }, [employee, onClose, project_id, pipeline_id, callback]);

  return {
    control,
    reset,
    errors,
    isClient,
    update,
    ableToSubmit,
    isUserEmployee,
    watch,
  };
};
