import { type FC, useState, type MouseEvent } from "react";
import { Button, TableContainer } from "@mui/material";

import ProjectsTableBody from "./ProjectsTableBody";
import ProjectsTableHead from "./ProjectsTableHead";
import { type Order } from "types/global";
import { TableStyled } from "./styles";
import { useTranslation } from "react-i18next";
import { NoTableDataTextStyled, NoDataContainerStyled } from "../../styles";
import { type ProjectWithPhases } from "interfaces/freelancer/project";
import { PlusIcon } from "assets/icons";
import ProjectTypeModal from "../ProjectTypeModal";
import { useDrawer } from "hooks/useDrawer";

interface ProductsTableProps {
  isActive: boolean;
  projects: ProjectWithPhases[];
}

const ProjectsTable: FC<ProductsTableProps> = ({ projects, isActive }) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof ProjectWithPhases>("name");
  const { open, isOpen, close } = useDrawer();
  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof ProjectWithPhases
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer>
        <TableStyled aria-labelledby="tableTitle">
          <ProjectsTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={6}
          />
          {!!projects?.length && (
            <ProjectsTableBody
              handleRequestSort={handleRequestSort}
              projects={projects}
              order={order}
              orderBy={orderBy}
            />
          )}
        </TableStyled>
        {!projects?.length && isActive && (
          <NoDataContainerStyled>
            <Button
              onClick={open}
              variant="textIcon"
              className="toolbar-btns create-btn"
              startIcon={<PlusIcon />}
            >
              {t("freelancer.page.addEditProject.addFirst.label")}
            </Button>
          </NoDataContainerStyled>
        )}
        {!projects?.length && !isActive && (
          <NoTableDataTextStyled variant="h2">
            {t("freelancer.page.projects.noArchiveProjects")}
          </NoTableDataTextStyled>
        )}
      </TableContainer>
      <ProjectTypeModal isOpen={isOpen} onClose={close} />
    </>
  );
};

export default ProjectsTable;
