import { type FC, MouseEvent, useCallback } from "react";
import { TableCell, TableRow, TableSortLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledTableHeader } from "pages/Teams/TeamTable/styles";

import { headCells } from "./data";
import { type Order } from "types/global";
import { useScreenSize } from "hooks/useScreenSize";
import { type UserTransaction } from "store/transactions/transactionsTypes";

interface ProjectsTableProps {
  onRequestSort: (event: MouseEvent, property: keyof UserTransaction) => void;
  order: Order;
  orderBy: string;
  collapse?: boolean;
}

const TransactionsTableHead: FC<ProjectsTableProps> = ({
  order,
  orderBy,
  collapse,
  onRequestSort,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useScreenSize();
  const createSortHandler = useCallback(
    (property: keyof UserTransaction) => (event: MouseEvent) => {
      onRequestSort(event, property);
    },
    [onRequestSort]
  );

  return (
    <StyledTableHeader className={collapse ? "collapse" : ""}>
      <TableRow>
        {headCells.map(({ label, id, showOnMobile }) => {
          if (isDesktop || (!isDesktop && showOnMobile)) {
            return (
              <TableCell
                key={id}
                sortDirection={orderBy === id ? order : false}
                width="auto"
              >
                <TableSortLabel
                  active={orderBy === id}
                  direction={orderBy === id ? order : "asc"}
                  onClick={createSortHandler(id)}
                >
                  {t(label)}
                </TableSortLabel>
              </TableCell>
            );
          }
          return null;
        })}
        {isDesktop && <TableCell />}
      </TableRow>
    </StyledTableHeader>
  );
};

export default TransactionsTableHead;
