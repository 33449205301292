import { type FC } from "react";
import { TableContainer } from "@mui/material";
import { TableStyled } from "components/Freelancer/Projects/ProjectsTable/styles";
import { useTranslation } from "react-i18next";
import { NoTableDataTextStyled } from "../../styles";
import InvoicesTableBody from "./InvoicesTableBody";
import { useOrder } from "hooks/freelancer/useOrder";
import InvoicesTableHead from "./InvoicesTableHead";
import { type TableInvoice } from "interfaces/freelancer/invoice";
interface InvoicesTableProps {
  isActive: boolean;
  invoices: TableInvoice[];
}

const InvoicesTable: FC<InvoicesTableProps> = ({ invoices, isActive }) => {
  const { t } = useTranslation();
  const { orderBy, order, handleRequestSort } = useOrder<TableInvoice>("name");
  const noDataLabel = isActive
    ? "freelancer.page.invoices.noInvoices"
    : "freelancer.page.invoices.noCanceledInvoices";

  return (
    <TableContainer>
      <TableStyled>
        <InvoicesTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        {!!invoices?.length && (
          <InvoicesTableBody
            invoices={invoices}
            order={order}
            orderBy={orderBy}
            isActive={isActive}
          />
        )}
      </TableStyled>
      {!invoices?.length && (
        <NoTableDataTextStyled variant="h2">
          {t(noDataLabel)}
        </NoTableDataTextStyled>
      )}
    </TableContainer>
  );
};

export default InvoicesTable;
