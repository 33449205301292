import { BaseQueryParams } from "store/baseQuery";
import type {
  UserExpense,
  UserExpenseCreate,
  UserExpenseUpdate,
} from "./userExpenseTypes";

export const userExpenseServices = BaseQueryParams("userExpense", [
  "USER_EXPENSES",
]).injectEndpoints({
  endpoints: (builder) => ({
    getAllUserExpenses: builder.query<UserExpense[], null>({
      query: () => ({
        url: "/user/expense",
        method: "GET",
      }),

      providesTags: () => ["USER_EXPENSES"],
    }),
    getUserExpense: builder.query<UserExpense, { id: string }>({
      query: ({ id }) => ({
        url: `/user/expense/${id}`,
        method: "GET",
      }),

      providesTags: () => ["USER_EXPENSES"],
    }),
    createUserExpense: builder.mutation<unknown, UserExpenseCreate>({
      query: (body) => ({
        url: "/user/expense",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["USER_EXPENSES"],
    }),
    deleteUserExpense: builder.mutation<unknown, { id: string }>({
      query: ({ id }) => ({
        url: `/user/expense/${id}`,
        method: "DELETE",
      }),

      invalidatesTags: () => ["USER_EXPENSES"],
    }),
    updateUserExpense: builder.mutation<
      unknown,
      { id: string; body: UserExpenseUpdate }
    >({
      query: ({ id, body }) => ({
        url: `/user/expense/${id}`,
        method: "PATCH",
        body,
      }),

      invalidatesTags: () => ["USER_EXPENSES"],
    }),
  }),
});

export const {
  useCreateUserExpenseMutation,
  useDeleteUserExpenseMutation,
  useGetAllUserExpensesQuery,
  useGetUserExpenseQuery,
  useUpdateUserExpenseMutation,
} = userExpenseServices;
