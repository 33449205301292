import { type FC } from "react";
import { Box, Typography } from "@mui/material";
import { useDrawer } from "hooks/useDrawer";
import { Arrow } from "pages/ProductsAndServices/ProductsTable/styles";
import { useTranslation } from "react-i18next";

import { palette } from "styles/theme/palette";
import {
  type Control,
  Controller,
  type FieldArrayWithId,
} from "react-hook-form";
import type { InvoiceCreateForm } from "interfaces/freelancer/invoice";
import {
  ArrowContainerStyled,
  CollapseStyled,
  HeaderStyled,
  IconContainerStyled,
  LabelStyled,
  TextareaAutosizeStyled,
  TitleContainerStyled,
} from "components/Freelancer/Projects/AddEdit/Expenses/styles";
import Services from "./Services";
import AdditionalExpenses from "./AdditionalExpenses";
import Employees from "./Employees";

interface PhaseProps {
  index: number;
  control: Control<InvoiceCreateForm>;
  field: FieldArrayWithId<InvoiceCreateForm, "phases">;
  overrideLanguage?: string;
}

const Phase: FC<PhaseProps> = ({ index, control, field, overrideLanguage }) => {
  const { t } = useTranslation();
  const { toggle, isOpen } = useDrawer(true);
  return (
    <CollapseStyled collapsedSize={50} in={isOpen}>
      <HeaderStyled>
        <TitleContainerStyled onClick={toggle}>
          <Typography variant="body1" color={palette.custom.green.dark}>
            {field.name}
          </Typography>
        </TitleContainerStyled>
        <ArrowContainerStyled>
          <IconContainerStyled onClick={toggle}>
            <Arrow $isOpen={isOpen} />
          </IconContainerStyled>
        </ArrowContainerStyled>
      </HeaderStyled>
      <Box>
        <LabelStyled>
          {t("freelancer.page.addEditProject.description.label", {
            lng: overrideLanguage,
          })}
        </LabelStyled>
        <Controller
          name={`phases.${index}.description`}
          control={control}
          render={({ field }) => (
            <TextareaAutosizeStyled
              minRows={4}
              placeholder={t(
                "freelancer.page.addEditProject.description.placeholder",
                { lng: overrideLanguage }
              )}
              {...field}
            />
          )}
        />
      </Box>
      {!!field.services?.length && (
        <Services
          parentIndex={index}
          control={control}
          overrideLanguage={overrideLanguage}
        />
      )}
      {!!field.employees?.length && (
        <Employees
          parentIndex={index}
          control={control}
          overrideLanguage={overrideLanguage}
        />
      )}
      {!!field.additionalExpenses?.length && (
        <AdditionalExpenses
          parentIndex={index}
          control={control}
          overrideLanguage={overrideLanguage}
        />
      )}
    </CollapseStyled>
  );
};

export default Phase;
