import { useCallback, useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";

import { Option } from "types/select";

import {
  ProjectPhaseCreate,
  ProjectPhaseEmployee,
  ProjectUpdate,
} from "interfaces/freelancer/project";

import { useUpdateProductMutation } from "store/productGroup/productGroupServices";
import { ClockifyProjectWorkspace, TimeLog } from "store/timeLog/timeLogTypes";
import { ClockifyIntegrationConfig } from "store/productGroup/productGroupTypes";
import {
  useDeleteTimeLogMutation,
  useLazyGetClockifyEmployeesQuery,
} from "store/timeLog/timeLogServices";
import { useAuth } from "./useAuth";
import { useFreelancerDefaults } from "./useFreelancerDefaults";

interface UseClockifyTimeTrackerInputHandlersParameters {
  timeLogData?: TimeLog[];
  selectedEmployee: ProjectPhaseEmployee;
  clockifyProjectsWorkspaces: ClockifyProjectWorkspace[];
  onClose: () => void;
  refetchTimeLogData: () => void;
  refetchClockifyEmployeesTimeEntries: () => void;
  clockifyIntegrationConfig?: ClockifyIntegrationConfig;
  phase: ProjectPhaseCreate;
}

export const useClockifyTimeTrackerModalConfig = ({
  timeLogData,
  selectedEmployee,
  clockifyProjectsWorkspaces,
  onClose,
  refetchTimeLogData,
  refetchClockifyEmployeesTimeEntries,
  clockifyIntegrationConfig,
  phase,
}: UseClockifyTimeTrackerInputHandlersParameters) => {
  const { pipeline_id } = useFreelancerDefaults();
  const [updatePhase] = useUpdateProductMutation();
  const [deleteTimeLog] = useDeleteTimeLogMutation();
  const [refetchClockifyEmployees, { data: clockifyEmployees }] =
    useLazyGetClockifyEmployeesQuery();
  const { user } = useAuth();
  const employeeId =
    selectedEmployee.name === user?.user_id
      ? user.user_id
      : selectedEmployee.employeeId;

  const workspaceOptions: Option[] = clockifyProjectsWorkspaces.map(
    ({ workspace }) => ({
      label: workspace,
      value: workspace,
    })
  );

  const employeeTimeLogData = timeLogData?.filter(
    ({ employee_id }) => employeeId === employee_id
  );

  const [selectedWorkspace, setSelectedWorkspace] = useState<string>("");
  const [selectedProject, setSelectedProject] = useState<string>("");
  const [selectedClockifyEmployee, setSelectedClockifyEmployee] =
    useState<string>("");

  const handleWorkspaceChange = useCallback(
    ({ target }: SelectChangeEvent<unknown>) => {
      if (target?.value) {
        setSelectedWorkspace(target?.value as string);
        setSelectedProject("");
        setSelectedClockifyEmployee("");
      }
    },
    []
  );
  const handleProjectChange = useCallback(
    ({ target }: SelectChangeEvent<unknown>) => {
      if (target?.value) {
        setSelectedProject(target?.value as string);
        setSelectedClockifyEmployee("");
      }
    },
    []
  );
  const handleEmployeeChange = useCallback(
    ({ target }: SelectChangeEvent<unknown>) => {
      if (target?.value) {
        setSelectedClockifyEmployee(target?.value as string);
      }
    },
    []
  );

  const [projectOptions, setProjectOptions] = useState<Option[]>([]);
  const [employeeOptions, setEmployeeOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (clockifyEmployees !== undefined) {
      const employeeOptions: Option[] = clockifyEmployees.map(
        ({ id, name }) => ({
          label: name,
          value: id,
        })
      );

      setEmployeeOptions(employeeOptions);
    }
  }, [clockifyEmployees]);

  useEffect(() => {
    if (selectedWorkspace !== "") {
      const filteredProjectOptions = clockifyProjectsWorkspaces
        .filter(({ workspace }) => workspace === selectedWorkspace)
        .map(({ name, id }) => ({
          label: name,
          value: id,
        }));

      setProjectOptions(filteredProjectOptions);

      refetchClockifyEmployees({
        workspace: selectedWorkspace,
      });
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (selectedProject !== "") {
      refetchClockifyEmployees({
        workspace: selectedWorkspace,
        project: selectedProject,
      });
    }
  }, [selectedProject]);

  const closeModal = useCallback(() => {
    setSelectedWorkspace("");
    setSelectedProject("");
    setSelectedClockifyEmployee("");
    setProjectOptions([]);
    setEmployeeOptions([]);

    onClose();
  }, []);
  const handleSubmit = async () => {
    const updatedPhase: ProjectUpdate = Object.assign({}, phase, {
      id: phase.id ?? "",
      pipeline_id,
      group_id: phase.groupId ?? "",
      expenses: undefined,
      price: 0,
      amount: 0,
      extras: {
        clockify: {
          project: selectedProject,
          workspace: selectedWorkspace,
          employees: {
            ...clockifyIntegrationConfig?.employees,
            [employeeId]: selectedClockifyEmployee,
          },
        },
      },
    });
    await updatePhase({
      products: [updatedPhase],
    });

    if (employeeTimeLogData !== undefined) {
      const promises = employeeTimeLogData.map(async ({ timelog_id }) =>
        deleteTimeLog(timelog_id)
      );

      await Promise.all(promises);

      refetchClockifyEmployeesTimeEntries();
      refetchTimeLogData();
    }

    closeModal();
  };

  return {
    workspaceOptions,
    projectOptions,
    employeeOptions,

    selectedWorkspace,
    selectedProject,
    selectedClockifyEmployee,

    handleWorkspaceChange,
    handleProjectChange,
    handleEmployeeChange,
    handleSubmit,
    closeModal,
  };
};
