import { useMemo } from "react";
import countryList from "react-select-country-list";

interface CountryOptionsData {
  options: { value: string; label: string }[];
  priorOptions: { value: string; label: string }[];
}
export const useCountryOptions = (): CountryOptionsData => {
  const allOptions = useMemo(() => countryList().getData(), []);
  const options = useMemo(
    () =>
      allOptions?.filter(
        ({ value }) => value !== "UA" && value !== "PL" && value !== "RU"
      ),
    [allOptions]
  );
  const priorOptions = useMemo(() => {
    const [optionUkraine] = allOptions.filter(({ value }) => value === "UA");
    const [optionPoland] = allOptions.filter(({ value }) => value === "PL");
    const [optionUSA] = allOptions.filter(({ value }) => value === "US");

    return [optionUkraine, optionPoland, optionUSA];
  }, [allOptions]);

  return { options, priorOptions };
};
