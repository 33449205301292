import React, { useEffect } from "react";
import {
  Control,
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@mui/material";

import Input from "components/UI/Input";
import { IProductCreate } from "interfaces/product";

import {
  useCreateProductMutation,
  useDeleteEmployeeMutation,
  useUpdateProductMutation,
} from "store/tutors/tutorsServices";
import { selectTutor } from "store/tutors/tutorsSlice";

import {
  FormContainer,
  LinkButton,
  FormRow,
  FormWrap,
  HeaderSection,
} from "../styles";

interface IProductFormState {
  product: IProductCreate;
}

interface ProductNameProps {}

const ProductName: React.FC<ProductNameProps> = ({}) => {
  const { t } = useTranslation();
  const { product_id } = useParams<string>();
  const { pipeline_id, project_id, products } = useSelector(selectTutor);
  const [create] = useCreateProductMutation();
  const [updateProduct] = useUpdateProductMutation();

  const {
    control,
    setValue,
    reset,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<{ products: IProductCreate[] }>({
    defaultValues: { products },
    mode: "all",
  });

  const { append, update, remove, fields } = useFieldArray({
    control: control,
    name: "products",
  });

  const updateItem = async (id?: string) => {
    if (!id) return;

    const productsArray = watch("products");
    const product = productsArray.find((item) => item?.pipeline_id === id);

    if (product) {
      const updateDataProduct = {
        name: product.name,
        amount: Number(product.amount),
        price: Number(product.price),
        group: product.group,
        extras: product.extras,
        pipeline_id: pipeline_id,
      };

      await updateProduct({
        body: updateDataProduct,
        product_id: id,
      }).unwrap();
    }
  };

  const onNextStep: SubmitHandler<{ products: IProductCreate[] }> = async (
    values
  ) => {
    try {
      const filteredProducts = values.products.filter(
        (item) => !item?.pipeline_id
      );
      const checkGroupProduct = values.products?.filter(
        (item) => item.group === "product"
      );

      if (!filteredProducts?.length) {
        if (checkGroupProduct?.length === 0) {
          // redirectToTutorStep(project_id, 6);

          return;
        }
        // redirectToTutorStep(project_id, 4);

        return;
      }

      if (filteredProducts?.length) {
        const newProducts = filteredProducts.map((item) => ({
          name: item.name,
          group: item.group,
          amount: item.amount,
          extras: item.extras,
          price: item.price,
        }));

        await create({
          project_id: project_id as string,
          body: [{ products: newProducts, pipeline_id: pipeline_id as string }],
        }).unwrap();

        if (checkGroupProduct?.length === 0) {
          // redirectToTutorStep(project_id, 6);
        } else {
          // redirectToTutorStep(project_id, 4);
        }
      }

      reset();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // @ts-ignore
    const findProduct: IProductCreate = products.find(
      (product) => product.pipeline_id === product_id
    );
    // console.log('findProduct', findProduct);

    // if (!findProduct) {
    //   setValue('products', products);
    // return;
    // }
    setValue("products", [findProduct]);
  }, [products]);
  // console.log('fields', fields);

  return (
    <FormWrap>
      <HeaderSection>
        <Typography variant="h2">{t("pages.AddProduct.Продукт")}</Typography>
        {/*<LinkButton variant="link" onClick={handleSubmit(onNextStep)}> Update</LinkButton>*/}
      </HeaderSection>
      <FormContainer>
        {fields.map((product, index) => (
          <FormRow
            onBlur={() => updateItem(product?.pipeline_id)}
            key={product.pipeline_id}
          >
            <Controller
              name={`products.${index}.name`}
              control={control}
              rules={{ required: t("fieldIsRequired") }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t("pages.AddProduct.Назва продукта або сервісу")}
                  className="inline-input time-input"
                  helperText={errors?.products?.[index]?.name?.message}
                  error={!!errors?.products?.[index]?.name?.message}
                  fullWidth
                />
              )}
            />
            <Controller
              name={`products.${index}.price`}
              control={control}
              rules={{ required: t("fieldIsRequired") }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  label={t("pages.AddProduct.Ціна продажу")}
                  className="inline-input time-input"
                  fullWidth
                  helperText={errors?.products?.[index]?.price?.message}
                  error={!!errors?.products?.[index]?.price}
                />
              )}
            />
          </FormRow>
        ))}
      </FormContainer>
    </FormWrap>
  );
};

export default ProductName;
