export enum TRANSACTION_TYPE {
  INVOICE = "invoice",
  USER_EXPENSE = "user_expense",
}
export interface UserTransaction {
  id: string;
  amount: number;
  type: TRANSACTION_TYPE;
  created_at: Date;
  updated_at: Date;
}
