import { type FC } from "react";
import { Typography, Box } from "@mui/material";
import { ColumnStyled, ContentContainerStyled } from "./styles";
import { useTranslation } from "react-i18next";
import ProjectOverview from "./ProjectOverview";
import Transactions from "./Transactions";
import PendingInvoices from "./PendingInvoices";
import ImportantNumbers from "./ImportantNumbers";
import AddInfo from "./AddInfo";
import { useProjectsWithPhases } from "hooks/freelancer/useProjectsWithPhases";
import { useDateRangeInterval } from "hooks/freelancer/useDateRangeInterval";
import { PageContainerStyled, TitleContainer } from "../styles";
import Footer from "../../Footer";
import { useScreenSize } from "hooks/useScreenSize";
import { EndTrialModal } from "../EndTrialModal";

const Dashboard: FC = () => {
  const { t } = useTranslation();
  const { activeProjectsWithPhases } = useProjectsWithPhases();
  const { startDate, updateStartDate, updateEndDate, endDate } =
    useDateRangeInterval();
  const { isDesktop } = useScreenSize();

  return (
    <PageContainerStyled>
      <Box>
        <TitleContainer>
          <Typography variant="jumboTitle">
            {t("freelancer.page.dashboard.title")}
          </Typography>
        </TitleContainer>
        <ContentContainerStyled>
          <ColumnStyled>
            <ProjectOverview projects={activeProjectsWithPhases} />
            <Transactions startDate={startDate} endDate={endDate} />
          </ColumnStyled>
          <ColumnStyled>
            <ImportantNumbers
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={updateStartDate}
              onEndDateChange={updateEndDate}
            />
            <PendingInvoices />
            <AddInfo />
          </ColumnStyled>
        </ContentContainerStyled>
      </Box>
      {isDesktop && <Footer isSpace isSticki={false} />}
      <EndTrialModal />
    </PageContainerStyled>
  );
};

export default Dashboard;
