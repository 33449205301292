import { type FC } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAddTransactionForm } from "hooks/useAddTransactionForm";
import { SelectWithMargin } from "../../Projects/AddEdit/styles";
import { Alert, Box, Snackbar } from "@mui/material";
import { getTransactionOptions } from "utils/freelancer/helpers/getTransactionOptions";
import {
  AmountInputContainer,
  ButtonStyled,
  CurrencyStyled,
  CustomInputStyled,
  FakeButtonStyled,
  FormRowStyled,
} from "./styles";
import { useAlert } from "hooks/freelancer/useAlert";
import { DEFAULT_ALERT_HIDE_DURATION } from "utils/freelancer/constants";
import { useTrialUser } from "hooks/useTrialUser";
import LimitedAccessTooltip from "components/Freelancer/LimitedAccessTooltip";

const AddTransactionForm: FC = () => {
  const { t } = useTranslation();
  const {
    setAlertSeverity,
    setAlertMessage,
    openAlert,
    isAlertOpen,
    alertSeverity,
    alertMessage,
    handleAlertClose,
  } = useAlert();
  const { errors, control, currency, handleSave, isValid } =
    useAddTransactionForm(setAlertSeverity, setAlertMessage, openAlert);
  const { typeOptions, operationTypeOptions, groupOptions } =
    getTransactionOptions(t);
  const { limitedAccess } = useTrialUser();

  return (
    <Box>
      <FormRowStyled>
        <Controller
          name="operationType"
          control={control}
          render={({ field }) => (
            <SelectWithMargin {...field} data={operationTypeOptions} />
          )}
        />
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <SelectWithMargin {...field} data={typeOptions} />
          )}
        />
        <Controller
          name="group"
          control={control}
          render={({ field }) => (
            <SelectWithMargin {...field} data={groupOptions} />
          )}
        />
      </FormRowStyled>
      <FormRowStyled>
        <AmountInputContainer>
          <Controller
            render={({ field }) => (
              <CustomInputStyled
                fullWidth={false}
                {...field}
                placeholder={t(
                  "freelancer.page.dashboard.add.transaction.amount.placeholder"
                )}
                error={!!errors?.amount?.message}
                helperText={errors?.amount?.message}
                type="number"
              />
            )}
            control={control}
            name="amount"
            rules={{
              required: {
                value: true,
                message: t("fieldIsRequired"),
              },
            }}
          />
          <CurrencyStyled>{currency}</CurrencyStyled>
        </AmountInputContainer>
        {limitedAccess ? (
          <LimitedAccessTooltip>
            <FakeButtonStyled>{t("addTransaction")}</FakeButtonStyled>
          </LimitedAccessTooltip>
        ) : (
          <ButtonStyled
            disabled={!isValid || limitedAccess}
            variant="contained"
            component="span"
            onClick={handleSave}
          >
            {t("addTransaction")}
          </ButtonStyled>
        )}
      </FormRowStyled>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={DEFAULT_ALERT_HIDE_DURATION}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity={alertSeverity}>{alertMessage}</Alert>
      </Snackbar>
    </Box>
  );
};

export default AddTransactionForm;
