import React, { FC } from "react";
import { HashLink } from "react-router-hash-link";
// Styles
import { Container, Link } from "./styles";
// Translation
import { useTranslation } from "react-i18next";

const TooltipText: FC<{ navigateTo?: string }> = ({ navigateTo }) => {
  const { t } = useTranslation();
  return (
    <Container>
      {t(
        "components.HeaderUserMenu.UserMembershipTooltip.TooltipText.Днів до завершення безкоштовного користування. Для продовження користування необхідно буде прейти на"
      )}

      <HashLink to={navigateTo ?? "/profile#plans"}>
        <Link>
          {t(
            "components.HeaderUserMenu.UserMembershipTooltip.TooltipText.платний тариф"
          )}
        </Link>
      </HashLink>
    </Container>
  );
};

export default TooltipText;
