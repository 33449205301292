import { TableCell } from "@mui/material";
import Tooltip from "../UI/Tooltip";
import {
  MenuButton,
  MenuList,
  MenuListItem,
  MenuListItemButton,
  Text,
} from "pages/Teams/TeamTable/styles";
import { MoreSetingsIcon } from "assets/icons";
import { useTranslation } from "react-i18next";
import { type FC, type ReactNode } from "react";
import { useTrialUser } from "hooks/useTrialUser";
import LimitedAccessTooltip from "./LimitedAccessTooltip";

interface EditDeleteTooltipProps {
  disabled?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  additionalItems?: ReactNode;
  showEdit?: boolean;
  showDelete?: boolean;
}
const EditDeleteTooltip: FC<EditDeleteTooltipProps> = ({
  disabled = false,
  onEdit = undefined,
  onDelete = undefined,
  additionalItems = undefined,
  showEdit = true,
  showDelete = true,
}) => {
  const { t } = useTranslation();
  const { limitedAccess } = useTrialUser();

  if (limitedAccess) {
    return (
      <TableCell>
        <LimitedAccessTooltip>
          <MenuButton>
            <MoreSetingsIcon />
          </MenuButton>
        </LimitedAccessTooltip>
      </TableCell>
    );
  }

  if (disabled) {
    return (
      <TableCell>
        <Tooltip
          arrow
          placement="bottom"
          tooltipText=""
          tooltipContent={
            <MenuList disablePadding>
              <MenuListItemButton>
                <Text variant="regularText">
                  {t("pages.UserProfile.ClockifyIntegration.cannotEditHours")}
                </Text>
              </MenuListItemButton>
            </MenuList>
          }
        >
          <MenuButton>
            <MoreSetingsIcon />
          </MenuButton>
        </Tooltip>
      </TableCell>
    );
  }

  return (
    <TableCell>
      <Tooltip
        arrow
        placement="bottom"
        tooltipText=""
        tooltipContent={
          <MenuList disablePadding>
            {showEdit && (
              <MenuListItem disablePadding>
                <MenuListItemButton onClick={onEdit}>
                  <Text variant="regularText">{t("Редагувати")}</Text>
                </MenuListItemButton>
              </MenuListItem>
            )}
            {additionalItems}
            {showDelete && (
              <MenuListItem disablePadding>
                <MenuListItemButton onClick={onDelete}>
                  <Text variant="regularText" className="red">
                    {t("Видалити")}
                  </Text>
                </MenuListItemButton>
              </MenuListItem>
            )}
          </MenuList>
        }
      >
        <MenuButton>
          <MoreSetingsIcon />
        </MenuButton>
      </Tooltip>
    </TableCell>
  );
};

export default EditDeleteTooltip;
