import { useDeleteInvoiceMutation } from "store/invoices/invoicesServices";
import { useCallback } from "react";

export const useInvoiceDelete = (invoiceId: string) => {
  const [deleteInvoice] = useDeleteInvoiceMutation();

  return useCallback(async () => {
    try {
      await deleteInvoice({ invoice_id: invoiceId });
    } catch (e) {
      console.error("Error on deleteInvoice, ", e);
    }
  }, [invoiceId]);
};
