import { styled } from "@mui/material/styles";
import { Box, Breakpoint } from "@mui/material";

interface ContainerProps {
  isSubscribe?: boolean;
  isDisabled?: boolean;
}

interface ImageProps {
  isActive: boolean;
}

export const Container = styled(Box)<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ isSubscribe, isDisabled }) =>
    isDisabled ? "#F8F9FD" : !isSubscribe ? "#EBF7F2" : "#F8F9FD"};
  border-radius: 8px;
  width: 32%;
  max-width: 500px;
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
  min-height: 400px;

  &:hover {
    background: #f8f9fd;
    transition: all 0.5s ease;
  }
`;

export const RowContainer = styled(Box)(() => ({
  flexDirection: "row",
  display: "flex",
  gap: "30px",
  flexWrap: "wrap",
}));

export const CardContainer = styled(Container)(({ theme }) => ({
  minWidth: "350px",
  width: "calc((100% - 60px) / 3)",
  paddingBottom: "20px",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    minWidth: "calc(100vw - 90px)",
  },
}));

export const Image = styled("img")<ImageProps>`
  display: ${({ isActive }) => (isActive ? "block" : "none")};
  width: 19px;
  height: 43px;
  position: absolute;
  top: 0;
  right: 16px;
`;
