import { type FC, useEffect } from "react";
import { type Control, useFieldArray, useWatch } from "react-hook-form";
import { type InvoiceCreateForm } from "interfaces/freelancer/invoice";
import { useTranslation } from "react-i18next";
import { type ProjectPhaseCreate } from "interfaces/freelancer/project";
import { NoTableDataTextStyled } from "components/Freelancer/styles";
import { palette } from "styles/theme/palette";
import { getInitialInvoicePhases } from "utils/freelancer/helpers/getInitialInvoicePhases";
import Phase from "./Phase";
import { useSearchParams } from "react-router-dom";
import { PROJECT_TYPE, URL_PARAMS } from "utils/freelancer/constants";
import { ExpensesContainerStyled, TitleStyled } from "./styles";

interface ExpensesProps {
  control: Control<InvoiceCreateForm>;
  initialPhases: ProjectPhaseCreate[];
  overrideLanguage?: string;
}

const Expenses: FC<ExpensesProps> = ({
  control,
  initialPhases,
  overrideLanguage,
}) => {
  const { t } = useTranslation();
  const { fields, replace } = useFieldArray({
    control,
    name: "phases",
  });
  const watchPhases = useWatch({ control, name: "phases" });
  const controlledPhases = fields.map((field, index) => {
    return {
      ...field,
      ...watchPhases[index],
    };
  });
  const [searchParams] = useSearchParams();
  const typeFromUrl = searchParams.get(URL_PARAMS.TYPE);

  useEffect(() => {
    if (initialPhases) {
      const projectType = typeFromUrl?.length
        ? (typeFromUrl as PROJECT_TYPE)
        : PROJECT_TYPE.FIX;
      const invoicePhases = getInitialInvoicePhases(initialPhases, projectType);
      replace(invoicePhases);
    }
  }, [initialPhases]);

  return (
    <ExpensesContainerStyled>
      <TitleStyled variant="h1">
        {t("freelancer.page.invoices.add.expenses.title", {
          lng: overrideLanguage,
        })}
      </TitleStyled>
      {!!controlledPhases?.length ? (
        controlledPhases.map((field, index) => (
          <Phase
            key={field.id}
            index={index}
            control={control}
            field={field}
            overrideLanguage={overrideLanguage}
          />
        ))
      ) : (
        <NoTableDataTextStyled color={palette.primary.main} variant="h1">
          {t("freelancer.page.invoices.add.noPhaseText", {
            lng: overrideLanguage,
          })}
        </NoTableDataTextStyled>
      )}
    </ExpensesContainerStyled>
  );
};

export default Expenses;
