import { styled } from "@mui/material/styles";
import { Box, Breakpoint, Typography } from "@mui/material";
import { palette } from "styles/theme/palette";

export const CardStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  flexDirection: "column",
  padding: "22px 15px",
  backgroundColor: palette.common.white,
  borderRadius: "28px",
  border: `1px solid ${palette.primary.main}`,
  width: "calc(100% - 30px)",
  margin: "0 5px",
  overflow: "hidden",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    marginTop: "10px",
    width: "calc((100% - 30px) / 3)",
  },
}));

export const ImportantNumbersStyled = styled(Box)(() => ({
  width: "100%",
}));

export const DateRangeContainerStyled = styled(Box)(() => ({
  display: "flex",
  "& > div": {
    marginLeft: "20px",
  },
}));

export const TitleContainerStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  marginTop: "20px",
  "& > svg": {
    marginLeft: "20px",
  },
}));

export const NumbersContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "stretch",
  margin: "20px 0 10px",
  [theme.breakpoints.down("lg" as Breakpoint)]: {
    flexWrap: "wrap",
    marginTop: 0,
  },
}));

export const LabelStyled = styled(Typography)(() => ({
  display: "inline-flex",
  lineHeight: "16px",
  marginTop: "10px",
}));
