import { PRODUCT_GROUP_NAME } from "components/Freelancer/Projects/data";
import { type ProjectWithPhases } from "interfaces/freelancer/project";
import {
  useGetProductGroupsQuery,
  useGetAllProductsQuery,
} from "store/productGroup/productGroupServices";
import { useFreelancerDefaults } from "../useFreelancerDefaults";
import { useGetEmployeesQuery } from "store/employees/employeesServices";
import { useCallback, useMemo } from "react";
import { type Employee } from "store/employees/employeesTypes";
import dayjs from "dayjs";
import { type ProductAsPhase } from "interfaces/product";
import {
  PRODUCT_GROUP_STATUS,
  PRODUCT_GROUP_VISIBILITY,
} from "utils/freelancer/constants";
import { type TableInvoice } from "interfaces/freelancer/invoice";

interface ProjectsWithPhasesReturn {
  activeProjectsWithPhases: ProjectWithPhases[];
  archiveProjectsWithPhases: ProjectWithPhases[];
  isLoading: boolean;
}

export const useProjectsWithPhases = (
  activeInvoices?: TableInvoice[]
): ProjectsWithPhasesReturn => {
  const { project_id, pipeline_id } = useFreelancerDefaults();
  const { data, isLoading } = useGetProductGroupsQuery(null);
  const { data: products } = useGetAllProductsQuery({
    project_id,
    pipeline_id,
  });
  const { data: employees } = useGetEmployeesQuery({ project_id, pipeline_id });

  const projectsAsProductGroup = data?.filter(
    (item) => item.name === PRODUCT_GROUP_NAME.PROJECTS
  )[0]?.children;

  const getClient = useCallback(
    (clientId?: string): Employee | undefined =>
      employees?.filter(({ id }) => clientId === id)?.[0],
    [employees]
  );

  const projectsWithPhases: ProjectWithPhases[] = useMemo(
    () =>
      projectsAsProductGroup
        ?.map((productGroup) => {
          const [phaseProductGroup] = productGroup.children.filter(
            ({ name }) => name === PRODUCT_GROUP_NAME.PHASES
          );
          const productsByGroupId = products?.filter(
            ({ group_entity }) => group_entity.id === phaseProductGroup?.id
          ) as ProductAsPhase[];
          const [firstPhase] =
            productsByGroupId?.sort(
              (a, b) =>
                dayjs(a.extras?.startDate).valueOf() -
                dayjs(b.extras?.startDate).valueOf()
            ) ?? [];
          const [lastPhase] =
            productsByGroupId?.sort(
              (a, b) =>
                dayjs(b.extras.endDate).valueOf() -
                dayjs(a.extras.endDate).valueOf()
            ) ?? [];
          const type = productsByGroupId?.[0]?.extras?.type ?? "";
          return {
            id: productGroup.id,
            type,
            name: productGroup?.name ?? "",
            groupId: phaseProductGroup?.id ?? "",
            phases: productsByGroupId ?? [],
            client: getClient(productGroup?.extras?.clientId),
            startDate: firstPhase?.extras?.startDate,
            endDate: lastPhase?.extras?.endDate,
            visibility: productGroup.group_visibility,
            status: productGroup.status,
            invoices:
              activeInvoices?.filter(
                ({ projectId }) => projectId === productGroup.id
              ) ?? [],
          };
        })
        .filter(
          ({ name, visibility }) =>
            name?.length > 0 && visibility === PRODUCT_GROUP_VISIBILITY.PRIVATE
        ) ?? [],
    [data, products, activeInvoices]
  );

  const activeProjectsWithPhases = useMemo(
    () =>
      projectsWithPhases.filter(
        ({ status }) => status === PRODUCT_GROUP_STATUS.ACTIVE
      ),
    [projectsWithPhases]
  );
  const archiveProjectsWithPhases = useMemo(
    () =>
      projectsWithPhases.filter(
        ({ status }) => status === PRODUCT_GROUP_STATUS.ARCHIVE
      ),
    [projectsWithPhases]
  );

  return {
    activeProjectsWithPhases,
    archiveProjectsWithPhases,
    isLoading,
  };
};
