import {
  type Control,
  FieldErrors,
  type SubmitHandler,
  useForm,
  type UseFormHandleSubmit,
  type UseFormWatch,
} from "react-hook-form";
import { useUpdateUserMutation } from "store/auth/authService";
import { useAuth } from "./useAuth";
import {
  useEditEmployeeMutation,
  useGetEmployeesQuery,
} from "store/employees/employeesServices";
import { type EditEmployeePayload } from "store/employees/employeesTypes";
import { type UserProfileEmployeeInfo } from "interfaces/freelancer/profile";
import { useEffect } from "react";

interface UseEmploymentInfoReturn {
  errors: FieldErrors<UserProfileEmployeeInfo>;
  control: Control<UserProfileEmployeeInfo>;
  handleSubmit: UseFormHandleSubmit<UserProfileEmployeeInfo>;
  onSubmit: SubmitHandler<UserProfileEmployeeInfo>;
  watch: UseFormWatch<UserProfileEmployeeInfo>;
  isLoading: boolean;
}

export const useEmploymentInfo = (): UseEmploymentInfoReturn => {
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const { user } = useAuth();
  const { data } = useGetEmployeesQuery({
    project_id: user?.default_project_id ?? "",
    pipeline_id: user?.default_pipeline_id ?? "",
  });
  const [editEmployee] = useEditEmployeeMutation();
  const [userEmployee] =
    data?.filter(
      ({ name, id }) => name === user?.user_id || id === user?.user_id
    ) ?? [];

  const {
    setValue,
    watch,
    formState: { errors },
    setError,
    control,
    handleSubmit,
  } = useForm<UserProfileEmployeeInfo>();

  useEffect(() => {
    if (userEmployee) {
      setValue(
        "accountNumber",
        userEmployee.payment_info?.account_number ?? ""
      );
      setValue("wage", userEmployee.wage ?? 0);
      setValue("edrpo", userEmployee.edrpo ?? "");
      setValue("address", userEmployee.address ?? "");
      setValue("bank_name", userEmployee.payment_info?.bank_name ?? "");
      setValue(
        "account_number",
        userEmployee.payment_info?.account_number ?? ""
      );
      setValue("bank_address", userEmployee.payment_info?.bank_address ?? "");
      setValue("swift_code", userEmployee.payment_info?.swift_code ?? "");
      setValue(
        "correspondent_bank",
        userEmployee.payment_info?.correspondent_bank ?? ""
      );
      setValue(
        "correspondent_swift_code",
        userEmployee.payment_info?.correspondent_swift_code ?? ""
      );
      setValue(
        "correspondent_account_number",
        userEmployee.payment_info?.correspondent_account_number ?? ""
      );
      setValue("legal_name", userEmployee.legal_name ?? "");
    }
  }, [userEmployee]);

  const onSubmit: SubmitHandler<UserProfileEmployeeInfo> = async (values) => {
    try {
      const {
        edrpo,
        accountNumber,
        address,
        wage,
        bank_name,
        bank_address,
        account_number,
        correspondent_bank,
        swift_code,
        correspondent_account_number,
        correspondent_swift_code,
        legal_name,
      } = values;
      const updateDataEmployee: EditEmployeePayload = {
        name: userEmployee?.name,
        legal_name,
        edrpo,
        wage: wage ? +wage : 0,
        account_number: accountNumber,
        department: "",
        address,
        payment_info: {
          bank_name,
          bank_address,
          account_number,
          swift_code,
          correspondent_bank,
          correspondent_swift_code,
          correspondent_account_number,
        },
      };

      await editEmployee({
        body: updateDataEmployee,
        project_id: user?.default_project_id ?? "",
        pipeline_id: user?.default_pipeline_id ?? "",
        product_id: userEmployee.id,
      }).unwrap();

      await updateUser({
        body: {
          firstname: user?.firstname ?? "",
          lastname: user?.lastname ?? "",
          phone_number: user?.phone_number ?? "",
          residence: user?.residence ?? "",
          settings: {
            language: user?.settings?.language || "",
            clockifyApiKey: user?.settings?.clockifyApiKey || "",
          },
        },
      }).unwrap();
    } catch (err) {
      setError("name", {
        type: "custom",
        message: `${err}`,
      });
    }
  };

  return { errors, control, handleSubmit, onSubmit, isLoading, watch };
};
