import {
  MenuListItem,
  MenuListItemButton,
  Text,
} from "pages/Teams/TeamTable/styles";
import { type FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { URL_PARAMS } from "utils/freelancer/constants";
import { useNavigate } from "react-router";
import { freelanceUserRoutesPath } from "routes/routePath";
import { getUrlWithParams } from "utils/helpers/getUrlWithParams";
import { type TableInvoice } from "interfaces/freelancer/invoice";
import { useInvoiceDelete } from "hooks/freelancer/useInvoiceDelete";
import { useInvoiceChangeState } from "hooks/freelancer/useInvoiceChangeState";
import ConfirmationModal from "../../../ConfirmationModal";
import { useDrawer } from "hooks/useDrawer";

interface AdditionalTooltipItemsProps {
  invoice: TableInvoice;
  isActive: boolean;
  onInvoiceDueDateChange: (invoice: TableInvoice) => void;
}

const AdditionalTooltipItems: FC<AdditionalTooltipItemsProps> = ({
  invoice,
  isActive,
  onInvoiceDueDateChange,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen, open, close } = useDrawer();
  const handleDeleteInvoice = useInvoiceDelete(invoice.id);
  const { setCanceledState, setCreatedState } = useInvoiceChangeState(
    invoice.id,
    invoice.state
  );
  const navigateToPreviewPage = useCallback(() => {
    const previewUrl = getUrlWithParams(
      freelanceUserRoutesPath.INVOICES_PREVIEW,
      [
        { key: URL_PARAMS.PROJECT_ID, value: invoice.projectId },
        { key: URL_PARAMS.INVOICE_ID, value: invoice.id },
      ]
    );
    navigate(previewUrl);
  }, [navigate]);

  const modalButtonHandler = useCallback(() => {
    isActive ? setCanceledState() : handleDeleteInvoice();
  }, [isActive]);

  const title = isActive ? "cancelInvoiceConfirmation" : "deleteConfirmation";
  const deleteButtonLabel = isActive
    ? "cancelInvoiceConfirmationLabel"
    : undefined;

  const openDueDateModal = useCallback(() => {
    onInvoiceDueDateChange?.(invoice);
  }, [onInvoiceDueDateChange]);
  return (
    <>
      {isActive ? (
        <>
          <MenuListItem>
            <MenuListItemButton onClick={navigateToPreviewPage}>
              <Text variant="regularText">
                {t("freelancer.page.invoices.tooltip.previewPdf")}
              </Text>
            </MenuListItemButton>
          </MenuListItem>
          <MenuListItem>
            <MenuListItemButton onClick={openDueDateModal}>
              <Text variant="regularText">
                {t("freelancer.page.invoices.tooltip.updateDueDate")}
              </Text>
            </MenuListItemButton>
          </MenuListItem>
          <MenuListItem disablePadding>
            <MenuListItemButton onClick={open}>
              <Text variant="regularText" className="red">
                {t("freelancer.page.invoices.tooltip.cancel")}
              </Text>
            </MenuListItemButton>
          </MenuListItem>
        </>
      ) : (
        <>
          <MenuListItem>
            <MenuListItemButton onClick={setCreatedState}>
              <Text variant="regularText">
                {t("freelancer.page.invoices.tooltip.reactivate")}
              </Text>
            </MenuListItemButton>
          </MenuListItem>
          <MenuListItem disablePadding>
            <MenuListItemButton onClick={open}>
              <Text variant="regularText" className="red">
                {t("freelancer.page.invoices.tooltip.delete")}
              </Text>
            </MenuListItemButton>
          </MenuListItem>
        </>
      )}
      <ConfirmationModal
        deleteHandler={modalButtonHandler}
        deleteButtonLabel={deleteButtonLabel}
        onClose={close}
        isOpen={isOpen}
        title={t(title)}
      />
    </>
  );
};

export default AdditionalTooltipItems;
