import {
  type ProjectPhaseCreate,
  type ProjectWithPhases,
  GRAPH_DATA_TYPE,
} from "interfaces/freelancer/project";
import { EXPENSE_STATUS, PROJECT_TYPE } from "utils/freelancer/constants";
import { useProjectCalculations } from "./useProjectCalculations";
import { getMappedPhases } from "utils/freelancer/dashboard/graphUtils";

export interface DashboardProjectGraphData {
  name: string;
  paymentReceived: number;
  budgetUsed: number;
  estimatedBudget: number;
  estimatedCosts?: number;
}

export const useDashboardProjectGraphData = (
  project?: ProjectWithPhases
): DashboardProjectGraphData => {
  const projectPlanPhases =
    project?.phases.filter(
      ({ extras }) => extras.phaseStatus === EXPENSE_STATUS.DRAFT
    ) ?? [];
  const projectFactPhases =
    project?.phases.filter(
      ({ extras }) => extras.phaseStatus === EXPENSE_STATUS.SUBMITTED
    ) ?? [];
  const phases: ProjectPhaseCreate[] = getMappedPhases(projectPlanPhases);
  const workPhases: ProjectPhaseCreate[] = getMappedPhases(projectFactPhases);
  const { graphData } = useProjectCalculations(
    phases,
    workPhases,
    (project?.type as PROJECT_TYPE) ?? PROJECT_TYPE.FIX,
    project?.id
  );

  const result: DashboardProjectGraphData = {
    name: project?.name ?? "",
    paymentReceived: 0,
    budgetUsed: 0,
    estimatedBudget: 0,
  };
  graphData.forEach((graph) => {
    if (graph.type === GRAPH_DATA_TYPE.BUDGET_USED) {
      result.budgetUsed = graph.value;
    }
    if (graph.type === GRAPH_DATA_TYPE.PAYMENT_RECEIVED) {
      result.paymentReceived = graph.value;
    }
    if (graph.type === GRAPH_DATA_TYPE.ESTIMATED_BUDGET) {
      result.estimatedBudget = graph.value;
    }
    if (
      graph.type === GRAPH_DATA_TYPE.ESTIMATED_COSTS &&
      project?.type !== PROJECT_TYPE.HOURLY
    ) {
      result.estimatedCosts = graph.value;
    }
  });

  return result;
};
