import { useUpdateInvoiceStateMutation } from "store/invoices/invoicesServices";
import { useCallback, useState } from "react";
import { INVOICE_STATE } from "utils/freelancer/constants";

export const useInvoiceChangeState = (
  invoiceId: string,
  initialState: INVOICE_STATE
) => {
  const [updateInvoiceState] = useUpdateInvoiceStateMutation();
  const [invoiceState, setInvoiceState] = useState<INVOICE_STATE>(initialState);

  const handleChangeState = useCallback(
    async (event, child) => {
      if (child?.props?.value?.length) {
        const newState = child.props.value as INVOICE_STATE;
        try {
          await updateInvoiceState({
            invoice_id: invoiceId,
            body: { state: newState },
          });
          setInvoiceState(newState);
        } catch (e) {
          console.error("Error on updateInvoiceState, ", e);
        }
      }
    },
    [invoiceId]
  );

  const setCanceledState = useCallback(async () => {
    try {
      await updateInvoiceState({
        invoice_id: invoiceId,
        body: { state: INVOICE_STATE.CANCELED },
      });
      setInvoiceState(INVOICE_STATE.CANCELED);
    } catch (e) {
      console.error("Error on setCanceledState, ", e);
    }
  }, [invoiceId]);

  const setCreatedState = useCallback(async () => {
    try {
      await updateInvoiceState({
        invoice_id: invoiceId,
        body: { state: INVOICE_STATE.CREATED },
      });
      setInvoiceState(INVOICE_STATE.CREATED);
    } catch (e) {
      console.error("Error on setCreatedState, ", e);
    }
  }, [invoiceId]);

  return { handleChangeState, setCanceledState, setCreatedState, invoiceState };
};
