import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

interface SectionProps {
  isSpaceBottom?: number;
}

export const Container = styled("div")`
  height: calc(100vh - 60px);
  overflow-y: auto;
  background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
  padding: 0 30px;
`;

export const Section = styled(Box)<SectionProps>`
  background-color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  padding: 30px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ isSpaceBottom }) =>
    isSpaceBottom ? `${isSpaceBottom}px` : 0};
`;
