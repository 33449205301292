import {
  type Control,
  type FieldErrors,
  useForm,
  type UseFormWatch,
} from "react-hook-form";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { type ClockifyIntegrationForm } from "../interfaces/clockify-integration";

import { useAuth } from "hooks/useAuth";
import { useUpdateUserMutation } from "store/auth/authService";
import { useAlert } from "./freelancer/useAlert";
import { ALERT_SEVERITY } from "utils/freelancer/constants";

interface ClockifyIntegrationFormReturn {
  control: Control<ClockifyIntegrationForm>;
  errors: FieldErrors<ClockifyIntegrationForm>;
  isValid: boolean;
  handleSave: () => void;
  isLoading: boolean;
  watch: UseFormWatch<ClockifyIntegrationForm>;
  validateApiKey: (key: string) => Promise<boolean | string>;
  isAlertOpen: boolean;
  handleAlertClose: () => void;
  alertSeverity: ALERT_SEVERITY;
  alertMessage: string;
}

export const useClockifyIntegrationForm = (): ClockifyIntegrationFormReturn => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const {
    setAlertMessage,
    setAlertSeverity,
    openAlert,
    isAlertOpen,
    handleAlertClose,
    alertSeverity,
    alertMessage,
  } = useAlert();
  const {
    control,
    formState: { errors, isValid },
    getValues,
    setValue,
    watch,
  } = useForm<ClockifyIntegrationForm>({
    mode: "all",
    defaultValues: {
      apiKey: user?.settings.clockifyApiKey,
    },
  });
  const [updateUser, { isLoading }] = useUpdateUserMutation();

  useEffect(() => {
    if (user?.settings.clockifyApiKey) {
      setValue("apiKey", user?.settings.clockifyApiKey);
    }
  }, [user]);

  const handleSave = useCallback(async () => {
    const { apiKey } = getValues();

    if (!user) {
      return;
    }

    try {
      await updateUser({
        body: {
          firstname: user.firstname,
          lastname: user.lastname,
          phone_number: user.phone_number,
          residence: user.residence,
          settings: {
            language: user.settings.language,
            clockifyApiKey: apiKey,
          },
        },
      });

      setAlertSeverity(ALERT_SEVERITY.SUCCESS);
      setAlertMessage(
        t(
          "pages.UserProfile.ClockifyIntegration.clockifyApiKeyUpdatedSuccessfully"
        )
      );
    } catch (err) {
      setAlertMessage(
        t("pages.UserProfile.ClockifyIntegration.errorUpdatingClockifyApiKey")
      );
    }
    openAlert();
  }, [getValues]);

  const validateApiKey = async (key: string) => {
    const CLOCKIFY_USER_API_URL = "https://api.clockify.me/api/v1/user";

    try {
      const { status } = await axios.get(CLOCKIFY_USER_API_URL, {
        headers: {
          "x-api-key": key,
        },
      });

      if (status !== 200) {
        return t(
          "pages.UserProfile.ClockifyIntegration.clockifyApiKeyNotValid"
        );
      } else {
        return true;
      }
    } catch (err) {
      return t("pages.UserProfile.ClockifyIntegration.clockifyApiKeyNotValid");
    }
  };

  return {
    isValid,
    control,
    errors,
    handleSave,
    isLoading,
    watch,
    validateApiKey,
    isAlertOpen,
    handleAlertClose,
    alertSeverity,
    alertMessage,
  };
};
