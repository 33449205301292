import { type FC, useCallback, useMemo } from "react";
import { Button, Typography, Box } from "@mui/material";
import Footer from "components/Footer";
import { useTranslation } from "react-i18next";
import { PageContainerStyled, TitleContainer } from "../styles";
import {
  ButtonsContainerStyled,
  HeaderRowStyled,
  TableContainerStyled,
} from "./styles";
import { useDrawer } from "hooks/useDrawer";
import { useGetAllProductsQuery } from "store/productGroup/productGroupServices";
import { PlusIcon } from "assets/icons";
import AddEditServiceModal from "./AddEditServiceModal";
import ServicesTable from "./ServicesTable";
import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";
import { PRODUCT_GROUP } from "utils/freelancer/constants";
import Loading from "../../UI/Loading";
import { useScreenSize } from "hooks/useScreenSize";
import { useTrialUser } from "hooks/useTrialUser";
import LimitedAccessTooltip from "../LimitedAccessTooltip";
import { ButtonDisabledStyled } from "../Projects/styles";

const Services: FC = () => {
  const { t } = useTranslation();
  const { limitedAccess } = useTrialUser();
  const { isDesktop } = useScreenSize();
  const { project_id, pipeline_id } = useFreelancerDefaults();
  const { data, refetch, isLoading } = useGetAllProductsQuery({
    project_id,
    pipeline_id,
  });

  const services = useMemo(
    () =>
      data?.filter(
        ({ group_entity }) => group_entity.name === PRODUCT_GROUP.SERVICE
      ),
    [data]
  );
  const { isOpen: isAddServiceOpen, toggle: toggleAddServiceOpen } =
    useDrawer();

  const handleButtonClick = useCallback(
    () => toggleAddServiceOpen(),
    [toggleAddServiceOpen]
  );

  if (isLoading) {
    return (
      <PageContainerStyled>
        <Loading />
      </PageContainerStyled>
    );
  }

  return (
    <PageContainerStyled>
      <Box>
        <TitleContainer>
          <Typography variant="jumboTitle">
            {t("freelancer.page.services.title")}
          </Typography>
        </TitleContainer>
        <TableContainerStyled>
          <HeaderRowStyled>
            <Typography variant="h2">
              {t("freelancer.page.services.title")}
            </Typography>
            <ButtonsContainerStyled>
              {limitedAccess ? (
                <LimitedAccessTooltip>
                  <ButtonDisabledStyled
                    disabled
                    variant="textIcon"
                    className="create-btn"
                    startIcon={<PlusIcon />}
                    onClick={handleButtonClick}
                  >
                    {t("service")}
                  </ButtonDisabledStyled>
                </LimitedAccessTooltip>
              ) : (
                <Button
                  variant="textIcon"
                  className="create-btn"
                  startIcon={<PlusIcon />}
                  onClick={handleButtonClick}
                >
                  {t("service")}
                </Button>
              )}
            </ButtonsContainerStyled>
          </HeaderRowStyled>
          <ServicesTable
            services={services}
            refetch={refetch}
            openAddServiceModal={handleButtonClick}
          />
          <AddEditServiceModal
            isOpen={isAddServiceOpen}
            onClose={toggleAddServiceOpen}
            refetch={refetch}
          />
        </TableContainerStyled>
      </Box>
      {isDesktop && <Footer isSpace isSticki={false} />}
    </PageContainerStyled>
  );
};

export default Services;
