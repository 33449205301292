import { type FC } from "react";
// Icons
import MasterCardIcon from "assets/icons/visa-master-card.png";
// Styles
import { Container, Wrapper, Image } from "./styles";

const LoginFooter: FC = () => {
  return (
    <Container>
      <Wrapper>
        <Image src={MasterCardIcon} alt="cardIcon" />
      </Wrapper>
    </Container>
  );
};

export default LoginFooter;
