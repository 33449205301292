import { BaseQueryParams } from "store/baseQuery";
import type { Invoice, InvoiceCreate, InvoicePaidItem } from "./invoicesTypes";
import { INVOICE_STATE } from "utils/freelancer/constants";

export const invoicesServices = BaseQueryParams("invoices", [
  "INVOICES",
]).injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.query<Invoice[], null>({
      query: () => ({
        url: "/invoices",
        method: "GET",
      }),

      providesTags: () => ["INVOICES"],
    }),
    getInvoice: builder.query<Invoice, { invoice_id: string }>({
      query: ({ invoice_id }) => ({
        url: `/invoices/${invoice_id}`,
        method: "GET",
      }),

      providesTags: () => ["INVOICES"],
    }),
    getPaidInvoiceItemsByProject: builder.query<
      InvoicePaidItem[],
      { project_id?: string }
    >({
      query: ({ project_id }) => ({
        url: `/invoices/paid-items/${project_id}`,
        method: "GET",
      }),

      providesTags: () => ["INVOICES"],
    }),
    createInvoice: builder.mutation<unknown, InvoiceCreate>({
      query: (body) => ({
        url: "/invoices",
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["INVOICES"],
    }),
    deleteInvoice: builder.mutation<unknown, { invoice_id: string }>({
      query: ({ invoice_id }) => ({
        url: `/invoices/${invoice_id}`,
        method: "DELETE",
      }),

      invalidatesTags: () => ["INVOICES"],
    }),
    updateInvoiceState: builder.mutation<
      unknown,
      { invoice_id: string; body: { state: INVOICE_STATE } }
    >({
      query: ({ invoice_id, body }) => ({
        url: `/invoices/${invoice_id}/state`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["INVOICES"],
    }),
    updateInvoice: builder.mutation<
      unknown,
      { invoice_id: string; body: { valid_until: Date } }
    >({
      query: ({ invoice_id, body }) => ({
        url: `/invoices/${invoice_id}`,
        method: "PATCH",
        body,
      }),

      invalidatesTags: () => ["INVOICES"],
    }),
  }),
});

export const {
  useCreateInvoiceMutation,
  useGetInvoicesQuery,
  useGetInvoiceQuery,
  useDeleteInvoiceMutation,
  useUpdateInvoiceStateMutation,
  useUpdateInvoiceMutation,
  useGetPaidInvoiceItemsByProjectQuery,
} = invoicesServices;
