import type { FC, MouseEvent } from "react";
// Styles
import {
  Container,
  Button,
  Wrapper,
  Label,
  ButtonContainer,
} from "pages/UserProfile/UserTariffPlans/ToggleButtons/styles";
import { useTranslation } from "react-i18next";
import { Link } from "@mui/material";
import { PrimaryButtonStyled } from "pages/UserProfile/UserTariffPlans/styles";
import { useTrialUser } from "hooks/useTrialUser";

interface ToggleButtonsProps {
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
}

const ToggleButtons: FC<ToggleButtonsProps> = ({
  value,
  setValue,
  disabled = false,
}) => {
  const handleChange = (event: MouseEvent<HTMLElement>, nextView: string) => {
    if (!nextView) return;
    setValue(nextView);
  };
  const { limitedAccess } = useTrialUser();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Label>{t("Тип підписки")}</Label>
      <ButtonContainer>
        <Container
          onChange={handleChange}
          orientation="horizontal"
          value={value}
          disabled={disabled}
          exclusive
        >
          <Button value="month" aria-label="list">
            {t("Місячна")}
          </Button>
          <Button value="year" aria-label="module">
            {t("Річна")}
          </Button>
        </Container>
        <PrimaryButtonStyled disabled={limitedAccess} variant="contained">
          <Link
            href={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL ?? ""}
            color="inherit"
            target="_blank"
            underline="none"
          >
            {t("Керувати підпискою")}
          </Link>
        </PrimaryButtonStyled>
      </ButtonContainer>
    </Wrapper>
  );
};

export default ToggleButtons;
