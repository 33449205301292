import type {
  ProjectCreate,
  ProjectCreateResponse,
} from "interfaces/freelancer/project";
import type { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import type { MutationDefinition } from "@reduxjs/toolkit/query";
import { ALERT_SEVERITY } from "../constants";

export const projectCreate = async (
  mappedPhases: ProjectCreate[],
  mappedWorkPhases: ProjectCreate[],
  createProduct: MutationTrigger<MutationDefinition<any, any, any, any>>,
  setAlertSeverity: (text: ALERT_SEVERITY) => void,
  project_id?: string,
  pipeline_id?: string
): Promise<string | undefined> => {
  try {
    if (mappedPhases?.length) {
      const createdPhaseResponse = await createProduct({
        project_id,
        body: [
          {
            pipeline_id,
            products: mappedPhases,
          },
        ],
      });

      const createdPhases =
        // @ts-ignore
        createdPhaseResponse.data[0] as ProjectCreateResponse[];

      if (mappedWorkPhases.length) {
        mappedWorkPhases.forEach((workPhase) => {
          const [twinPhase] = createdPhases.filter(
            ({ name }) => workPhase.name === name
          );
          let workPhaseToAdd = workPhase;
          if (twinPhase) {
            workPhaseToAdd = {
              ...workPhase,
              extras: {
                ...workPhase.extras,
                twin_id: twinPhase.id,
              },
            };
          }
          createProduct({
            project_id,
            body: [
              {
                pipeline_id,
                products: [workPhaseToAdd],
              },
            ],
          });
        });
      }
      return createdPhases[0].group_id;
    } else {
      if (mappedWorkPhases?.length) {
        const response = createProduct({
          project_id,
          body: [
            {
              pipeline_id,
              products: mappedWorkPhases,
            },
          ],
        });
        const createdPhases =
          // @ts-ignore
          response.data[0] as ProjectCreateResponse[];

        return createdPhases[0].group_id;
      }
    }
  } catch (e) {
    setAlertSeverity(ALERT_SEVERITY.ERROR);
    console.error("Error on project create, ", e);
  }
};
