/* eslint-disable max-len */
import React, { forwardRef, useState } from "react";
import { Controller } from "react-hook-form";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import Input from "components/UI/Input";
// Styles
import { Label, FormControl } from "./styles";

export interface MuiCreatableSelectProps {
  data: { label: string; value: string; inputValue?: string }[];
  label: string;
  control: any;
  name: string;
  errors?: any;
  activityIndex: number;
  index: number;
  defaultValue?: any;
}

interface FilmOptionType {
  inputValue?: string;
  label: string;
  value?: string;
}

const filter = createFilterOptions<FilmOptionType>();

const MuiCreatableSelect = forwardRef(
  (
    {
      errors,
      activityIndex,
      index,
      data,
      label,
      control,
      name,
      defaultValue,
    }: MuiCreatableSelectProps,
    ref
  ) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<FilmOptionType | null>(
      defaultValue || null
    );

    const onChange = (event: any, newValue: any, onChangeForm: any) => {
      if (typeof newValue === "string") {
        onChangeForm(newValue as string);
        setValue({
          label: newValue,
        });
      } else if (newValue && newValue.inputValue) {
        onChangeForm(newValue?.inputValue);
        setValue({
          label: newValue.inputValue,
        });
      } else {
        onChangeForm(newValue?.value);
        setValue(newValue);
      }
    };

    const filterOptions = (options: any, params: any) => {
      const filtered = filter(options, params);
      const { inputValue } = params;
      const isExisting = options.some(
        (option: any) => inputValue === option.label
      );

      if (inputValue !== "" && !isExisting) {
        filtered.push({
          inputValue,
          label: `Додати "${inputValue}"`,
        });
      }

      return filtered;
    };

    const getOptionLabel = (option: any) => {
      if (typeof option === "string") {
        return option;
      }

      if (option.inputValue) {
        return option.inputValue;
      }

      return option.label;
    };

    return (
      <FormControl ref={ref as React.RefObject<HTMLDivElement>}>
        {label && <Label>{label}</Label>}

        <Controller
          render={({ field }) => (
            <Autocomplete
              {...field}
              value={value}
              onChange={(event: any, newValue: any) =>
                onChange(event, newValue, field.onChange)
              }
              filterOptions={filterOptions}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={data}
              getOptionLabel={getOptionLabel}
              renderOption={(props, option) => (
                <li {...props}>{option.label}</li>
              )}
              sx={{
                maxWidth: 120,
                height: 49,
                padding: 0,
                width: 120,
              }}
              freeSolo
              renderInput={(params) => (
                <Input
                  defaultValue={defaultValue}
                  {...params}
                  name={name}
                  error={!!errors}
                  helperText={errors}
                />
              )}
            />
          )}
          // rules={{ required: t('fieldIsRequired') }}
          control={control}
          name={name}
        />
      </FormControl>
    );
  }
);

export default MuiCreatableSelect;
