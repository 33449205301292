import { type UserTransaction } from "store/transactions/transactionsTypes";

export interface Data {
  project: string;
  details: string;
  date: string;
  amount: number;
}

interface HeadCell {
  id: keyof UserTransaction;
  label: string;
  showOnMobile: boolean;
}

export const headCells: HeadCell[] = [
  {
    id: "type",
    label: "freelancer.page.dashboard.transactions.type",
    showOnMobile: true,
  },
  // {
  //   id: "details",
  //   label: "freelancer.page.dashboard.transactions.details",
  //   showOnMobile: false,
  // },
  {
    id: "created_at",
    label: "freelancer.page.dashboard.transactions.date",
    showOnMobile: true,
  },
  {
    id: "amount",
    label: "freelancer.page.dashboard.transactions.amount",
    showOnMobile: true,
  },
];
