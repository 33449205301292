import { styled } from "@mui/material/styles";
import { Box, Breakpoint, Button as StyledButton } from "@mui/material";
import Input from "../../../components/UI/Input";

export const Container = styled(Box)`
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const Button = styled(StyledButton)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`;

export const ContainerButton = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const InputWrap = styled(Box, {
  shouldForwardProp: (prop) => prop !== "fullWidth",
})<{ fullWidth?: boolean }>(({ theme, fullWidth = false }) => ({
  width: fullWidth ? "calc(100% - 40px)" : "calc((100% - 40px) / 2)",
  marginRight: "20px",
  ".MuiFormControl-root": {
    width: "100%",
  },
  [theme.breakpoints.down("md" as Breakpoint)]: {
    width: "100%",
    marginRight: 0,
  },
}));

export const InputStyled = styled(Input)(({ theme }) => ({
  width: "calc((100% - 40px) / 2)",
  marginRight: "20px",
  ".MuiFormControl-root": {
    width: "100%",
  },
  [theme.breakpoints.down("md" as Breakpoint)]: {
    width: "100%",
    marginRight: 0,
  },
}));
