import { useSearchParams } from "react-router-dom";
import {
  INVOICE_ITEM_KIND,
  URL_PARAMS,
} from "../../utils/freelancer/constants";
import { useGetPaidInvoiceItemsByProjectQuery } from "store/invoices/invoicesServices";
import { useCallback } from "react";
import { getNumber } from "../../utils/freelancer/helpers/getNumber";
import { type InvoicePaidItem } from "store/invoices/invoicesTypes";
import { type InvoiceEmployeeExpense } from "interfaces/freelancer/invoice";

interface BillableHoursReturn {
  paidExpensesByProject: InvoicePaidItem[];
  getBillableHours: (employeeField: InvoiceEmployeeExpense) => {};
}

export const useBillableHours = (): BillableHoursReturn => {
  const [searchParams] = useSearchParams();
  const projectIdFromUrl = searchParams.get(URL_PARAMS.PROJECT_ID);
  const { data: paidExpenses = [] } = useGetPaidInvoiceItemsByProjectQuery({
    project_id: `${projectIdFromUrl}`,
  });

  const getBillableHours = useCallback(
    (employeeField): string => {
      const billedHours = paidExpenses
        .filter(
          ({ id, kind }) =>
            (employeeField.itemId === id || employeeField.name === id) &&
            kind === INVOICE_ITEM_KIND.TEAM
        )
        .reduce((partialSum, { total }) => partialSum + total, 0);
      return (getNumber(employeeField.totalHours) - billedHours).toFixed(1);
    },
    [paidExpenses]
  );

  return { paidExpensesByProject: paidExpenses, getBillableHours };
};
