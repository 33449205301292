import { type FC } from "react";
import {
  type Control,
  Controller,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import type { InvoiceCreateForm } from "interfaces/freelancer/invoice";
import { Checkbox } from "@mui/material";
import { StyledInput } from "pages/Teams/AddEmployeeModal/styles";
import { useTranslation } from "react-i18next";
import { LabelStyled } from "components/Freelancer/Projects/AddEdit/Expenses/styles";
import { getNumber } from "utils/freelancer/helpers/getNumber";
import { CheckboxNameContainerStyled, InputContainerStyled } from "./styles";

import {
  LabelsContainerStyled,
  RowStyled,
  ColumnContainerStyled,
} from "../styles";
import { DownPaymentContainerStyled } from "../../styles";
import { useServiceBillableAmount } from "hooks/freelancer/useServiceBillableAmount";

interface ServicesProps {
  parentIndex: number;
  control: Control<InvoiceCreateForm>;
  overrideLanguage?: string;
}

const Services: FC<ServicesProps> = ({
  parentIndex,
  control,
  overrideLanguage,
}) => {
  const { fields } = useFieldArray({
    control,
    name: `phases.${parentIndex}.services`,
  });
  const { t } = useTranslation();
  const labels = [
    "",
    "",
    "freelancer.page.invoices.add.services.billedServicePrice",
    "freelancer.page.invoices.add.services.invoicedAmount",
    "freelancer.page.invoices.add.services.remaining",
  ];
  const watchServices = useWatch({
    control,
    name: `phases.${parentIndex}.services`,
  });
  const controlledServices = fields.map((field, index) => {
    return {
      ...field,
      ...watchServices?.[index],
    };
  });
  const { getAmount } = useServiceBillableAmount();

  return (
    <ColumnContainerStyled>
      {controlledServices?.length && (
        <LabelsContainerStyled>
          {labels.map((label) => (
            <LabelStyled key={label}>
              {t(label, {
                lng: overrideLanguage,
              })}
            </LabelStyled>
          ))}
        </LabelsContainerStyled>
      )}
      {controlledServices?.map((serviceField, index) => (
        <RowStyled key={`invoice-services-${serviceField.id}`}>
          <CheckboxNameContainerStyled>
            <Controller
              name={`phases.${parentIndex}.services.${index}.name`}
              control={control}
              render={({ field }) => <StyledInput {...field} />}
            />
          </CheckboxNameContainerStyled>
          <InputContainerStyled>
            <StyledInput disabled value={serviceField.billedPrice} />
          </InputContainerStyled>
          <InputContainerStyled>
            <Controller
              name={`phases.${parentIndex}.services.${index}.invoicedAmount`}
              control={control}
              render={({ field }) => <StyledInput {...field} type="number" />}
            />
          </InputContainerStyled>
          <InputContainerStyled>
            <StyledInput disabled value={getAmount(serviceField)} />
          </InputContainerStyled>
        </RowStyled>
      ))}
      {controlledServices?.length && (
        <DownPaymentContainerStyled>
          <Controller
            name={`phases.${parentIndex}.isDownPaymentService`}
            control={control}
            render={({ field: CheckboxField }) => (
              <Checkbox checked={CheckboxField.value} {...CheckboxField} />
            )}
          />
          <LabelStyled>
            {t("downPayment", {
              lng: overrideLanguage,
            })}
          </LabelStyled>
        </DownPaymentContainerStyled>
      )}
    </ColumnContainerStyled>
  );
};

export default Services;
