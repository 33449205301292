import { FC } from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/UI/Modal";
import {
  Button,
  Container,
  ContainerButton,
  Title,
} from "components/ConfirmationModal/styles";

interface ModalProps {
  submitHandler?: () => void;
  onClose: () => void;
  isOpen: boolean;
}

const SaveProjectModal: FC<ModalProps> = ({
  submitHandler,
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation();
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Container>
        <Title variant="h1">
          {t("freelancer.page.addEditProject.employee.saveProject.title")}
        </Title>

        <ContainerButton>
          <Button
            isDelete={false}
            onClick={submitHandler}
            variant="contained"
            component="span"
          >
            {t(
              "freelancer.page.addEditProject.employee.saveProject.submitTitle"
            )}
          </Button>
          <Button variant="light" onClick={onClose}>
            {t(
              "freelancer.page.addEditProject.employee.saveProject.cancelTitle"
            )}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default SaveProjectModal;
