import { Tooltip } from "@mui/material";
import TooltipText from "components/HeaderUserMenu/UserMembershipTooltip/TooltipText";
import { type FC, type ReactElement } from "react";
import { freelanceUserRoutesPath } from "routes/routePath";

interface LimitedAccessTooltipProps {
  children: ReactElement;
}
const LimitedAccessTooltip: FC<LimitedAccessTooltipProps> = ({ children }) => {
  return (
    <Tooltip
      enterTouchDelay={0}
      title={
        <TooltipText navigateTo={`${freelanceUserRoutesPath.SETTINGS}#plans`} />
      }
      placement="bottom"
    >
      {children}
    </Tooltip>
  );
};

export default LimitedAccessTooltip;
