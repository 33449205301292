import { type FC, useCallback } from "react";
import { Button } from "@mui/material";
import { PlusIcon } from "assets/icons";
import { useTranslation } from "react-i18next";
import { ButtonsContainerStyled } from "./styles";
import { EMPLOYEE_TYPE } from "utils/freelancer/constants";
import { useTrialUser } from "hooks/useTrialUser";
import LimitedAccessTooltip from "../LimitedAccessTooltip";
import { ButtonDisabledStyled } from "../Projects/styles";

interface ButtonsProps {
  handleClick: (type: EMPLOYEE_TYPE) => void;
}

const Buttons: FC<ButtonsProps> = ({ handleClick }) => {
  const { t } = useTranslation();
  const { limitedAccess } = useTrialUser();
  const handlePartnerClick = useCallback(
    () => handleClick(EMPLOYEE_TYPE.PARTNER),
    [handleClick]
  );
  const handleCustomerClick = useCallback(
    () => handleClick(EMPLOYEE_TYPE.CONTRACTOR),
    [handleClick]
  );

  return (
    <ButtonsContainerStyled>
      {limitedAccess ? (
        <LimitedAccessTooltip>
          <ButtonDisabledStyled
            disabled
            variant="textIcon"
            className="create-btn"
            startIcon={<PlusIcon />}
            onClick={handleCustomerClick}
          >
            {t("client")}
          </ButtonDisabledStyled>
        </LimitedAccessTooltip>
      ) : (
        <Button
          variant="textIcon"
          className="create-btn"
          startIcon={<PlusIcon />}
          onClick={handleCustomerClick}
        >
          {t("client")}
        </Button>
      )}
      {limitedAccess ? (
        <LimitedAccessTooltip>
          <ButtonDisabledStyled
            disabled
            variant="textIcon"
            className="create-btn"
            startIcon={<PlusIcon />}
            onClick={handlePartnerClick}
          >
            {t("teammate")}
          </ButtonDisabledStyled>
        </LimitedAccessTooltip>
      ) : (
        <Button
          variant="textIcon"
          className="create-btn"
          startIcon={<PlusIcon />}
          onClick={handlePartnerClick}
        >
          {t("teammate")}
        </Button>
      )}
    </ButtonsContainerStyled>
  );
};

export default Buttons;
