import { type ReactNode } from "react";
import { freelanceUserRoutesPath } from "routes/routePath";
import {
  InvoicesIcon,
  ProjectsIcon,
  HomeIcon,
  ServicesIcon,
  FreelanceSettingsIcon,
  MembersIcon,
  NeedHelpIcon,
} from "assets/icons";
import { type TFunction } from "i18next";

export interface SideBarLink {
  isActive: boolean;
  icon: ReactNode;
  label: string;
  route: string;
}

export const getSideBarElements = (
  pathname: string,
  t: TFunction
): SideBarLink[] => [
  {
    isActive: pathname === freelanceUserRoutesPath.DASHBOARD,
    icon: <HomeIcon />,
    label: t("freelancer.sidebar.overview"),
    route: freelanceUserRoutesPath.DASHBOARD,
  },
  {
    isActive: pathname.includes(freelanceUserRoutesPath.PROJECTS),
    icon: <ProjectsIcon />,
    label: t("freelancer.sidebar.projects"),
    route: freelanceUserRoutesPath.PROJECTS,
  },
  {
    isActive: pathname === freelanceUserRoutesPath.INVOICES,
    icon: <InvoicesIcon />,
    label: t("freelancer.sidebar.invoices"),
    route: freelanceUserRoutesPath.INVOICES,
  },
  {
    isActive: pathname === freelanceUserRoutesPath.SERVICES,
    icon: <ServicesIcon />,
    label: t("freelancer.sidebar.services"),
    route: freelanceUserRoutesPath.SERVICES,
  },
  // {
  //   isActive: pathname === freelanceUserRoutesPath.PROFIT,
  //   icon: <ChartPieIcon />,
  //   label: t("freelancer.sidebar.profit"),
  //   route: freelanceUserRoutesPath.PROFIT,
  // },
  // {
  //   isActive: pathname === freelanceUserRoutesPath.EXPENSES,
  //   icon: <ChartPie2Icon />,
  //   label: t("freelancer.sidebar.expenses"),
  //   route: freelanceUserRoutesPath.EXPENSES,
  // },
  {
    isActive: pathname === freelanceUserRoutesPath.MEMBERS,
    icon: <MembersIcon />,
    label: t("freelancer.sidebar.members"),
    route: freelanceUserRoutesPath.MEMBERS,
  },
  {
    isActive: pathname === freelanceUserRoutesPath.SETTINGS,
    icon: <FreelanceSettingsIcon />,
    label: t("freelancer.sidebar.settings"),
    route: freelanceUserRoutesPath.SETTINGS,
  },
  {
    isActive: false,
    icon: <NeedHelpIcon />,
    label: t("freelancer.sidebar.needHelp"),
    route: "mailto: support@pipeliner.online",
  },
];
