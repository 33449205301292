import type {
  ProjectPhaseCreate,
  ProjectUpdate,
} from "interfaces/freelancer/project";
import { ALERT_SEVERITY } from "../constants";
import { getMappedPhaseToUpdate } from "./getMappedPhaseToUpdate";

export const updatePhases = async (
  phasesToUpdate: ProjectPhaseCreate[],
  pipelineId: string,
  updateProjectById: (props: { product: ProjectUpdate; id: string }) => void,
  setAlertSeverity: (text: ALERT_SEVERITY) => void,
  projectType?: string
): Promise<void> => {
  try {
    await Promise.all(
      phasesToUpdate.map((phaseToUpdate) => {
        if (phaseToUpdate.id) {
          const mappedPhaseToUpdate = getMappedPhaseToUpdate(
            phaseToUpdate,
            pipelineId,
            projectType
          );
          return updateProjectById({
            product: mappedPhaseToUpdate,
            id: phaseToUpdate.id,
          });
        }
        return null;
      })
    );
  } catch (e) {
    setAlertSeverity(ALERT_SEVERITY.ERROR);
    console.error("Error on remove phase, ", e);
  }
};
