import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { palette } from "styles/theme/palette";
import { BaseInfoContainerStyled } from "../../BaseInfo/styles";

export const BankDetailsContainer = styled(BaseInfoContainerStyled)(() => ({
  padding: "25px 0 15px",
}));
export const ContentContainerStyled = styled(Box)(() => ({
  display: "flex",
  padding: "10px 10px 0 30px",
}));

export const ColumnStyled = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  width: "50%",
  "&:not(:last-of-type)": {
    borderRight: `1px solid ${palette.custom.green.chartGreen}`,
    marginRight: "20px",
  },
}));

export const InputContainerStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "calc((100% - 40px) / 2)",
  marginRight: "20px",
  overflow: "hidden",
  "& > span": {
    margin: "10px 0",
  },
  "& > .MuiFormControl-root": {
    marginBottom: "20px",
    ".MuiFormHelperText-root": {
      minHeight: 0,
      height: 0,
      margin: 0,
    },
  },
}));
