import React, { useState } from "react";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  ToggleButton,
} from "@mui/material";
import {
  CancelIcon,
  TrashIcon,
  ArrowGroupIcon,
  CalendarIcon,
} from "assets/icons";
import Input from "components/UI/Input";
import Select from "components/UI/Select";
import DatePicker from "react-datepicker";
import { Label, Container as InputContainer } from "components/UI/Input/styles";

// Styles
import "react-datepicker/dist/react-datepicker.css";

// Styles
import {
  Container,
  FieldWrap,
  Arrow,
  CollapseHeader,
  DepartmentNameCollapse,
  DeleteButton,
  DatePickerWrapper,
  IconWrap,
  InlineFormWrap,
  StyledToggleButtonGroup,
} from "./styles";

interface Product {
  name: string;
  startDate: Date;
  endDate: Date;
  shareOfsales: string;
  unit: string;
}
interface ProductsForm {
  name: string;
  link: string;
  groupOfChannels: string;
  products: Product[];
}

interface IFormRow {
  control: Control<ProductsForm>;
  index: number;
  errors: FieldErrors<ProductsForm>;
  update: any;
  remove: any;
  watched: FieldValues;
}

const FormRow: React.FC<IFormRow> = ({
  control,
  index,
  errors,
  update,
  remove,
  watched,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(watched.products[index].startDate);
  const [endDate, setEndDate] = useState(watched.products[index].endDate);

  return (
    <Container>
      <CollapseHeader onClick={() => setIsOpen((prev) => !prev)}>
        <DepartmentNameCollapse>
          {watched.products[index].name}
        </DepartmentNameCollapse>
        <Arrow $isOpen={isOpen} />
      </CollapseHeader>
      <Collapse className="callapse" in={isOpen} timeout="auto" unmountOnExit>
        <FieldWrap key={watched.products[index].name}>
          <Controller
            render={({ field }) => (
              <Select
                label="Назва продукта"
                data={[]}
                {...field}
                className="amount-input"
                fullWidth
              />
            )}
            control={control}
            name={`products.${index}.name`}
            rules={{ required: "fieldIsRequired" }}
          />
        </FieldWrap>
        <InlineFormWrap>
          <InputContainer className="inline-input time-input">
            <Label>Дата початку</Label>
            <DatePickerWrapper>
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
              />
              <IconWrap>
                <CalendarIcon />
              </IconWrap>
            </DatePickerWrapper>
          </InputContainer>
          <InputContainer className="inline-input">
            <Label>Дата завершення</Label>
            <DatePickerWrapper>
              <DatePicker
                selected={endDate}
                onChange={(date: Date) => setEndDate(date)}
              />
              <IconWrap>
                <CalendarIcon />
              </IconWrap>
            </DatePickerWrapper>
          </InputContainer>
        </InlineFormWrap>
        <InlineFormWrap className="checkbox-container">
          <InputContainer>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label="Не визначено" />
            </FormGroup>
          </InputContainer>
        </InlineFormWrap>
        <FieldWrap className="toggle-container">
          <InputContainer>
            <Controller
              render={({ field }) => (
                <Input
                  label="Доля продажів"
                  {...field}
                  className="amount-input"
                />
              )}
              control={control}
              name={`products.${index}.shareOfsales`}
              rules={{ required: "fieldIsRequired" }}
            />
          </InputContainer>
          <InputContainer>
            <Controller
              render={({ field }) => (
                <StyledToggleButtonGroup {...field} exclusive>
                  <ToggleButton value="%">%</ToggleButton>
                  <ToggleButton value="Шт">Шт.</ToggleButton>
                </StyledToggleButtonGroup>
              )}
              control={control}
              name={`products.${index}.unit`}
              rules={{ required: "fieldIsRequired" }}
            />
          </InputContainer>
        </FieldWrap>
      </Collapse>
    </Container>
  );
};

export default FormRow;
