import { useUpdateProductMutation } from "../store/dashboard/dashboardServices";
import { useCreateProductMutation } from "../store/tutors/tutorsServices";
import { type Control, type FieldErrors, useForm } from "react-hook-form";
import { formDefaultValues } from "../components/Freelancer/Services/AddEditServiceModal/data";
import { useCallback, useEffect } from "react";
import { type ServiceForm } from "../components/Freelancer/Services/AddEditServiceModal";
import { type ProductAsService } from "../interfaces/product";
import { PRODUCT_GROUP } from "../utils/freelancer/constants";
import { useFreelancerDefaults } from "./useFreelancerDefaults";

interface AddEditServiceFormReturn {
  control: Control<ServiceForm>;
  errors: FieldErrors<ServiceForm>;
  isValid: boolean;
  handleSave: () => void;
  handleClose: () => void;
}

export const useAddEditServiceForm = (
  service?: ProductAsService | null,
  onClose?: () => void,
  refetch?: () => void
): AddEditServiceFormReturn => {
  const { project_id, pipeline_id } = useFreelancerDefaults();
  const [updateService] = useUpdateProductMutation();
  const [createService] = useCreateProductMutation();
  const {
    control,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm<ServiceForm>({
    mode: "all",
    defaultValues:
      {
        name: service?.name,
        price: service?.price,
        description: service?.extras?.serviceDescription,
      } || formDefaultValues,
  });

  const handleSave = useCallback(async () => {
    const { name, price, description } = getValues();
    if (service) {
      await updateService({
        product_id: service.id,
        body: {
          ...service,
          name,
          price,
          pipeline_id,
          extras: { serviceDescription: description },
        },
      });
    } else {
      await createService({
        project_id,
        body: [
          {
            pipeline_id,
            products: [
              {
                name,
                price,
                amount: 1,
                group: `${PRODUCT_GROUP.SERVICE}`,
                extras: {
                  serviceDescription: description,
                },
              },
            ],
          },
        ],
      });
    }
    handleClose();
    refetch?.();
  }, [service]);
  const handleClose = useCallback(() => {
    reset();
    onClose?.();
  }, [reset, onClose]);

  useEffect(() => {
    if (service) {
      setValue("name", service?.name);
      setValue("price", service?.price);
      setValue("description", service?.extras?.serviceDescription ?? "");
    }
  }, [service]);

  return { isValid, control, errors, handleClose, handleSave };
};
