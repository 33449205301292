import { type ChangeEvent, type FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/UI/Modal";
import {
  Button,
  Container,
  ContainerButton,
  Title,
} from "components/ConfirmationModal/styles";
import { useNavigate } from "react-router";
import { freelanceUserRoutesPath } from "routes/routePath";
import { PROJECT_TYPE, URL_PARAMS } from "utils/freelancer/constants";
import { getUrlWithParams } from "utils/helpers/getUrlWithParams";
import { getProjectTypes } from "utils/freelancer/helpers/getProjectTypes";
import { FormControlLabel, Radio } from "@mui/material";
import { ButtonWithMarginStyled, RadioGroupStyled } from "./styles";

interface ProjectTypeModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const ProjectTypeModal: FC<ProjectTypeModalProps> = ({ onClose, isOpen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [projectType, setProjectType] = useState<PROJECT_TYPE>(
    PROJECT_TYPE.HOURLY
  );
  const projectTypeOptions = getProjectTypes(t);
  const navigateToCreateProjectPage = useCallback(() => {
    const projectUrl = getUrlWithParams(freelanceUserRoutesPath.PROJECTS_ADD, [
      { key: URL_PARAMS.TYPE, value: projectType },
    ]);
    navigate(projectUrl);
  }, [navigate, projectType]);

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setProjectType(target.value as PROJECT_TYPE);
    },
    [setProjectType]
  );

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Container>
        <Title variant="h1">
          {t("freelancer.page.projects.projectTypeModal.title")}
        </Title>
        <RadioGroupStyled value={projectType} onChange={handleChange}>
          {projectTypeOptions.map(({ label, value }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio />}
              label={label}
            />
          ))}
        </RadioGroupStyled>
        <ContainerButton>
          <ButtonWithMarginStyled
            onClick={navigateToCreateProjectPage}
            variant="contained"
            component="span"
          >
            {t("freelancer.page.projects.projectTypeModal.confirmButton")}
          </ButtonWithMarginStyled>
          <Button variant="light" onClick={onClose}>
            {t("cancel")}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default ProjectTypeModal;
