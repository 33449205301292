import { type FC, useCallback, useState } from "react";
import Modal from "components/UI/Modal";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  ContainerButton,
  Title,
} from "pages/Teams/AddEmployeeModal/styles";
import { AddHoursContainerStyled, InputStyled } from "../styles";
import {
  ProjectPhaseCreate,
  ProjectUpdate,
  type ProjectPhaseEmployee,
} from "interfaces/freelancer/project";
import { useAddTimeLogMutation } from "store/timeLog/timeLogServices";
import { useUpdateProductMutation } from "store/productGroup/productGroupServices";
import { useAuth } from "hooks/useAuth";
import {
  ClockifyIntegrationConfig,
  ClockifyTimeEntry,
} from "store/productGroup/productGroupTypes";
import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";

interface SelectTeammateModalProps {
  onClose: () => void;
  isOpen: boolean;
  selectedEmployee: ProjectPhaseEmployee;
  projectId?: string;
  clockifyTimeEntries?: ClockifyTimeEntry[];
  refetchTimeLogData: () => void;
  refetchClockifyEmployeesTimeEntries: () => void;
  phase: ProjectPhaseCreate;
  clockifyIntegrationConfig?: ClockifyIntegrationConfig;
}

const AddHoursModal: FC<SelectTeammateModalProps> = ({
  isOpen,
  selectedEmployee,
  onClose,
  projectId,
  clockifyTimeEntries,
  refetchTimeLogData,
  refetchClockifyEmployeesTimeEntries,
  phase,
  clockifyIntegrationConfig,
}) => {
  const { t } = useTranslation();
  const [hoursToAdd, setHoursToAdd] = useState<string>("0");
  const [addTimeLogHours] = useAddTimeLogMutation();
  const [updatePhase] = useUpdateProductMutation();
  const { user } = useAuth();
  const { pipeline_id } = useFreelancerDefaults();

  const handleAddHours = useCallback(({ target }) => {
    setHoursToAdd(target.value);
  }, []);

  const closeModal = useCallback(() => {
    setHoursToAdd("0");
    onClose();
  }, []);

  const submitHours = useCallback(async () => {
    try {
      const employeeId =
        selectedEmployee?.name === user?.user_id
          ? user?.user_id
          : selectedEmployee.employeeId;
      const hoursToAddInMinutes = parseFloat(hoursToAdd) * 60;
      await addTimeLogHours({
        project_id: projectId ?? "",
        phase_id: phase.id ?? "",
        employee_id: employeeId,
        value: hoursToAddInMinutes,
      });

      if ((clockifyTimeEntries?.length ?? 0) > 0) {
        const { [employeeId]: _, ...updatedClockifyIntegrationEmployees } =
          clockifyIntegrationConfig?.employees ?? {};
        const updatedPhase: ProjectUpdate = Object.assign({}, phase, {
          id: phase.id ?? "",
          pipeline_id,
          group_id: phase.groupId ?? "",
          expenses: undefined,
          price: 0,
          amount: 0,
          extras: {
            clockify: {
              project: clockifyIntegrationConfig?.project,
              workspace: clockifyIntegrationConfig?.workspace,
              employees: updatedClockifyIntegrationEmployees,
            },
          },
        });
        await updatePhase({
          products: [updatedPhase],
        });
      }

      refetchClockifyEmployeesTimeEntries();
      refetchTimeLogData();
      closeModal();
    } catch (e) {
      console.error("Error on add hours, ", e);
    }
  }, [hoursToAdd, refetchTimeLogData, refetchClockifyEmployeesTimeEntries]);

  return (
    <Modal onClose={closeModal} isOpen={isOpen}>
      <AddHoursContainerStyled>
        <Title variant="h1">
          {t("freelancer.page.addEditProject.employee.addHours.title")}
        </Title>
        <InputStyled
          type="number"
          label={t("freelancer.page.addEditProject.employee.addHours.label")}
          placeholder={t(
            "freelancer.page.addEditProject.employee.addHours.placeholder"
          )}
          fullWidth
          onChange={handleAddHours}
        />
        <ContainerButton>
          <Button
            disabled={hoursToAdd === "0"}
            variant="contained"
            component="span"
            onClick={submitHours}
          >
            {t("Додати")}
          </Button>

          <Button variant="light" onClick={closeModal}>
            {t("Скасувати")}
          </Button>
        </ContainerButton>
      </AddHoursContainerStyled>
    </Modal>
  );
};

export default AddHoursModal;
