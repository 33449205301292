import React, { useState } from "react";
import dayjs from "dayjs";
// Icons
import { TutorInfoIcon } from "assets/icons";
// Components
import { NAVBAR_GOOGLE_LINK } from "utils/constants/nav-bar";
import { ITariff } from "store/payments/paymentsTypes";
import PaywallModal from "components/PaywallModal";
// Store
import { selectCurrentUser } from "store/auth/authSlice";
import { useGetTariffsQuery } from "store/payments/paymentsServicers";
import { useSelector } from "react-redux";
// import ToggleButtons from './ToggleButtons';
import UserMenu from "./UserMenu";
import UserTooltip from "./UserTooltip";
import UserMembershipTooltip from "./UserMembershipTooltip";
// Styles
import { Container, StyledLink } from "./styles";

import { userRoutesPath } from "../../routes/routePath";
import { TRIAL_PERIOD } from "../../utils/constants/user-profile";

const HeaderUserMenu: React.FC = () => {
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const [openUserMenu, setOpenUserMenu] = useState<null | HTMLElement>(null);
  const { user } = useSelector(selectCurrentUser);
  const date = dayjs(user?.created_at as string);
  const dateNow = new Date();
  const differenceDate = date.diff(dateNow, "day");
  const diffDate = differenceDate?.toString()?.replace("-", "");
  const startDate = TRIAL_PERIOD - +diffDate;
  const isEndFreePeriod = startDate === 4 || startDate < 4;
  const isMembership =
    user?.membership_status === "free" || user?.membership_status === "trial";

  const { data } = useGetTariffsQuery({});

  const compareAge = (a: ITariff, b: ITariff) => {
    return a.amountCents - b.amountCents;
  };

  const filteredTariffs = () => {
    const tariffs = data?.filter((item) => item?.recurrency === "month");

    if (tariffs?.length) {
      return tariffs?.sort(compareAge);
    }
    return [];
  };

  const onClose = () => setOpenSubscribeModal((prev) => !prev);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenUserMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenUserMenu(null);
  };

  return (
    <Container>
      {isMembership && (
        <UserMembershipTooltip
          isEndFreePeriod={isEndFreePeriod}
          startDate={startDate < 1 ? 0 : startDate}
          differenceDate={diffDate}
        />
      )}

      <StyledLink to={userRoutesPath.HELP} target="_blank">
        <TutorInfoIcon />
      </StyledLink>

      <UserMenu handleOpenMenu={handleOpenMenu} open={!!openUserMenu} />

      <UserTooltip handleCloseMenu={handleCloseMenu} anchorEl={openUserMenu} />

      <PaywallModal
        open={openSubscribeModal}
        onClose={onClose}
        tariffs={filteredTariffs()}
        typeSubscribe="міс"
      />
    </Container>
  );
};

export default HeaderUserMenu;
