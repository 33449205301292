import { styled } from "@mui/material/styles";
import { Button, Select } from "@mui/material";
import { palette } from "styles/theme/palette";

interface FormHelperTextProps {
  isError: boolean | undefined;
}

export const Label = styled("span")<{
  required?: boolean;
  isEmpty?: boolean;
}>`
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
  color: ${({ required, isEmpty = true }) =>
    required && isEmpty
      ? palette.custom.red.main
      : palette.custom.primary.silver};
`;

export const FormHelperText = styled("span")<FormHelperTextProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #d52727;
  margin-top: 8px;
  display: ${({ isError }) => (isError ? "block" : "none")};
`;

export const MaterialUISelect = styled(Select)`
  &&:hover {
    fieldset {
      border-color: ${({ theme }) => theme?.palette?.primary?.light};
    }
  }
`;

export const ButtonStyled = styled(Button)(() => ({
  color: palette.primary.dark,
  padding: "6px 16px",
  borderTop: `1px solid ${palette.primary.main}`,
  width: "100%",
  justifyContent: "flex-start",
  textTransform: "none",
  fontSize: "16px",
}));
