import { type FC, useCallback } from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { useDrawer } from "hooks/useDrawer";
import { Arrow } from "pages/ProductsAndServices/ProductsTable/styles";
import { EXPENSE_STATUS, PROJECT_TYPE } from "utils/freelancer/constants";
import { useTranslation } from "react-i18next";
import {
  ArrowContainerStyled,
  DateContainerStyled,
  DatePickerContainerStyled,
  DeletePhaseIconContainerStyled,
  HeaderStyled,
  LabelStyled,
  TextareaAutosizeStyled,
  TitleContainerStyled,
  OverlayStyled,
  CollapseStyled,
  StatusStyled,
  IconContainerStyled,
  PromoteButtonStyled,
  DescriptionContainerStyled,
} from "./styles";
import { Label } from "components/UI/Input/styles";
import { palette } from "styles/theme/palette";
import { ContainerInput, Input } from "components/UI/DatePicker/styles";
import {
  type Control,
  Controller,
  type FieldArrayWithId,
  useFieldArray,
  type UseFormWatch,
} from "react-hook-form";
import { type UseFormSetValue } from "react-hook-form/dist/types/form";
import {
  type ProjectCreateForm,
  type ProjectPhaseCreate,
} from "interfaces/freelancer/project";
import Employees from "./Employees";
import { DeleteIcon, PlusIcon } from "assets/icons";
import AdditionalExpenses from "./AdditionalExpenses";
import Services from "./Services";
import { useProjectDatePickerData } from "hooks/freelancer/useProjectDatePickerData";
import { useTrialUser } from "hooks/useTrialUser";

interface PhaseProps {
  index: number;
  type: PROJECT_TYPE;
  control: Control<ProjectCreateForm>;
  setValue: UseFormSetValue<ProjectCreateForm>;
  removePhase: (ind: number) => void;
  field: FieldArrayWithId<ProjectCreateForm, "phases">;
  initialPhase?: ProjectPhaseCreate;
  promotePhase: (phaseIndex: number) => void;
  watch: UseFormWatch<ProjectCreateForm>;
  alreadyPromoted: boolean;
  onAddNewTeammateClick?: () => void;
  saveProject?: () => void;
  ableToSaveProject?: boolean;
}

const Phase: FC<PhaseProps> = ({
  index,
  type,
  control,
  setValue,
  removePhase,
  field,
  initialPhase = undefined,
  promotePhase,
  watch,
  alreadyPromoted,
  onAddNewTeammateClick,
  saveProject,
  ableToSaveProject,
}) => {
  const isPromoted =
    alreadyPromoted || field.status === EXPENSE_STATUS.SUBMITTED;
  const { t } = useTranslation();
  const { limitedAccess } = useTrialUser();
  const { toggle, isOpen } = useDrawer(!isPromoted);

  const { disableEndDate, handleDisableEndDate } = useProjectDatePickerData(
    setValue,
    index,
    "phases",
    initialPhase
  );
  const { append: appendRemovedExpense } = useFieldArray({
    control,
    name: "removedExpenses",
  });
  const watchedEmployees = watch(`phases.${index}.expenses.employees`);
  const watchedServices = watch(`phases.${index}.expenses.services`);

  const ableToPromote =
    type === PROJECT_TYPE.FIX
      ? !!watchedServices?.length &&
        watchedServices.some(({ price }) => price > 0)
      : !!watchedEmployees?.length &&
        watchedEmployees.some(({ amount, hours }) => amount > 0 && hours > 0);
  const handleRemovePhase = useCallback(
    () => removePhase(index),
    [index, removePhase]
  );

  const promoteToWorkingProcess = useCallback(() => {
    promotePhase(index);
    toggle();
  }, [index, promotePhase]);

  const titleColor = isPromoted
    ? palette.custom.grey.dark
    : palette.custom.green.dark;
  const collapsedSize = isPromoted ? 50 : 125;

  return (
    <CollapseStyled collapsedSize={collapsedSize} in={isOpen}>
      <HeaderStyled isPromoted={isPromoted}>
        <TitleContainerStyled isPromoted={isPromoted} onClick={toggle}>
          <Typography variant="body1" color={titleColor}>
            {field.name}
          </Typography>
        </TitleContainerStyled>
        <ArrowContainerStyled isPromoted={isPromoted}>
          {!isPromoted && (
            <>
              <PromoteButtonStyled
                disabled={!ableToPromote}
                onClick={promoteToWorkingProcess}
                variant="contained"
                className="toolbar-btns create-btn"
                startIcon={<PlusIcon />}
              >
                {t("freelancer.page.addEditProject.promote.label")}
              </PromoteButtonStyled>
              <DeletePhaseIconContainerStyled onClick={handleRemovePhase}>
                <DeleteIcon />
              </DeletePhaseIconContainerStyled>
            </>
          )}
          {isPromoted && (
            <StatusStyled variant="regularText">{t("promoted")}</StatusStyled>
          )}
          <IconContainerStyled onClick={toggle}>
            <Arrow $isOpen={isOpen} />
          </IconContainerStyled>
        </ArrowContainerStyled>
      </HeaderStyled>
      {(isPromoted || limitedAccess) && <OverlayStyled />}
      <DescriptionContainerStyled>
        <LabelStyled>
          {t("freelancer.page.addEditProject.description.label")}
        </LabelStyled>
        <Controller
          name={`phases.${index}.description`}
          control={control}
          render={({ field }) => (
            <TextareaAutosizeStyled
              minRows={4}
              placeholder={t(
                "freelancer.page.addEditProject.description.placeholder"
              )}
              {...field}
            />
          )}
        />
      </DescriptionContainerStyled>
      {type === PROJECT_TYPE.FIX && (
        <Services
          parentIndex={index}
          control={control}
          onExpenseRemove={appendRemovedExpense}
          ableToSaveProject={ableToSaveProject}
          saveProject={saveProject}
        />
      )}
      <Employees
        parentIndex={index}
        control={control}
        initialEmployees={initialPhase?.expenses.employees}
        watch={watch}
        onExpenseRemove={appendRemovedExpense}
        onAddNewTeammateClick={onAddNewTeammateClick}
        ableToSaveProject={ableToSaveProject}
      />
      <AdditionalExpenses
        parentIndex={index}
        control={control}
        onExpenseRemove={appendRemovedExpense}
      />
      <DateContainerStyled>
        <DatePickerContainerStyled>
          <Label>{t("startDate")}</Label>
          <ContainerInput>
            <Controller
              name={`phases.${index}.startDate`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input selected={value} onChange={onChange} />
              )}
            />
          </ContainerInput>
        </DatePickerContainerStyled>
        <DatePickerContainerStyled>
          <Label>{t("endDate")}</Label>
          <ContainerInput>
            <Controller
              name={`phases.${index}.endDate`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  disabled={disableEndDate}
                  selected={value}
                  onChange={onChange}
                />
              )}
            />
          </ContainerInput>
          <Box>
            <Checkbox
              checked={disableEndDate}
              onChange={handleDisableEndDate}
            />
            <Label>{t("undefined")}</Label>
          </Box>
        </DatePickerContainerStyled>
      </DateContainerStyled>
    </CollapseStyled>
  );
};

export default Phase;
