import {
  ContentBoxStyled,
  GraphContainerStyled,
  LinkStyled,
  NoDataContainerStyled,
} from "./styles";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { useScreenSize } from "hooks/useScreenSize";
import { palette } from "styles/theme/palette";
import {
  ContentContainerStyled,
  LinkContainerStyled,
} from "../Projects/AddEdit/styles";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { type ProjectWithPhases } from "interfaces/freelancer/project";
import {
  type DashboardProjectGraphData,
  useDashboardProjectGraphData,
} from "hooks/freelancer/useDashboardProjectGraphData";
import { freelanceUserRoutesPath } from "routes/routePath";
import { PlusIcon } from "assets/icons";
import ProjectTypeModal from "../Projects/ProjectTypeModal";
import { useDrawer } from "hooks/useDrawer";

interface ProjectOverviewProps {
  projects: ProjectWithPhases[];
}

const ProjectOverview: FC<ProjectOverviewProps> = ({ projects }) => {
  const { t } = useTranslation();
  const { open, isOpen, close } = useDrawer();
  const { isMobile } = useScreenSize();
  const margin = isMobile
    ? { top: 10, right: 30, left: 0, bottom: 0 }
    : { top: 10, right: 40, left: 20, bottom: 0 };
  const { purple, red, green } = palette.custom;

  const dataProject1 = useDashboardProjectGraphData(projects[0]);
  const dataProject2 = useDashboardProjectGraphData(projects[1]);
  const dataProject3 = useDashboardProjectGraphData(projects[2]);

  const graphData: DashboardProjectGraphData[] = [
    dataProject1,
    dataProject2,
    dataProject3,
  ].filter((data) => !!data.name);

  return (
    <>
      <ContentBoxStyled>
        <Typography variant="h1">
          {t("freelancer.page.dashboard.projectOverview.title")}
        </Typography>
        <ContentContainerStyled>
          <GraphContainerStyled>
            {!!graphData?.length ? (
              <ResponsiveContainer minHeight={350} width="100%">
                <BarChart
                  data={graphData}
                  layout="vertical"
                  barCategoryGap={10}
                  margin={margin}
                >
                  <XAxis type="number" unit="$" fontSize={isMobile ? 12 : 16} />
                  <YAxis
                    type="category"
                    dataKey="name"
                    fontSize={isMobile ? 12 : 16}
                  />
                  <Tooltip />
                  <Legend />
                  <Bar
                    name={t(
                      "freelancer.page.dashboard.projectOverview.graph.estimatedBudget"
                    )}
                    dataKey="estimatedBudget"
                    fill={purple.dark}
                  />
                  <Bar
                    minPointSize={5}
                    name={t(
                      "freelancer.page.dashboard.projectOverview.graph.paymentReceived"
                    )}
                    dataKey="paymentReceived"
                    fill={green.chartGreen}
                  />
                  <Bar
                    name={t(
                      "freelancer.page.dashboard.projectOverview.graph.budgetUsed"
                    )}
                    dataKey="budgetUsed"
                    fill={red.silver}
                  />
                  <Bar
                    name={t(
                      "freelancer.page.dashboard.projectOverview.graph.estimatedCosts"
                    )}
                    dataKey="estimatedCosts"
                    fill={red.dark}
                  />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <NoDataContainerStyled>
                <Button
                  onClick={open}
                  variant="textIcon"
                  className="toolbar-btns create-btn"
                  startIcon={<PlusIcon />}
                >
                  {t("freelancer.page.addEditProject.addFirst.label")}
                </Button>
              </NoDataContainerStyled>
            )}
          </GraphContainerStyled>
        </ContentContainerStyled>
        {!!graphData?.length && (
          <LinkContainerStyled>
            <LinkStyled
              underline="none"
              href={freelanceUserRoutesPath.PROJECTS}
            >
              {t("freelancer.page.dashboard.projectOverview.viewMore")}
            </LinkStyled>
          </LinkContainerStyled>
        )}
      </ContentBoxStyled>
      <ProjectTypeModal isOpen={isOpen} onClose={close} />
    </>
  );
};

export default ProjectOverview;
