import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "store/auth/authSlice";
import { TRIAL_PERIOD } from "../utils/constants/user-profile";
import { useGetSubscriptionsQuery } from "store/payments/paymentsServicers";

export const useTrialUser = () => {
  const { data: subscriptions, isLoading } = useGetSubscriptionsQuery({});
  const { user } = useSelector(selectCurrentUser);
  const date = dayjs(user?.created_at);
  const dateNow = new Date();
  const differenceDate = date.diff(dateNow, "day");
  const diffDate = differenceDate?.toString()?.replace("-", "");
  const trialSubscriptionEnd = TRIAL_PERIOD - +diffDate < 0;
  const daysLeft = TRIAL_PERIOD - +diffDate;
  const trialDaysLeft = daysLeft > 0 ? daysLeft : 0;
  const userSubscriptionFree =
    user?.membership_status === "free" || user?.membership_status === "trial";
  const hasActiveSubscription =
    !!subscriptions &&
    subscriptions?.filter(
      ({ status, current_period_end }) =>
        status === "active" &&
        !!current_period_end &&
        current_period_end >= dayjs().unix()
    )?.length > 0;
  const limitedAccess =
    trialSubscriptionEnd &&
    userSubscriptionFree &&
    !hasActiveSubscription &&
    !isLoading;

  return {
    trialSubscriptionEnd,
    userSubscriptionFree,
    lockNotFreeFunctionality: trialSubscriptionEnd && userSubscriptionFree,
    trialDaysLeft,
    limitedAccess,
  };
};
