interface EmploymentInputData {
  name:
    | "address"
    | "wage"
    | "legal_name"
    | "edrpo"
    | "bank_name"
    | "bank_address"
    | "swift_code"
    | "account_number"
    | "correspondent_bank"
    | "correspondent_swift_code"
    | "correspondent_account_number";
  label: string;
  placeholder: string;
  isRequired?: boolean;
  type?: "text" | "number";
}

export const employmentInputsData: EmploymentInputData[] = [
  {
    name: "legal_name",
    label: "pages.UserProfile.EmploymentInfo.Name.Label",
    placeholder: "pages.UserProfile.EmploymentInfo.Name.Placeholder",
    isRequired: true,
  },
  {
    name: "wage",
    label: "pages.UserProfile.EmploymentInfo.Wage.Label",
    placeholder: "pages.UserProfile.EmploymentInfo.Wage.Placeholder",
    type: "number",
  },
  {
    name: "edrpo",
    label: "pages.UserProfile.EmploymentInfo.Edrpo.Label",
    placeholder: "pages.UserProfile.EmploymentInfo.Edrpo.Placeholder",
  },
  {
    name: "address",
    label: "pages.UserProfile.EmploymentInfo.Address.Label",
    placeholder: "pages.UserProfile.EmploymentInfo.Address.Placeholder",
  },
  {
    name: "account_number",
    label: "pages.UserProfile.EmploymentInfo.Account.Label",
    placeholder: "pages.UserProfile.EmploymentInfo.Account.Placeholder",
  },
  {
    name: "bank_name",
    label: "pages.UserProfile.EmploymentInfo.BankName.Label",
    placeholder: "pages.UserProfile.EmploymentInfo.BankName.Placeholder",
  },
  {
    name: "bank_address",
    label: "pages.UserProfile.EmploymentInfo.BankAddress.Label",
    placeholder: "pages.UserProfile.EmploymentInfo.BankAddress.Placeholder",
  },
  {
    name: "swift_code",
    label: "pages.UserProfile.EmploymentInfo.BankSwift.Label",
    placeholder: "pages.UserProfile.EmploymentInfo.BankSwift.Placeholder",
  },
  {
    name: "correspondent_bank",
    label: "pages.UserProfile.EmploymentInfo.CorrespondentBankName.Label",
    placeholder:
      "pages.UserProfile.EmploymentInfo.CorrespondentBankName.Placeholder",
  },
  {
    name: "correspondent_account_number",
    label: "pages.UserProfile.EmploymentInfo.CorrespondentBankAccount.Label",
    placeholder:
      "pages.UserProfile.EmploymentInfo.CorrespondentBankAccount.Placeholder",
  },
  {
    name: "correspondent_swift_code",
    label: "pages.UserProfile.EmploymentInfo.CorrespondentBankSwift.Label",
    placeholder:
      "pages.UserProfile.EmploymentInfo.CorrespondentBankSwift.Placeholder",
  },
];
