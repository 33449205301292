import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { PAGE_CONTENT_WIDTH } from "styles/theme/constants";

export const ToggleContainerStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "30px",
  maxWidth: PAGE_CONTENT_WIDTH,

  ".MuiFormControl-root": {
    maxWidth: "211px",
    "& > p": {
      display: "none",
    },
  },
}));

export const DownPaymentContainerStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginLeft: "50px",

  span: {
    margin: 0,
  },
}));
