import { FC } from "react";
import Modal from "components/UI/Modal";
// Icons
import { InfoSubscribe } from "assets/icons";
// Styles
import {
  Container,
  Title,
  SubTitle,
  ContainerButton,
  Wrapper,
  Text,
  TitleTariff,
} from "./styles";
import { useTranslation } from "react-i18next";

interface CancelSubscribeModalProps {
  open: boolean;
  onClose: () => void;
}

const SuccessSubscribeModal: FC<CancelSubscribeModalProps> = ({
  onClose,
  open,
}) => {
  const { t } = useTranslation();
  return (
    <Modal maxWidth={500} onClose={onClose} isOpen={open}>
      <Container>
        <Title variant="h2">{t("Супер!")}</Title>

        <SubTitle variant="regularText">
          {t("Ви успішно оформили підписку за тарифним планом")}
        </SubTitle>

        <ContainerButton>
          <TitleTariff>{t("Діскавері")}</TitleTariff>
        </ContainerButton>

        <Wrapper>
          <InfoSubscribe />

          <Text>
            {t(
              "Наступний платіж буде списано через місяць користування. Пам’ятайте, Ви маєте можливість відписатся у будь який час!"
            )}
          </Text>
        </Wrapper>
      </Container>
    </Modal>
  );
};

export default SuccessSubscribeModal;
