import { forwardRef, type RefObject, useState } from "react";

import {
  type TextFieldProps,
  InputAdornment,
  type InputProps,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { EyeIcon, EyeClosedIcon } from "assets/icons";
import { Container, Label, StyledInput, StyledPasswordInput } from "./styles";

export interface CustomInputProps {
  isSpaceBottom?: number;
  fullWidth?: boolean;
}

const Input = forwardRef(
  (
    {
      label,
      isSpaceBottom,
      helperText,
      fullWidth,
      type,
      required,
      ...props
    }: TextFieldProps & CustomInputProps & InputProps,
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    return (
      <Container
        ref={ref as RefObject<HTMLDivElement>}
        className={props.className || ""}
        isSpaceBottom={isSpaceBottom}
        fullWidth={fullWidth}
      >
        {label && (
          <Label
            required={required}
            isEmpty={(`${props.value}` || "").length === 0}
          >
            {label}
          </Label>
        )}

        {type === "password" ? (
          <>
            <StyledPasswordInput
              {...props}
              className={`${props.className} ${
                props.InputProps && props.InputProps.readOnly ? "readonly" : ""
              }`}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText>{helperText ? helperText : " "}</FormHelperText>
          </>
        ) : (
          <StyledInput
            helperText={helperText || " "}
            type={type}
            {...props}
            className={`${props.className} ${
              props.InputProps && props.InputProps.readOnly ? "readonly" : ""
            }`}
          />
        )}
      </Container>
    );
  }
);

export default Input;
