import { styled } from "@mui/material/styles";
import { Box, Breakpoint, Typography } from "@mui/material";
import { palette } from "styles/theme/palette";

export const NoTableDataTextStyled = styled(Typography)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  color: palette.custom.grey.light,
  padding: "50px 0",
}));

export const NoDataContainerStyled = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  color: palette.custom.grey.light,
  padding: "50px 0",
}));

export const PageContainerStyled = styled(Box)(({ theme }) => ({
  minHeight: "calc(100vh - 80px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    padding: "0 15px 0 8px",
  },
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  marginBottom: "20px",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    padding: "0 15px 0 8px",
  },
}));
