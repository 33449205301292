import { type FC } from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/UI/Modal";
import { Container, Title } from "components/ConfirmationModal/styles";
import { WarningButtonStyled } from "./styles";

interface WarningModalProps {
  onClose: () => void;
  isOpen: boolean;
  error: string;
}

const WarningModal: FC<WarningModalProps> = ({ onClose, isOpen, error }) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Container>
        <Title variant="h1">{error}</Title>
        <WarningButtonStyled variant="contained" onClick={onClose}>
          {t("close")}
        </WarningButtonStyled>
      </Container>
    </Modal>
  );
};

export default WarningModal;
