import React, { useState } from "react";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import { Collapse, FormControl, ToggleButton } from "@mui/material";
import { CancelIcon, TrashIcon, ArrowGroupIcon } from "assets/icons";
import Input from "components/UI/Input";
// Styles
import {
  Container,
  FieldWrap,
  Arrow,
  CollapseHeader,
  DepartmentNameCollapse,
  DeleteButton,
} from "./styles";

interface Department {
  name: string;
  project_id: string;
  unit_id: string;
}
interface SettingForm {
  departments: Department[];
}

interface IFormRow {
  control: Control<SettingForm>;
  index: number;
  errors: FieldErrors<SettingForm>;
  update: any;
  remove: any;
  watched: FieldValues;
}

const FormRow: React.FC<IFormRow> = ({
  control,
  index,
  errors,
  update,
  remove,
  watched,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <CollapseHeader onClick={() => setIsOpen((prev) => !prev)}>
        <DepartmentNameCollapse>
          <ArrowGroupIcon />
          {watched.departments[index].name}
        </DepartmentNameCollapse>
        <Arrow $isOpen={isOpen} />
      </CollapseHeader>
      <Collapse className="callapse" in={isOpen} timeout="auto" unmountOnExit>
        <FieldWrap key={watched.departments[index].name}>
          <Controller
            render={({ field }) => (
              <Input
                label="Назва департаменту"
                {...field}
                className="amount-input"
                fullWidth
              />
            )}
            control={control}
            name={`departments.${index}.name`}
            rules={{ required: "fieldIsRequired" }}
          />
          <DeleteButton variant="link" onClick={() => remove(index)}>
            <TrashIcon />
            Видалити
          </DeleteButton>
        </FieldWrap>
      </Collapse>
    </Container>
  );
};

export default FormRow;
