import type {
  ProjectPhaseCreate,
  ProjectUpdate,
} from "interfaces/freelancer/project";

export const getMappedPhaseToUpdate = (
  {
    id,
    status,
    name,
    clientId,
    twin_id,
    endDate,
    startDate,
    expenses,
    groupId,
    description,
  }: ProjectPhaseCreate,
  pipeline_id: string,
  projectType?: string
): ProjectUpdate => ({
  id: id ?? "",
  pipeline_id,
  amount: 0,
  group_id: groupId ?? "",
  price: 0,
  name,
  extras: {
    phaseDescription: description,
    phaseStatus: status,
    startDate,
    endDate,
    twin_id,
    clientId,
    type: projectType,
  },
});
