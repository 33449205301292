import { INVOICE_STATE } from "utils/freelancer/constants";
import { useGetInvoicesQuery } from "store/invoices/invoicesServices";
import { type TableInvoice } from "interfaces/freelancer/invoice";
import dayjs from "dayjs";
import { useGetProductGroupsQuery } from "store/productGroup/productGroupServices";
import { PRODUCT_GROUP_NAME } from "../../components/Freelancer/Projects/data";
import { useMemo } from "react";

interface AllInvoicesReturn {
  isLoading: boolean;
  activeInvoices: TableInvoice[];
  cancelledInvoices: TableInvoice[];
  allInvoices: TableInvoice[];
}

export const useAllInvoices = (): AllInvoicesReturn => {
  const { data, isLoading } = useGetInvoicesQuery(null);
  const { data: projects } = useGetProductGroupsQuery(null);
  const allProjects =
    projects?.filter((item) => item.name === PRODUCT_GROUP_NAME.PROJECTS)[0]
      ?.children ?? [];

  const tableInvoices: TableInvoice[] =
    useMemo(
      () =>
        data?.map(
          ({
            name,
            state,
            id,
            created_at,
            customer_info,
            total,
            valid_until,
            parent_id,
            currency,
            items,
          }) => ({
            name,
            id,
            state,
            startDateFormatted: dayjs(created_at).format("DD.MM.YYYY"),
            startDate: created_at,
            dueDateFormatted: dayjs(valid_until).format("DD.MM.YYYY"),
            dueDate: valid_until,
            clientName: customer_info?.name,
            invoiced: total,
            currency,
            projectName:
              allProjects?.filter(({ id }) => parent_id === id)[0]?.name ?? "",
            projectId: parent_id,
            items,
          })
        ),
      [allProjects, data]
    ) ?? [];
  const activeInvoices =
    tableInvoices
      .filter(({ state }) => state !== INVOICE_STATE.CANCELED)
      .sort(
        (a, b) =>
          dayjs(a?.startDate).millisecond() - dayjs(b?.startDate).millisecond()
      ) ?? [];
  const cancelledInvoices =
    tableInvoices
      .filter(({ state }) => state === INVOICE_STATE.CANCELED)
      .sort(
        (a, b) =>
          dayjs(a?.startDate).millisecond() - dayjs(b?.startDate).millisecond()
      ) ?? [];

  return {
    isLoading,
    activeInvoices,
    cancelledInvoices,
    allInvoices: tableInvoices,
  };
};
