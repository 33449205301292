import { type FC, useState, type MouseEvent } from "react";
import { Table, TableContainer } from "@mui/material";

import TransactionsTableBody from "./TransactionsTableBody";
import TransactionsTableHead from "./TransactionsTableHead";
import { type Order } from "types/global";
import { useTranslation } from "react-i18next";
import { NoTableDataTextStyled } from "../../../styles";
import { type UserTransaction } from "store/transactions/transactionsTypes";

interface TransactionsTableProps {
  transactions?: UserTransaction[] | [];
}

const TransactionsTable: FC<TransactionsTableProps> = ({ transactions }) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof UserTransaction>("created_at");
  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof UserTransaction
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <Table aria-labelledby="tableTitle">
        <TransactionsTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        {!!transactions?.length && (
          <TransactionsTableBody
            transactions={transactions}
            order={order}
            orderBy={orderBy}
          />
        )}
      </Table>
      {!transactions?.length && (
        <NoTableDataTextStyled variant="h2">
          {t("freelancer.page.dashboard.transactions.noData")}
        </NoTableDataTextStyled>
      )}
    </TableContainer>
  );
};

export default TransactionsTable;
