import { type FC, useCallback, useState, type MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useFreelancerDefaults } from "hooks/useFreelancerDefaults";
import { useExistingProject } from "hooks/freelancer/useExistingProject";
import {
  FooterStyled,
  HeaderStyled,
  PageContainerStyled,
} from "../../Projects/AddEdit/styles";
import { H1 } from "pages/HelpPage/styles";
import { Box, Button, ToggleButtonGroup } from "@mui/material";
import {
  StyledToggleButton,
  Wrapper,
} from "pages/Pipelines/PipelinesFilterSection/styles";
import { StyledInput } from "pages/Teams/AddEmployeeModal/styles";
import BaseInfo from "./BaseInfo";
import { useForm } from "react-hook-form";
import { type InvoiceCreateForm } from "interfaces/freelancer/invoice";
import { ToggleContainerStyled } from "./styles";
import Expenses from "./Expenses";
import Footer from "../../../Footer";
import { freelanceUserRoutesPath } from "routes/routePath";
import { useScreenSize } from "hooks/useScreenSize";
import { useNavigate } from "react-router";
import NotesAndCalculations from "./NotesAndCalculations";
import BankAccountDetails from "./Expenses/BankAccountDetails";
import Preview from "./Preview";
import { useDrawer } from "hooks/useDrawer";
import { getInvoiceFieldsAbleToPreview } from "utils/freelancer/helpers/getInvoiceFieldsAbleToPreview";

const AddEditInvoice: FC = () => {
  const { t, i18n } = useTranslation();
  const { isDesktop } = useScreenSize();
  const navigate = useNavigate();
  const { currency } = useFreelancerDefaults();
  const { initialWorkPhases, existingProject } = useExistingProject();
  const [mode, setMode] = useState<number>(0);
  const [lng, setLng] = useState<string>(i18n.language);
  const {
    open: showPreview,
    isOpen: isPreviewVisible,
    close: hidePreview,
  } = useDrawer(false);
  const handleChangeMode = useCallback(
    (event: MouseEvent<HTMLElement>, newMode: number | null) => {
      if (typeof newMode === "number") {
        setMode(newMode);
        const newLangToOverride = newMode === 1 ? "en" : "";
        setLng(newLangToOverride);
      }
    },
    []
  );

  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm<InvoiceCreateForm>({
    defaultValues: {
      phases: [],
      name: "",
      notes: "",
      contractNumber: "",
      invoiceDate: new Date(),
      dueDate: new Date(),
      total: 0,
      subTotal: 0,
      tax: 0,
      discount: 0,
    },
  });
  const ableToPreview = getInvoiceFieldsAbleToPreview(watch, isValid);
  const navigateToProjects = useCallback(
    () => navigate(freelanceUserRoutesPath.PROJECTS),
    [navigate, freelanceUserRoutesPath]
  );
  const notEnglishLang = i18n.language !== "en";

  return (
    <PageContainerStyled>
      {isPreviewVisible ? (
        <Preview
          values={getValues()}
          onCancel={hidePreview}
          from={freelanceUserRoutesPath.INVOICES_ADD}
          projectId={existingProject?.id}
        />
      ) : (
        <Box>
          <HeaderStyled>
            <H1>{t("freelancer.page.invoices.add.title")}</H1>
          </HeaderStyled>
          <ToggleContainerStyled>
            <Wrapper>
              {notEnglishLang && (
                <ToggleButtonGroup
                  onChange={handleChangeMode}
                  value={mode}
                  exclusive
                >
                  <StyledToggleButton value={0}>
                    {t("freelancer.page.invoices.add.toggle.domestic")}
                  </StyledToggleButton>
                  <StyledToggleButton value={1}>
                    {t("freelancer.page.invoices.add.toggle.international")}
                  </StyledToggleButton>
                </ToggleButtonGroup>
              )}
            </Wrapper>
            <StyledInput fullWidth={false} disabled value={currency} />
          </ToggleContainerStyled>
          <BaseInfo control={control} errors={errors} overrideLanguage={lng} />
          <Expenses
            initialPhases={initialWorkPhases}
            control={control}
            overrideLanguage={lng}
          />
          <BankAccountDetails
            control={control}
            errors={errors}
            setValue={setValue}
            client={existingProject?.client}
            overrideLanguage={lng}
          />
          <NotesAndCalculations
            control={control}
            setValue={setValue}
            overrideLanguage={lng}
          />
          <FooterStyled>
            <Button
              disabled={!ableToPreview}
              onClick={showPreview}
              variant="contained"
              className="toolbar-btns create-btn"
            >
              {t("preview")}
            </Button>
            <Button
              onClick={navigateToProjects}
              variant="textIcon"
              className="toolbar-btns create-btn"
            >
              {t("cancel")}
            </Button>
          </FooterStyled>
        </Box>
      )}
      {isDesktop && <Footer isSpace isSticki={false} />}
    </PageContainerStyled>
  );
};

export default AddEditInvoice;
