import React from "react";
// Styles
import {
  Container,
  Title,
} from "../../TariffPlanCard/TariffPlanCardHeader/styles";
import PayWallIcon from "assets/icons/PayWallIcon.svg";
import { ImageStyled } from "./styles";

interface TariffPlanCardHeaderProps {
  title: string;
  isActive?: boolean;
}

const TariffPlanCardHeader: React.FC<TariffPlanCardHeaderProps> = ({
  title,
  isActive,
}) => (
  <Container>
    <Title>{title}</Title>
    {isActive && <ImageStyled src={PayWallIcon} />}
  </Container>
);

export default TariffPlanCardHeader;
