import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { palette } from "styles/theme/palette";
import { Link } from "react-router-dom";

interface LinkContainerStyledProps {
  isActive: boolean;
  isOpen?: boolean;
}

export const NavContainerStyled = styled(Box)(() => ({
  marginTop: "27px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

export const LinkContainerStyled = styled(Link)<LinkContainerStyledProps>(
  ({ isActive, isOpen = true }) => ({
    display: "flex",
    padding: "11px 16px",
    margin: "0 30px",
    width: isOpen ? "calc(100% - 70px)" : "80px",
    background: isActive ? palette.custom.dark.main : "transparent",
    color: `${isActive ? palette.common.white : palette.custom.grey.light}`,
    transition: "300ms ease all",
    borderRadius: "4px",
    justifyContent: isOpen ? "flex-start" : "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "700",
    overflow: "hidden",
    "svg, path": {
      width: "22px",
      transition: "300ms ease color",
      fill: `${isActive ? palette.common.white : palette.custom.grey.light}`,
      marginRight: isOpen ? "25px" : 0,
      flex: "0 0 22px",
    },
  })
);
