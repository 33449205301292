import { type FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { H1 } from "pages/HelpPage/styles";
import {
  FooterStyled,
  HeaderStyled,
  PageContainerStyled,
  TypeChipStyled,
} from "./styles";
import {
  DEFAULT_ALERT_HIDE_DURATION,
  EMPLOYEE_TYPE,
  EXPENSE_STATUS,
  PROJECT_TYPE,
} from "utils/freelancer/constants";
import { Alert, Button, Snackbar } from "@mui/material";
import { useForm } from "react-hook-form";
import Expenses from "./Expenses";
import BaseProjectInfo from "./BaseProjectInfo";
import { useNavigate } from "react-router";
import { freelanceUserRoutesPath } from "routes/routePath";
import Footer from "../../../Footer";
import {
  type ProjectCreateForm,
  type ProjectPhaseCreate,
} from "interfaces/freelancer/project";
import { useScreenSize } from "hooks/useScreenSize";
import { useExistingProject } from "hooks/freelancer/useExistingProject";
import { useProjectUpsert } from "hooks/freelancer/useProjectUpsert";
import { useAlert } from "hooks/freelancer/useAlert";
import AddEditMemberModal from "../../Members/AddEditMemberModal";
import { useDrawer } from "hooks/useDrawer";
import { useTrialUser } from "hooks/useTrialUser";

const AddEditProject: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isDesktop } = useScreenSize();
  const { limitedAccess } = useTrialUser();
  const { initialPhases, initialWorkPhases, existingProject, projectType } =
    useExistingProject();
  const [selectedType, setSelectedType] = useState<EMPLOYEE_TYPE>(
    EMPLOYEE_TYPE.PARTNER
  );
  const { isOpen: isAddEmployeeOpen, toggle: toggleAddEmployeeOpen } =
    useDrawer();
  const onAddNewClientClick = useCallback(() => {
    setSelectedType(EMPLOYEE_TYPE.CONTRACTOR);
    toggleAddEmployeeOpen();
  }, [toggleAddEmployeeOpen]);
  const onAddNewTeammateClick = useCallback(async () => {
    setSelectedType(EMPLOYEE_TYPE.PARTNER);
    toggleAddEmployeeOpen();
  }, [toggleAddEmployeeOpen]);

  const {
    setAlertSeverity,
    setAlertMessage,
    openAlert,
    isAlertOpen,
    alertSeverity,
    alertMessage,
    handleAlertClose,
  } = useAlert();

  const initialProduct: ProjectPhaseCreate = {
    name: `${t("phase")} 1`,
    startDate: new Date(),
    status: EXPENSE_STATUS.DRAFT,
    isPromoted: false,
    expenses: {
      employees: [],
      services: [],
      additionalExpenses: [],
    },
  };
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useForm<ProjectCreateForm>({
    defaultValues: {
      phases: [initialProduct],
      workPhases: [],
      removedPhases: [],
      removedExpenses: [],
      projectName: "",
      clientId: "",
    },
  });

  useEffect(() => {
    if (existingProject) {
      setValue("projectName", existingProject?.name);
      setValue("clientId", existingProject?.client?.id ?? "");
    }
  }, [existingProject]);

  const pageTitle = !!existingProject ? existingProject?.name : t("newProject");
  const buttonLabel = !!existingProject ? t("save") : t("addProject");
  const navigateToProjects = useCallback(
    () => navigate(freelanceUserRoutesPath.PROJECTS),
    [navigate, freelanceUserRoutesPath]
  );

  const handleSave = useProjectUpsert(
    getValues,
    setAlertSeverity,
    setAlertMessage,
    openAlert,
    projectType,
    existingProject,
    initialPhases,
    initialWorkPhases
  );
  const handleSaveWithRedirect = useProjectUpsert(
    getValues,
    setAlertSeverity,
    setAlertMessage,
    openAlert,
    projectType,
    existingProject,
    initialPhases,
    initialWorkPhases,
    true
  );
  const watchName = watch("projectName");
  const watchClientId = watch("clientId");
  const watchPhases = watch("phases");
  const watchWorkPhases = watch("workPhases");

  const hasChanged =
    watchName !== existingProject?.name ||
    watchClientId !== existingProject?.client?.id ||
    JSON.stringify(initialPhases) !== JSON.stringify(watchPhases) ||
    JSON.stringify(initialWorkPhases) !== JSON.stringify(watchWorkPhases);

  const ableToSaveProject = !!watchName?.length && hasChanged;

  return (
    <PageContainerStyled>
      <HeaderStyled>
        <H1>{pageTitle}</H1>
        {!!projectType && <TypeChipStyled>{projectType.label}</TypeChipStyled>}
      </HeaderStyled>
      <BaseProjectInfo
        control={control}
        errors={errors}
        onAddNewClientClick={onAddNewClientClick}
      />
      <Expenses
        initialPhases={initialPhases}
        initialWorkPhases={initialWorkPhases}
        type={projectType?.value as PROJECT_TYPE}
        control={control}
        setValue={setValue}
        watch={watch}
        projectId={existingProject?.id}
        handleSave={handleSave}
        onAddNewTeammateClick={onAddNewTeammateClick}
        ableToSaveProject={ableToSaveProject}
      />
      <FooterStyled>
        {!limitedAccess && (
          <Button
            onClick={handleSaveWithRedirect}
            disabled={!ableToSaveProject}
            variant="contained"
            className="toolbar-btns create-btn"
          >
            {buttonLabel}
          </Button>
        )}
        <Button
          onClick={navigateToProjects}
          variant="textIcon"
          className="toolbar-btns create-btn"
        >
          {limitedAccess ? t("back") : t("cancel")}
        </Button>
      </FooterStyled>
      {isDesktop && <Footer isSpace isSticki={false} />}
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={DEFAULT_ALERT_HIDE_DURATION}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity={alertSeverity}>{alertMessage}</Alert>
      </Snackbar>
      <AddEditMemberModal
        isOpen={isAddEmployeeOpen}
        onClose={toggleAddEmployeeOpen}
        selectedType={selectedType}
        callback={handleSave}
      />
    </PageContainerStyled>
  );
};

export default AddEditProject;
