import { styled } from "@mui/material/styles";
import { Box, Breakpoint, Typography } from "@mui/material";
import { Drawer } from "components/SideBar/styles";
import { palette } from "styles/theme/palette";
import { PAGE_CONTENT_WIDTH } from "styles/theme/constants";
import { Title, Button } from "pages/Teams/AddEmployeeModal/styles";
import { InputStyled } from "components/Freelancer/Projects/AddEdit/Expenses/styles";

export const PageContainerStyled = styled(Box)(({ theme }) => ({
  padding: "30px 0",
  background: palette?.custom?.grey?.darkLite,
  maxWidth: "100%",
  overflow: "hidden",
  [theme.breakpoints.down("md" as Breakpoint)]: {
    padding: "30px 15px",
  },
}));

export const IconContainerStyled = styled(Box)(() => ({
  position: "absolute",
  right: "25px",
  top: "25px",
  cursor: "pointer",
  zIndex: 100,
}));

export const ContainerStyled = styled(Box)(() => ({
  display: "flex",
  background: palette.custom.grey.background,
  minHeight: "100vh",
}));

export const DrawerStyled = styled(Drawer)(() => ({
  "& > .MuiDrawer-paper": {
    background: palette.custom.black.light,
    maxWidth: "220px",
  },
}));

export const SaleButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  maxWidth: PAGE_CONTENT_WIDTH,
  padding: "20px 15px 20px",
  zIndex: 1,
  [theme.breakpoints.down("md" as Breakpoint)]: {
    padding: "0 15px 15px",
    justifyContent: "flex-start",
    ".MuiButton-root": {
      height: "40px",
    },
  },
}));

export const ImageStyled = styled("img")(() => ({
  height: "40px",
}));

export const ModalContainerStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
}));

export const ModalTitleStyled = styled(Title)(() => ({
  marginTop: "20px",
}));

export const ModalSubtitleStyled = styled(Typography)(() => ({
  marginBottom: "40px",
  lineHeight: "20px",
  textAlign: "center",
  color: palette?.custom.grey.main,
}));

export const ModalOldPriceStyled = styled(Typography)(() => ({
  textDecoration: "line-through",
  fontWeight: "bold",
}));

export const ModalNewPriceStyled = styled(Typography)(() => ({
  color: palette?.custom.grey.main,
}));

export const ModalInputStyled = styled(InputStyled)(() => ({
  width: "100%",
  marginTop: "20px",
  ".MuiFormControl-root": {
    margin: 0,
  },
}));

export const ModalButtonStyled = styled(Button)(() => ({
  width: "100%",
  textTransform: "uppercase",
  fontWeight: "bold",
}));
