import { getDefaultDateValues } from "utils/freelancer/dashboard/getDefaultDateValues";
import { useCallback, useState } from "react";

interface DateRangeInterval {
  startDate: Date | null;
  endDate: Date | null;
  updateStartDate: (newDate: Date) => void;
  updateEndDate: (newDate: Date) => void;
}
export const useDateRangeInterval = (): DateRangeInterval => {
  const { defaultStartDate, defaultEndDate } = getDefaultDateValues();
  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);

  const updateStartDate = useCallback((newDate: Date) => {
    setStartDate(newDate);
    localStorage.setItem("periodStartDate", JSON.stringify(newDate));
  }, []);
  const updateEndDate = useCallback((newDate: Date) => {
    setEndDate(newDate);
    localStorage.setItem("periodEndDate", JSON.stringify(newDate));
  }, []);

  return { startDate, endDate, updateStartDate, updateEndDate };
};
