import { type FC } from "react";
import { Box, Typography } from "@mui/material";
import { useDrawer } from "hooks/useDrawer";
import { Arrow } from "pages/ProductsAndServices/ProductsTable/styles";
import { PROJECT_TYPE } from "utils/freelancer/constants";
import { useTranslation } from "react-i18next";
import {
  ArrowContainerStyled,
  DateContainerStyled,
  DatePickerContainerStyled,
  HeaderStyled,
  LabelStyled,
  TextareaAutosizeStyled,
  CollapseStyled,
  TitleContainerStyled,
  IconContainerStyled,
  OverlayStyled,
} from "./styles";
import { Label } from "components/UI/Input/styles";
import { palette } from "styles/theme/palette";
import { ContainerInput, Input } from "components/UI/DatePicker/styles";
import {
  type Control,
  Controller,
  type FieldArrayWithId,
  useFieldArray,
  type UseFormWatch,
} from "react-hook-form";
import { type UseFormSetValue } from "react-hook-form/dist/types/form";
import {
  type ProjectCreateForm,
  type ProjectPhaseCreate,
} from "interfaces/freelancer/project";
import AdditionalExpenses from "./AdditionalExpenses";
import WorkEmployees from "./Employees/workEmployees";
import WorkServices from "./Services/WorkServices";
import { useTrialUser } from "hooks/useTrialUser";

interface PhaseProps {
  index: number;
  type: PROJECT_TYPE;
  control: Control<ProjectCreateForm>;
  setValue: UseFormSetValue<ProjectCreateForm>;
  field: FieldArrayWithId<ProjectCreateForm, "workPhases">;
  initialPhase?: ProjectPhaseCreate;
  watch: UseFormWatch<ProjectCreateForm>;
  projectId: string;
  handleSave?: () => Promise<void>;
}

const WorkPhase: FC<PhaseProps> = ({
  index,
  type,
  control,
  setValue,
  field,
  initialPhase = undefined,
  watch,
  projectId,
  handleSave,
}) => {
  const { t } = useTranslation();
  const { limitedAccess } = useTrialUser();
  const { toggle, isOpen } = useDrawer(true);
  const { append: appendRemovedExpense } = useFieldArray({
    control,
    name: "removedExpenses",
  });

  return (
    <CollapseStyled collapsedSize={50} in={isOpen}>
      <HeaderStyled onClick={toggle}>
        <TitleContainerStyled>
          <Typography variant="body1" color={palette.custom.green.dark}>
            {field.name}
          </Typography>
        </TitleContainerStyled>
        <ArrowContainerStyled>
          <IconContainerStyled>
            <Arrow $isOpen={isOpen} />
          </IconContainerStyled>
        </ArrowContainerStyled>
      </HeaderStyled>
      {limitedAccess && <OverlayStyled />}
      <Box>
        <LabelStyled>
          {t("freelancer.page.addEditProject.description.label")}
        </LabelStyled>
        <Controller
          name={`workPhases.${index}.description`}
          control={control}
          render={({ field }) => (
            <TextareaAutosizeStyled
              minRows={4}
              placeholder={t(
                "freelancer.page.addEditProject.description.placeholder"
              )}
              {...field}
            />
          )}
        />
      </Box>
      {type === PROJECT_TYPE.FIX && (
        <WorkServices parentIndex={index} control={control} />
      )}
      <WorkEmployees
        parentIndex={index}
        control={control}
        initialEmployees={initialPhase?.expenses.employees}
        initialPhaseId={initialPhase?.id}
        phase={Object.assign({}, field, { group_id: field.groupId })}
        projectId={projectId}
        clockifyIntegrationConfig={initialPhase?.clockify}
        watch={watch}
        setValue={setValue}
        fixTypeProject={type === PROJECT_TYPE.FIX}
        handleSave={handleSave}
      />
      <AdditionalExpenses
        phasesName="workPhases"
        parentIndex={index}
        control={control}
        onExpenseRemove={appendRemovedExpense}
      />
      <DateContainerStyled>
        <DatePickerContainerStyled>
          <Label>{t("startDate")}</Label>
          <ContainerInput>
            <Controller
              name={`workPhases.${index}.startDate`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input selected={value} onChange={onChange} />
              )}
            />
          </ContainerInput>
        </DatePickerContainerStyled>
        <DatePickerContainerStyled>
          <Label>{t("endDate")}</Label>
          <ContainerInput>
            <Controller
              name={`workPhases.${index}.endDate`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input selected={value} onChange={onChange} />
              )}
            />
          </ContainerInput>
        </DatePickerContainerStyled>
      </DateContainerStyled>
    </CollapseStyled>
  );
};

export default WorkPhase;
